<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let pocFor">{{ pocFor.type }}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let pocFor">
      <span *ngIf="pocFor.isRequestPending; else nameLink">{{ pocFor.name }}</span>
      <ng-template #nameLink>
        <a [routerLink]="route(pocFor)">{{ pocFor.name }}</a>
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let pocFor" class="status-column">
      <span
        class="status-box"
        [ngClass]="getStatusClass(pocFor)"
        attr.data-status-abbreviation="{{ getStatusDisplay(pocFor) | firstLetter }}"
      ></span>
    </td>
  </ng-container>
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef>License<br />Number</th>
    <td mat-cell *matCellDef="let pocFor">
      <ng-container *ngIf="pocFor.hasLicense">
        {{ pocFor.license.number }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="expirationDate">
    <th mat-header-cell *matHeaderCellDef>Expiration</th>
    <td mat-cell *matCellDef="let pocFor">
      <ng-container *ngIf="pocFor.hasLicense">
        {{ pocFor.license.expirationDate | date: 'shortDate' }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="requestStatus">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let pocFor">
      <ng-container *ngIf="pocFor.hasRequest">
        {{ pocFor.displayableRequestStatus.status }}
        <br />
        {{ pocFor.displayableRequestStatus.date | date: 'shortDate' }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef [attr.colspan]="columns.length">No Access</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let pocFor; columns: columns" [ngClass]="{ pending: pocFor.isRequestPending }"></tr>
</table>
