import { spaceCapCase } from '../../utility/string-utils';
import { JurisdictionArgsType, StateCodes } from './jurisdiction-constants';
import { Profession } from './profession';

export class TenantSettingsJson {
  allContinuingEducationExemptionOptions: string[];
  lookupItemsMax: number;
  enableEasyTesting: boolean;
  tenant: string | JurisdictionArgsType;
  hasProfessionalReferences: boolean;
  stateName: string;
  stateCode: string | StateCodes;
  boardAbbreviation: string;
  doesLicenseFirms: boolean;
  hasDemographic: boolean;
  hasArchPocs: boolean;
  hasContinuingEducation: boolean;
  hasLawfulPresence: boolean;
  hasWorkHistory: boolean;
  hasMilitaryNumber: boolean;
  professions: (string | Profession)[]; //when trying to cast a JSON representation in storybook to TenantSettings, it doesn't like this type being "Profession". "string" added only for Storybook and JSON.
  requireReinstatementTransmittal: boolean;
  requiresEligibilityForInitialLandscapeArchitects: boolean;
  personRenewals: RenewalSettingsJson;
  firmRenewals: RenewalSettingsJson;
  personPointOfContactRequestWindowLengthInDays?: number;
  sendFirmNotRegisteredEmail: boolean;
  chargesProcessingFee: boolean;
  timeZone: string;
  contactUsUrl: string;
  supportEmail: string;
  supportPhoneNumber: string;
  loginSupportContact: string;
  hasEmeritus: boolean;
  allowEmeritusSelfSelect: boolean;
  emeritusRenews: boolean;
  emeritusReinstatable: boolean;
  firmPrefix: string;
  hasPersonalIdentification: boolean;
  hasStateDocuments: boolean;
  hasFirmDirectors: boolean;
  showApplicationFooter: boolean;
  showSocialSecurityNumberAlternative: string | SocialSecurityNumberAlternativeType; //when trying to cast a JSON representation in storybook to TenantSettings, it doesn't like this type being "SocialSecurityNumberAlternativeType". "string" added only for Storybook and JSON.
  hideApplicationListOnFirmProfile: boolean;
  downloadableLicense: string | DownloadableLicenseType;
  downloadableFirmLicense: string | DownloadableLicenseType;
  lastNameChangeCanUpdateExpirationDate: boolean;
  validFirmTypes?: ValidFirmType[];
  architectProfileSettings: ArchitectProfileSettings;
  firmProfileSettings: FirmProfileSettings;
  reportSettings: ReportSettings;
  architectLicenseCertificateVariants: LicenseCertificateVariant[];

  paymentSettings: {
    allowAchPayment: boolean;
    ignoreAchPaymentProcess: boolean;
    allowCheckPayment: string | AllowCheckPaymentType;
  };
  boardName: string;
  licenseStatuses: Record<string, LicenseStatus>;

  supervisingProfessionalsSettings: SupervisingProfessionalsSettings;
}
//if you need status from here try Object.entries(settings.licenseStatuses).map(([statusText:string, value: LicenseStatus])=>{})
interface LicenseStatus {
  isUsedWithFirms: boolean;
  label: string;
  customStyleName: string;
  orderingIndex: number;
}

type bannerText = '' | 'staging' | 'dev' | 'local';
function getBannerText(): bannerText {
  const domain = window.location.origin;
  if (domain.includes('al.ncarb.dev')) {
    return '';
  }
  if (domain.includes('.ncarb.dev')) {
    return domain.includes('staging') ? 'staging' : 'dev';
  }
  if (domain.includes('localhost')) {
    return 'local';
  }
  return '';
}

export class TenantSettings extends TenantSettingsJson {
  mailToSupportEmail: string;
  welcome: string;
  bannerText: bannerText;
  showSocialSecurityNumberAlternative: SocialSecurityNumberAlternativeType;
  tenant: JurisdictionArgsType;
  stateCode: StateCodes;
  professions: Profession[];
  systemStatusLabels: string[];
  personRenewals: RenewalSettings;
  firmRenewals: RenewalSettings;

  constructor(
    init?: Partial<TenantSettingsJson>,
    public source: 'portal' | 'staff' = 'portal'
  ) {
    super();
    Object.assign(this, init);
    this.mailToSupportEmail = `mailto:${this.supportEmail}`;
    if (!this.allContinuingEducationExemptionOptions) this.allContinuingEducationExemptionOptions = [];
    if (!this.validFirmTypes) this.validFirmTypes = [];
    this.welcome = `Welcome to the ${this.boardAbbreviation} Licensing Portal`;
    this.bannerText = getBannerText();
    this.systemStatusLabels = ['Unlicensed', 'New', 'Open', 'Hold', 'Submitted', 'Pending', 'Closed', 'Approved'];
  }

  getStatusLabel(status: string): string {
    if (!status) return '';

    let licenseStatus = this.licenseStatuses[status];

    if (!licenseStatus) {
      // placed here to allow overriding by the mapping
      if (this.systemStatusLabels.includes(status)) return status;

      console.error(`Status "${status}" missing from mapping`);
      return spaceCapCase(status);
    }

    //licenseStatuses[status].label should always be filled in
    return licenseStatus.label ?? spaceCapCase(status);
  }

  getCustomStatusCssClass(status: string, prepend?: string, defaultTo?: string): string {
    const style = (this.licenseStatuses[status]?.customStyleName ?? status ?? defaultTo).toLowerCase();
    return !prepend ? style : prepend + style;
  }

  hasFirmTypes(): boolean {
    return this.validFirmTypes.length > 0;
  }

  isLicenseStatusEnabled(status: string): boolean {
    return !!this.licenseStatuses[status];
  }
}

export interface ValidFirmType {
  id: string;
  shortName: string;
  name: string;
  allowCorporateTitle: boolean;
}

export type SocialSecurityNumberAlternativeType = 'None' | 'VisaNumber' | 'UscisNumber';
export type AllowCheckPaymentType = 'Yes' | 'StaffOnly' | 'No';
export type DownloadableLicenseType = 'Yes' | 'StaffOnly' | 'No';

export interface ArchitectProfileSettings {
  allowUserEditingOfEmailAddress: boolean;
  alwaysOverrideCounty: boolean;
  hideCounty: boolean;
  hideHonorific: boolean;
  hideCredentials: boolean;
  hideDateOfBirth: boolean;
  hideGender: boolean;
  hideSecondaryPhoneNumber: boolean;
  hideHomeAddress: boolean;
  workAddressCardTitle: string;
  homeAddressFirstLineLabel: string;
  homeAddressSecondLineLabel: string;
  workAddressFirstLineLabel: string;
  workAddressSecondLineLabel: string;
  requireZipPlus4: boolean;
  zipCodeLabel: string;
}

class FirmProfileSettings {
  addressFirstLineLabel: string;
  addressSecondLineLabel: string;
  alwaysOverrideCounty: boolean;
  hideCounty: boolean;
  requireZipPlus4: boolean;
  businessInfoContent: FirmProfileBusinessInfoContent;
  zipCodeLabel: string;
}

class FirmProfileBusinessInfoContent {
  pointOfContactDescription: string;
}

export class LicenseCertificateVariant {
  name: string;
  fileType: string;
  isDefault: boolean;
}

export interface ContinuingEducationStepSettings {
  affidavitText: string;
  educationCompletedText: string;
  headerSubtext: string;
  headerSubtextContent: string;

  //if falsy, can't pick 'I haven't completed education'
  exemptionConfiguration?: ExemptionConfiguration;

  //if falsy, documentation not required if education completed
  completedEducationDocumentation?: CompletedEducationDocumentation;
}
export interface CompletedEducationDocumentation {
  proofText: string;
  isUnderAudit: boolean;
  auditText: string;
  headerDocSubtext?: string;
  headerDocSubtextContent?: string;
}
export interface ExemptionConfiguration {
  reasons: string[];
  reasonsNoDocsRequired: string[];
  educationNotCompletedText: string;
  requireDocumentation: boolean;
  proofExceptionText: string;
  exemptionSubtext?: string;
  exemptionSubtextContent?: string;
}

export interface PersonalStepSettings {
  allowSocialSecurityNumberAlternative: boolean;
  allowUserEditingOfEmailAddress: boolean;
  stepLabel: string; //label above work address. Defaults to 'Work Details'
  hideCounty: boolean;
  hideHonorific: boolean;
  hideCredentials: boolean;
  hideDateOfBirth: boolean;
  hideGender: boolean;
  hideSecondaryPhoneNumber: boolean;
  hideHomeAddress: boolean;
  homeAddressFirstLineLabel: string;
  homeAddressSecondLineLabel: string;
  workAddressFirstLineLabel: string;
  workAddressSecondLineLabel: string;
}

export interface FirmStepSettings {
  addressFirstLineLabel: string;
  addressSecondLineLabel: string;
  hideCounty: boolean;
}

export interface LawfulPresenceSettings {
  isCitizenText: string;
  isNonCitizenText: string;
  citizenNumberDocs: number;
  nonCitizenNumberDocs: number;
}

interface ReportSettings {
  excludedFields?: string[];
  licenseNumberIsText?: boolean;
}

interface RenewalSettingsJson {
  renewalWindowClosingMonth: number;
  delinquencyWindowInMonths: number;
  renewalWindowInMonths: number;
  periodType: string | 'EveryYear' | 'OddYear' | 'EvenYear' | 'EveryOtherYear'; //TS barfs when pulling this from json file
}

interface RenewalSettings extends RenewalSettingsJson {
  periodType: 'EveryYear' | 'OddYear' | 'EvenYear' | 'EveryOtherYear'; //remove string
}

export interface SupervisingProfessionalsSettings {
  showDocumentUploadOnPortalEdit: boolean;
  requireDocumentUploadOnPortalEdit: boolean;
  documentUploadOnPortalEditHeaderText: string;
  documentUploadOnPortalEditText: string;
}
