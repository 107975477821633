import { AuthTokenService, Tokens } from './auth-token-service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRefreshService {
  private refreshTimer;
  private refreshTabId = Math.ceil(Math.random() * 10000000);
  private authService: AuthService;

  constructor(private tokenService: AuthTokenService) {
    window.addEventListener('storage', () => {
      this.randomDelayedSetRefreshTimeoutIfApplicable();
    });

    window.addEventListener('unload', () => {
      if (this.isThisTabResponsibleForTheRefresh) {
        this.tokenService.unset(Tokens.refreshTabId);
      }
    });
  }

  init(authService: AuthService) {
    this.authService = authService;
    if (authService.isAuthenticated()) {
      this.randomDelayedSetRefreshTimeoutIfApplicable();
    }
  }

  setRefreshTimeout() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    let expiresAt = +this.tokenService.get(Tokens.expires);
    let twoMinutesBeforeExpiration = expiresAt - new Date().getTime() - 2 * 60 * 1000;
    // twoMinutesBeforeExpiration = 30 * 1000 // for testing
    // console.log('renewing in', new Date(expiresAt), twoMinutesBeforeExpiration)
    this.refreshTimer = setTimeout(() => this.authService.renewToken(true), twoMinutesBeforeExpiration);
    this.tokenService.set(Tokens.refreshTabId, +this.refreshTabId);
  }

  protected get isThisTabResponsibleForTheRefresh(): boolean {
    return this.refreshTabId == +this.tokenService.get(Tokens.refreshTabId);
  }

  protected randomDelayedSetRefreshTimeoutIfApplicable() {
    // randomizing prevents other tabs from setting a refresh at the same time
    let fiveUpToTenSeconds = 5 * 1000 + Math.random() * 5 * 1000;
    setTimeout(() => this.trySetRefreshTimeoutIfApplicable(), fiveUpToTenSeconds);
  }

  protected trySetRefreshTimeoutIfApplicable() {
    // console.log('take refresh?')
    if (!this.tokenService.get(Tokens.refreshTabId) && this.authService.isAuthenticated()) {
      this.setRefreshTimeout();
    }
  }
}
