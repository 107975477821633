import { OnInit, Input, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BrowserDetect } from '../constants/browser-detect';

const webkitPasswordType = 'webkit_password';
const passwordType = 'password';

class SecretsParams {
  pattern: string;
  mask: { mask: (string | RegExp)[] | ((string) => RegExp[]) };
}

@Injectable()
export abstract class EditSecretComponent implements OnInit {
  //add @Component({  inputs: EditSecretComponent.Inputs })
  //to any extending classes to make production build happy
  public static readonly Inputs = ['control', 'notRequired', 'readonly'];

  //@HostBinding('class') here doesn't work on site. Does work in storybook though
  get getClass() {
    return 'mat-form-field';
  }

  @Input() control: FormControl;
  @Input() notRequired: boolean;
  @Input() readonly: boolean;
  pattern: string;
  mask: { mask: (string | RegExp)[] | ((string) => RegExp[]) };
  passwordType: string;
  type: string;
  constructor({ pattern, mask }: SecretsParams) {
    this.pattern = pattern;
    this.mask = mask;
    this.passwordType = BrowserDetect.webkit ? webkitPasswordType : passwordType;
    this.type = this.passwordType;
  }
  ngOnInit() {
    if (!this.control.value) {
      this.type = 'text';
    }
  }
}
