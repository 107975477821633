import { AuthConfig } from 'angular-oauth2-oidc';

export class NcarbSettings {
  constructor(json: any) {
    if (json.url.endsWith('/')) {
      this.url = json.url;
    } else {
      this.url = `${json.url}/`;
    }
    this.publicApi = json.publicApi;
    this.auth = {
      loginUrl: `${this.url}Login/issue/oauth2/authorize`,
      redirectUri: `${window.location.origin}/ncarb`,
      clientId: json.clientId,
      scope: 'urn:ncarb:api',
      userinfoEndpoint: `${this.url}public/api/identity`,
      logoutUrl: `${this.url}Home/Account/Logout`,
      oidc: false,
    };
  }
  url: string;
  publicApi: string;
  auth: AuthConfig;
}
