import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PersonalInfo } from 'projects/common/src/lib/models/personal-info';
import { PersonalInfoService } from '../services/personal-info.service';

@Injectable()
export class PersonalInfoResolve implements Resolve<PersonalInfo> {
  constructor(private personalInfoService: PersonalInfoService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<PersonalInfo> {
    return this.personalInfoService.get(route.params.personId);
  }
}
