import { Input, Output, EventEmitter, Directive, TemplateRef, ContentChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogFactoryService } from './dialog-factory-service';
import { DialogComponent } from './dialog.component';
import { firstValueFrom } from 'rxjs';

@Directive({
  selector: 'lib-dialog, [lib-dialog]',
  exportAs: 'dialog',
})
export class DialogDirective {
  @Input() yesText: string;
  @Input() noText: string = 'Cancel';
  @Input() header: string;
  @Input() width: number;
  @Input() isOkayWarnColor: boolean;
  @Input() minHeightPx?: number;
  //Whether the user can use escape or clicking on the backdrop to close the modal
  @Input() disableClose: boolean;
  @Input() formGroup: FormGroup;
  @Input() template: TemplateRef<any>;
  @Output() yes = new EventEmitter<boolean>();
  @Output() no = new EventEmitter<boolean>();

  @ContentChild(TemplateRef) inlineTemplate: TemplateRef<any>;

  constructor(private dialogFactoryService: DialogFactoryService) {}

  //called from HTML ex. (click)="infoModal.open()"
  open(): Promise<boolean> {
    const dialogRef = this.dialogFactoryService.open(
      this.inlineTemplate || this.template,
      {
        yesText: this.yesText,
        noText: this.noText,
        header: this.header,
        formGroup: this.formGroup,
        isOkayWarnColor: this.isOkayWarnColor,
        minHeightPx: this.minHeightPx,
      },
      {
        disableClose: this.disableClose,
        width: this.width,
      }
    );

    return firstValueFrom(dialogRef.afterClosed()).then(result => {
      if (result) {
        this.yes.emit();
      } else {
        this.no.emit();
      }
      return result;
    });
  }
}
