import { FormBuilder, FormGroup } from '@angular/forms';
import { ArchitectApplication } from '../../../models/applications/architect-application';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import { JurisdictionConstants } from '../jurisdiction-constants';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { Injectable } from '@angular/core';
import { TenantSettings } from '../TenantSettings';
export type Confirmations = {
  laws: string[];
  attestations: string[];
};

export type LegendWithNote = {
  legend: string;
  note: string;
};

export type MoralCharacterApplicationLabels = {
  title: string;
  subtitle: string;
};

export type CustomLabels = {
  moralCharacterApplication: MoralCharacterApplicationLabels;
};

@Injectable({
  providedIn: 'root',
})
export abstract class JurisdictionConfirmationService {
  formBuilder: FormBuilder;
  constants: JurisdictionConstants;
  constructor(
    formBuilder: FormBuilder,
    constants: JurisdictionConstants,
    protected settings: TenantSettings
  ) {
    this.formBuilder = formBuilder;
    this.constants = constants;
  }
  abstract getArchitectConfirmations(application: ArchitectApplication): Confirmations;
  abstract getFirmConfirmations(application: FirmApplicationFull): Confirmations;
  abstract getShareHolderNote(): string;
  abstract getBoardOfDirectorsNote(): string;
  abstract getSupervisorNotes(application: FirmApplication): LegendWithNote[];

  getArchitectForm(application: ArchitectApplication, value: boolean) {
    const confirmations = this.getArchitectConfirmations(application);
    return this.getForm(confirmations, value);
  }

  getCustomLabels(): CustomLabels {
    return {
      moralCharacterApplication: {
        title: 'Disclosure Questions',
        subtitle:
          this.constants.applicationStepContent.disclosureQuestions?.subtitle ??
          'Please answer the following questions truthfully. If yes, please provide an explanation.',
      },
    };
  }

  getFirmForm(application: FirmApplicationFull, value: boolean) {
    const confirmations = this.getFirmConfirmations(application);
    return this.getForm(confirmations, value);
  }

  private getForm(confirmations: Confirmations, value: boolean): FormGroup {
    const laws = this.formBuilder.array([]);
    for (var i = 0; i < confirmations.laws.length; i++) {
      laws.push(this.formBuilder.control(value));
    }
    const attestations = this.formBuilder.array([]);
    for (var i = 0; i < confirmations.attestations.length; i++) {
      attestations.push(this.formBuilder.control(value));
    }
    return this.formBuilder.group({
      laws,
      attestations,
    });
  }
}
