import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  template: 'You are currently logged out. Please login to access this area of the application. <a href="/landing">Login</a>',
  styles: [],
})
export class UnauthorizedComponent {
  constructor() {}
}
