import { AfterViewInit, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent implements AfterViewInit {
  @HostBinding('class.mr-xs') marginRightClass = true;
  @HostBinding('class.mb-xs') marginBottomClass = true;
  @Input() filterName: string;
  @Input() menu: MatMenu;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @Output() onTriggerSet = new EventEmitter<MatMenuTrigger>();

  ngAfterViewInit(): void {
    this.setTrigger();
  }

  setTrigger() {
    if (!this.menuTrigger) {
      setTimeout(() => this.setTrigger(), 500);
      return;
    }
    this.onTriggerSet.next(this.menuTrigger);
  }
}
