import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressLookups, CountryIds } from '../../models/address';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
@Component({
  selector: 'lib-read-address-new',
  templateUrl: './read-address-new.component.html',
  styleUrls: ['./read-address-new.component.scss'],
})
export class ReadAddressNewComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() line1Label: string;
  @Input() line2Label: string;
  @Input() isNarrow: boolean = false;
  @Input() addressLookups: AddressLookups;
  @Input() hideCounty: boolean = false;
  @Input() hideValidation: boolean = false;
  showState = true;
  showProvince = false;
  showRegion = false;
  firstLine: string;
  secondLine: string;
  constructor(public settings: TenantSettings) {}

  ngOnInit(): void {
    this.setZipCodeForCountry(this.formGroup.controls.countryId.value);
    this.firstLine = this.line1Label ?? 'First Line';
    this.secondLine = this.line2Label ?? 'Second Line';
  }

  displayState(stateId: number) {
    if (!this.addressLookups) {
      return stateId;
    }
    var state = this.addressLookups.states.find(s => s.id == stateId);
    if (state) {
      return state.code;
    }
    return this.addressLookups.provinces.find(s => s.id == stateId)?.code;
  }

  displayCountry(countryId: number) {
    if (!this.addressLookups) {
      return countryId;
    }
    var country = this.addressLookups.countries.find(s => s.id == countryId);
    return country?.name;
  }

  setZipCodeForCountry(countryId: number) {
    if (countryId == CountryIds.USA) {
      this.showState = true;
      this.showProvince = false;
      this.showRegion = false;
      return;
    }
    this.showState = false;
    if (countryId == CountryIds.CAN) {
      this.showProvince = true;
      this.showRegion = false;
    } else {
      this.showRegion = true;
      this.showProvince = false;
    }
  }
}
