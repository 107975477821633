import { Component, Input, ViewChild } from '@angular/core';
import { UploadWidgetDialog } from '../upload-widget/upload-widget.dialog';
import { NotificationService } from '../../services/notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { S3Service } from '../../services/api/s3.service';
import { UploadListenerComponent } from '../upload-widget/upload-listener.component';

@Component({
  selector: 'lib-upload-button-with-file-list',
  templateUrl: './upload-button-with-file-list.component.html',
  styleUrls: ['./upload-button-with-file-list.component.scss'],
})
export class UploadButtonWithFileListComponent extends UploadListenerComponent {
  @ViewChild('uploads') uploadElement;
  @Input() label: string;
  @Input() labelNoDocuments: string;
  @Input() labelWithDocuments: string;
  @Input() title: string;

  dialogRef: MatDialogRef<UploadWidgetDialog>;

  constructor(
    private dialogue: MatDialog,
    protected s3Service: S3Service,
    protected notificationService: NotificationService
  ) {
    super(s3Service, notificationService);
  }

  getLabelUploadText(docs: any[]): string {
    const labelWithDoc = this.labelWithDocuments || this.label || 'View/Edit Documents';
    const labelNoDoc = this.labelNoDocuments || this.label || 'Upload a Document';
    return docs && docs.length > 0 ? labelWithDoc : labelNoDoc;
  }

  open() {
    //pass data and functionality to dialog file window.
    this.dialogRef = this.dialogue.open(UploadWidgetDialog, {
      data: {
        title: this.title,
        deleteDoc: doc => this.delete(doc),
        docSearch: this.docSearch,
        docsList$: this.docsList$,
      },
    });
  }
}
