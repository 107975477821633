import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base.component';
import { PersonPointOfContact } from '../../models/person-point-of-contact';
import { PointOfContactFor } from '../../models/point-of-contact-for';
import { JurisdictionConstants } from '../../constants/jurisdiction/jurisdiction-constants';
@Component({
  selector: 'lib-point-of-contact-card',
  templateUrl: './point-of-contact-card.component.html',
  styleUrls: ['./point-of-contact-card.component.scss'],
})
export class PointOfContactCardComponent extends BaseComponent implements OnInit {
  @Input() pointOfContacts$: Observable<PersonPointOfContact[]>;
  @Input() pointOfContactForList$: Observable<PointOfContactFor[]>;
  @Input() canHavePOC: boolean;
  @Input() isStaffView;
  terms = {};
  portalTerms = {
    Your: 'Your',
    your: 'your',
    Grant: 'Grant',
  };
  staffTerms = {
    Your: 'This',
    your: 'this',
  };
  hasPointOfContact: boolean;
  isPointOfContact: boolean;
  assignRoute = [];

  constructor(public constants: JurisdictionConstants) {
    super();
  }

  ngOnInit() {
    this.terms = this.isStaffView ? this.staffTerms : this.portalTerms;
    this.assignRoute = ['archpointofcontact', 'assign'];
    if (this.isStaffView) {
      this.assignRoute = ['..', 'archpointofcontact', 'assign'];
    }
    // If there is a POC, then show that person in a table.
    // If there is not a POC, then show the grant access CTA.
    this.unsubOnDestroy = this.pointOfContacts$.subscribe(pocs => {
      this.hasPointOfContact = pocs.length > 0;
    });

    this.unsubOnDestroy = this.pointOfContactForList$.subscribe(list => {
      this.isPointOfContact = list.length > 0;
    });
  }

  revoke() {
    this.hasPointOfContact = false;
  }
}
