import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { WorkHistory } from 'projects/common/src/lib/models/work-history';
import { FirmRoleHolder } from 'projects/common/src/lib/models/firm-role-holder';

@Injectable({
  providedIn: 'root',
})
export class WorkHistoryService {
  constructor(private api: ApiService) {}

  getPath(personId, endpoint) {
    return 'person/'.concat(personId, '/', endpoint);
  }

  get(personId: string): Observable<WorkHistory[]> {
    let path = this.getPath(personId, 'workHistory');
    return this.api.get(path).pipe(map(data => data.map(w => new WorkHistory(w))));
  }

  add(personId, workHistory: WorkHistory, dryRun = false): Promise<WorkHistory> {
    let path = this.getPath(personId, 'workHistory');
    if (dryRun) {
      path += '?dryRun=true';
    }
    return this.api.postAsync(path, workHistory).then(data => new WorkHistory(data));
  }

  update(personId, workHistory: WorkHistory, dryRun = false): Promise<WorkHistory> {
    let path = this.getPath(personId, 'workHistory');
    if (workHistory.firm.status == 'Unlicensed') {
      // model binding bug fix
      delete workHistory.firm.licenseStatus;
      delete workHistory.firm.licenseType;
    }
    return this.api.putAsync(path, workHistory).then(data => new WorkHistory(data));
  }

  delete(personId, workHistory: WorkHistory): Observable<boolean> {
    let path = this.getPath(personId, 'workHistory').concat('/', workHistory.id);
    return this.api.delete(path).pipe(map(data => true));
  }

  setupCanManage(personId): Observable<FirmRoleHolder> {
    let path = this.getPath(personId, 'workHistory/setupCanManage');
    return this.api.put(path).pipe(map(data => new FirmRoleHolder(data)));
  }
}
