import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { InjectorModule } from '../modules/injector.module';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private snackBar: MatSnackBar;
  private snackBarConfig: MatSnackBarConfig = new MatSnackBarConfig();
  lastNotification: MatSnackBarRef<any>;

  constructor() {
    this.snackBar = InjectorModule.injector.get(MatSnackBar);
    this.snackBarConfig.verticalPosition = 'top';
    this.snackBarConfig.horizontalPosition = 'right';
    this.snackBarConfig.duration = 5000;
    this.snackBarConfig.panelClass = 'mat-panel-class';
  }

  notify(message: string) {
    this.lastNotification = this.snackBar.open(message, null, this.snackBarConfig);
  }

  notifyWait(message: string) {
    this.notify(message + '... ⏳');
  }

  notifyFormIssue(message: string) {
    let tenSecondConfig = Object.assign({}, this.snackBarConfig);
    tenSecondConfig.duration = 10000;
    this.lastNotification = this.snackBar.open(message + ' ❌', null, tenSecondConfig);
  }

  notifySuccess(message: string) {
    this.notify(message + ' 👍');
  }

  notifyFailTransient(message: string) {
    this.notify(message + ' 😯');
  }

  notifyFail(message: string) {
    this.notifyWithDismiss(message + ' 😯');
  }

  notifyWithDismiss(message: string) {
    let longConfig = Object.assign({}, this.snackBarConfig);
    longConfig.duration = 1000000;
    this.lastNotification = this.snackBar.open(message, 'DISMISS', longConfig);
  }

  closeLast(delayMillis = 0) {
    if (this.lastNotification) {
      setTimeout(() => this.lastNotification.dismiss(), delayMillis);
    }
  }
}
