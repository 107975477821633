import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  template: '',
  selector: 'app-person-detail-base',
})
export abstract class PersonDetailBaseComponent {
  isEditing: boolean;
  @Input() personProfileForm: FormGroup;
  @Output() onSave = new EventEmitter<PersonDetailBaseComponent>();
  private formSnapshot: FormGroup;

  abstract get successfulSaveMessage(): string;

  abstract get errorSaveMessage(): string;

  edit() {
    this.formSnapshot = this.personProfileForm.getRawValue();
    this.isEditing = true;
  }

  cancel() {
    this.personProfileForm.setValue(this.formSnapshot);
    this.isEditing = false;
  }

  save() {
    this.onSave.emit(this);
  }
}

export interface SaveParameters {
  form: FormGroup;
  defaultErrorMessage: string;
  successMessage: string;
  callApi: () => Promise<any>;
  profileUpdate?: {
    firmName: string;
  };
}
