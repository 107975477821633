<lib-titled-card>
  <h2>Supervising Professionals</h2>
  <mat-menu>
    <button mat-button type="button" routerLink="supervisorsEdit">Update Supervising Professionals</button>
  </mat-menu>

  <firm-supervisors-list
    [roles$]="roles$"
    [columns]="['licenseNumber', 'name', 'role']"
    [linkedName]="false"
  ></firm-supervisors-list>

  <ls-banner variant="inactive" class="mt-small mb-small" *ngIf="!!spSettings.showDocumentUploadOnPortalEdit">
    Uploaded supervising professional documentation is not shown in Licensing Portal but will be available to the Board.
  </ls-banner>
</lib-titled-card>
