<!--Can't use multiple ng-content in a component, so need to do this :-(-->
<ng-template #fieldLabel><ng-content></ng-content></ng-template>

<ng-template *ngIf="value; then hasValue; else noValue"></ng-template>

<!--
  for some reason re-setting value in the init of the parent function was breaking rendering
  probably something to do with limitation able to render fieldLabel twice?
  this goofy work around seems to work though and it the most clear.
-->
<ng-template #hasValue>
  <div class="ls-hyperlink label">
    <ng-template *ngIf="value" [ngTemplateOutlet]="fieldLabel"></ng-template>
  </div>
  <div class="value">{{ value }}</div>
</ng-template>

<ng-template #noValue>
  <div class="ls-hyperlink label hidden">&nbsp;</div>
  <div class="value no-value"><ng-template [ngTemplateOutlet]="fieldLabel"></ng-template></div>
</ng-template>
