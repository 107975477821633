<ol>
  <li>Make checks payable to <strong>LSBAE</strong></li>
  <li>Include your <strong>confirmation number</strong> on the memo line</li>
  <li>
    Mail to:
    <address>
      LSBAE
      <br />
      9625 Fenway Avenue, Suite B
      <br />
      Baton Rouge, LA 70809-1592
    </address>
  </li>
</ol>
