import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-person-detail-contact-information',
  templateUrl: './person-detail-contact-information.component.html',
  styleUrls: ['./person-detail-contact-information.component.scss'],
})
export class PersonDetailContactInformationComponent {
  constructor(public settings: TenantSettings) {}

  @Input() contactInfoForm: FormGroup;
  @Output() onSave = new EventEmitter<PersonDetailContactInformationComponent>();

  isEditing: boolean;
  private formSnapshot: FormGroup;

  edit() {
    this.formSnapshot = this.contactInfoForm.getRawValue();
    this.isEditing = true;
  }

  cancel() {
    this.contactInfoForm.setValue(this.formSnapshot);
    this.isEditing = false;
  }

  save() {
    this.onSave.emit(this);
  }

  get successfulSaveMessage(): string {
    return 'Contact information information was saved';
  }

  get errorSaveMessage(): string {
    return 'There was an issue updating contact information.';
  }

  revertEmailChange() {
    this.contactInfoForm.controls['email'].setValue(this.formSnapshot['email']);
  }
}
