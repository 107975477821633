import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'display-role-icons',
  templateUrl: './display-role-icons.component.html',
  styleUrls: ['./display-role-icons.component.scss'],
})
export class DisplayRoleIconsComponent implements OnInit {
  rolesDetails: { icon: string; tooltip: string }[];

  @Input() context: 'all' | 'poc' | 'nonPoc';
  @Input() roles: string[];

  ngOnInit(): void {
    const pocRole = 'Point of Contact';
    const displayData = {
      [pocRole]: { icon: 'record_voice_over', tooltip: 'Point of Contact' },
      'Supervising Architect': { icon: 'approval', tooltip: 'Supervising Professional' },
      Shareholder: { icon: 'gavel', tooltip: 'Shareholder' },
      Director: { icon: 'person', tooltip: 'Director' },
      Owner: { icon: 'key', tooltip: 'Sole Owner' },
    };

    const context = this.context || 'all';
    const roleFilter = {
      all: function (x: string) {
        return true;
      },
      poc: function (x: string) {
        return x === pocRole;
      },
      nonPoc: function (x: string) {
        return x !== pocRole;
      },
    }[context];

    const tooltipPrefix = context == 'all' ? '' : 'Also ';

    this.rolesDetails = (this.roles || [])
      .filter(roleFilter)
      .map(role => displayData[role])
      .filter(roleData => roleData != undefined)
      .map(function (roleData) {
        const { icon, tooltip } = roleData;
        return { icon, tooltip: tooltipPrefix + tooltip };
      });
  }
}
