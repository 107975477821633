<div [formGroup]="formGroup">
  <h3>Standards of Eligibility<span>:</span></h3>
  <p>
    By checking on the boxes below, I certify that I have completed the corresponding requirements. If I have not completed
    these requirements, I understand I must do so before applying for licensure in the state of North Dakota.
  </p>
  <ul class="vanilla multiline-checkboxes">
    <li *ngFor="let eligibility of eligibilities; let i = index">
      <mat-checkbox [formControl]="formGroup.controls.eligibilities.controls[i]" color="primary" required>
        <span [outerHTML]="eligibility"></span>
      </mat-checkbox>
    </li>
  </ul>
</div>
