<div class="notes">
  <ul *ngIf="notes$ | async as notes">
    <li *ngFor="let note of notes">
      <ng-container *ngIf="!note.edit">
        <div class="note-mod float-right" *ngIf="isUserAuthor(note)">
          <mat-icon class="muted-text" [matMenuTriggerFor]="editMenu">more_horiz</mat-icon>
          <mat-menu #editMenu="matMenu">
            <button mat-menu-item (click)="note.edit = true">Edit Comment</button>
            <button mat-menu-item (click)="confirmDelete.open()">Delete Comment</button>
          </mat-menu>
          <lib-confirm
            #confirmDelete="confirm"
            prompt="Are you sure you want to delete this comment?"
            yesText="Yes, delete it"
            noText="No, cancel"
            (yes)="delete(note)"
          >
          </lib-confirm>
        </div>
        <span class="dark-blue">{{ note.author.name.full }}</span>
        &nbsp;
        <span class="muted-text">{{ note.createdOn | dateTimeFormat }}</span>
        &nbsp;
        <span class="muted-text" *ngIf="note.edited">(Edited)</span>
        <div>
          {{ note.text }}
        </div>
      </ng-container>
      <lib-note *ngIf="note.edit" [note]="note.text" (save)="edit(note, $event)" (cancel)="note.edit = false"></lib-note>
    </li>
  </ul>
  <lib-note (save)="add($event)"></lib-note>
</div>
