import { Component, ElementRef, Input, OnChanges, Optional, Renderer2, SimpleChanges } from '@angular/core';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

export class ImagePathConfig {
  basePath: string;
}

//usage for tenant files <img lib-image image="logo-reversed.png" />
//usage for asset <img lib-image image="/credit-cards-powered-by-stripe.png" />
@Component({
  selector: 'img[lib-image]',
  template: '',
})
export class ImageLoaderComponent implements OnChanges {
  //if image starts with slash '/', will not set path to assets/{tenant}, only assets/
  @Input() image: string;

  private jurisdictionPath: string;
  private rootPath: string;
  constructor(
    settings: TenantSettings,
    private renderer: Renderer2,
    private imageRef: ElementRef<HTMLImageElement>,
    @Optional() config?: ImagePathConfig
  ) {
    //storybook can't use absolute paths when running from https://ncarb.github.io/
    this.rootPath = config?.basePath ? `${config?.basePath}` : `/assets`;
    this.jurisdictionPath = config?.basePath ? `${config?.basePath}${settings.tenant}/` : `/assets/${settings.tenant}/`;
  }
  ngOnChanges(): void {
    if (this.image) {
      //if path starts with / will not include tenant name
      const inRootDirectory = this.image.startsWith('/');
      //example 1. image="/credit-cards-powered-by-stripe.png" becomes src="/assets/credit-cards-powered-by-stripe.png"
      //example 2. image="logo.png" becomes src="/assets/DEMO/logo.png" (assuming tenant is demo)
      const imgElement: HTMLImageElement = this.imageRef.nativeElement;

      this.renderer.setAttribute(
        imgElement,
        'src',
        inRootDirectory ? `${this.rootPath}${this.image}` : `${this.jurisdictionPath}${this.image}`
      );
    }
  }
}
