import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object) {
    const keys = Object.keys(data);
    let names = [];
    keys.forEach(k => {
      if (isNaN(+k)) {
        names.push(k);
      }
    });
    return names;
  }
}
