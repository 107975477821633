import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';

@Component({
  selector: 'app-notapplicable',
  template: `
    <h3>Application Not Available</h3>
    <p>Your application is not available for the following reason:</p>
    <p>
      {{ reason }}
    </p>
  `,
  styles: [],
})
export class NotApplicableComponent implements OnInit {
  reason;
  constructor(
    private applicationService: ArchitectApplicationService,
    private notificationService: NotificationService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.notificationService.closeLast(2000);
    this.reason = this.applicationService.badApplicationReason;
    this.applicationService.badApplicationReason = null;
    if (!this.reason) {
      this.router.navigate(['/']);
    }
  }
}
