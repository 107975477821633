import { FormBuilder, FormGroup } from '@angular/forms';
import { FirmLookup } from './firm-lookup';
import { Phone } from './phone';
import { WorkHistoryBase } from './work-history-base';

// TODO refactor this out of arch app review
// and consolilate to just WorkHistory
export class WorkHistoryForArchApp extends WorkHistoryBase<WorkHistoryForArchApp> {
  isEmployed: boolean;
  worksForFirm: boolean;
  firm: FirmLookup;
  selectedFirm: string;

  acceptPatch(formGroup: FormGroup) {
    let formInfo = Object.assign({}, formGroup.getRawValue());
    this.update(formInfo);
    if (!this.isEmployed) {
      this.worksForFirm = false;
    }
    if (!this.worksForFirm) {
      this.id = null;
      this.email = null;
      this.phone = null;
      this.title = null;
      delete this.firm;
      return;
    }
    let firmInfo = Object.assign({}, formGroup.controls.workHistory.value);
    this.update(firmInfo);
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    if (!this.firm) {
      delete this.firm;
    }
    formGroup.controls.workHistory.patchValue(this);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      isEmployed: '',
      worksForFirm: '',
      workHistory: formBuilder.group({
        id: '',
        email: '',
        title: '',
        phone: Phone.GetFormGroup(formBuilder),
        selectedFirm: '',
        firm: formBuilder.group({
          id: '',
        }),
      }),
    });
  }
}
