import { Injectable } from '@angular/core';
import { ConfirmDialogueDirective } from '../../components/confirm-dialogue/confirm.dialogue.directive';

@Injectable({ providedIn: 'root' })
export class ReloadService {
  private reloadModalComponent: ConfirmDialogueDirective;
  constructor() {}

  setModal(dialog: ConfirmDialogueDirective) {
    this.reloadModalComponent = dialog;
  }

  reload() {
    if (this.reloadModalComponent) {
      this.reloadModalComponent.open();
    } else {
      //the reloadModal is not available fall back to classic JS
      window.alert(
        `New Version of the site available! If this message appears again; try holding the 'Shift' key as you press the 'F5' key.`
      );
      window.location.reload();
    }
  }
}
