import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { PersonFull } from '../../models/person-full';
import { Transaction } from '../../models/transaction';
import { IEntityService } from './i-entity.service';
import { ProfessionalReference } from '../../models/professional-reference';
import { PersonContactInfo } from '../../models/person-contact-info';
import { PersonPersonalInfo } from '../../models/person-personal-info';
import { ReferenceSummary } from '../../models/reference-summary';

@Injectable({
  providedIn: 'root',
})
export class PersonService implements IEntityService<PersonFull> {
  private path: string;

  constructor(private api: ApiService) {
    this.path = 'person/';
  }

  get(personId): Observable<PersonFull> {
    let path = this.path.concat(personId);
    return this.api.get(path).pipe(map(data => new PersonFull(data)));
  }

  add(person: PersonFull): Observable<PersonFull> {
    return this.api.post(this.path, person).pipe(map(data => new PersonFull(data)));
  }

  async update(person: PersonFull, showErrorSnack = false): Promise<PersonFull> {
    return await this.api
      .putAsync<PersonFull>(`${this.path}${person.id}`, person, showErrorSnack)
      .then(data => new PersonFull(data));
  }

  //not used
  delete(personId): Observable<PersonFull> {
    let path = this.path.concat(personId);
    return this.api.delete(path).pipe(map(data => new PersonFull(data)));
  }

  async updateContactInfo(
    personId: string,
    personContactInfo: PersonContactInfo,
    showErrorSnack = false
  ): Promise<PersonContactInfo> {
    return await this.api
      .putAsync<PersonContactInfo>(`${this.path}${personId}/contactInfo`, personContactInfo, showErrorSnack)
      .then(data => new PersonContactInfo(data));
  }

  async updatePersonPersonalInfo(
    personId: string,
    personPersonalInfo: PersonPersonalInfo,
    isPortal: boolean
  ): Promise<PersonPersonalInfo> {
    return await this.api
      .putAsync<PersonPersonalInfo>(
        `${this.path}${personId}/profilePersonalInfo${isPortal ? '' : '/staff'}`,
        personPersonalInfo,
        false
      )
      .then(data => new PersonPersonalInfo(data));
  }

  async updatePersonFirmName(personId: string, firmName: string, showErrorSnack = false): Promise<PersonFull> {
    return await this.api
      .putAsync(`${this.path}${personId}/profileFirmName`, { value: firmName }, showErrorSnack)
      .then(data => new PersonFull(data));
  }

  async updatePersonAddressPreference(personId: string, sendMailTo: string, showErrorSnack = false): Promise<PersonFull> {
    return await this.api
      .putAsync(`${this.path}${personId}/profileAddressPreference`, { value: sendMailTo }, showErrorSnack)
      .then(data => new PersonFull(data));
  }

  getQuestionnaireResponses(personId: string): Observable<QuestionnaireResponse[]> {
    let path = this.path.concat(personId, '/questionnaireResponses');
    return this.api.get(path).pipe(map(data => data.map(r => new QuestionnaireResponse(r))));
  }

  async hasAffirmativeQuestionnaireResponse(personId: string): Promise<boolean> {
    let path = this.path.concat(personId, '/questionnaireResponses');
    return await this.api.getAsync<QuestionnaireResponse[]>(path).then(responses => {
      if (!responses || responses.length == 0) return false;

      return responses.some(resp => resp.answers && resp.answers.length > 0);
    });
  }

  getPayments(personId: string): Observable<Transaction[]> {
    let path = this.path.concat(personId, '/payments');
    return this.api.get(path).pipe(map(data => data.map(t => new Transaction(t))));
  }

  addOrUpdateReferenceAsync(personId: string, reference: ProfessionalReference): Promise<ProfessionalReference> {
    const path = this.path.concat(personId, '/references');
    return this.api.putAsync<ProfessionalReference>(path, reference, true);
  }

  deleteReferenceAsync(personId: string, referenceId: string): Promise<any> {
    const path = this.path.concat(personId, '/references/', referenceId);
    return this.api.deleteAsync(path, true);
  }

  async canVerifyEmail(personId): Promise<boolean> {
    const path = `${this.path}${personId}/email/can-verify`;
    return this.api.getAsync<boolean>(path);
  }

  async verifyEmail(personId): Promise<boolean> {
    const path = `${this.path}${personId}/email/verify`;
    return this.api.getAsync<boolean>(path);
  }

  async getReferenceSummary(personId: string): Promise<ReferenceSummary> {
    return await this.api.getAsync<ReferenceSummary>(`person/${personId}/references/summary`);
  }
}
