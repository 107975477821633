<lib-titled-card *ngIf="show">
  <h2>License Information</h2>
  <aside *ngIf="settings.downloadableLicense == 'Yes'">
    <button mat-button class="auto alt" type="button" (click)="download()">Download</button>
  </aside>
  <form [formGroup]="form" class="no-underlines">
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>License Number</mat-label>
        <input matInput name="number" formControlName="number" />
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Issue Date</mat-label>
        <input matInput formControlName="issueDate" [matDatepicker]="issueDatePicker" readonly />
        <mat-datepicker #issueDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Status</mat-label>
        <input matInput name="status" [value]="license.status | licenseStatus" readonly />
      </mat-form-field>
    </div>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Last Renewal</mat-label>
        <input matInput formControlName="lastRenewalDate" [matDatepicker]="lastRenewalDatePicker" readonly />
        <mat-datepicker #lastRenewalDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Expiration Date</mat-label>
        <input matInput formControlName="expirationDate" [matDatepicker]="expirationDatePicker" readonly />
        <mat-datepicker #expirationDatePicker></mat-datepicker>
      </mat-form-field>
      <div class="mat-form-field">
        <!-- PLACEHOLDER FOR SPACING -->
      </div>
    </div>
  </form>
</lib-titled-card>
