import { UpdatableModel } from '../updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';

export class QuestionnaireResponseAnswer extends UpdatableModel<QuestionnaireResponseAnswer> {
  id: string;
  questionText: string;
  additionalPrompt: string;
  explanation: string;
  responseValue: boolean;
  hasDocuments?: boolean;

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      questionText: '',
      additionalPrompt: '',
      explanation: '',
      responseValue: '',
      hasDocuments: '',
    });
  }
}
