import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FirmPointOfContactService } from '../services/api/firm-point-of-contact.service';
import { FirmPointOfContact } from '../models/firm-point-of-contact';

@Injectable()
export class FirmPointOfContactResolve implements Resolve<FirmPointOfContact> {
  constructor(private pointOfContactService: FirmPointOfContactService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FirmPointOfContact> {
    return this.pointOfContactService.get(route.params.firmId);
  }
}
