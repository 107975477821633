import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { FirmApplicationFull } from 'projects/common/src/lib/models/applications/firm-application-full';

@Injectable()
export class FirmApplicationReviewResolve implements Resolve<FirmApplicationFull> {
  constructor(private firmApplicationService: FirmApplicationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FirmApplicationFull> {
    return this.firmApplicationService.get(route.params.firmId, route.params.applicationId);
  }
}
