import { Input, HostListener, Directive } from '@angular/core';

@Directive({
  selector: '[toFragment]',
})
export class ToFragmentDirective {
  @Input() toFragment: string;
  @HostListener('click') onClick() {
    location.href = location.pathname + location.search + '#' + this.toFragment;
  }
}
