import { GroupHeader } from './group-header';

// A group of data to display in a GroupedListComponent.
export class Group<T> {
  header: GroupHeader;
  items: T[];

  constructor(label: string, cssClass: string) {
    this.header = new GroupHeader(label, cssClass);
    this.items = new Array<T>();
  }
}
