<lib-titled-card>
  <h2>Proof of Lawful Presence</h2>

  <mat-menu>
    <button mat-button type="button" (click)="editMode = !editMode">
      {{ editMode ? 'View' : 'Edit Documents' }}
    </button>
  </mat-menu>

  <app-person-lawful-presence-proof-list [list$]="applications$"></app-person-lawful-presence-proof-list>

  <div style="width: 100%; margin: 10px">
    <lib-upload-widget
      [readonly]="!editMode"
      [docSearch]="docSearch"
      noDocumentsMessage="No lawful presence documents for this profile"
      viewStyle="Table"
      [columns]="['file', 'lastModified', 'uploadedByUsername', 'delete']"
      [canDocBeDeletedCallback]="canDeleteDoc"
    ></lib-upload-widget>
  </div>
</lib-titled-card>
