import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PersonPointOfContactService } from 'projects/common/src/lib/services/api/person-point-of-contact.service';
import { PointOfContactFor } from '../models/point-of-contact-for';

@Injectable()
export class PointOfContactForListResolve implements Resolve<PointOfContactFor[]> {
  constructor(private personPointOfContactService: PersonPointOfContactService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId || route.parent.data.person.id;
    return this.personPointOfContactService.getPointOfContactForList(personId);
  }
}
