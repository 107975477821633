import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';

@Component({
  selector: 'lib-file-pill',
  template: `
    <mat-icon class="ls-hyperlink" (click)="fileClick()">attach_file</mat-icon>
    <a class="ls-hyperlink pr-medium" href="#" (click)="fileClick()">
      {{ file.fileNameWithoutTimestamp }}
    </a>
    <mat-icon class="ls-text-grey" (click)="confirmDelete.open()">close</mat-icon>

    <lib-dialog
      #confirmDelete="dialog"
      yesText="Yes, delete"
      width=""
      noText="No, cancel"
      [header]="'Delete ' + file.fileNameWithoutTimestamp + '?'"
      (yes)="deleteClick()"
    ></lib-dialog>
  `,
  styleUrls: ['./file-pill.component.scss'],
})
export class FilePillComponent {
  @Output() deleteClicked = new EventEmitter<S3DocumentReference>();
  @Output() fileClicked = new EventEmitter<S3DocumentReference>();
  @Input() file: S3DocumentReference;
  @HostBinding('class') get getClass() {
    return 'px-xs py-xxs ls-outline-border ls-background-grey';
  }

  fileClick() {
    this.fileClicked.emit(this.file);
  }

  deleteClick() {
    this.deleteClicked.emit(this.file);
  }
}
