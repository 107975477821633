import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizingComponent } from 'projects/common/src/lib/components/authorizing/authorizing.component';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { Router } from '@angular/router';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-apply-authorizing',
  templateUrl: './apply-authorizing.component.html',
  styleUrls: ['./apply-authorizing.component.scss'],
})
export class ApplyAuthorizingComponent extends AuthorizingComponent implements OnInit, OnDestroy {
  constructor(
    auth: AuthService,
    router: Router,
    accountService: AccountService,
    public settings: TenantSettings
  ) {
    super(auth, router, settings, accountService);
  }

  ngOnInit(): void {
    this.toLandingPageIfNotAuthenticated();
  }

  ngOnDestroy(): void {
    this.onAuthSubscription.unsubscribe();
  }
}
