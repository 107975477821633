import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { RegexPatterns } from 'projects/common/src/lib/constants/regex-patterns';
import { FormComponent } from '../../../form.component';
import { ConfirmDialogueDirective } from '../../../confirm-dialogue/confirm.dialogue.directive';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { JurisdictionConfirmationService } from 'projects/common/src/lib/constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { S3DocumentSearch } from 'projects/common/src/lib/models/s3/s3-document-search';
import { S3DocumentReference } from 'projects/common/src/lib/models/s3/s3-document-reference';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

const maxUnlicenseDirectors = 10;

@Component({
  selector: 'lib-owners-form',
  styleUrls: ['./owners-form.component.scss'],
  templateUrl: './owners-form.component.html',
})
export class OwnersFormComponent extends FormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() application: FirmApplication;
  @Input() readonly: boolean;

  boardOfDirectorsNote: string;
  shareHolderNote: string;
  patterns = RegexPatterns;
  sharesIssued: FormGroup;
  sharesAllowed: FormGroup;
  sharesValidators = [];
  listOfDirectorCounts: number[];
  showOldBoardOfDirectors: boolean = false;
  docSearch: S3DocumentSearch;
  directorsUploadCustomText: string;

  constructor(
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    private router: Router,
    private confirmationService: JurisdictionConfirmationService,
    public settings: TenantSettings,
    public constants: JurisdictionConstants
  ) {
    super();
    this.listOfDirectorCounts = [];
    for (let directorCount = 0; directorCount < maxUnlicenseDirectors; directorCount++) {
      this.listOfDirectorCounts.push(directorCount);
    }
  }

  ngOnInit(): void {
    this.sharesIssued = this.form['controls'].sharesIssued as FormGroup;
    this.sharesAllowed = this.form['controls'].sharesAllowed as FormGroup;
    this.sharesValidators = [
      () => (!this.issuedGreaterThanAllowed ? null : { allowed: { valid: false } }),
      () => (this.hasMoreThanFifty ? null : { fifty: { valid: false } }),
      () => (!this.isPercentOver ? null : { over: { valid: false } }),
      () => (this.isSupervisorsInStateMajority ? null : { majority: { valid: false } }),
    ];
    this.boardOfDirectorsNote = this.confirmationService.getBoardOfDirectorsNote();
    this.shareHolderNote = this.confirmationService.getShareHolderNote();
    this.showOldBoardOfDirectors = this.constants.firmApplicationSettings?.showOldBoardOfDirectors ?? false;
    this.directorsUploadCustomText = this.constants.firmApplicationSettings?.directorsUploadCustomText;
    this.unsubOnDestroy = this.route.params.subscribe(d => {
      this.docSearch = new S3DocumentSearch({
        aggregateRootId: d.firmId,
        subjectId: d.applicationId,
        documentType: 'Directors',
      });
    });

    this.setShareValidation();

    this.unsubOnDestroy = this.form['controls'].corporationSharesType.valueChanges.subscribe(type => {
      let controls = {
        SoleOwnership: ['owner'],
        Shareholders: ['shareholders', 'directorsInState', 'unlicensedDirectorsCount', 'sharesAllowed', 'sharesIssued'],
        HoldingCompany: [
          'holdingCompanyTitle',
          'shareholders',
          'directorsInState',
          'unlicensedDirectorsCount',
          'sharesAllowed',
          'sharesIssued',
        ],
      }[type];
      const ignoredControls = ['corporationSharesType'];
      if (!this.constants.firmApplicationSettings?.showOldBoardOfDirectors) {
        ignoredControls.push('directorsInState');
        ignoredControls.push('unlicensedDirectorsCount');
      }
      this.enableFormGroupControls(this.form, controls, ignoredControls);
      this.setShareValidation();
    });
  }

  setShareValidation() {
    let validators = this.isSoleOwner ? [] : this.sharesValidators;
    this.form.setValidators(validators);
  }

  get isShareholdersOrHoldingCompany() {
    return this.isShareholders || this.isHoldingCompany;
  }

  get isSoleOwner() {
    return this.form['controls'].corporationSharesType.value == 'SoleOwnership';
  }

  get isShareholders() {
    return this.form['controls'].corporationSharesType.value == 'Shareholders';
  }

  get isHoldingCompany() {
    return this.form['controls'].corporationSharesType.value == 'HoldingCompany';
  }

  get sharesOwned() {
    let shareholders = this.form['controls'].shareholders as FormArray;
    let sharesOwned = 0;
    shareholders['controls'].forEach(c => {
      let shares = +c.value['shares'];
      if (shares) {
        sharesOwned += shares;
      }
    });
    return sharesOwned;
  }

  get issuedGreaterThanAllowed() {
    let issued = +this.sharesIssued.value;
    let allowed = +this.sharesAllowed.value;
    return issued && allowed && issued > allowed;
  }

  get percentOwned() {
    let sharesIssued = +this.sharesIssued.value;
    return sharesIssued ? ((this.sharesOwned * 100) / sharesIssued).toFixed(1) : 0;
  }

  get hasMoreThanFifty(): boolean {
    let sharesIssued = +this.sharesIssued.value;
    return sharesIssued ? this.percentOwned >= 50.1 : true;
  }

  get isPercentOver(): boolean {
    return this.percentOwned > 100;
  }

  get isSupervisorsInStateMajority(): boolean {
    if (this.isSoleOwner || !this.constants.firmApplicationSettings.showOldBoardOfDirectors) return true;
    let directorsInState = this.form.controls.directorsInState as FormArray;

    const unlicensedDirectorsCount = this.form.controls.unlicensedDirectorsCount.value
      ? parseInt(this.form.controls.unlicensedDirectorsCount.value)
      : 0;

    let directorsInStateCount = directorsInState.controls.filter(s => !!s['controls'].licenseNumber.value).length;

    let totalDirectors = directorsInStateCount + unlicensedDirectorsCount;
    if (totalDirectors <= 2) {
      return !!directorsInStateCount;
    } else {
      return directorsInStateCount > unlicensedDirectorsCount;
    }
  }

  get goodPercent() {
    return this.hasMoreThanFifty && !this.isPercentOver;
  }

  doConfirmSwitch(dialogue: ConfirmDialogueDirective) {
    dialogue.open();
    return false;
  }

  switch() {
    this.router.navigate(['../../../switch'], { relativeTo: this.route });
  }

  onDocListChange(docList: S3DocumentReference[]) {}
}
