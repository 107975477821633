import { Injectable } from '@angular/core';
import { ArchitectApplication } from '../../../models/applications/architect-application';
import { FirmApplication } from '../../../models/applications/firm-application';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import { Confirmations, JurisdictionConfirmationService, LegendWithNote } from './jurisdiction-confirmation-service';

@Injectable({
  providedIn: 'root',
})
export class NDConfirmationService extends JurisdictionConfirmationService {
  getArchitectConfirmations(application: ArchitectApplication): Confirmations {
    const laws = [
      `North Dakota Century Code (<a href="${this.constants.commonLinks.centuryCode}" target="_blank">ND 43-03 01:25</a>)`,
      `North Dakota Administrative Code (<a href="${this.constants.commonLinks.rules}" target="_blank">ND Title 08 01:07</a>)`,
    ];
    const attestations = [
      'The information I have provided on this form is true and accurate to the best of my knowledge.',
      'I attest to being the license applicant or holder. I am not an agent acting on behalf of the license applicant or holder.',
      'I am aware I will need to register my firm with the North Dakota Secretary of State before being allowed to practice in the jurisdiction.',
    ];
    const gap =
      'I hereby acknowledge that I have not practiced architecture in the State of North Dakota since my previously issued architectural license lapsed.';
    if (application.hasGap()) {
      attestations.push(gap);
    }
    return {
      laws,
      attestations,
    };
  }
  //ND does not use firms
  getFirmConfirmations(application: FirmApplicationFull): Confirmations {
    throw new Error('North Dakota does not license Firms');
  }
  getShareHolderNote(): string {
    return null;
  }
  getBoardOfDirectorsNote(): string {
    return null;
  }
  getSupervisorNotes(application: FirmApplication): LegendWithNote[] {
    return [];
  }
}
