import { Injectable } from '@angular/core';
import { allowedDomains } from '../../constants/environment';

export const Tokens = {
  access: 'access_token',
  id: 'id_token',
  expires: 'access_token_expires',
  claims: 'claims',
  scopes: 'scopes',
  error: 'error',
  refreshTabId: 'refresh_tab_id',
};

export function JwtConfig() {
  return {
    config: {
      tokenGetter: tokenGetter,
      allowedDomains: allowedDomains(),
    },
  };
}

function GetAccessToken() {
  return () => localStorage.getItem('auth0_' + Tokens.access);
}

function tokenGetter() {
  return GetAccessToken()();
}

const prefix: string = 'auth0_';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  get(token) {
    let item = localStorage.getItem(prefix + token);
    if (item && item.length && item[0] == '{') {
      return JSON.parse(item);
    }
    if (item == 'null') {
      return null;
    }
    return item;
  }

  set(token, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(prefix + token, value);
  }

  isSet(token) {
    return !!localStorage.getItem(prefix + token);
  }

  unset(token) {
    delete localStorage[prefix + token];
  }

  unsetAll() {
    for (var token in Tokens) {
      this.unset(Tokens[token]);
    }
  }
}
