import { FirmRoleHolder } from './firm-role-holder';

export interface RoleInfo {
  name: string;
  role: string;
}

export class FirmRoleHolderGroups {
  currentRoleHolders: FirmRoleHolder[] = [];

  pastRoleHolders: FirmRoleHolder[] = [];

  activeRolesByPersonId: Record<string, RoleInfo[]> = {};

  currentPOC: FirmRoleHolder;

  pastPOCs: FirmRoleHolder[] = [];

  constructor(firmRoleHolders: FirmRoleHolder[]) {
    for (var i = 0; i < firmRoleHolders.length; i++) {
      const roleHolder = firmRoleHolders[i];
      if (!roleHolder.isActive) {
        if (roleHolder.role == 'PointOfContact') {
          this.pastPOCs.push(roleHolder);
        } else {
          this.pastRoleHolders.push(roleHolder);
        }
        continue;
      }

      if (!this.activeRolesByPersonId[roleHolder.person.id]) {
        this.activeRolesByPersonId[roleHolder.person.id] = [];
      }

      this.activeRolesByPersonId[roleHolder.person.id].push({
        name: roleHolder.roleName,
        role: roleHolder.role,
      });

      if (roleHolder.role == 'PointOfContact') {
        this.currentPOC = roleHolder;
      } else if (!this.currentRoleHolders.find(r => r.person.id == roleHolder.person.id)) {
        this.currentRoleHolders.push(roleHolder);
      }
    }
  }
}
