import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ReplaySubject, merge } from 'rxjs';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { ListComponent } from '../list';
import { PersonPointOfContact } from '../../models/person-point-of-contact';
import { PersonPointOfContactService } from '../../services/api/person-point-of-contact.service';
import { NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogueDirective } from '../confirm-dialogue/confirm.dialogue.directive';

@Component({
  selector: 'lib-point-of-contact-list',
  templateUrl: './point-of-contact-list.component.html',
  styleUrls: ['./point-of-contact-list.component.scss'],
  animations: [
    trigger('statusChange', [
      state(
        'Pending',
        style({
          backgroundColor: '#FDEED4',
        })
      ),
      state(
        'Active',
        style({
          backgroundColor: 'white',
        })
      ),
      state(
        'Rejected',
        style({
          backgroundColor: 'white',
        })
      ),
      transition('Pending => Active', [
        animate(
          '2s ease-in-out',
          keyframes([
            style({ backgroundColor: '#00C25B26', offset: 0.1 }),
            style({ backgroundColor: '#00C25B26', offset: 0.7 }),
            style({ backgroundColor: 'white', offset: 1 }),
          ])
        ),
      ]),
      transition('Pending => Rejected', [
        animate(
          '2s ease-in-out',
          keyframes([
            style({ backgroundColor: '#FF000026', offset: 0.1 }),
            style({ backgroundColor: '#FF000026', offset: 0.7 }),
            style({ backgroundColor: 'white', offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class PointOfContactListComponent extends ListComponent<PersonPointOfContact> implements OnInit {
  static readonly activeStatus = 'Active';
  static readonly pendingStatus = 'Pending';
  @Input() isStaffView: boolean;
  @Output() onRevoke = new EventEmitter();
  emptyList$ = new ReplaySubject<PersonPointOfContact[]>(1);
  columns = ['name', 'email', 'title', 'requestInfo', 'actions', 'menu'];
  isProcessing: boolean;
  pocToRemove: PersonPointOfContact;

  @ViewChild(ConfirmDialogueDirective) confirmRemove: ConfirmDialogueDirective;
  constructor(
    private pocService: PersonPointOfContactService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.hideOnEmpty = true;
    this.listToUse$ = merge(this.list$, this.emptyList$);
    super.ngOnInit();
  }

  getRequestLabel(poc: PersonPointOfContact): string {
    if (poc.isPending) return 'Access Pending';
    if (poc.isActive) return 'Access Approved';
    if (poc.isRejected) return 'Access Denied';

    return '';
  }

  getRequestDate(poc: PersonPointOfContact): Date {
    if (poc.isPending) return poc.requestExpirationDate;
    if (poc.isActive) return poc.acceptedDate;
    if (poc.isRejected) return poc.endDate;

    return null;
  }

  staffViewRoute(poc: PersonPointOfContact) {
    return ['/person', poc.pointOfContactPerson.id];
  }

  onApprove(poc: PersonPointOfContact): void {
    this.isProcessing = true;
    this.pocService.approve(this.route.snapshot.params.personId, poc).subscribe(
      response => {
        this.notificationService.notifySuccess('Point of contact access approved');
        poc.update(response);
        this.isProcessing = false;
      },
      err => {
        this.notificationService.notifySuccess('There was an error approving the point of contact request');
        this.isProcessing = false;
      }
    );
  }

  onDeny(poc: PersonPointOfContact): void {
    this.isProcessing = true;
    this.pocService.deny(this.route.snapshot.params.personId, poc).subscribe(
      response => {
        this.notificationService.notifySuccess('Point of contact access denied');
        poc.update(response);
        this.isProcessing = false;
      },
      err => {
        this.notificationService.notifySuccess('There was an error denying the point of contact request');
        this.isProcessing = false;
      }
    );
  }

  onUpdate(): void {
    if (this.isStaffView) {
      this.router.navigate(['..', 'archpointofcontact', 'assign'], { relativeTo: this.route });
    } else {
      this.router.navigate(['archpointofcontact', 'assign'], { relativeTo: this.route });
    }
  }

  onRemove(poc: PersonPointOfContact): void {
    this.pocToRemove = poc;
    this.confirmRemove.open();
  }

  revoke(poc: PersonPointOfContact) {
    this.pocService.revoke(this.route.snapshot.params.personId, poc).subscribe(
      response => {
        this.notificationService.notifySuccess('Point of contact access removed');
        this.emptyList$.next([]);
        this.onRevoke.emit();
      },
      err => {
        this.notificationService.notifySuccess('There was an error removing access for the point of contact');
      }
    );
  }
}
