import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonService } from '../../services/api/person.service';

@Component({
  selector: 'lib-verify-email-dialog',
  templateUrl: './verify-email-dialog.html',
  styleUrls: ['./verify-email-dialog.scss'],
})
export class VerifyEmailDialog {
  personId: string;
  verifying: boolean = false;
  @HostBinding('class.opt-in') optIn = true;

  constructor(
    private dialogRef: MatDialogRef<VerifyEmailDialog>,
    private personService: PersonService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.personId = data as string;
  }

  async handleVerifyEmailClick() {
    this.verifying = true;

    await this.personService
      .verifyEmail(this.personId)
      .then((data: boolean) => this.dialogRef.close(data ? true : false))
      .catch(err => this.dialogRef.close(false));
  }
}
