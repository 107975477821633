import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { FirmApplicationFull } from 'projects/common/src/lib/models/applications/firm-application-full';

@Injectable()
export class ApplicationResolve implements Resolve<ArchitectApplication | FirmApplicationFull> {
  constructor(
    private applicationService: ArchitectApplicationService,
    private firmApplicationService: FirmApplicationService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArchitectApplication | FirmApplicationFull> {
    let getApplication: Observable<ArchitectApplication | FirmApplicationFull>;
    let path = '';
    if (route.params.personId) {
      getApplication = this.applicationService.get(route.params.personId, route.params.applicationId);
      path = `/architect/${route.params.personId}`;
    } else {
      getApplication = this.firmApplicationService.get(route.params.firmId, route.params.applicationId);
      path = `/firm/${route.params.firmId}`;
    }
    return getApplication.pipe(
      tap(a => {
        if (a.status == 'Submitted' || a.orderStatus == 'Completed') {
          if (!state.url.endsWith('submitted')) {
            this.router.navigate([`${path}/application/${route.params.applicationId}/submitted`]);
          }
        }
      })
    );
  }
}
