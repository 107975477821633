import { UpdatableModel } from './updatable-model';

export class Transaction extends UpdatableModel<Transaction> {
  id: string;
  orderNumber: string;
  batchNumber: string;
  transactionDate: Date;
  comments: string;
  type: string;
  paymentMethodType: string;
  paymentMethodAccountSuffix: string;
  paymentMethodCardType: string;
  paymentDescription: string;
  totalAmount: number;
  description: string;
  paidBy: string;
}
