import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ReloadService } from '../services/api/reload.service';

class CustomErrorHandler implements ErrorHandler {
  constructor(private reloadService: ReloadService) {}

  handleError(error: any) {
    //this is the error message when a lazily loaded module fails to load.
    if (error?.message && /Loading chunk.*?failed./.test(error.message)) {
      this.reloadService.reload(); //display modal and then reload the site
    } else {
      console.error(error);
    }
  }
}

@NgModule()
export class ErrorHandlingModule {
  static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [{ provide: ErrorHandler, useClass: CustomErrorHandler, deps: [ReloadService] }],
    };
  }
}
