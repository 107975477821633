import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ArchitectAvailableApplication } from 'projects/common/src/lib/models/applications/architect-available-application';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';

/**
 * Loads information about applications for the person.
 */
@Injectable()
export class PersonAvailableApplicationsResolve implements Resolve<ArchitectAvailableApplication[]> {
  constructor(private architectApplicationService: ArchitectApplicationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ArchitectAvailableApplication[]> {
    return this.architectApplicationService.getAvailable(route.params.personId);
  }
}
