import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdatableModel } from 'projects/common/src/lib/models/updatable-model';
import { Name } from 'projects/common/src/lib/models/name';
import { Phone } from 'projects/common/src/lib/models/phone';
import { Address } from 'projects/common/src/lib/models/address';
import { TenantSettings } from '../constants/jurisdiction/TenantSettings';
import { atLeastOne } from '../directives/validators/at-least-one.directive';

export class PersonProfile extends UpdatableModel<PersonProfile> {
  id: string;
  name: Name;
  credentials: string;
  email: string;
  ncarbNumber?: number;
  clarbNumber?: number;
  socialSecurityNumber: string;
  visaNumber: string;
  uscisNumber: string;
  immigrationStatus: string;
  dateOfBirth: Date;
  gender: string;
  primaryPhone: Phone;
  secondaryPhone: Phone;
  sendMailTo: string;
  firmName: string;

  update(init?: Partial<PersonProfile>, skipCleanup = false) {
    super.update(init);
    if (!skipCleanup) {
      this.cleanup();
    }
  }

  acceptPatch(formGroup: FormGroup) {
    this.id = formGroup.controls.id.value;
    for (let c in formGroup.controls) {
      if (!formGroup.controls[c]['controls']) {
        continue;
      }
      let info = Object.assign({}, formGroup.controls[c].value);
      this.update(info, false);
    }
    this.cleanup();
  }

  cleanup() {
    let _ = ['workAddress', 'homeAddress'].forEach(a => {
      if (!this[a] || !this[a].streetLine1) {
        delete this[a];
      } else {
        this[a] = new Address(this[a]);
      }
    });
    _ = ['primaryPhone', 'secondaryPhone'].forEach(p => {
      if (!this[p] || !this[p].number) {
        delete this[p];
      } else {
        this[p] = new Phone(this[p]);
      }
    });

    if (this.socialSecurityNumber) {
      if (/\d{9}/.test(this.socialSecurityNumber)) {
        this.socialSecurityNumber = this.socialSecurityNumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  patch(formGroup: FormGroup) {
    this.cleanup();
    formGroup.controls.id.setValue(this.id);
    formGroup.controls.names.patchValue({
      name: this.name,
      nameFull: this.name.full,
      credentials: this.credentials,
    });
    if (!!formGroup.controls.contact) {
      formGroup.controls.contact.patchValue({
        email: this.email,
        primaryPhone: this.primaryPhone || new Phone(),
        secondaryPhone: this.secondaryPhone || new Phone(),
      });
    }
    formGroup.controls.addresses.patchValue({
      sendMailTo: this.sendMailTo,
      firmName: this.firmName,
    });
    formGroup.controls.info.patchValue({
      socialSecurityNumber: this.socialSecurityNumber,
      visaNumber: this.visaNumber,
      uscisNumber: this.uscisNumber,
      immigrationStatus: this.immigrationStatus,
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      ncarbNumber: this.ncarbNumber,
      clarbNumber: this.clarbNumber,
    });
  }

  static GetContactInfoFormGroup(formBuilder: FormBuilder): FormGroup {
    const fg = formBuilder.group({
      email: '',
      primaryPhone: Phone.GetFormGroup(formBuilder),
      secondaryPhone: Phone.GetFormGroup(formBuilder),
    });

    return fg;
  }

  static GetFormGroup(formBuilder: FormBuilder, disabled: boolean, settings: TenantSettings): FormGroup {
    let infoFormGroup = this.GetInfoFormGroup(formBuilder, settings);

    const form = formBuilder.group({
      id: '',
      names: formBuilder.group({
        name: Name.GetFormGroup(formBuilder),
        nameFull: '',
        credentials: '',
      }),
      addresses: formBuilder.group({
        sendMailTo: '',
        firmName: '',
      }),
      info: infoFormGroup,
    });
    if (disabled) {
      const names = form.controls.names as FormGroup;
      names.controls.name.disable();

      const info = form.controls.info as FormGroup;
      info.controls.ncarbNumber.disable();
      info.controls.clarbNumber.disable();
    }

    return form;
  }

  static GetInfoFormGroup(formBuilder: FormBuilder, settings: TenantSettings): FormGroup {
    if ((settings.showSocialSecurityNumberAlternative ?? 'None') == 'None')
      return formBuilder.group({
        dateOfBirth: '',
        gender: '',
        socialSecurityNumber: ['', Validators.required],
        visaNumber: '',
        uscisNumber: '',
        immigrationStatus: '',
        ncarbNumber: '',
        clarbNumber: '',
      });

    const atLeastOneValidator = this.GetSsnVisaUscisValidator(settings);

    return formBuilder.group(
      {
        dateOfBirth: '',
        gender: '',
        socialSecurityNumber: '',
        visaNumber: '',
        uscisNumber: '',
        immigrationStatus: '',
        ncarbNumber: '',
        clarbNumber: '',
      },
      atLeastOneValidator
    );
  }

  static GetSsnVisaUscisValidator(settings: TenantSettings): Record<string, any> {
    if (settings.showSocialSecurityNumberAlternative == 'UscisNumber')
      return {
        validator: atLeastOne(
          Validators.required,
          ['socialSecurityNumber', 'uscisNumber'],
          'Social Security Number or USCIS Number is required.'
        ),
      };
    else
      return {
        validator: atLeastOne(
          Validators.required,
          ['socialSecurityNumber', 'visaNumber'],
          'Social Security Number or Visa Number is required.'
        ),
      };
  }
}
