import { Input, Output, EventEmitter, Directive, TemplateRef, ContentChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogue } from './confirm.dialogue';
import { firstValueFrom } from 'rxjs';

@Directive({
  selector: 'lib-confirm, [lib-confirm]',
  exportAs: 'confirm',
})
export class ConfirmDialogueDirective {
  @Input() prompt: string;
  @Input() isError: boolean;
  @Input() yesText: string;
  @Input() noText: string;
  @Input() optIn: boolean;
  //disabled escape key Or clicking off to close.
  @Input() disableClose: boolean;
  @Input() template: TemplateRef<any>;
  @Output() yes = new EventEmitter<boolean>();
  @Output() no = new EventEmitter<boolean>();
  dialogRef: MatDialogRef<ConfirmDialogue>;
  @ContentChild(TemplateRef) inlineTemplate: TemplateRef<any>;
  constructor(private dialogue: MatDialog) {}

  open(): Promise<boolean> {
    this.dialogRef = this.dialogue.open(ConfirmDialogue, {
      data: {
        prompt: this.prompt,
        isError: this.isError,
        yesText: this.yesText,
        noText: this.noText,
        optIn: this.optIn,
        template: this.inlineTemplate || this.template,
      },
      disableClose: this.disableClose,
    });

    return firstValueFrom(this.dialogRef.afterClosed()).then(result => {
      if (result) {
        this.yes.emit();
      } else {
        this.no.emit();
      }
      return result;
    });
  }
}
