import { UpdatableModel } from '../updatable-model';
import { ApplicationCallToAction } from './application-call-to-action';

export abstract class AvailableApplication extends UpdatableModel<AvailableApplication> {
  id: string;
  applicationType: string;
  applicationTypeName: string;
  callToAction: ApplicationCallToAction;
  isInProgress: boolean;

  get title(): string {
    return this.applicationTypeName;
  }

  get shortTitle(): string {
    return this.applicationTypeName == 'License' ? 'License Application' : this.applicationTypeName;
  }

  get action(): string {
    return this.isInProgress ? 'Continue' : 'Start';
  }
}
