import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PersonService } from '../services/api/person.service';
import { Transaction } from '../models/transaction';

@Injectable()
export class PaymentsResolve implements Resolve<Transaction[]> {
  constructor(private personService: PersonService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId;
    return this.personService.getPayments(personId);
  }
}
