<div [formGroup]="formGroup">
  <section-header [title]="content.headerSubtext">
    {{ content.headerSubtextContent }}
  </section-header>
  <ls-subtitle [level]="2" class="pt-medium" *ngIf="confirmations.laws.length"
    >I certify that I have read the following laws and rules:</ls-subtitle
  >
  <ul class="vanilla multiline-checkboxes">
    <li *ngFor="let law of confirmations.laws; let lawIndex = index">
      <mat-checkbox [formControl]="formGroup.controls.laws.controls[lawIndex]" color="primary" required>
        <span [outerHTML]="law"></span>
      </mat-checkbox>
    </li>
  </ul>
  <ls-subtitle [level]="2">{{ content.attestationsLabel }}</ls-subtitle>
  <ul class="vanilla multiline-checkboxes">
    <li *ngFor="let attest of confirmations.attestations; let attestIndex = index">
      <mat-checkbox [formControl]="formGroup.controls.attestations.controls[attestIndex]" color="primary" required>
        <span [outerHTML]="attest"></span>
      </mat-checkbox>
    </li>
  </ul>
</div>
