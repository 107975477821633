import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public isEditing: boolean;

  @Input()
  public tip: string;

  @Output() editClicked = new EventEmitter();

  @Output() cancelClicked = new EventEmitter();

  @Output() saveClicked = new EventEmitter();

  @Output() tipClicked = new EventEmitter();

  tipClick() {
    this.tipClicked.emit();
  }

  editClick() {
    this.editClicked.emit();
  }
  cancelClick() {
    this.cancelClicked.emit();
  }
  saveClick() {
    this.saveClicked.emit();
  }
}
