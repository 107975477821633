import { Component, Input } from '@angular/core';
import { ColorVariant } from './pill.component';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { spaceCapCase } from '../../utility/string-utils';

const LicenseStatuses = [
  'Expired',
  'ExpiredEmeritus',
  'Revoked',
  'Active',
  'Due',
  'Emeritus',
  'EmeritusDue',
  'Delinquent',
  'Probation',
  'Suspended',
  'Inactive',
  'Retired',
  'Deceased',
] as const;
export type LicenseStatus = (typeof LicenseStatuses)[number];

export const ApplicationStatuses = ['New', 'Open', 'Hold', 'Submitted', 'Pending', 'Closed'] as const;

type ApplicationStatus = (typeof ApplicationStatuses)[number];
type ApplicationOrLicenseStatus = LicenseStatus | ApplicationStatus;

const mappingApplicationOrLicenseStatusToVariant: Record<ApplicationOrLicenseStatus, ColorVariant> = {
  Expired: 'error',
  ExpiredEmeritus: 'error',
  Revoked: 'error',
  Active: 'success',
  Due: 'success',
  Emeritus: 'success',
  EmeritusDue: 'success',
  Delinquent: 'warning',
  Probation: 'warning',
  Suspended: 'warning',
  Inactive: 'inactive',
  Retired: 'inactive',
  Deceased: 'inactive',
  New: 'success',
  Open: 'warning',
  Hold: 'error',
  Submitted: 'submitted',
  Pending: 'pending',
  Closed: 'inactive',
};

@Component({
  selector: 'lib-status-pill',
  templateUrl: './status-pill.component.html',
})
export class StatusPillComponent {
  @Input() applicationOrLicenseStatus: ApplicationOrLicenseStatus;

  get variant(): ColorVariant {
    return mappingApplicationOrLicenseStatusToVariant[this.applicationOrLicenseStatus] || 'error'; //default to error
  }
}
