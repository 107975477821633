import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  SupervisingProfessionalsSettings,
  TenantSettings,
} from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { FirmRoleHolder } from 'projects/common/src/lib/models/firm-role-holder';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-portal-firm-supervisors-info',
  templateUrl: './portal-firm-supervisors-info.component.html',
  styleUrls: ['./portal-firm-supervisors-info.component.scss'],
})
export class PortalFirmSupervisorsInfoComponent implements OnInit {
  @HostBinding('class.opt-in') optin = true;
  @Input() roles$: Observable<FirmRoleHolder[]>;
  spSettings: SupervisingProfessionalsSettings;

  constructor(public settings: TenantSettings) {}

  ngOnInit(): void {
    this.spSettings = this.settings.supervisingProfessionalsSettings;
  }
}
