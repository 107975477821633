import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lookup' })
export class LookupPipe implements PipeTransform {
  transform(key: string, context: string) {
    context = context || 'default';
    return this.dict[context] && this.dict[context][key];
  }

  dict = {
    default: {},
    eventHistoryIcon: {
      SystemEvent: 'note',
      Note: 'comment',
      Audit: 'update',
    },
    eventHistoryLabel: {
      '': 'All',
      SystemEvent: 'System',
      Note: 'Notes',
      Audit: 'Updates',
    },
    eventHistoryName: {
      '': '',
      SystemEvent: 'System Events',
      Note: 'Notes',
      Audit: 'Updates',
    },
  };
}
