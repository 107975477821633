import { OnInit, Input, Output, EventEmitter, Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { Group } from './group';
import { GroupHeader } from './group-header';
import { NoItemsRow } from './no-items-row';

@Component({
  selector: 'lib-grouped-list',
  template: '',
})
export class GroupedListComponent<T> extends BaseComponent implements OnInit {
  @Input() groups$: Observable<Group<T>[]>;
  @Input() includeEmptyHeaders = false;
  @Output() sortChanged = new EventEmitter<Sort>();
  items$ = new BehaviorSubject<(T | GroupHeader | NoItemsRow)[]>([]);
  isLoading: boolean;

  ngOnInit() {
    this.isLoading = true;
    this.unsubOnDestroy = this.groups$.subscribe(groups => {
      let flatItems = [];
      groups
        .filter(g => g.items.length > 0 || this.includeEmptyHeaders)
        .forEach(g => {
          flatItems.push(g.header);
          if (g.items.length) {
            flatItems = flatItems.concat(g.items);
          } else {
            flatItems.push(new NoItemsRow());
          }
        });

      if (!flatItems.length) {
        flatItems.push(new NoItemsRow());
      }
      this.isLoading = false;
      this.items$.next(flatItems);
    });
  }

  isHeaderRow = (_, item) => item instanceof GroupHeader;
  noItems = (_, item) => item instanceof NoItemsRow;

  sortChange(sort: Sort) {
    this.sortChanged.emit(sort);
  }
}
