import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { SaveParameters } from '../person-detail-base.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../../../models/address';
import { AddressService } from '../../../services/address.service';
import { Subscription } from 'rxjs';
import { Profession } from '../../../constants/jurisdiction/profession';

//person-address-details
@Component({
  selector: 'app-person-work-details',
  templateUrl: './person-work-details.component.html',
  styleUrls: ['./person-work-details.component.scss'],
})
export class PersonWorkDetailsComponent implements OnDestroy, OnInit {
  hideCounty: boolean;
  line1Label: string;
  line2Label: string;
  addressForm: FormGroup;
  isEditing: boolean;
  profession: Profession;
  @Output() onSectionSave = new EventEmitter<SaveParameters>();
  private formSnapshot: FormGroup;
  private unsubscribeOnDestroy: Subscription;
  edit() {
    this.formSnapshot = this.addressForm.getRawValue();
    this.isEditing = true;
  }

  cancel() {
    this.addressForm.setValue(this.formSnapshot);
    this.isEditing = false;
  }

  constructor(
    public constants: JurisdictionConstants,
    public settings: TenantSettings,
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.line1Label = this.settings.architectProfileSettings.workAddressFirstLineLabel ?? 'Work Address First Line';
    this.line2Label = this.settings.architectProfileSettings.workAddressSecondLineLabel ?? 'Work Address Second Line';
    this.hideCounty = this.settings.architectProfileSettings.hideCounty;
    this.addressForm = Address.GetFormGroup(this.formBuilder, { workAddressSameAsHome: '', firmName: '' });
    this.profession = settings.professions[0];
  }

  ngOnInit() {
    //need to do this because onInit doesn't run when switching between two different people so address wouldn't update
    this.unsubscribeOnDestroy = this.route.params.subscribe(async params => {
      const address = await this.addressService.getAddress(params.personId, 'work', true);
      if (this.useFirmName && this.route.snapshot.data?.person?.firmName) {
        this.addressForm.patchValue({ ...address, firmName: this.route.snapshot.data.person.firmName });
      } else {
        this.addressForm.patchValue(address);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeOnDestroy.unsubscribe();
  }

  get useFirmName(): boolean {
    return !this.settings.hasWorkHistory;
  }

  save() {
    this.onSectionSave.emit({
      form: this.addressForm,
      defaultErrorMessage: 'There was an issue updating address information.',
      successMessage: 'Address information was saved',
      callApi: async () => {
        const updatedAddress = await this.addressService.updateAddress(
          this.route.snapshot.params.personId,
          'work',
          this.addressForm.value
        );
        this.addressForm.patchValue(updatedAddress); //zip might be updated and any validation errors
        this.isEditing = false;
      },
      profileUpdate: this.useFirmName
        ? {
            firmName: this.addressForm.value.firmName,
          }
        : undefined,
    });
  }

  async onWorkAddressSameChange(event: MatCheckboxChange) {
    if (!event.checked) {
      return;
    }
    //copy home address into this form
    const address = await this.addressService.getAddress(this.route.snapshot.params.personId, 'home', true);
    this.addressForm.patchValue({
      ...address,
      workAddressSameAsHome: true,
    });
  }
}
