import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FirmService } from '../services/api/firm.service';
import { Transaction } from '../models/transaction';

@Injectable()
export class FirmPaymentsResolve implements Resolve<Transaction[]> {
  constructor(private firmService: FirmService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let firmId = route.params.firmId;
    return this.firmService.getPayments(firmId);
  }
}
