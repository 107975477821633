<mat-form-field appearance="standard">
  <lib-autocomplete
    #auto="autocomplete"
    [textDisplay]="true"
    [displayWith]="keepCurrentText"
    [search]="search"
    [keepUnselectedTextOnBlur]="true"
    (optionSelected)="onSelect($event)"
  >
    <input
      type="search"
      matInput
      #input
      [matAutocomplete]="auto.autocomplete"
      [ngModel]="text"
      (ngModelChange)="onType($event)"
      (keydown.enter)="onGo(input.value)"
      [placeholder]="placeholder"
    />
    <ng-template let-item let-highlight="highlight">
      <lib-lookup-option [item]="item" [showStatus]="true" [highlight]="highlight"> </lib-lookup-option>
    </ng-template>
  </lib-autocomplete>
  <mat-icon matSuffix (click)="onClear()" *ngIf="input.value">close</mat-icon>
</mat-form-field>
<button mat-button (click)="onGo(input.value)"><mat-icon>search</mat-icon></button>
