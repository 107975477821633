<fieldset [formGroup]="addressForm">
  <ng-content></ng-content>
  <p *ngIf="constants.commonLinks.architectLookup && workOrHome == 'work'">
    This address will appear in the
    <a [href]="constants.commonLinks.architectLookup" target="_blank">Find {{ profession | spaceCapCase | addAnOrA }}</a>
    section of the {{ settings.boardAbbreviation }} public website.
  </p>
  <mat-checkbox
    class="pb-medium pl-small ml-xs"
    formControlName="workAddressSameAsHome"
    *ngIf="!settings.architectProfileSettings.hideHomeAddress && workOrHome == 'work'"
    color="primary"
    [disabled]="true"
  >
    Work Address is same as Home Address
  </mat-checkbox>
  <div class="flex-fields" *ngIf="showFirmName">
    <mat-form-field appearance="outline">
      <mat-label>Firm Name</mat-label>
      <input matInput formControlName="firmName" placeholder="Firm Name" />
    </mat-form-field>
  </div>
  <lib-address
    [editing]="true"
    [formGroup]="addressForm"
    [line1Label]="line1Label"
    [line2Label]="line2Label"
    [hideCounty]="hideCounty"
  ></lib-address>
</fieldset>
