import { FormBuilder, FormGroup } from '@angular/forms';
export class Address {
  constructor(init?: Partial<Address>) {
    Object.assign(this, init);
  }

  streetLine1: string;
  streetLine2: string;
  city: string;
  region: string;
  stateId?: number;
  provinceId?: number;
  countryId: number;
  county: string;
  zip: string;

  isValid: boolean;
  workAddressSameAsHome: boolean;
  error: string;

  get oneLine() {
    //can't get this line to trigger. Is is in use anymore?
    return this.streetLine1 + ', FixMe'; // + this.cityStateZip;
  }

  static GetFormGroup(formBuilder: FormBuilder, extraFields = {}): FormGroup {
    return formBuilder.group({
      ...extraFields, //for setting up firmName and workAddressSameAsHome
      streetLine1: '',
      streetLine2: '',
      city: '',
      stateId: '',
      provinceId: '',
      region: '',
      countryId: CountryIds.USA,
      zip: '',
      county: '',
      error: '',
      isValid: false,
    });
  }
}

export class State {
  id: number;
  code: string;
  name: string;
}

export interface Country {
  id: number;
  code: string;
  code2: string;
  name: string;
}

export const CountryIds = {
  USA: 840,
  CAN: 124,
};

export interface AddressLookups {
  countries: Country[];
  states: State[];
  provinces: State[];
}

export function getStateName(address: Address, addressLookups: AddressLookups): string {
  if (address.stateId) {
    return addressLookups.states.find(s => s.id == address.stateId)?.code;
  }
  if (address.provinceId) {
    return addressLookups.provinces.find(s => s.id == address.stateId)?.code;
  }
  return address.region || '';
}
