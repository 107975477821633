import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IEntityService } from 'projects/common/src/lib/services/api/i-entity.service';

export abstract class DetailService<TEntity, TEntityService extends IEntityService<TEntity>> {
  id: string;
  entity$ = new BehaviorSubject<TEntity>(null);
  constructor(protected entityService: TEntityService) {}

  get entity() {
    return this.entity$.value;
  }

  init(id: string): Observable<TEntity> {
    this.id = id;
    return this.entityService.get(this.id).pipe(tap(p => this.next(p)));
  }

  next(entity: TEntity) {
    this.entity$.next(entity);
  }

  refresh(): Observable<TEntity> {
    return this.init(this.id);
  }

  refreshWithSubscribe(): Subscription {
    return this.refresh().subscribe();
  }
}
