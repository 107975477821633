import { UpdatableModel } from './updatable-model';
import { Phone } from './phone';
import { PersonLite } from './person-lite';
import { License } from './i-license';

export class FirmRoleHolder extends UpdatableModel<FirmRoleHolder> {
  role: string;
  roleName: string;
  isActive: boolean;
  startDate: Date;
  endDate: Date;
  person: PersonLite;
  license: License;
  lastVerifiedOn: Date;

  // poc props
  email: string;
  title: string;
  phone: Phone;

  update(init: Partial<FirmRoleHolder>) {
    super.update(init);

    if (this.person) {
      this.person = new PersonLite(this.person);
    }
    if (this.phone && !this.phone.numberFull) {
      this.phone = new Phone(this.phone);
    }
    if (this.license) {
      this.license = new License(this.license);
    }
  }
}
