import { DemoConstants } from './DEMO-constants';
import { LAConstants } from './LA-constants';
import { NDConstants } from './ND-constants';
import { ALConstants } from './AL-constants';
import { NEConstants } from './NE-constants';
import { JurisdictionArgsType, JurisdictionConstants } from './jurisdiction-constants';
import { TenantSettings } from './TenantSettings';
import { NEBOGConstants } from './NEBOG-constants';

const allJurisdictionConstants: Record<JurisdictionArgsType, JurisdictionConstants> = {
  LA: LAConstants,
  ND: NDConstants,
  DEMO: DemoConstants,
  AL: ALConstants,
  NE: NEConstants,
  NEBOG: NEBOGConstants,
};

export const GetConstantsForJurisdiction = (jurisdiction: JurisdictionArgsType) => {
  const constants = allJurisdictionConstants[jurisdiction];
  if (!constants) {
    throw new Error(`no Jurisdiction Constants found for ${jurisdiction}`);
  }
  return constants;
};

export const JurisdictionConstantsProvider = {
  provide: JurisdictionConstants,
  useFactory: (settings: TenantSettings) => GetConstantsForJurisdiction(settings.tenant),
  deps: [TenantSettings],
};
