<ol [formGroup]="formArray">
  <li *ngFor="let principal of formArray['controls']; let i = index">
    <lib-arch-select
      [role]="role"
      [formGroup]="formArray['controls'][i]"
      [removable]="!readonly && (i || allowNone)"
      [readonly]="readonly"
      (cleared)="formArray.removeAt(i)"
      [alreadySelectedPersonIds]="selectedPersonIds"
      [supervisorLabel]="supervisorLabel"
    >
    </lib-arch-select>
  </li>
  <li *ngIf="showAddAnother()">
    <a href="" (click)="addAnother()">
      + Add
      {{ formArray['controls'].length ? 'Another' : 'a' }}
      {{ label }}
    </a>
  </li>
</ol>
