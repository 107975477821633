<lib-titled-card class="opt-in">
  <h2>Contact Information</h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
  </aside>
  <form [formGroup]="contactInfoForm" [class.no-underlines]="!isEditing">
    <div class="grid two-columns xs-collapse">
      <mat-form-field
        [appearance]="isEditing ? 'outline' : 'standard'"
        [class.readonly]="!isEditing"
        class="all-columns"
        [floatLabel]="isEditing ? 'auto' : ''"
      >
        <mat-label *ngIf="isEditing || contactInfoForm.controls.email.value"> Email Address </mat-label>
        <input
          matInput
          formControlName="email"
          [required]="isEditing"
          [readonly]="!isEditing"
          placeholder="Email Address"
          (change)="confirmEmailChange.open()"
        />
      </mat-form-field>
      <lib-edit-phone-redesign
        label="Primary Phone Number"
        [formGroup]="contactInfoForm['controls'].primaryPhone"
        [required]="isEditing"
        [readonly]="!isEditing"
      ></lib-edit-phone-redesign>
      <lib-edit-phone-redesign
        label="Secondary Phone Number"
        [formGroup]="contactInfoForm['controls'].secondaryPhone"
        [readonly]="!isEditing"
        *ngIf="!settings.architectProfileSettings.hideSecondaryPhoneNumber"
      ></lib-edit-phone-redesign>
    </div>
  </form>
</lib-titled-card>

<lib-confirm
  #confirmEmailChange="confirm"
  yesText="Yes"
  noText="No"
  [optIn]="true"
  prompt="Email Changed"
  (no)="revertEmailChange()"
>
  <ng-template>
    Note: changing the email address will also change the user's login. Are you sure you want to change the email?
  </ng-template>
</lib-confirm>
