const config = window['__CONFIG__'];

function isLocalHost(): boolean {
  return window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost';
}

export function isProdModeEnabled(): boolean {
  return isLocalHost() ? false : true;
}

export function allowedDomains(): [string] {
  return isLocalHost() ? ['localhost:8080'] : [window.location.host];
}

export function getApiUrl(): string {
  return isLocalHost() ? `http://localhost:8080/api/` : '/api/';
}

export function dataDogSettings(): { service; env } {
  return config ? config.datadog : {};
}
