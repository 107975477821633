import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { ActivatedRoute } from '@angular/router';
import {
  Confirmations,
  JurisdictionConfirmationService,
} from '../../../constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { CertificationContent, JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';

@Component({
  selector: 'lib-firm-application-confirmation',
  templateUrl: './firm-application-confirmation.component.html',
})
export class FirmApplicationConfirmationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() application: FirmApplication;
  confirmations: Confirmations;
  content: CertificationContent;
  constructor(
    private route: ActivatedRoute,
    public constants: JurisdictionConstants,
    private confirmationService: JurisdictionConfirmationService
  ) {}
  ngOnInit(): void {
    this.confirmations = this.confirmationService.getFirmConfirmations(this.route.snapshot.data.application);
    this.content = this.certificationContentDefaults(this.constants.applicationStepContent.certification);
  }

  certificationContentDefaults = (certification: CertificationContent = {}): CertificationContent => {
    return {
      headerSubtextContent: `Using the check${
        this.confirmations.laws.length > 1 ? 'boxes' : 'box'
      } below, please attest to understanding and reviewing the applicable rules and laws.`,
      attestationsLabel: `I ${this.confirmations.laws.length > 0 ? 'also ' : ''}certify that:`,
      headerSubtext: 'Certification',
      ...certification,
    };
  };
}
