import { Component, OnInit } from '@angular/core';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { ListComponent } from 'projects/common/src/lib/components/list';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { ApplicationSettingsService } from '../../../resolves/application-settings.resolve';

@Component({
  selector: 'app-person-lawful-presence-proof-list',
  templateUrl: './person-lawful-presence-proof-list.component.html',
  styleUrls: ['./person-lawful-presence-proof-list.component.scss'],
})
export class PersonLawfulPresenceProofListComponent extends ListComponent<ArchitectApplication> implements OnInit {
  columns = ['applicationType', 'submittedOn', 'orderNumber', 'isUsCitizen'];
  citizenText: string;
  nonCitizenText: string;

  constructor(
    public settings: TenantSettings,
    private applicationSettingsService: ApplicationSettingsService
  ) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
    const configuration = await this.applicationSettingsService.getByVariant('ArchitectInitial'); //could also use Reciprocal or Reinstatement
    this.citizenText = configuration.stepConfiguration.lawfulPresence.isCitizenText;
    this.nonCitizenText = configuration.stepConfiguration.lawfulPresence.isNonCitizenText;
  }
}
