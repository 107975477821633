import { Injectable } from '@angular/core';
import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from 'projects/common/src/lib/models/order';
import { ProcessPayment } from '../../models/process-payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private api: ApiService) {}

  getOrder(orderId): Promise<Order> {
    return this.api.getAsync(`order/${orderId}/`);
  }

  getOrderByApplicationId(applicationId): Promise<Order> {
    return this.api.getAsync(`order/${applicationId}/byApplicationId`);
  }

  getOrderForPayment(orderId): Promise<Order> {
    return this.api.getAsync(`order/${orderId}/forpayment`);
  }

  toggleDelinquencyFee(orderId, doOverride: boolean): Observable<Order> {
    const method = doOverride ? this.api.post : this.api.delete;
    return method.call(this.api, `order/${orderId}/delinquencyFeeOverride`, {}).pipe(map(data => new Order(data)));
  }

  submitPaymentToken(orderId, payload: ProcessPayment): Promise<Order> {
    return this.api.postAsync(`order/${orderId}/payment`, payload, false);
  }

  submitCheckPayment = (orderId, manualPayment): Promise<any> =>
    this.api.postAsync(`order/${orderId}/checkPayment`, {
      paymentType: manualPayment.paymentType,
      checkNumber: manualPayment.checkNumber,
      NameOnCheck: manualPayment.nameOnCheck,
    });

  submitFutureCheckPayment(orderId): Promise<Order> {
    return this.api.postAsync(`order/${orderId}/submitfuturecheckpayment`, {});
  }

  submitFutureAchPayment(orderId, lastFour: string): Promise<Order> {
    return this.api.postAsync(`order/${orderId}/submitFutureAchPayment`, {
      lastFour: lastFour,
    });
  }

  submitNoCostPayment(orderId): Promise<Order> {
    return this.api.postAsync(`order/${orderId}/submitnocostorder`, {});
  }

  getOrderForAch(orderId): Promise<Order> {
    return this.api.getAsync(`order/${orderId}/forAch`);
  }

  getOrderForCheckPayment(orderId): Promise<Order> {
    return this.api.getAsync(`order/${orderId}/forcheckpayment`);
  }
}
