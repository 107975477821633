import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { State } from 'projects/common/src/lib/models/address';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import {
  JurisdictionConfirmationService,
  LegendWithNote,
} from 'projects/common/src/lib/constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-supervisors-form',
  templateUrl: './supervisors-form.component.html',
  styleUrls: ['./supervisors-form.component.scss'],
})
export class SupervisorsFormComponent implements OnInit {
  @Input() application: FirmApplication;
  @Input() form: FormGroup;
  @Input() states?: State[];
  @Input() readonly: boolean;

  legendWithNotes: LegendWithNote[];

  submitAttempted: boolean;
  today = new Date();
  maximumSupervisors: number = 0;
  supervisingProfessionalSectionHeader: string = 'Supervising Professional Architect(s)';
  supervisingProfessionalDescription: string =
    'Please designate a supervising professional to take responsibility of the firm and the work it completes in this jurisdiction.';
  supervisingProfessionalArchSelectLabel: string = 'Supervising Professional Architect';

  constructor(
    private confirmationService: JurisdictionConfirmationService,
    public constants: JurisdictionConstants,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.legendWithNotes = this.confirmationService.getSupervisorNotes(this.application);
    this.maximumSupervisors = this.constants.firmApplicationSettings?.maximumSupervisors ?? 0;
    this.setSupervisingProfessionalLabels();
  }

  get isAEApplication() {
    return this.application.licenseType === 'ArchitecturalEngineering';
  }

  get showState() {
    return this.constants.firmApplicationSettings?.askForStateInFirmSupervisor ?? false;
  }

  get incorporationLabel() {
    return this.constants.firmApplicationSettings?.incorporationDateLabel;
  }

  setSupervisingProfessionalLabels() {
    if (!!this.constants.applicationStepContent.businessInformationContent?.supervisingProfessionalSectionHeader)
      this.supervisingProfessionalSectionHeader =
        this.constants.applicationStepContent.businessInformationContent.supervisingProfessionalSectionHeader;

    if (!!this.constants.applicationStepContent.businessInformationContent?.supervisingProfessionalDescription)
      this.supervisingProfessionalDescription =
        this.constants.applicationStepContent.businessInformationContent.supervisingProfessionalDescription;

    if (!!this.constants.applicationStepContent.businessInformationContent?.supervisingProfessionalArchSelectLabel)
      this.supervisingProfessionalArchSelectLabel =
        this.constants.applicationStepContent.businessInformationContent.supervisingProfessionalArchSelectLabel;
  }
}
