<div class="upload-container" *ngIf="docsList$ | async as docList">
  <div class="button-upload">
    <button mat-button type="button" color="primary" (click)="open()">
      {{ this.getLabelUploadText(docList) }}
    </button>
  </div>
  <div class="file-items-container">
    <ul>
      <li *ngFor="let docRef of docList">
        <a href="#" (click)="download(docRef)" class="file-name">
          <span>{{ docRef.fileNameWithoutTimestamp }}</span>
        </a>

        <a class="imagelink" href="#" (click)="confirmDelete.open()">
          <mat-icon style="vertical-align: top">delete</mat-icon>
        </a>
        <lib-confirm
          #confirmDelete="confirm"
          [prompt]="'Delete ' + docRef.fileNameWithoutTimestamp + '?'"
          yesText="Yes, delete"
          noText="No, cancel"
          (yes)="delete(docRef)"
        ></lib-confirm>
      </li>
    </ul>
  </div>
</div>
