import { FormBuilder, FormGroup } from '@angular/forms';
import { Phone } from './phone';
import { PersonLite } from './person-lite';
import { UpdatableModel } from './updatable-model';

export class FirmPointOfContact extends UpdatableModel<FirmPointOfContact> {
  id: string;
  firmId: string;
  email: string;
  title: string;
  phone: Phone;
  person: PersonLite;
  licenseNumber: string;
  isActive: boolean;

  update(init?: Partial<FirmPointOfContact>) {
    super.update(init);
    if (this.person) {
      this.person = new PersonLite(this.person);
    }
    if (this.phone) {
      this.phone = new Phone(this.phone);
    }
  }

  acceptPatch(formGroup: FormGroup) {
    this.update(formGroup.value);
  }

  patch(formGroup: FormGroup) {
    this.id = this.id || '';
    this.title = this.title || '';
    this.email = this.email || '';
    this.phone = this.phone || new Phone();
    formGroup.patchValue(this);
    this.person.patch(formGroup.controls.person as FormGroup);
    this.phone.patch(formGroup.controls.phone as FormGroup);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      firmId: '',
      email: '',
      title: '',
      phone: Phone.GetFormGroup(formBuilder),
      person: PersonLite.GetFormGroup(formBuilder),
      licenseNumber: '',
    });
  }
}
