import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route } from '@angular/router';

import { LibModule } from 'projects/common/src/lib/lib.module';
import { ArchitectReviewComponent } from './architect-review/architect-review.component';
import { WorkHistoryReviewComponent } from './architect-review/work-history-review/work-history-review.component';
import { MoralCharacterReviewComponent } from './moral-character-review/moral-character-review.component';
import { FirmReviewComponent } from './firm-review/firm-review.component';
import { ReviewFooterComponent } from './review-footer/review-footer.component';
import { ReviewPaymentComponent } from './review-payment/review-payment.component';
import { ArchitectApplicationReviewResolve } from './resolves/architect-application-review.resolve';
import { FirmApplicationReviewResolve } from './resolves/firm-application-review.resolve';
import { FirmReviewInfoComponent } from './firm-review/firm-review-info/firm-review-info.component';
import { MilitaryInfoReviewComponent } from './architect-review/military-info-review/military-info-review.component';
import { MilitaryInformationResolve } from '../../resolves/military-information.resolve';
import { StatesResolve } from '../../resolves/states.resolve';
import { FirmPointOfContactResolve } from '../../resolves/firm-point-of-contact.resolve';
import { ApplicationSettingsResolve } from '../../resolves/application-settings.resolve';
import { WorkHistoryForArchAppResolve } from '../../resolves/work-history-for-arch-app.resolve';
import { PersonalInfoResolve } from '../../resolves/personal-info.resolve';
import { HomeAddressesResolve, WorkAddressesResolve } from '../../resolves/personal-addresses.resolve';
import { AddressLookupsResolve } from '../../resolves/address-lookups.resolve';
import { OrderReviewResolve } from './resolves/order-review.resolve';
import { QuestionnaireResponseResolve } from '../../resolves/questionnaire-response.resolve';
import { ReviewSectionHeaderComponent } from './review-section-header/review-section-header.component';

const commonResolves = {
  order: OrderReviewResolve,
  questionnaireResponse: QuestionnaireResponseResolve,
  addressLookups: AddressLookupsResolve,
  applicationSettings: ApplicationSettingsResolve,
};

type reviewRoutes = { architectReviewRoute: Route; firmReviewRoute: Route };

export const ReviewRoutes = (architectReviewPath: string, firmReviewPath: string, canActivateGuard): reviewRoutes => {
  return {
    architectReviewRoute: {
      path: architectReviewPath,
      component: ArchitectReviewComponent,
      resolve: {
        ...commonResolves,
        homeAddress: HomeAddressesResolve,
        workAddress: WorkAddressesResolve,
        personalInfo: PersonalInfoResolve,
        workHistory: WorkHistoryForArchAppResolve,
        application: ArchitectApplicationReviewResolve,
        militaryInformation: MilitaryInformationResolve,
      },
      canActivate: [canActivateGuard],
    },
    firmReviewRoute: {
      path: firmReviewPath,
      component: FirmReviewComponent,
      resolve: {
        ...commonResolves,
        states: StatesResolve,
        application: FirmApplicationReviewResolve,
        pointOfContact: FirmPointOfContactResolve,
      },
      canActivate: [canActivateGuard],
    },
  };
};

@NgModule({
  imports: [CommonModule, LibModule],
  declarations: [
    ArchitectReviewComponent,
    WorkHistoryReviewComponent,
    MoralCharacterReviewComponent,
    FirmReviewComponent,
    FirmReviewInfoComponent,
    ReviewSectionHeaderComponent,
    ReviewFooterComponent,
    ReviewPaymentComponent,
    MilitaryInfoReviewComponent,
  ],
  providers: [
    ArchitectApplicationReviewResolve, //move to lib module? used other places
    FirmApplicationReviewResolve,
    OrderReviewResolve,
    QuestionnaireResponseResolve,
    PersonalInfoResolve,
    WorkHistoryForArchAppResolve,
    MilitaryInformationResolve,
    StatesResolve,
  ],
  exports: [ReviewFooterComponent, FirmReviewComponent, ArchitectReviewComponent, ReviewSectionHeaderComponent],
})
export class ApplicationReviewModule {}
