import { Component, OnInit, Inject, TemplateRef, AfterViewInit, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-confirm-dialogue',
  templateUrl: './confirm.dialogue.html',
  styleUrls: ['./confirm.dialogue.scss'],
})
export class ConfirmDialogue implements OnInit, AfterViewInit {
  template: TemplateRef<any>;
  prompt: string;
  isError: boolean;
  yesText: string;
  noText: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data) {}

  @HostBinding('class.opt-in') optIn = false;

  ngOnInit() {
    this.prompt = this.data.prompt;
    this.isError = this.data.isError;
    this.yesText = this.data.yesText;
    this.noText = this.data.noText;
    this.optIn = this.data.optIn;
  }

  ngAfterViewInit(): void {
    if (this.data.template) {
      this.template = this.data.template;
    }
  }
}
