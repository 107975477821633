import { Component } from '@angular/core';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-env-banner',
  templateUrl: './env-banner.component.html',
  styleUrls: ['./env-banner.component.scss'],
})
export class EnvBannerComponent {
  constructor(public settings: TenantSettings) {}
}
