import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { S3Service } from '../../services/api/s3.service';
import { NotificationService } from '../../services/notification.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'lib-upload-listener',
  template: '',
})
export class UploadListenerComponent extends BaseComponent implements OnInit {
  /**
   * Custom string inserted in the upload text
   */
  @Input() filesTitle: string;

  //will either have a docSearch in put OR
  @Input() docSearch: S3DocumentSearch;
  //If No docSearch; should have documentType, aggregateRootId, and possibly subjectId
  @Input() documentType: string;
  @Input() aggregateRootId: string;
  @Input() subjectId: string;

  /**
   * Whether or not API should pre-populate the S3 presigned URL link
   */
  @Input() prePopulateUrl: boolean = false;

  @Input() docsList$: ReplaySubject<S3DocumentReference[]>;
  /**
   * Event triggered when new documents are uploaded
   */
  @Output() onDocListChange: EventEmitter<S3DocumentReference[]>;

  constructor(
    protected s3Service: S3Service,
    protected notificationService: NotificationService
  ) {
    super();
    this.onDocListChange = new EventEmitter<S3DocumentReference[]>();
  }

  ngOnInit() {
    this.docsList$ = this.docsList$ || new ReplaySubject<S3DocumentReference[]>();
    this.docSearch =
      this.docSearch ||
      new S3DocumentSearch({
        aggregateRootId: this.aggregateRootId,
        subjectId: this.subjectId,
        documentType: this.documentType,
        prePopulateUrl: this.prePopulateUrl,
      });

    // Observe getting docs or deleting docs
    this.unsubOnDestroy = this.s3Service.getRefs(this.docSearch).subscribe(docs => {
      this.docsList$.next(docs);
    });

    // For parent controls to get list of docs
    this.unsubOnDestroy = this.docsList$.subscribe(docs => {
      this.onDocListChange.emit(docs);
    });
  }

  download(docRef: S3DocumentReference) {
    this.s3Service.open(docRef);
    return false;
  }

  /**
   * Deletes a document from the appropriate S3 bucket
   * @param doc The file/S3 document to be deleted
   * @returns
   */
  async delete(doc: S3DocumentReference): Promise<S3DocumentReference[]> {
    this.notificationService.notifyWait('Deleting ' + doc.fileNameWithoutTimestamp);
    await this.s3Service.delete(doc);
    const docs = await firstValueFrom(this.s3Service.getRefs(this.docSearch));
    this.notificationService.notifySuccess(doc.fileNameWithoutTimestamp + ' deleted');
    this.docsList$.next(docs);
    return docs;
  }
}
