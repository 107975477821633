import { Observable, of } from 'rxjs';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { map } from 'rxjs/operators';
import { FileInfo } from '../../models/file-info';
import { HttpErrorResponse } from '@angular/common/http';

const path = 'document/';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor(private api: ApiService) {}

  private getDocSearchUri = (search: S3DocumentSearch, subRoute: string = ''): string =>
    `${path}${search.path}${subRoute}${this.getDocSearchQueryString(search)}`;

  private getDocSearchQueryString = (search: S3DocumentSearch) =>
    !search.documentType && !search.showAll && !search.prePopulateUrl
      ? '' //if no documentType, showAll, or prePopulateUrl; we can leave it off the querystring
      : `?documentType=${search.documentType}&showAll=${search.showAll}&prePopulateUrl=${search.prePopulateUrl}`;

  getRefs(search: S3DocumentSearch): Observable<S3DocumentReference[]> {
    let queryString = '';
    if (search.documentType || search.showAll || search.prePopulateUrl) {
      queryString = `?documentType=${search.documentType}&showAll=${search.showAll}&prePopulateUrl=${search.prePopulateUrl}`;
    }

    return this.api.get(this.getDocSearchUri(search)).pipe(map(data => data.map(docRef => new S3DocumentReference(docRef))));
  }

  getLatestRef(search: S3DocumentSearch): Promise<S3DocumentReference> {
    return this.api
      .getAsync(this.getDocSearchUri(search, '/latest'))
      .then(json => (json ? new S3DocumentReference(json) : null));
  }

  async getLink(document: S3DocumentReference): Promise<string> {
    return await this.api.getAsync(`${path}${document.safePath}`);
  }

  async open(document: S3DocumentReference): Promise<string> {
    const link = await this.getLink(document);
    window.open(link);
    return link;
  }

  upload(
    docRef: S3DocumentReference,
    fileInfo: FileInfo,
    errorHandler: (errorResponse: HttpErrorResponse) => Observable<any> = null
  ): Observable<S3DocumentReference[]> {
    let formData = new FormData();
    formData.append(fileInfo.name, fileInfo.rawFile);
    return this.api
      .post(
        `${path}${docRef.path}`,
        formData,
        {
          /* do not send content-type header */
        },
        errorHandler
      )
      .pipe(map(data => data.map(docRef => new S3DocumentReference(docRef))));
  }

  delete(docRef: S3DocumentReference): Promise<any> {
    return this.api.deleteAsync(`${path}${docRef.safePath}`);
  }
}
