import { Injectable } from '@angular/core';
import { ArchitectApplication } from '../../../models/applications/architect-application';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import { Confirmations, JurisdictionConfirmationService, LegendWithNote } from './jurisdiction-confirmation-service';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';

@Injectable({
  providedIn: 'root',
})
export class LAConfirmationService extends JurisdictionConfirmationService {
  attestations = ['The information I have provided on this form is true and accurate to the best of my knowledge.'];
  commonLaws = [
    `${this.settings.stateName} Architect Licensing Law (<a href="${this.constants.commonLinks.rules}" target="_blank">La. R.S. 37:141 et seq.</a>)`,
    `${this.settings.boardName} Rules (<a href="${this.constants.commonLinks.rules}" target="_blank">LAC Title 46:I§101-2303</a>)`,
  ];

  getArchitectConfirmations(application: ArchitectApplication): Confirmations {
    return {
      laws: this.commonLaws,
      attestations: this.attestations,
    };
  }

  getShareHolderNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and own the number of shares listed
    (no percentages) and that the following number of total shares is correct. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">La. R.S. 12:1090(B)</a>
    which requires that a majority stock in a professional architectural corporation must be owned by the individually licensed ${this.settings.stateName} architects.`;
  }

  getBoardOfDirectorsNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and serve as Directors of the corporation. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">La. R.S. 12:1095</a>
    which requires that a majority of the board of directors, if more than two, shall be licensed ${this.settings.stateName} architects or, if there are less than three directors, at least one must be a licensed ${this.settings.stateName} architect.`;
  }

  getSupervisorNotes(application: FirmApplication): LegendWithNote[] {
    if (application.licenseType === 'ArchitecturalFirm') {
      return [
        {
          legend: null,
          note: `<a href="${this.constants.commonLinks.rules}" target="_blank">Board Rule §1705(G)</a>
          provides that the architectural firm shall designate in its application for certificate of authority one or more
          supervising professional architect(s) who shall perform or directly supervise the performance of all
          architectural services by said firm in ${this.settings.stateName}. Performing or directly supervising the performance of all
          architectural services shall mean unrestricted, unchecked, and unqualified command of, and legal accountability
          for, the architectural services performed. Specifications, drawings, or other related documents will be deemed
          to have been prepared by the architect or under the architect’s direct supervision only when the requirements of
          <a href="${this.constants.commonLinks.rules}" target="_blank">Board Rule §1313</a> are fully satisfied. Only natural
          persons (i) who are licensed by the Board, (ii) who are full-time active employees of the architectural firm,
          and (iii) whose primary occupation is with the architectural firm may be designated as a supervising
          professional architect. The following architects are hereby designated as the supervising professional
          architects of the Architectural Firm according to
          <a href="${this.constants.commonLinks.rules}" target="_blank">Board Rule §1705</a>:`,
        },
      ];
    }
    if (application.licenseType === 'ArchitecturalEngineering') {
      return [
        {
          legend: null,
          note: `<a href="${this.constants.commonLinks.rules}" target="_blank">LA R.S. 12:1173</a>
          requires the applicant to designate the supervising professional architect(s) who shall perform or directly
          supervise the performance of all professional architectural services by said corporation, and further
          designate the supervising professional engineer who shall perform or directly supervise the performance of all
          professional civil, mechanical or electrical engineering services or those services in any other designated
          branch of professional engineering which the State Board of Registration for Professional Engineers and Land
          Surveyors and the State Board of Architectural Examiners may jointly specify. Said statute further provides
          that persons designated must be full-time active employees of the corporation and whose primary occupation is
          with that corporation.`,
        },
        {
          legend: 'Designated Supervising Professional Architect(s)',
          note: `The following architects are hereby designated as the supervising professional architects according to
          <a href="${this.constants.commonLinks.rules}" target="_blank">LA R.S. 12:1173</a>:`,
        },
      ];
    }
    return [];
  }

  getFirmConfirmations(application: FirmApplicationFull): Confirmations {
    const laws = [];
    if (application.licenseType == 'ProfessionalArchitectural') {
      laws.push(
        `${application.licenseTypeName} Law (<a href="${this.constants.commonLinks.rules}" target="_blank">LAR.S. 12:1086 et. seq.</a>)`
      );
    }
    if (application.licenseType == 'ProfessionalArchitectural' || application.licenseType == 'ArchitecturalFirm') {
      laws.push(...this.commonLaws);
    }
    if (application.licenseType == 'ArchitecturalEngineering') {
      laws.push(
        `${application.licenseTypeName} Law (<a href="${this.constants.commonLinks.rules}" target="_blank">LA R.S. 12:1171 et. seq.</a>)`
      );
    }
    return {
      laws,
      attestations: this.attestations,
    };
  }
}
