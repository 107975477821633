import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-titled-card-upload-widget',
  templateUrl: './titled-card-upload-widget.component.html',
  styleUrls: ['./titled-card-upload-widget.component.scss'],
})
export class TitledCardUploadWidgetComponent implements OnChanges {
  constructor(private settings: TenantSettings) {}
  docSearch: S3DocumentSearch;
  @Input()
  aggregateRootId: string;
  @Input()
  documentType?: string;
  editMode = false;
  helpToolTip = 'Only manually uploaded documents listed here. Find other document types in their corresponding tabs.';

  ngOnChanges(changes: SimpleChanges): void {
    const aggregateRootId =
      changes.aggregateRootId && changes.aggregateRootId.previousValue != changes.aggregateRootId.currentValue
        ? changes.aggregateRootId.currentValue
        : this.docSearch?.aggregateRootId;
    const documentType =
      changes.documentType && changes.documentType.previousValue != changes.documentType.currentValue
        ? changes.documentType.currentValue
        : this.docSearch?.documentType;
    if (aggregateRootId == this.docSearch?.aggregateRootId && documentType == this.docSearch?.documentType) {
      return; //no change
    }
    if (documentType) {
      this.helpToolTip = 'Only manually uploaded documents listed here. Find other document types in their corresponding tabs';
      this.docSearch = new S3DocumentSearch({
        aggregateRootId: aggregateRootId,
        subjectId: aggregateRootId,
        documentType: documentType,
      });
    } else {
      this.helpToolTip = this.buildShowAllToolTip();
      this.docSearch = new S3DocumentSearch({
        aggregateRootId: aggregateRootId,
        showAll: true,
      });
    }
  }
  //vary the tool tip based on jurisdiction settings
  private buildShowAllToolTip() {
    let docTypesDescription = ' ';
    if (this.settings.hasLawfulPresence && this.settings.hasPersonalIdentification) {
      docTypesDescription = ', lawful presence, and personal identification ';
    } else if (this.settings.hasLawfulPresence || this.settings.hasPersonalIdentification) {
      docTypesDescription = ` and ${this.settings.hasLawfulPresence ? 'lawful presence' : 'personal identification'} `;
    }

    return `Only manually uploaded${docTypesDescription}documents listed here. Find other document types in their corresponding tabs`;
  }
}
