<lib-card *ngIf="supervisors">
  <ls-headline class="pb-small">Edit Supervising Professional Information</ls-headline>
  <ls-banner variant="inactive" class="mt-small mb-large">
    Please note that, while directors and shareholders show up in the list of Supervising Professionals, they cannot be edited
    here. As such only supervisors show in the editable list below.
  </ls-banner>

  <lib-multi-arch-select
    [formArray]="supervisors"
    [maximumSelectable]="maximumSupervisors"
    role="Supervisor"
    [readonly]="false"
    [allowNone]="true"
  >
  </lib-multi-arch-select>

  <div *ngIf="isPortal && !!spSettings.showDocumentUploadOnPortalEdit" class="mt-large mb-large">
    <ls-title level="1" *ngIf="spSettings.documentUploadOnPortalEditHeaderText">
      {{ spSettings.documentUploadOnPortalEditHeaderText }}
    </ls-title>
    <ls-p
      *ngIf="spSettings.documentUploadOnPortalEditText"
      [innerHTML]="spSettings.documentUploadOnPortalEditText"
      class="mb-large"
    ></ls-p>

    <!-- we're telling the upload control that no documents are required to hide its error handling because we're handling it ourselves -->
    <lib-upload-widget
      [aggregateRootId]="aggregateRootId"
      documentType="Other"
      viewStyle="None"
      noDocumentsMessage=""
      [uploadsRequired]="false"
      (onDocListChange)="onDocListChange()"
    ></lib-upload-widget>

    <div id="supportingDocumentsFeedback" class="d-flex py-small gap-small">
      <lib-pill variant="error" *ngIf="docCount == 0 && !!spSettings.requireDocumentUploadOnPortalEdit">
        Please upload a document
      </lib-pill>

      <ls-p class="readonly-no-docs ls-text-grey" *ngIf="docCount == 0 && !spSettings.requireDocumentUploadOnPortalEdit">
        No document uploaded
      </ls-p>

      <lib-pill variant="success" *ngIf="docCount > 0"> Document uploaded! </lib-pill>
    </div>
  </div>

  <footer class="d-flex gap-medium">
    <a mat-stroked-button class="no-text-decoration" color="primary" type="button" routerLink="../">Back</a>
    <button mat-flat-button color="primary" type="button" (click)="save()">Save</button>
  </footer>
</lib-card>
