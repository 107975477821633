import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { FirmAvailableApplication } from 'projects/common/src/lib/models/applications/firm-available-application';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';

@Injectable()
export class FirmAvailableApplicationsResolve implements Resolve<FirmAvailableApplication[]> {
  constructor(
    private firmApplicationService: FirmApplicationService,
    private auth: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FirmAvailableApplication[]> {
    return this.firmApplicationService.getAvailable(route.params.firmId);
  }
}
