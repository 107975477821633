import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectOption } from '../../models/select-option';

@Component({
  selector: 'app-action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss'],
})
export class ActionSelectorComponent {
  @Input() actions: SelectOption[];
  @Output() actionSelected = new EventEmitter<string>();
  selectedAction = '';

  onAction(value) {
    setTimeout(_ => (this.selectedAction = ''), 0);
    this.actionSelected.emit(value);
  }
}
