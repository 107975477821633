import { UpdatableModel } from './updatable-model';
import { FirmLookup } from './firm-lookup';
import { Phone } from './phone';
import { PersonLite } from './person-lite';

// TODO refactor WorkHistoryForArchApp out of arch app review
// and consolilate to just WorkHistory
export class WorkHistoryBase<T> extends UpdatableModel<T> {
  id: string;
  email: string;
  title: string;
  phone: Phone;
  firm: FirmLookup;
  person: PersonLite;

  update(init?: Partial<T>) {
    super.update(init);
    if (this.person) {
      this.person = new PersonLite(this.person);
    }
    if (this.phone) {
      this.phone = new Phone(this.phone);
    }
    if (this.firm) {
      this.firm = new FirmLookup(this.firm);
    }
  }
}
