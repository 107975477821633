<lib-titled-card>
  <h3>{{ title }}</h3>
  <table mat-table [dataSource]="datasource" class="simple-table equal-widths last-col-longer">
    <ng-container matColumnDef="orderNumber">
      <th mat-header-cell *matHeaderCellDef>Confirmation<br />Number</th>
      <td mat-cell *matCellDef="let application; let i = index">
        <a href="#" *ngIf="application.snapshotDocumentRef; else noS3" (click)="openS3Link(application)">
          {{ application.orderNumber }}
        </a>
        <ng-template #noS3>
          <a target="_blank" [routerLink]="application.reviewRoute">
            {{ application.orderNumber }}
          </a>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="residency">
      <th mat-header-cell *matHeaderCellDef>Residency</th>
      <td mat-cell *matCellDef="let application">{{ application.residency }}</td>
    </ng-container>
    <ng-container matColumnDef="applicationType">
      <th mat-header-cell *matHeaderCellDef>Application</th>
      <td mat-cell *matCellDef="let application">{{ application.typeName }}</td>
    </ng-container>
    <ng-container matColumnDef="profession">
      <th mat-header-cell *matHeaderCellDef>Profession</th>
      <td mat-cell *matCellDef="let application">{{ application.professionDescription }}</td>
    </ng-container>
    <ng-container matColumnDef="blank">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let application"></td>
    </ng-container>
    <ng-container matColumnDef="transmittalDate">
      <th mat-header-cell *matHeaderCellDef>Transmittal<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.createdOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="applicationDate">
      <th mat-header-cell *matHeaderCellDef>Application<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.submittedOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="paymentDate">
      <th mat-header-cell *matHeaderCellDef>Payment<br />Date</th>
      <td mat-cell *matCellDef="let application">{{ application.orderCompletedOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="approvedOn">
      <th mat-header-cell *matHeaderCellDef>Issued Date</th>
      <td mat-cell *matCellDef="let application">{{ application.approvedOn | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="wider">Application<br />Status</th>
      <td mat-cell *matCellDef="let application">
        <ng-container
          *ngTemplateOutlet="statusTemplate ?? defaultStatusTemplate; context: { $implicit: application }"
        ></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="noItems">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let application" [attr.colspan]="columns.length">No {{ title }} on file</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: noItemsColumns; when: isEmpty"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  </table>
  <table>
    <th mat-header-cell *matHeaderCellDef>No {{ title }} on file</th>
  </table>
</lib-titled-card>

<ng-template let-application #defaultStatusTemplate>
  <span>{{ application | applicationStatus }}</span>
</ng-template>
