import { UpdatableModel } from '../updatable-model';
import { S3DocumentReference } from './s3-document-reference';

export class S3DocumentSearch extends UpdatableModel<S3DocumentSearch> {
  aggregateRootId: string;
  subjectId?: string;
  documentType?: string;
  showAll: boolean;
  prePopulateUrl?: boolean;

  get path() {
    if (this.subjectId) {
      return `${this.aggregateRootId}/${this.subjectId}`;
    }
    return this.aggregateRootId;
  }

  AsDocumentReferenceForUpload(fileName: string) {
    var ref = new S3DocumentReference(this);
    ref.path = `${this.aggregateRootId}/${this.subjectId ?? this.aggregateRootId}/${this.documentType ?? 'Other'}`;
    ref.fileName = fileName;
    return ref;
  }
}
