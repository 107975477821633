import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MilitaryInformation } from '../models/military-information';
import { MilitaryInformationService } from '../services/api/military-information.service';

@Injectable()
export class MilitaryInformationResolve implements Resolve<MilitaryInformation> {
  constructor(private militaryInformationService: MilitaryInformationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.militaryInformationService.get(route.params.personId, route.params.applicationId);
  }
}
