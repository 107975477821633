<h1>
  {{ settings.welcome }}
</h1>
<h2 class="mt-5">
  <strong>Please verify your account</strong>
</h2>
<p>
  You will receive an email from {{ settings.supportEmail }} with a link to verify your account.
  <strong>You cannot log in until your email address is verified.</strong>
</p>

<div style="margin-top: 100px" class="note-area">
  <h5>Having trouble verifying?</h5>
  <ol>
    <li>Check your spam folder.</li>
    <li>Add {{ settings.supportEmail }} as an authorized sender to your account.</li>
    <li>
      Your account creation and verification must occur on the same device.
      <ng-template [ngIf]="!settings.hasArchPocs">
        <br /><strong>Remember: </strong>
        You should be the one completing your own own application.
      </ng-template>
    </li>
    <li>
      If none of the above worked, reach out to
      <a [href]="settings.mailToSupportEmail">{{ settings.supportEmail }}</a>
      or {{ settings.supportPhoneNumber }}.
    </li>
  </ol>
</div>
