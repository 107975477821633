import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-toggle-edit-form-group',
  templateUrl: './toggle-edit-form-group.component.html',
  styleUrls: ['./toggle-edit-form-group.component.scss'],
})
export class ToggleEditFormGroupComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() onSave = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  originalValues;
  constructor() {}

  ngOnInit() {}

  edit() {
    this.formGroup.enable();
    this.originalValues = this.formGroup.value;
    this.onEdit.emit();
    return false;
  }

  save() {
    this.onSave.emit();
    return false;
  }

  cancel() {
    this.formGroup.patchValue(this.originalValues);
    this.formGroup.disable();
    this.formGroup.disable(); // fix for double-disable needed to close name fieldset :/
    return false;
  }
}
