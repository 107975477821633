import { PipeTransform, Pipe } from '@angular/core';
import { TenantSettings } from '../constants/jurisdiction/TenantSettings';

@Pipe({
  name: 'applicationStatus',
})
export class ApplicationStatusPipe implements PipeTransform {
  constructor(private settings: TenantSettings) {}

  transform({ status, type }: { status: string; type: string }) {
    if (status !== 'Approved') {
      return status;
    }
    //approve license status is aliased based on application.type
    if (type == 'License') {
      return 'Licensed';
    } else if (type === 'Renewal') {
      return 'Renewed';
    } else if (type === 'EmeritusRenewal') {
      return this.settings.emeritusRenews ? 'Renewed, Emeritus' : 'Emeritus';
    } else if (type === 'Reinstatement') {
      return 'Reinstated';
    }
  }
}
