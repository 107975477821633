import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ArchitectLicense } from '../models/architect-license';
import { ArchitectLicenseService } from '../services/api/architect-license.service';
import { of } from 'rxjs';

@Injectable()
export class ArchitectLicensesResolve implements Resolve<ArchitectLicense[]> {
  constructor(private architectLicenseService: ArchitectLicenseService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId || route.queryParams.personId;
    if (!personId) {
      return of([]);
    }
    return this.architectLicenseService.get(personId);
  }
}
