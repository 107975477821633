import { Component, Input } from '@angular/core';
import { PersonDetailBaseComponent } from '../person-detail-base.component';
import { FormGroup } from '@angular/forms';
import { Profession } from 'projects/common/src/lib/constants/jurisdiction/profession';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-person-detail-personal-info',
  templateUrl: './person-detail-personal-info.component.html',
  styleUrls: ['./person-detail-personal-info.component.scss'],
})
export class PersonDetailPersonalInfoComponent extends PersonDetailBaseComponent {
  genders: string[];
  over10YearsAgo: Date;
  useCLARBNumber: boolean;
  @Input() requiredFields: Record<string, any>;

  constructor(public settings: TenantSettings) {
    super();
    this.genders = ['Female', 'Male'];
    this.over10YearsAgo = new Date(new Date().getFullYear() - 10, 0, -1);
    this.useCLARBNumber = this.settings.professions.some(p => p == Profession.LandscapeArchitect);
  }

  get infoForm(): FormGroup {
    return this.personProfileForm['controls'].info as FormGroup;
  }

  get namesForm(): FormGroup {
    return this.personProfileForm['controls'].names as FormGroup;
  }

  get successfulSaveMessage(): string {
    return 'Personal information was saved';
  }

  get errorSaveMessage(): string {
    return 'There was an issue updating personal information.';
  }
}
