<lib-side-nav-container>
  <aside>
    <div #sticky></div>
    <nav stickyThing [spacer]="sticky">
      <mat-nav-list class="side-links" role="navigation" snScrollSpy id="widgetLinks">
        <a
          mat-list-item
          [href]="'#' + link.path"
          [toFragment]="link.path"
          *ngFor="let link of activePageLinks"
          snScrollSpyItem
          for="widgetLinks"
        >
          {{ link.label }}
        </a>
      </mat-nav-list>
    </nav>
  </aside>

  <lib-license-status-pill *ngIf="license" class="float-right" [license]="license"></lib-license-status-pill>

  <h1>
    {{ firm.nameFull }}
  </h1>

  <lib-call-to-action *ngIf="firm.doesPointOfContactNeedVerification">
    <h2>Please Update the Point of Contact for Your Firm</h2>
    <p>
      As we prepare for renewal season, please update the point of contact information for the individual who will be authorized
      to complete the firm renewal application. All notices about the firm’s license will be sent to the Point of Contact as
      well as licensed {{ settings.stateName }} architects identified as owners, directors or a Supervising Professional.
    </p>
    <div class="mini-call-to-action" *ngIf="primaryAvailableApplication">
      <strong
        ><mat-icon class="inline warning">warning</mat-icon> &nbsp;You must update your point of contact information to begin
        your {{ primaryAvailableApplication.shortTitle | lowercase }}.</strong
      >
    </div>
    <button (click)="selectPointOfContact()" mat-button color="primary">Update Point of Contact</button>
  </lib-call-to-action>

  <lib-call-to-action *ngIf="primaryApplicationCallToAction">
    <h2>{{ primaryApplicationCallToAction.header }}</h2>
    <p>{{ primaryApplicationCallToAction.body }}</p>
    <p *ngIf="isLimitedLiabilityStart">
      Your application will be for an Architectural Firm because {{ constants.acronym }} is retiring the Limited Liability
      License.
    </p>
    <button *ngIf="primaryApplicationCallToAction.action" mat-button color="primary" (click)="gotoApplication()">
      {{ primaryApplicationCallToAction.action }}
    </button>
  </lib-call-to-action>

  <sn-scroll-spy-section id="info" for="widgetLinks"></sn-scroll-spy-section>
  <app-portal-firm-info [firm$]="firm$"> </app-portal-firm-info>

  <sn-scroll-spy-section id="address" for="widgetLinks"></sn-scroll-spy-section>
  <firm-detail-address> </firm-detail-address>

  <sn-scroll-spy-section id="pointOfContact" for="widgetLinks"></sn-scroll-spy-section>
  <app-portal-firm-point-of-contact-info [pointOfContact$]="pointOfContact$"> </app-portal-firm-point-of-contact-info>

  <sn-scroll-spy-section id="supervisors" for="widgetLinks"></sn-scroll-spy-section>
  <app-portal-firm-supervisors-info [roles$]="roles$"></app-portal-firm-supervisors-info>

  <sn-scroll-spy-section id="license" for="widgetLinks"></sn-scroll-spy-section>
  <app-portal-firm-license-info *ngIf="license" [license]="license" [canSwitch$]="canSwitch$"> </app-portal-firm-license-info>

  <sn-scroll-spy-section id="applications" for="widgetLinks"></sn-scroll-spy-section>
  <app-application-list
    title="Applications"
    [list]="applications"
    [columns]="applicationColumns"
    *ngIf="!settings.hideApplicationListOnFirmProfile"
  >
  </app-application-list>

  <sn-scroll-spy-section id="payments" for="widgetLinks"></sn-scroll-spy-section>
  <lib-titled-card *ngIf="(payments$ | async).length">
    <h2>Firm Payments</h2>
    <app-payment-list [list$]="payments$"> </app-payment-list>
  </lib-titled-card>

  <sn-scroll-spy-section id="roster" for="widgetLinks"></sn-scroll-spy-section>
  <app-portal-firm-roster [list$]="roster$" *ngIf="showRoster()"> </app-portal-firm-roster>

  <sn-scroll-spy-section id="testing" for="widgetLinks"></sn-scroll-spy-section>
  <app-setup-firm-application (change)="reload()"> </app-setup-firm-application>
</lib-side-nav-container>
