import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  @Input() note: string;
  @Output() save = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();
  constructor() {}

  doSave(note) {
    this.note = '';
    this.save.emit(note);
    return false;
  }

  doCancel() {
    this.note = '';
    this.cancel.emit();
    return false;
  }
}
