<lib-edit-address-new
  *ngIf="addressLookups && editing"
  [formGroup]="formGroup"
  [line1Label]="line1Label"
  [line2Label]="line2Label"
  [addressLookups]="addressLookups"
  [hideCounty]="hideCounty"
  [hideValidation]="hideValidation"
></lib-edit-address-new>
<lib-read-address-new
  *ngIf="addressLookups && !editing"
  [formGroup]="formGroup"
  [line1Label]="line1Label"
  [line2Label]="line2Label"
  [addressLookups]="addressLookups"
  [hideCounty]="hideCounty"
  [hideValidation]="hideValidation"
></lib-read-address-new>
