import { FormGroup } from '@angular/forms';
import { UpdatableModel } from './updatable-model';

export class MilitaryInformation extends UpdatableModel<MilitaryInformation> {
  isActiveMilitary?: boolean;
  militaryNumber: string;

  static formToModel(formGroup: FormGroup) {
    return new MilitaryInformation(formGroup.value);
  }

  static defaultModel() {
    return new MilitaryInformation({
      isActiveMilitary: null,
      militaryNumber: null,
    });
  }
}
