import { UpdatableModel } from './updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';

export class ProfessionalReference extends UpdatableModel<ProfessionalReference> {
  id: string;
  personId: string;
  applicationId: string;
  email: string;
  dateReceived: Date;
  firstName: string;
  lastName: string;

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  acceptPatch(formGroup: FormGroup) {
    this.update(formGroup.value);
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
  }

  update(init?: Partial<ProfessionalReference>) {
    super.update(init);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      email: '',
    });
  }
}
