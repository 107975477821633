import { Component, Input, Output, EventEmitter, HostBinding, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AutocompleteComponent } from 'projects/common/src/lib/components/autocomplete/autocomplete.component';
import { SearchApiService } from '../../services/api/search/search-api.service';

@Component({
  selector: 'lib-firm-license-autocomplete',
  templateUrl: './firm-license-autocomplete.component.html',
  styleUrls: ['./firm-license-autocomplete.component.scss'],
})
export class FirmLicenseAutocompleteComponent implements OnInit {
  @HostBinding('class.mat-form-field') matFormField = true;
  @HostBinding('class.readonly') readonlyClass;
  @Input() control: FormControl;
  @Input() readonly: boolean;
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() cleared = new EventEmitter<MatAutocompleteSelectedEvent>();
  @ViewChild(AutocompleteComponent) autocomplete: AutocompleteComponent;
  label = 'Select Firm Registration';
  placeholder = 'Enter firm license number';
  search = (text: string) => {
    return this.searchApiService.getFirmLicenseLookup(text, null, null, null, true);
  };

  constructor(private searchApiService: SearchApiService) {}

  ngOnInit(): void {
    if (this.readonly) {
      this.readonlyClass = true;
      this.label = 'Firm Registration';
    }
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit($event);
  }

  onCleared($event: MatAutocompleteSelectedEvent) {
    this.cleared.emit($event);
  }
}
