import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FirmRosterMember } from '../models/firm-roster-member';
import { FirmService } from '../services/api/firm.service';

@Injectable()
export class FirmRosterResolve implements Resolve<FirmRosterMember[]> {
  constructor(private firmService: FirmService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FirmRosterMember[]> {
    return this.firmService.getRoster(route.params.firmId);
  }
}
