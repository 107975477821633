import { Component, OnInit } from '@angular/core';
import { AuthTokenService, Tokens } from 'projects/common/src/lib/services/auth/auth-token-service';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';

@Component({
  selector: 'app-timedout',
  templateUrl: 'timedout.component.html',
  styleUrls: ['timedout.component.scss'],
})
export class TimedoutComponent implements OnInit {
  description;
  auth0ErrorDescriptionTranslations = AuthService.ErrorDescriptionTranslations;
  constructor(
    private authTokenService: AuthTokenService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    let error = this.authTokenService.get(Tokens.error);
    if (error) {
      this.description = error.errorDescription;
      this.preventCircularGlobalAuthLoginWithNeedingVerification();
      if (this.description === 'QuotaExceededError') {
        localStorage.clear();
      } else {
        this.authTokenService.unsetAll();
      }
    }
  }

  preventCircularGlobalAuthLoginWithNeedingVerification() {
    if (this.description == 'Please verify your email before logging in.') {
      if (localStorage.getItem('verificationneeded')) {
        localStorage.removeItem('verificationneeded');
        this.auth.logout();
      } else {
        localStorage.setItem('verificationneeded', 'true');
      }
    }
  }
}
