import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { UpdatableModel } from '../updatable-model';
import { QuestionnaireResponseAnswer } from './questionnaire-response-answer';

export class QuestionnaireResponse extends UpdatableModel<QuestionnaireResponse> {
  id: string;
  name: string;
  updatedOn: Date;
  answers: QuestionnaireResponseAnswer[];

  acceptPatch(formGroup: FormGroup) {
    let answersArray = formGroup.controls.answers as FormArray;
    this.answers.length = 0;
    answersArray.controls.forEach(q => {
      let group = q as FormGroup;
      let answer = new QuestionnaireResponseAnswer();
      let info = Object.assign({}, group.value);
      answer.update(info);
      this.answers.push(answer);
    });
  }

  patch(formGroup: FormGroup, formBuilder: FormBuilder) {
    formGroup.controls.id.setValue(this.id);
    let answersArray = formGroup.controls.answers as FormArray;
    answersArray.controls.length = 0;
    this.answers?.forEach(q => {
      let fg = QuestionnaireResponseAnswer.GetFormGroup(formBuilder);
      answersArray.controls.push(fg);
      q.hasDocuments = null;
      fg.setValue(q);
    });
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      answers: formBuilder.array([]),
    });
  }
}
