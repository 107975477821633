import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { Address } from '../models/address';
import { Injectable } from '@angular/core';

export type addressType = 'home' | 'work' | 'firm';

export type homeAndWorkAddress = { homeAddress: Address; workAddress: Address };

@Injectable()
export class AddressService {
  constructor(private api: ApiService) {}

  async getAddress(personOrFirmId: string, addressType: addressType, handleError: boolean = true): Promise<Address> {
    return await this.api.getAsync<Address>(`address/${personOrFirmId}/${addressType}`, handleError);
  }

  async getHomeAndWorkAddress(personId, hideHomeAddress: boolean): Promise<homeAndWorkAddress> {
    const workAddressPromise = this.getAddress(personId, 'work', true);

    const homeAddressPromise = hideHomeAddress ? Promise.resolve({} as Address) : this.getAddress(personId, 'home', true);

    const workAddress = await workAddressPromise;
    const homeAddress = await homeAddressPromise;
    return {
      workAddress,
      homeAddress,
    };
  }

  async updateAddress(personOrFirmId: string, addressType: addressType, address: Address): Promise<Address> {
    return await this.api
      .putAsync<Address>(
        `address/${personOrFirmId}/${addressType}`,
        {
          ...address,
          workAddressSameAsHome: !!address?.workAddressSameAsHome, //null and empty string treated as false
        },
        false
      )
      .then(data => new Address(data));
  }
}
