import { Injectable } from '@angular/core';
import { Firm } from '../../models/firm';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Transaction } from '../../models/transaction';
import { IEntityService } from './i-entity.service';
import { QuestionnaireResponse } from '../../models/questionnaire/questionnaire-response';
import { FirmAvailable } from '../../models/firm-available';
import { FirmRelationships } from '../../models/firm-relationships';
import { FirmRoleHolder } from '../../models/firm-role-holder';
import { FirmAvailableApplication } from '../../models/applications/firm-available-application';
import { FirmRosterMember } from '../../models/firm-roster-member';
import { Address } from '../../models/address';
import { FirmApplicationPrincipal } from '../../models/firm-application-principal';

@Injectable({
  providedIn: 'root',
})
export class FirmService implements IEntityService<Firm> {
  private path: string;
  lookupItemsMax: number;

  constructor(private api: ApiService) {
    this.path = 'firm/';
  }

  get(id: string): Observable<Firm> {
    let path = this.path.concat(id);
    return this.api.get(path).pipe(map(data => new Firm(data)));
  }

  available(): Observable<FirmAvailable[]> {
    return this.api.get(this.path.concat('available')).pipe(map(fas => fas.map(fa => new FirmAvailable(fa))));
  }

  getAddressAsync(firmId: string): Promise<Address> {
    const path = this.path.concat(firmId, '/address');
    return this.api.getAsync(path, false);
  }

  add(firm: Firm): Observable<Firm> {
    return this.api.post(this.path, firm).pipe(map(data => new Firm(data)));
  }

  update(firm: Firm): Observable<Firm> {
    let path = this.path.concat(firm.id);
    return this.api.put(path, firm).pipe(map(data => new Firm(data)));
  }

  delete(firm: Firm): Observable<Firm> {
    let path = this.path.concat(firm.id);
    return this.api.delete(path).pipe(map(data => new Firm(data)));
  }

  relationships(firmId: string): Observable<FirmRelationships> {
    let path = this.path.concat(firmId).concat('/relationships');
    return this.api.get(path).pipe(map(data => new FirmRelationships(data)));
  }

  getRoleHolders = (firmId: string): Promise<FirmRoleHolder[]> =>
    this.api
      .getAsync<FirmRoleHolder[]>(this.path.concat(firmId, '/roleHolders'))
      .then(json => json.map(data => new FirmRoleHolder(data)));

  getSupervisors = (firmId: string): Promise<FirmApplicationPrincipal[]> =>
    this.api
      .getAsync<FirmApplicationPrincipal[]>(this.path.concat(firmId, '/supervisors'))
      .then(json => json.map(data => new FirmApplicationPrincipal(data)));

  saveSupervisors = (firmId: string, supervisors: FirmApplicationPrincipal[]): Promise<FirmApplicationPrincipal[]> =>
    this.api
      .postAsync<FirmApplicationPrincipal[]>(this.path.concat(firmId, '/supervisors'), supervisors)
      .then(json => json.map(data => new FirmApplicationPrincipal(data)));

  getRoster(firmId: string): Observable<FirmRosterMember[]> {
    let path = this.path.concat(firmId, '/roster');
    return this.api.get(path).pipe(map(data => data.map(r => new FirmRosterMember(r))));
  }

  showRoster = (firmId: string): Promise<boolean> => this.api.getAsync<boolean>(this.path.concat(firmId, '/showRoster'));

  getQuestionnaireResponses(firmId: string): Observable<QuestionnaireResponse[]> {
    let path = this.path.concat(firmId, '/questionnaireResponses');
    return this.api.get(path).pipe(map(data => data.map(r => new QuestionnaireResponse(r))));
  }

  getPayments(firmId: string): Observable<Transaction[]> {
    let path = this.path.concat(firmId, '/payments');
    return this.api.get(path).pipe(map(data => data.map(t => new Transaction(t))));
  }

  getAvailableApplications(): Observable<FirmAvailableApplication[]> {
    let path = this.path.concat('availableapplications');
    return this.api.get(path).pipe(map(data => data.map(a => new FirmAvailableApplication(a))));
  }
}
