<lib-titled-card>
  <h3>Roster</h3>
  <table mat-table [dataSource]="items$" class="simple-table equal-widths last-col-longer">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let member">{{ member.name.firstLast }}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let member">{{ member.title }}</td>
    </ng-container>
    <ng-container matColumnDef="licenseNumber">
      <th mat-header-cell *matHeaderCellDef>License<br />Number</th>
      <td mat-cell *matCellDef="let member">{{ member.license?.number }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let member">{{ member.license?.status | licenseStatus }}</td>
    </ng-container>
    <ng-container matColumnDef="issueDate">
      <th mat-header-cell *matHeaderCellDef>Issue<br />Date</th>
      <td mat-cell *matCellDef="let member">{{ member.license?.issueDate | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef>Expiration<br />Date</th>
      <td mat-cell *matCellDef="let member">{{ member.license?.expirationDate | date: 'shortDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="noItems">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let member" [attr.colspan]="columns.length">Nobody is associated with this firm yet.</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  </table>
</lib-titled-card>
