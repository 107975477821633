import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FilterPanelHelper } from './filter-panel-helper';

@Component({
  selector: 'lib-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  @Input() helper: FilterPanelHelper;
  @Input() filters: unknown[];
  globalSearch: string;
  @HostBinding('class.opt-in') optin = true;
  @ViewChild(MatMenuTrigger) globalMenuTrigger: MatMenuTrigger;

  ngOnInit() {
    this.globalSearch = this.helper.initialGlobalSearchValue;
  }

  prevent($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  closeGlobalSearch() {
    this.globalMenuTrigger.restoreFocus = false;
    this.globalMenuTrigger.closeMenu();
  }

  registerFilterTrigger(filter, trigger: MatMenuTrigger) {
    if (this.helper.menuTriggers) {
      const filterKey = this.helper.getFilterKey(filter);
      this.helper.menuTriggers[filterKey] = trigger;
    }
  }
}
