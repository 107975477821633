import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { Angulartics2Module } from 'angulartics2';
import { LibModule } from 'projects/common/src/lib/lib.module';
import { CommonAppModule } from 'projects/common/src/lib/common-app.module';
import { ApplyRoutingModule } from './apply.routing';
import { SharedApplyModule } from './shared/shared-apply.module';
import { ApplicationReviewModule } from 'projects/common/src/lib/modules/application-review/application-review.module';
import { ScrollSpyModule } from './portal/scroll-spy/scroll-spy.module';
import { HttpClientModule } from '@angular/common/http';

import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { AppComponent } from './app.component';
import { MainComponent } from './main.component';
import { PersonPortalGuard } from './portal/person-portal.guard';
import { FirmManagerGuard } from './firm/firm-manager.guard';
import { LandingComponent } from './access/landing/landing.component';
import { LoginComponent } from './access/login/login.component';
import { UnauthorizedComponent } from './access/unauthorized.component';
import { TimedoutComponent } from './access/timedout/timedout.component';
import { NotApplicableComponent } from './access/notapplicable.component';
import { AccountCreatedComponent } from './access/account-created/account-created.component';
import { ImpersonateComponent } from './access/impersonate.component';
import { JwtConfig } from 'projects/common/src/lib/services/auth/auth-token-service';
import { EmailNotFoundDialogue } from './access/login/email-not-found/email-not-found.dialogue';
import { PortalComponent } from './portal/portal.component';
import { FirmPortalComponent } from './firm/firm-portal.component';
import { FirmPortalRedirectComponent } from './firm/firm-portal-redirect.component';
import { JustWhiteBodyComponent } from './just-white-body/just-white-body.component';
import { JustPortalBodyComponent } from './just-portal-body/just-portal-body.component';
import { PortalFirmInfoComponent } from './firm/widgets/portal-firm-info/portal-firm-info.component';
import { ClaimCheckResolve } from './portal/claim-check.resolve';
import { SetupApplicationComponent } from './portal/setup-application/setup-application.component';
import { SetupManageFirmComponent } from './portal/setup-manage-firm/setup-manage-firm.component';
import { PortalFirmPointOfContactInfoComponent } from './firm/widgets/portal-firm-point-of-contact-info/portal-firm-point-of-contact-info.component';
import { PortalFirmLicenseInfoComponent } from './firm/widgets/portal-firm-license-info/portal-firm-license-info.component';
import { PortalFirmRosterComponent } from './firm/widgets/portal-firm-roster/portal-firm-roster.component';
import { PortalArchitectRegistrationComponent } from './portal/portal-architect-registration/portal-architect-registration.component';
import { ApplyAuthorizingComponent } from './access/apply-authorizing/apply-authorizing.component';
import { FirmSelectTypeComponent } from './firm/register/firm-select-type.component';
import { PortalActionsComponent } from './portal/portal-actions/portal-actions.component';
import { PersonPortalRedirectComponent } from './portal/person-portal-redirect.component';
import { ConfirmPasswordValidatorDirective } from './access/login/confirm-password-validator.directive';
import { PersonDetailContactInformationComponent } from 'projects/common/src/lib/components/person/person-detail-contact-information/person-detail-contact-information.component';
import { PersonHomeAddressComponent } from 'projects/common/src/lib/components/person/person-home-address/person-home-address.component';
import { PersonWorkDetailsComponent } from 'projects/common/src/lib/components/person/person-work-details/person-work-details.component';
import { PersonPreferencesComponent } from 'projects/common/src/lib/components/person/person-preferences/person-preferences.component';
import { PersonLawfulPresenceProofComponent } from 'projects/common/src/lib/components/person/person-lawful-presence-proof/person-lawful-presence-proof.component';
import { PersonLawfulPresenceProofListComponent } from 'projects/common/src/lib/components/person/person-lawful-presence-proof-list/person-lawful-presence-proof-list.component';
import { TenantServiceProvider } from 'projects/common/src/lib/services/api/tenant.service';
import { PortalFirmSupervisorsInfoComponent } from './firm/widgets/portal-firm-supervisors-info/portal-firm-supervisors-info.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    UnauthorizedComponent,
    TimedoutComponent,
    NotApplicableComponent,
    ImpersonateComponent,
    LandingComponent,
    LoginComponent,
    EmailNotFoundDialogue,
    AccountCreatedComponent,
    PortalComponent,
    FirmPortalComponent,
    FirmPortalRedirectComponent,
    PersonPortalRedirectComponent,
    JustWhiteBodyComponent,
    JustPortalBodyComponent,
    PortalFirmInfoComponent,
    SetupApplicationComponent,
    SetupManageFirmComponent,
    PortalFirmPointOfContactInfoComponent,
    PortalFirmSupervisorsInfoComponent,
    PortalFirmLicenseInfoComponent,
    PortalFirmLicenseInfoComponent,
    PortalFirmRosterComponent,
    PortalArchitectRegistrationComponent,
    ApplyAuthorizingComponent,
    FirmSelectTypeComponent,
    PortalActionsComponent,
    ConfirmPasswordValidatorDirective,
    PersonDetailContactInformationComponent,
    PersonHomeAddressComponent,
    PersonWorkDetailsComponent,
    PersonPreferencesComponent,
    PersonLawfulPresenceProofComponent,
    PersonLawfulPresenceProofListComponent,
  ],
  imports: [
    HttpClientModule,
    CommonAppModule,
    ApplyRoutingModule,
    SharedApplyModule,
    LibModule,
    ApplicationReviewModule,
    JwtModule.forRoot(JwtConfig()), //needed for AuthService
    Angulartics2Module.forRoot(),
    AngularStickyThingsModule,
    ScrollSpyModule.forRoot(),
  ],
  providers: [
    TenantServiceProvider('portal'),
    ClaimCheckResolve,
    FirmManagerGuard,
    PersonPortalGuard,
    { provide: Angulartics2GoogleGlobalSiteTag, useClass: Angulartics2GoogleGlobalSiteTag },
  ],
  exports: [EmailNotFoundDialogue],
  entryComponents: [EmailNotFoundDialogue],
  bootstrap: [AppComponent],
})
export class AppApplyModule {}
