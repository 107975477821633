<h2 mat-dialog-title [class.error]="isError">{{prompt}}</h2>
<div mat-dialog-content *ngIf="template">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</div>
<p mat-dialog-actions class="reverse">
  <button mat-flat-button class="large" type="submit" [mat-dialog-close]="true" color="primary" *ngIf="yesText">
    {{yesText}}
  </button>
  <button mat-stroked-button class="large" type="button" [mat-dialog-close]="false" color="accent" *ngIf="noText">
    {{noText}}
  </button>
</p>
