<table mat-table [dataSource]="items$" class="simple-table equal-widths last-col-longer">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let ref">{{ getName(ref) }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let ref">
      {{ ref?.email }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateReceived">
    <th mat-header-cell *matHeaderCellDef>Date Received</th>
    <td mat-cell *matCellDef="let ref">{{ ref?.dateReceived | date: 'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="menu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let ref">
      <mat-icon class="muted-text" [matMenuTriggerFor]="optionsMenu">more_horiz</mat-icon>
      <mat-menu #optionsMenu="matMenu">
        <button mat-button type="button" (click)="edit(ref)">Edit Professional Reference</button>
        <button mat-button type="button" (click)="confirmDelete(ref)">Delete Professional Reference</button>
      </mat-menu>
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let ref" [attr.colspan]="columns.length">No references on file</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let ref; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let ref; columns: columns"></tr>
</table>
<lib-confirm
  #confirmRemove="confirm"
  yesText="Delete"
  noText="Cancel"
  (yes)="delete()"
  [template]="confirmDeleteDirective"
></lib-confirm>
<ng-template #confirmDeleteDirective>
  <p>Are you sure you want to delete this reference?</p>
</ng-template>
