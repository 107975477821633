import { TenantService } from './tenant.service';

export function delegatedManagementDomain(domain) {
  return domain
    .replace('auth0.com', 'us.webtask.io') // la prod and local exception
    .replace('us.us.webtask.io', 'us.webtask.run');
}

export class Auth0Settings {
  constructor(json: any, source: 'portal' | 'staff') {
    this.domain = json.domain;

    this.redirectUri =
      window.location.hostname === '127.0.0.1'
        ? `${window.location.origin.replace('127.0.0.1', 'localhost')}/authorizing`
        : `${window.location.origin}/authorizing`;
    this.audience = json.audience;
    this.managerConnection = json.managerConnection;
    if (source === 'portal') {
      this.scopes = 'applicant';
      this.connection = json.portalConnection;
      this.clientId = json.portalClientId;
    } else {
      //staff
      this.delegatedManagementUrl = 'https://' + delegatedManagementDomain(json.domain) + '/auth0-delegated-admin/';
      this.scopes = 'admin applicant';
      this.connection = this.managerConnection;
      this.clientId = json.managerClientId;
    }
  }

  clientId: string;
  domain: string;
  audience: string;
  connection: string;
  managerConnection: string; //only on portal when impersonating
  scopes: string;
  delegatedManagementUrl: string;
  redirectUri: string;
}

export const Auth0SettingsProvider = {
  provide: Auth0Settings,
  useFactory: (service: TenantService) => service.auth0,
  deps: [TenantService],
};
