import { FormBuilder, FormGroup } from '@angular/forms';
import { Firm } from './firm';
import { LookupItem } from './search/lookup-item';
import { UpdatableModel } from './updatable-model';
import { ILicense } from './i-license';
import { FirmLookup } from './firm-lookup';

export class FirmLicense extends UpdatableModel<FirmLicense> implements LookupItem, ILicense {
  id: string;
  number: string;
  status: string;
  origin: string;
  residency: string;
  issueDate: Date;
  lastRenewalDate?: Date;
  expirationDate: Date;
  type: string;
  typeName: string;
  typeShortName: string;
  firm: Firm;

  update(init?: Partial<FirmLicense>) {
    super.update(init);
    if (this.firm && !this.firm.update) {
      this.firm = new Firm(this.firm);
    }
  }

  get lookupId() {
    return this.firm.id;
  }

  get lookupStatus() {
    return this.status;
  }

  get lookupName() {
    return this.firm.nameFull;
  }

  get lookupInfo() {
    return [
      this.number,
      this.firm.email,
      this.firm.publicAddress ? this.firm.publicAddress.oneLine : null,
      this.firm.primaryPhone ? this.firm.primaryPhone.numberFull : null,
    ]
      .filter(_ => !!_)
      .join(' | ');
  }

  get lookupInfoWithoutNumber() {
    return [
      this.firm.email,
      this.firm.publicAddress ? this.firm.publicAddress.oneLine : null,
      this.firm.primaryPhone ? this.firm.primaryPhone.numberFull : null,
    ]
      .filter(_ => !!_)
      .join(' | ');
  }

  get canHaveApplication(): boolean {
    return this.status == 'Due' || this.status == 'Delinquent' || this.status == 'Expired' || this.status == 'Inactive';
  }

  asFirmLookup(): FirmLookup {
    var firmLookup = new FirmLookup(this.firm);
    firmLookup.licenseNumber = this.number;
    firmLookup.licenseStatus = this.status;
    return firmLookup;
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      number: '',
      status: '',
      residency: '',
      issueDate: '',
      lastRenewalDate: '',
      expirationDate: '',
      typeName: '',
    });
  }
}
