import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { JurisdictionConstants } from '../../constants/jurisdiction/jurisdiction-constants';
import { PersonService } from '../../services/api/person.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-references-detail',
  templateUrl: './references-detail.component.html',
  styleUrls: ['./references-detail.component.scss'],
})
export class ReferencesDetailComponent implements OnInit {
  @Input() referenceCount: string;
  @HostBinding('class.opt-in') optin = true;
  constructor(
    public constants: JurisdictionConstants,
    private personService: PersonService,
    private route: ActivatedRoute
  ) {}
  async ngOnInit() {
    const count = (await this.personService.getReferenceSummary(this.route.snapshot.params.personId)).count;
    if (count === 0 || count) {
      this.referenceCount = count.toString();
    }
  }
}
