import { JurisdictionConstants } from './jurisdiction-constants';
import { Profession } from './profession';

export const DemoConstants: JurisdictionConstants = {
  legal:
    'The legislature has enacted laws which govern the practice of architecture in a demo environment. In accordance with those laws, the state of DEMO has adopted rules which regulate the registration of individuals practicing architecture. To register as an architect, one must meet the education, experience and examination requirements. Additionally, architects renewing their license have continuing education requirements that must be met on an annual basis.',
  acronym: 'DEMO',
  workAddressSuffix: '(Public)',
  homeAddressSuffix: '(Private)',
  commonLinks: {
    publicSite: 'https://licensingmanager-demo.dev.ncarb.dev/',
    publicUrl: 'LicensingManager-demo.dev.ncarb.dev',
    registrationInfo: 'https://licensingportal-demo.dev.ncarb.dev/',
    rules: 'https://licensingportal-demo.dev.ncarb.dev/',
    centuryCode: null,
    lapels: 'https://licensingportal-demo.dev.ncarb.dev/',
    architectLookup: 'https://licensingportal-demo.dev.ncarb.dev/',
    firmLookup: 'https://licensingportal-demo.dev.ncarb.dev/',
  },
  manualProfessions: [Profession.LandscapeArchitect],
  architectTabName: 'Licenses',
  ceRule: {
    section: '§1315.D',
    exemption: '§1315.B',
  },
  professionalReferences: {
    text: 'If you need to submit additional references to the board, <a href="https://licensingportal-demo.dev.ncarb.dev">download the reference form</a>, send it to another desired reference, and have them send it directly to the board. Applicants that cannot furnish the required number of references for an application, will need to email a detailed letter to the board at nsbla.board@nebraska.gov requesting an exemption from this requirement',
    dataPoint: 'References Received',
    cardTitle: 'References',
  },
  timezoneAbbreviation: 'ET',
  checkAddress: {
    address1: 'Demo address line 1',
    address2: 'Demo address line 2',
    cityStateZip: 'City State Zip',
  },
  firmApplicationSettings: {
    askForStateInFirmSupervisor: false,
    incorporationDateLabel: 'Date of Incorporation in Demo',
    maximumSupervisors: 1,
    // showOldBoardOfDirectors: true,
    directorsUploadCustomText: 'This is a custom text with <b>html</b>',
  },
  applicationStepContent: {
    militaryInfo: {
      question: 'Are you active military or the spouse of an active military member?',
    },
  },
};
