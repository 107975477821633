<lib-titled-card>
  <h2>Firm Info</h2>
  <form [formGroup]="form" class="no-underlines">
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Firm Name</mat-label>
        <input matInput formControlName="name" readonly />
      </mat-form-field>
    </div>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" readonly />
      </mat-form-field>
      <lib-edit-phone-redesign
        label="Phone Number"
        [formGroup]="form['controls'].primaryPhone"
        [readonly]="true"
      ></lib-edit-phone-redesign>
    </div>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Website</mat-label>
        <input matInput formControlName="website" readonly />
      </mat-form-field>
    </div>
  </form>
</lib-titled-card>
