import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdatableModel } from './updatable-model';
import { Name } from './name';
import { ArchitectApplication } from './applications/architect-application';
import { Phone } from './phone';
import { ArchitectLicense } from './architect-license';

//Maps to PersonalInfoModel.cs
//Only used on PersonalInfoComponent step and ArchitectReview Component
export class PersonalInfo extends UpdatableModel<PersonalInfo> {
  name: Name;
  credentials: string;
  email: string;
  socialSecurityNumber: string;
  noSocialSecurityNumber: boolean;
  visaNumber: string;
  uscisNumber: string;
  immigrationStatus: string;
  dateOfBirth: Date;
  ncarbNumber?: number;
  clarbNumber?: number;
  architectApplication: ArchitectApplication;
  primaryPhone: Phone;
  secondaryPhone: Phone;
  firmName: string;
  gender: string;
  sendMailTo: string;

  update(init?: Partial<PersonalInfo>) {
    super.update(init);

    if (this.name) {
      this.name = new Name(this.name);
    }
    if (this.architectApplication) {
      this.architectApplication = new ArchitectApplication(this.architectApplication);
    }
    if (this.primaryPhone) {
      this.primaryPhone = new Phone(this.primaryPhone);
    }
    if (this.secondaryPhone) {
      this.secondaryPhone = new Phone(this.secondaryPhone);
    }
  }

  acceptPatch(formGroup: FormGroup) {
    let formInfo = Object.assign({}, formGroup.value);
    this.update(formInfo);
    this.cleanup();
  }

  cleanup() {
    ['primaryPhone', 'secondaryPhone'].forEach(a => {
      if (!this[a] || !this[a].number) {
        delete this[a];
      } else {
        this[a] = new Phone(this[a]);
      }
    });
  }

  populateForm(formGroup: FormGroup) {
    this.cleanup();
    formGroup.patchValue(this);
  }

  isArchitectOrApplicant(license: ArchitectLicense): boolean {
    return !!this.ncarbNumber || !!license;
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: Name.GetFormGroup(formBuilder),
      credentials: '',
      email: '',
      ncarbNumber: '',
      clarbNumber: '',
      primaryPhone: Phone.GetFormGroup(formBuilder),
      secondaryPhone: Phone.GetFormGroup(formBuilder),
      dateOfBirth: '',
      gender: '',
      firmName: '',
      workAddressSameAsHome: false,
      socialSecurityNumber: '',
      noSocialSecurityNumber: '',
      visaNumber: '',
      uscisNumber: '',
      immigrationStatus: '',
      sendMailTo: '',
    });
  }
}
