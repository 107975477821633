import { Component, OnInit, Input } from '@angular/core';
import { LookupItem } from '../../../models/search/lookup-item';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-lookup-option',
  templateUrl: './lookup-option.component.html',
  styleUrls: ['./lookup-option.component.scss'],
})
export class LookupOptionComponent {
  @Input() item: LookupItem;
  @Input() highlight: string;
  @Input() showStatus: boolean;

  constructor(public settings: TenantSettings) {}

  public getStatusCss(status: string): string {
    return this.settings.getCustomStatusCssClass(status, 'status-');
  }

  public getStatusDisplay(status: string): string {
    return this.settings.getStatusLabel(status);
  }
}
