import { PipeTransform, Pipe } from '@angular/core';
import { TenantSettings } from '../constants/jurisdiction/TenantSettings';
import { spaceCapCase } from '../utility/string-utils';
@Pipe({
  name: 'licenseStatus',
})
export class LicenseStatusPipe implements PipeTransform {
  constructor(private settings: TenantSettings) {}
  transform(licenseStatus: string) {
    if (this.settings.licenseStatuses[licenseStatus]) {
      return this.settings.licenseStatuses[licenseStatus].label;
    }
    return spaceCapCase(licenseStatus);
  }
}
