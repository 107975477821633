import { Component } from '@angular/core';
import { ListComponent } from '../list/list.component';
import { Transaction } from 'projects/common/src/lib/models/transaction';
import { Observable } from 'rxjs';
import { PaymentListCrimpService } from './payment-list-crimp.service';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
})
export class PaymentListComponent extends ListComponent<Transaction> {
  columns = [
    'transactionDate',
    'orderNumber',
    'description',
    'paymentMethodType',
    'paymentDescription',
    'totalAmount',
    'invoice',
  ];
  crimp$: Observable<boolean>;
  constructor(private paymentListCrimpService: PaymentListCrimpService) {
    super();
    this.crimp$ = this.paymentListCrimpService.crimp$;
  }

  toggleCrimp() {
    this.paymentListCrimpService.crimp$.next(!this.paymentListCrimpService.crimp$.value);
  }
}
