import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-card-list-item',
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss'],
})
export class CardListItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
