import { FirmClaim } from './firm-claim';

export class Claims {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  staffId: string;
  isStaff: boolean;
  personId: string;
  personPointOfContactFor: string[];
  firms: FirmClaim[];
  managedFirms: FirmClaim[];
  raw: any;

  get actorId() {
    return this.staffId || this.personId;
  }

  canManageFirmId(firmId): boolean {
    let firm = this.firms.find(f => f.id == firmId);
    return firm && firm.canManage;
  }

  authorizedForPersonId(personId): boolean {
    return this.personId == personId || !!this.personPointOfContactFor.find(id => id == personId);
  }

  static CustomNameSpace = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';

  constructor(claims) {
    this.email = claims[Claims.CustomNameSpace + 'emailaddress'];
    this.name = claims[Claims.CustomNameSpace + 'name'];
    this.firstName = claims[Claims.CustomNameSpace + 'givenname'];
    this.lastName = claims[Claims.CustomNameSpace + 'surname'];
    this.staffId = claims[Claims.CustomNameSpace + 'staffId'];
    this.isStaff = !!this.staffId;
    this.personId = claims[Claims.CustomNameSpace + 'personId'];
    this.personPointOfContactFor = claims[Claims.CustomNameSpace + 'personPointOfContactFor'] || [];
    this.firms = claims[Claims.CustomNameSpace + 'firms'] || [];
    // TODO: remove when firm portal is available for non-managers
    this.firms = this.firms.filter(f => f.canManage);
    this.managedFirms = this.firms.filter(f => f.canManage);
    this.raw = claims;
  }
}
