import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addAnOrA' })
export class AnOrAPipe implements PipeTransform {
  transform(word: string) {
    if (word) {
      const pronoun = word.match('^[aieouAIEOU].*') ? 'an' : 'a';
      return pronoun + ' ' + word;
    }

    return word;
  }
}
