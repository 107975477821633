import { Component } from '@angular/core';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss'],
})
export class AccountCreatedComponent {
  constructor(
    private auth: AuthService,
    public settings: TenantSettings
  ) {}

  login() {
    this.auth.login();
  }
}
