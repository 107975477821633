export const Masks = {
  ssn: { mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
  visa: { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] },
  uscis: { mask: uscisMask },
};

const letterOrNumber = /[a-zA-Z0-9]/;
const sevenLettersOrNumbers = [
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
];
const eightLettersOrNumbers = [
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
];
const nineLettersOrNumbers = [
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
  letterOrNumber,
];

function uscisMask(userInput: string): RegExp[] {
  // According to documentation the text-mask plugin can only accept a static array for a mask so a function is used
  // here to construct a static mask of the appropriate length.

  let matchExp = RegExp(letterOrNumber, 'g');
  let chars = userInput.match(matchExp);
  let inputLength = 0;

  if (chars) {
    inputLength = chars.join('').length;
  }

  if (inputLength <= 7) return sevenLettersOrNumbers;

  if (inputLength == 8) return eightLettersOrNumbers;

  if (inputLength >= 9) return nineLettersOrNumbers;
}
