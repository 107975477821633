import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { FirmRoleHolder } from '../models/firm-role-holder';
import { FirmService } from '../services/api/firm.service';

@Injectable()
export class FirmRolesResolve implements Resolve<FirmRoleHolder[]> {
  constructor(private firmService: FirmService) {}

  resolve = (route: ActivatedRouteSnapshot): Promise<FirmRoleHolder[]> => this.firmService.getRoleHolders(route.params.firmId);
}
