import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

export type ReadMode = 'readonly' | 'readonlyExceptHonorific' | 'readonlyExceptHonorificAndSuffix' | 'editing';
@Component({
  selector: 'lib-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss'],
})
export class EditNameComponent implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() honorificNotShown: boolean; //hidden on POC form
  @Input() readMode: ReadMode;
  firstMiddleLastSettings: FieldReadOnly;
  public suffixIsEditable: boolean;
  @HostBinding('class.opt-in') optin = true;

  ngOnInit() {
    this.firstMiddleLastSettings = this.makeFieldReadOnly();
  }

  ngOnChanges(): void {
    //readMode might change at will
    this.firstMiddleLastSettings = this.makeFieldReadOnly();
  }

  private makeFieldReadOnly(): FieldReadOnly {
    if (this.readMode === 'editing') {
      this.suffixIsEditable = true;
      return {
        appearance: 'outline',
        floatLabel: 'auto',
        readonly: false,
      };
    }
    this.suffixIsEditable = this.readMode === 'readonlyExceptHonorificAndSuffix';

    return {
      appearance: 'standard',
      floatLabel: '',
      readonly: true,
    };
  }

  getCurrentMode(): 'readonly' | 'disabled' | 'edit' {
    if (this.readMode === 'readonly') return 'readonly';
    if (this.readMode === 'readonlyExceptHonorific' && this.honorificNotShown) {
      return 'readonly';
    }
    if (this.formGroup.disabled) {
      return 'disabled';
    }
    return 'edit';
  }

  getHonorificDisplay() {
    return this.formGroup.controls.honorific.value === 'NonePreferred' ? 'N/A' : this.formGroup.controls.honorific.value;
  }
}

interface FieldReadOnly {
  appearance: string;
  floatLabel: string;
  readonly: boolean;
}
