<section-header [title]="stepSettings.headerSubtext" class="d-block pb-small">
  {{ stepSettings.headerSubtextContent }}
</section-header>

<div [formGroup]="form">
  <mat-radio-group
    formControlName="completed"
    aria-label="Select an option"
    class="flex-column gap-16"
    [ngClass]="{ 'not-interactive': readonly }"
    required
    (change)="onCompletedChange($event)"
    *ngIf="stepSettings.exemptionConfiguration"
  >
    <mat-radio-button [value]="true">{{ stepSettings.educationCompletedText }}</mat-radio-button>
    <mat-radio-button [value]="false">{{ stepSettings.exemptionConfiguration?.educationNotCompletedText }}</mat-radio-button>
  </mat-radio-group>

  <mat-checkbox
    [ngClass]="{ 'not-interactive': readonly }"
    formControlName="completed"
    required
    (change)="onCompletedChange($event)"
    *ngIf="!stepSettings.exemptionConfiguration"
  >
    {{ stepSettings.educationCompletedText }}
  </mat-checkbox>

  <div class="pt-medium px-large pb-x-large" [innerHTML]="stepSettings.affidavitText"></div>

  <mat-divider class="pb-small"></mat-divider>

  <div *ngIf="showCompletedFileUpload()" class="flex-column">
    <div class="d-flex justify-content-between">
      <section-header [title]="stepSettings.completedEducationDocumentation?.headerDocSubtext">{{
        stepSettings.completedEducationDocumentation?.headerDocSubtextContent
      }}</section-header>
      <button
        mat-button
        color="primary"
        type="button"
        (click)="infoModal.open()"
        *ngIf="
          stepSettings.completedEducationDocumentation?.auditText && !stepSettings.completedEducationDocumentation?.isUnderAudit
        "
      >
        Subject To Random Audit <span class="material-icons md-36">info</span>
      </button>
    </div>
    <ls-banner class="mt-small auditInfo" variant="inactive" *ngIf="stepSettings.completedEducationDocumentation.isUnderAudit">
      <div [innerHTML]="stepSettings.completedEducationDocumentation?.auditText | safeHtml"></div>
    </ls-banner>
    <lib-dialog yesText="Close" noText="" #infoModal="dialog" header="Subject to Random Audit">
      <ng-template>
        <ls-p level="2" [innerHTML]="stepSettings.completedEducationDocumentation?.auditText"></ls-p>
      </ng-template>
    </lib-dialog>
    <mat-form-field appearance="outline" class="remove-hint-pad">
      <input class="d-none" matInput formControlName="docCount" required />
      <lib-upload-widget
        [readonly]="readonly"
        [caption]="stepSettings.completedEducationDocumentation?.headerDocSubtext"
        [viewStyle]="fileViewStyle"
        [noDocumentsMessage]="readonly && 'No Documents Uploaded'"
        [docSearch]="docSearch"
        (onDocListChange)="onDocListChange($event)"
      >
      </lib-upload-widget>
    </mat-form-field>

    <div [innerHTML]="stepSettings.completedEducationDocumentation?.proofText"></div>
  </div>

  <div *ngIf="showExempt()" class="flex-column">
    <section-header [title]="stepSettings.exemptionConfiguration?.exemptionSubtext" class="pb-small">
      {{ stepSettings.exemptionConfiguration?.exemptionSubtextContent }}
    </section-header>

    <mat-form-field appearance="outline" floatLabel="auto" class="remove-hint-pad" [ngClass]="{ 'not-interactive': readonly }">
      <mat-label>Exemption Reason</mat-label>
      <input
        matInput
        *ngIf="readonly"
        [ngModel]="getDisplayLabelExemptionReason()"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="true"
        placeholder="Exemption Reason*"
      />
      <mat-select *ngIf="!readonly" formControlName="exemption" (selectionChange)="onExemptionReasonChange($event)">
        <mat-option></mat-option>
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="showExemptionFileUpload()" class="flex-column">
      <mat-form-field appearance="outline" class="remove-hint-pad">
        <input class="d-none" matInput formControlName="docCount" required />
        <lib-upload-widget
          [readonly]="readonly"
          caption="Continuing Education Exemption documentation"
          [viewStyle]="fileViewStyle"
          [noDocumentsMessage]="readonly && 'No Documents Uploaded'"
          [docSearch]="docSearch"
          (onDocListChange)="onDocListChange($event)"
        >
        </lib-upload-widget>
      </mat-form-field>
      <div [innerHTML]="stepSettings.exemptionConfiguration?.proofExceptionText"></div>
    </div>
  </div>
</div>
