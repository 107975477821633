import { Injectable } from '@angular/core';
import { PersonFull } from 'projects/common/src/lib/models/person-full';
import { PersonService } from 'projects/common/src/lib/services/api/person.service';
import { DetailService } from './detail.service';

@Injectable({
  providedIn: 'root',
})
export class PersonDetailService extends DetailService<PersonFull, PersonService> {
  constructor(protected personService: PersonService) {
    super(personService);
  }

  get person$() {
    return this.entity$;
  }

  get person() {
    return this.entity;
  }
}
