import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { UpdatableModel } from '../../models/updatable-model';
import { UploadError } from './upload-widget.component';
//Todo: cleanup
//UploadedFile.error is never actually by any API. Only storybook and the "noUploads" field

export class UploadedFile extends UpdatableModel<UploadedFile> {
  file?: S3DocumentReference;
  error: UploadError;
}
