<lib-titled-card>
  <h2>Point of Contact</h2>

  <p class="note">
    A Point of Contact (POC) has the authority to perform any duties pertaining to the maintenance of an architect's record and
    registration with {{ constants.acronym }}. This does not absolve the architect of their responsibilities for registration.
  </p>
  <section class="card-section" *ngIf="canHavePOC">
    <h3>{{ terms.Grant }} Access to {{ terms.Your }} Record</h3>
    <div class="call-to-action" *ngIf="!hasPointOfContact">
      <p>Would you like to make someone else the Point of Contact for {{ terms.your }} record?</p>
      <span>
        <button mat-button class="large align-top" color="inverse" type="button" [routerLink]="assignRoute">
          Assign Point of Contact
        </button>
      </span>
    </div>
    <div *ngIf="hasPointOfContact">
      <lib-point-of-contact-list [list$]="pointOfContacts$" [isStaffView]="isStaffView" (onRevoke)="revoke($event)">
      </lib-point-of-contact-list>
    </div>
  </section>

  <hr />

  <section class="card-section">
    <h3>Access to Other Records</h3>
    <ng-container *ngIf="!isStaffView">
      <ng-container *ngIf="isPointOfContact; else cta">
        <a [routerLink]="['archpointofcontact/request']">+ {{ terms.request }} more access</a>
      </ng-container>
      <ng-template #cta>
        <div class="call-to-action">
          <p>Would you like to request to be the Point of Contact for an architect?</p>
          <span>
            <button
              mat-button
              class="large align-top"
              color="inverse"
              type="button"
              [routerLink]="['archpointofcontact/request']"
            >
              {{ terms.Request }} Access
            </button>
          </span>
        </div>
      </ng-template>
    </ng-container>
    <lib-point-of-contact-for-list
      *ngIf="isPointOfContact || isStaffView"
      [list$]="pointOfContactForList$"
      [isStaffView]="isStaffView"
    >
    </lib-point-of-contact-for-list>
  </section>
</lib-titled-card>
