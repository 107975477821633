import { Component, Input } from '@angular/core';
import { ListComponent } from '../list';
import { PointOfContactFor } from '../../models/point-of-contact-for';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-point-of-contact-for-list',
  templateUrl: './point-of-contact-for-list.component.html',
  styleUrls: ['./point-of-contact-for-list.component.scss'],
})
export class PointOfContactForListComponent extends ListComponent<PointOfContactFor> {
  @Input() isStaffView: boolean;
  columns = ['type', 'name', 'status', 'number', 'expirationDate', 'requestStatus'];

  constructor(private settings: TenantSettings) {
    super();
  }

  getStatusDisplay(pocFor: PointOfContactFor): string {
    const status = pocFor?.license?.status;
    return this.settings.getStatusLabel(status);
  }

  getStatusClass(pocFor: PointOfContactFor): string {
    return this.settings.getCustomStatusCssClass(pocFor?.license?.status, 'status-', 'unlicensed');
  }

  route(pocFor: PointOfContactFor) {
    var dir = 'firm';
    if (pocFor.isForArchitect) {
      dir = this.isStaffView ? 'person' : 'portal';
    }
    return ['/', dir, pocFor.id];
  }
}
