import { Component, Input, Output, EventEmitter, ViewChild, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { SearchApiService } from '../../services/api/search/search-api.service';

@Component({
  selector: 'lib-architect-autocomplete',
  templateUrl: './architect-autocomplete.component.html',
  styleUrls: ['./architect-autocomplete.component.scss'],
})
export class ArchitectAutocompleteComponent {
  @HostBinding('class.mat-form-field') matFormField = true;
  @Input() control: FormControl;
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() cleared = new EventEmitter<MatAutocompleteSelectedEvent>();
  @ViewChild(AutocompleteComponent) autocomplete: AutocompleteComponent;
  placeholder = 'Search by license number, name, or email';

  constructor(
    private searchApiService: SearchApiService,
    private settings: TenantSettings
  ) {}

  getStatusCss(status: string): string {
    return this.settings.getCustomStatusCssClass(status, 'status-');
  }

  search = (text: string) => this.searchApiService.getArchitectLicenseLookup(text);

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit($event);
  }

  onCleared($event: MatAutocompleteSelectedEvent) {
    this.cleared.emit($event);
  }
}
