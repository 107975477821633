<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="licenseNumber">
    <th mat-header-cell *matHeaderCellDef>License<br />Number</th>
    <td mat-cell *matCellDef="let element">{{ element.license?.number }}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      <a routerLink="/person/{{ element.person.id }}" *ngIf="linkedName; else unlinkedName">{{
        element.person.name.complete
      }}</a>
      <ng-template #unlinkedName>{{ element.person.name.complete }}</ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let element">
      {{ element.roleName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef>Start Date of Role</th>
    <td mat-cell *matCellDef="let element">
      {{ element.startDate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef>End Date of Role</th>
    <td mat-cell *matCellDef="let element">
      {{ element.endDate }}
    </td>
  </ng-container>

  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">No past roles</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>
