<table mat-table class="simple-table with-detail-row" [dataSource]="docList">
  <ng-container matColumnDef="file">
    <td mat-cell *matCellDef="let docRef">
      <a class="attach" href="#" (click)="download(docRef)">
        <mat-icon>attach_file</mat-icon>
      </a>
      <div>
        <a class="file-name" [href]="docRef.link" target="_blank">
          {{ docRef.fileNameWithoutTimestamp }}
        </a>
        <div class="small">{{ docRef.lastModified | dateTimeFormat }} &#183; {{ docRef.uploadedByName }}</div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="expand">
    <td mat-cell *matCellDef="let docRef" class="text-right caret">
      <mat-icon>expand_more</mat-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <td mat-header-cell *matHeaderCellDef>No {{ filesLabel }} on file</td>
  </ng-container>

  <tr mat-header-row [ngClass]="hideHeader()" *matHeaderRowDef="docList?.length ? columns : ['noItems']"></tr>

  <tr
    mat-row
    *matRowDef="let docRef; columns: docList?.length ? columns : ['noItems']"
    matRipple
    #detailDir="cdkDetailRow"
    [cdkDetailRow]="docRef"
    [cdkDetailRowTpl]="tpl"
    class="pointer"
  ></tr>
</table>
<ng-template #tpl let-docRef>
  <tr class="mat-row detail-row">
    <td class="mat-cell" colspan="2">
      <iframe *ngIf="docRef.isPdf" [src]="docRef.link + '&navpanes=0' | safeUrl" type="application/pdf"></iframe>
      <img *ngIf="docRef.isImage" [src]="docRef.link | safeUrl" />
      <div *ngIf="!docRef.isPdf && !docRef.isImage" class="not-available">
        Preview is not available for {{ docRef.fileNameWithoutTimestamp }}
      </div>
    </td>
  </tr>
</ng-template>
