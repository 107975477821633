<table *ngIf="militaryInformation">
  <tr>
    <td>
      <span class="question-text"> Are you active military or the spouse of an active military member? </span>
      <div class="explanation" *ngIf="militaryInformation.militaryNumber">
        <label>Number</label>
        <div>{{ militaryInformation.militaryNumber }}</div>
      </div>
    </td>
    <td width="1">
      {{ militaryInformation.isActiveMilitary === true ? 'Yes' : militaryInformation.isActiveMilitary === false ? 'No' : null }}
    </td>
  </tr>
</table>
