import { Component, OnInit, Input, EventEmitter, Output, HostBinding, ViewChild } from '@angular/core';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { ConfirmDialogueDirective } from '../confirm-dialogue/confirm.dialogue.directive';
import { StatusService } from '../../services/status.service';

interface StatusOption {
  display: string;
  value: string;
  style?: string;
}

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
})
export class StatusSelectorComponent implements OnInit {
  @HostBinding('class.selector') selectorClass = true;
  //if noPrompt: true, will change without prompting
  //if noPrompt: falsy, will update right away
  @Input() noPrompt: boolean;
  @Output() statusChanged = new EventEmitter<string>();
  statuses: StatusOption[];
  @ViewChild(ConfirmDialogueDirective) confirmDialog: ConfirmDialogueDirective;
  private _originalStatus: StatusOption;
  //ng-model
  selectedStatus: StatusOption;
  @Input() licenseId: string;
  @Input() applicationId: string;
  constructor(
    private settings: TenantSettings,
    private statusService: StatusService
  ) {}

  getOptionFromStatus = (status: string): StatusOption => {
    const mapping = this.settings.licenseStatuses[status];
    return {
      display: mapping?.label ?? status,
      value: status,
      style: mapping?.customStyleName ?? status,
    };
  };

  private setupStatuses = async (): Promise<any> => {
    let selectedStatus: string;
    if (this.applicationId) {
      const applicationStatues = await this.statusService.availableApplicationStatuses(this.applicationId);
      this.statuses = applicationStatues.availableStatuses.map(status => ({
        display: status,
        value: status,
        style: status,
      }));
      selectedStatus = applicationStatues.currentStatus;
    } else if (this.licenseId) {
      const licenseStatues = await this.statusService.availableLicenseStatuses(this.licenseId);
      this.statuses = licenseStatues.availableStatuses.map(status => this.getOptionFromStatus(status));
      selectedStatus = licenseStatues.currentStatus;
    } else {
      selectedStatus = 'All';
      this.statuses = [
        {
          display: 'All',
          value: 'All',
          style: 'All',
        },
        ...Object.entries(this.settings.licenseStatuses)
          .sort(([_, a], [__, b]) => a.orderingIndex - b.orderingIndex)
          .map(([key, value]) => ({
            display: value.label,
            value: key,
            style: value.customStyleName ?? key,
          })),
      ];
    }

    this.selectedStatus = this.statuses.find(s => s.value == selectedStatus);
    if (!this.selectedStatus) {
      console.error(`could not find a status matching ${selectedStatus}`);
    }
    this._originalStatus = this.selectedStatus; //for rolling back
  };

  async ngOnInit() {
    await this.setupStatuses();
  }

  onStatusChanged() {
    if (this.noPrompt) {
      this.statusChanged.emit(this.selectedStatus.value);
      return;
    }

    this.confirmDialog.open().then(result => {
      if (result) {
        this.statusChanged.emit(this.selectedStatus.value);
      } else {
        this.selectedStatus = this._originalStatus; //rollback
      }
    });
  }
}
