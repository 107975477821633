<fieldset [formGroup]="form">
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" email required />
    </mat-form-field>
  </div>
  <div class="flex-fields flex-fields-split">
    <lib-edit-phone-redesign
      label="Primary Phone Number"
      [formGroup]="form.controls.primaryPhone"
      [readonly]="true"
      [required]="true"
    >
    </lib-edit-phone-redesign>

    <lib-edit-phone-redesign
      label="Secondary Phone Number"
      [formGroup]="form.controls.secondaryPhone"
      [readonly]="true"
      *ngIf="!personalStepSettings.hideSecondaryPhoneNumber"
    >
    </lib-edit-phone-redesign>
  </div>
</fieldset>
