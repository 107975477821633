import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CommonApiService } from 'projects/common/src/lib/services/api/common-api.service';
import { AddressLookups } from 'projects/common/src/lib/models/address';

@Injectable()
export class AddressLookupsResolve implements Resolve<AddressLookups> {
  constructor(private commonService: CommonApiService) {}

  resolve() {
    return this.commonService.addressLookups();
  }
}
