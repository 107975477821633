import { CanActivate, Router, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { Claims } from 'projects/common/src/lib/models/auth/claims';

@Injectable()
export class PersonPortalGuard implements CanActivate, CanActivateChild {
  claims: Claims;
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    // TODO known issue: claims can be stale at times :O !?
    this.authService.isAuthenticated$.subscribe(a => {
      this.claims = this.authService.claims;
    });
  }

  isAuthorized(personId): boolean {
    if (!this.claims.authorizedForPersonId(personId)) {
      this.router.navigate(['/portal']);
      return false;
    }
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthorized(route.params.personId);
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAuthorized(route.params.personId);
  }
}
