import { ContinuingEducationStepSettings, TenantSettings } from '../constants/jurisdiction/TenantSettings';
import { ArchitectApplication } from './applications/architect-application';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface IContinuingEducationModel {
  completed?: boolean;
  exemption?: string;
}

export class ContinuingEducationForm {
  static GetFormGroup(
    formBuilder: FormBuilder,
    application: ArchitectApplication,
    stepSettings: ContinuingEducationStepSettings
  ): FormGroup {
    if (!application?.continuingEducation) {
      console.error('application.continuingEducation not set');
      return formBuilder.group({
        completed: ['', Validators.required],
        exemption: '',
        docCount: [0, [Validators.required, Validators.min(1)]],
      });
    }
    const form = formBuilder.group({
      completed: [application.continuingEducation.completed, Validators.required],
      exemption: application.continuingEducation.exemption,
      docCount: [0, [Validators.required, Validators.min(1)]], //docCount has to wait for S3 response
    });

    if (!application?.applicationVariant) {
      console.error('application.applicationVariant not set');
      return form;
    }

    ContinuingEducationForm.setControls(
      application.continuingEducation.completed,
      application.continuingEducation.exemption,
      form.controls.exemption,
      form.controls.docCount,
      stepSettings
    );
    return form;
  }

  //set the enabled/disabled status for the exemption and document control
  static setControls(
    isCompleted: boolean,
    reason: string,
    exemption: AbstractControl,
    docCount: AbstractControl,
    settings: ContinuingEducationStepSettings
  ) {
    const { exemptionRequired, docsRequired } = ContinuingEducationForm.calculateControlStatuses(isCompleted, reason, settings);

    if (exemptionRequired) {
      exemption.setValidators(Validators.required);
      exemption.enable();
    } else {
      exemption.clearValidators();
      exemption.disable();
    }
    if (docsRequired) {
      docCount.enable();
    } else {
      docCount.disable();
    }
    exemption.updateValueAndValidity();
  }

  //calculate if
  //1) the exemption reason control should be required
  //2) the doc upload control should be required
  private static calculateControlStatuses(
    isCompleted: boolean,
    reason: string,
    settings: ContinuingEducationStepSettings
  ): { exemptionRequired: boolean; docsRequired: boolean } {
    if (isCompleted || !settings.exemptionConfiguration) {
      //if ContinuingEd completed OR no allowed exceptions required then
      //no exemption reasons are never required. docs requires pulled from stepSettings.requireDocumentationForCompleted
      return { exemptionRequired: false, docsRequired: !!settings.completedEducationDocumentation };
    }
    //settings.exemptionConfiguration guaranteed to be truthy

    if (isCompleted === false) {
      if (settings.exemptionConfiguration.requireDocumentation) {
        return {
          exemptionRequired: true,
          docsRequired: !settings.exemptionConfiguration.reasonsNoDocsRequired.some(o => o === reason), //settings.requireDocumentationForExemption
        };
      }
      return { exemptionRequired: true, docsRequired: false };
    }

    //isCompleted is undefined and there are exemptions
    return { exemptionRequired: true, docsRequired: false };
  }

  public static getApiModel(formGroup: FormGroup): IContinuingEducationModel {
    return formGroup.value.completed
      ? {
          completed: true,
        }
      : {
          completed: formGroup.value.completed,
          exemption: formGroup.value.exemption,
        };
  }
}
