import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CommonApiService } from 'projects/common/src/lib/services/api/common-api.service';
import { State } from 'projects/common/src/lib/models/address';

@Injectable()
export class StatesResolve implements Resolve<State[]> {
  constructor(private commonService: CommonApiService) {}

  resolve() {
    return this.commonService.states();
  }
}
