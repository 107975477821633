import { Component, OnInit, Output, EventEmitter, HostBinding } from '@angular/core';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { ActivatedRoute } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { TestInfo } from 'projects/common/src/lib/services/api/architect-application.service';
import { WizardSetupApplicationArgs } from '../license-wizard/license-wizard.component';

@Component({
  selector: 'app-setup-firm-application',
  templateUrl: './setup-firm-application.component.html',
  styleUrls: ['./setup-firm-application.component.scss'],
})
export class SetupFirmApplicationComponent implements OnInit {
  @HostBinding('class.opt-in') optin = true;
  @Output() change = new EventEmitter();
  show: boolean;
  applications = ['License', 'Renewal', 'Reinstatement'];
  applicationType;
  isCreateInitialApp: boolean;
  firmLicenseType;
  testOptions: TestInfo;
  constructor(
    private firmApplicationService: FirmApplicationService,
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {}

  async ngOnInit() {
    this.show = this.settings.enableEasyTesting;
    await this.getTestOptions();
  }

  async getTestOptions() {
    this.testOptions = await this.firmApplicationService.getTestOptions(this.route.snapshot.params.firmId);
    if (this.settings.validFirmTypes) {
      if (!!this.settings.validFirmTypes[0]) this.firmLicenseType = this.settings.validFirmTypes[0].id;
    }
    this.isCreateInitialApp = false;
  }

  async submit($event: WizardSetupApplicationArgs | true) {
    const payload =
      $event === true
        ? {
            licenseType: this.firmLicenseType,
            createInitialApplication: true,
          }
        : {
            ...$event,
            createInitialApplication: false,
            licenseType: this.firmLicenseType,
          };

    await this.firmApplicationService.setup(this.route.snapshot.params.firmId, payload);
    this.change.emit();
    await this.getTestOptions();
  }
}
