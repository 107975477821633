import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkHistoryForArchApp } from 'projects/common/src/lib/models/work-history-for-arch-app';
import { ArchitectApplicationService } from '../services/api/architect-application.service';

@Injectable()
export class WorkHistoryForArchAppResolve implements Resolve<WorkHistoryForArchApp> {
  constructor(private architectApplicationService: ArchitectApplicationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<WorkHistoryForArchApp> {
    let personId = route.params.personId;
    let applicationId = route.params.applicationId;
    return this.architectApplicationService.getWorkHistoryForArchApp(personId, applicationId);
  }
}
