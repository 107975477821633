import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ISearch, ISearchWithApplicationDateRanges, ISearchWithLicenseDateRanges } from '../../../models/search/i-search';
import { Observable, map } from 'rxjs';
import { ListData } from '../../../models/search/list-data';
import { GlobalSearchLookupItem } from '../../../models/search/global-search-lookup-item';
import { GlobalSearch } from '../../../models/search/global-search';
import { ArchitectLicense } from '../../../models/architect-license';
import { ArchitectLicenseSearch } from '../../../models/search/architect-license-search';
import { IndividualLicenseSearch } from '../../../models/search/individual-license-search';
import { ApplicationSearch } from '../../../models/search/application-search';
import { ApplicationSearchView } from '../../../models/applications/application-search-view';
import { IndividualLicensesSearchView } from '../../../models/licenses/individual-licenses-search-view';
import { FirmLicense } from '../../../models/firm-license';
import { FirmLicenseSearch } from '../../../models/search/firm-license-search';
import { FirmSearch } from '../../../models/search/firm-search';
import { FirmLookup } from '../../../models/firm-lookup';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { LicenseStatusSummary } from '../../../models/license-status-summary';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService {
  lookupItemsMax: number;

  constructor(
    protected api: ApiService,
    tenantSettings: TenantSettings
  ) {
    this.lookupItemsMax = tenantSettings.lookupItemsMax;
  }

  //#region Global

  searchGlobal(searchTerm: string): Observable<GlobalSearchLookupItem[]> {
    let searchParams = new GlobalSearch({
      search: searchTerm,
      pageSize: this.lookupItemsMax + 1,
      sort: 'name',
    });

    let newItem = (init: any): GlobalSearchLookupItem => {
      return new GlobalSearchLookupItem(init);
    };

    return this.search('', searchParams, newItem).pipe(map((x: ListData<GlobalSearchLookupItem>) => x.data));
  }

  //#endregion

  //#region Architect License

  getArchitectLicenseStatusSummary = () => this.getLicenseStatusSummary('architectlicense/');

  getArchitectLicenseLookup(
    searchTerm: string,
    maxItems: number = null,
    status: string = null,
    statusGroup: string = null,
    searchOnlyOnNumber = false
  ): Observable<ArchitectLicense[]> {
    const newSearch = (init: any): ArchitectLicenseSearch => {
      return new ArchitectLicenseSearch(init);
    };

    const searchParams = this.getLookup(newSearch, searchTerm, status, statusGroup, searchOnlyOnNumber, maxItems);

    return this.searchArchitectLicenses(searchParams).pipe(map((x: ListData<ArchitectLicense>) => x.data));
  }

  searchArchitectLicenses(searchTerm: string);
  searchArchitectLicenses(searchTerm: ArchitectLicenseSearch);
  searchArchitectLicenses(searchTerm: string | ArchitectLicenseSearch): Observable<ListData<ArchitectLicense>> {
    let searchParams: ArchitectLicenseSearch;

    if (typeof searchTerm === 'string') {
      searchParams = new ArchitectLicenseSearch({
        search: searchTerm as string,
        pageSize: this.lookupItemsMax + 1,
        sort: 'name',
      });
    } else {
      searchParams = searchTerm as ArchitectLicenseSearch;
    }

    const newItem = (init: any): ArchitectLicense => {
      return new ArchitectLicense(init);
    };

    return this.search('architectlicense/', searchParams, newItem);
  }

  //#endregion

  //#region Individual License

  getIndividualLicenseLookup(
    searchTerm: string,
    maxItems: number = null,
    status: string = null,
    statusGroup: string = null,
    searchOnlyOnNumber = false,
    licenseType: string = null,
    profession: string = null
  ): Observable<IndividualLicensesSearchView[]> {
    const newSearch = (init: any): IndividualLicenseSearch => {
      return new IndividualLicenseSearch(init);
    };

    const searchParams = this.getLookup(newSearch, searchTerm, status, statusGroup, searchOnlyOnNumber, maxItems);
    searchParams.licenseType = licenseType;
    searchParams.profession = profession;

    return this.searchIndividualLicenses(searchParams).pipe(map((x: ListData<IndividualLicensesSearchView>) => x.data));
  }

  searchIndividualLicenses(searchTerm: string);
  searchIndividualLicenses(searchTerm: IndividualLicenseSearch);
  searchIndividualLicenses(searchTerm: string | IndividualLicenseSearch): Observable<ListData<IndividualLicensesSearchView>> {
    let searchParams: IndividualLicenseSearch;

    if (typeof searchTerm === 'string') {
      searchParams = new IndividualLicenseSearch({
        search: searchTerm as string,
        pageSize: this.lookupItemsMax + 1,
        sort: 'name',
      });
    } else {
      searchParams = searchTerm as IndividualLicenseSearch;
    }

    const datedSearchParams = this.getSearchParamsWithLicenseDateRange(searchParams);
    const newItem = (init: any): IndividualLicensesSearchView => {
      return new IndividualLicensesSearchView(init);
    };

    return this.search('individualLicense/', datedSearchParams, newItem);
  }

  //#endregion

  //#region Applications

  getApplicationLookup(
    searchTerm: string,
    maxItems: number = null,
    status: string = null,
    statusGroup: string = null,
    searchOnlyOnNumber = false
  ): Observable<ApplicationSearchView[]> {
    const newSearch = (init: any): ApplicationSearch => {
      return new ApplicationSearch(init);
    };

    const searchParams = this.getLookup(newSearch, searchTerm, status, statusGroup, searchOnlyOnNumber, maxItems);

    return this.searchApplications(searchParams).pipe(map((x: ListData<ApplicationSearchView>) => x.data));
  }

  searchApplications(searchTerm: string);
  searchApplications(searchTerm: ApplicationSearch);
  searchApplications(searchTerm: string | ApplicationSearch): Observable<ListData<ApplicationSearchView>> {
    let searchParams: ApplicationSearch;

    if (typeof searchTerm === 'string') {
      searchParams = new ApplicationSearch({
        search: searchTerm as string,
        pageSize: this.lookupItemsMax + 1,
        sort: 'name',
      });
    } else {
      searchParams = searchTerm as ApplicationSearch;
    }

    const datedSearchParams = this.getSearchParamsWithApplicationDateRange(searchParams);
    const newItem = (init: any): ApplicationSearchView => {
      return new ApplicationSearchView(init);
    };

    return this.search('applications/', datedSearchParams, newItem);
  }

  //#endregion

  //#region FirmLicenses

  getFirmLicenseStatusSummary = () => this.getLicenseStatusSummary('firmLicense/');

  getFirmLicenseLookup(
    searchTerm: string,
    maxItems: number = null,
    status: string = null,
    statusGroup: string = null,
    searchOnlyOnNumber = false
  ): Observable<FirmLicense[]> {
    const newSearch = (init: any): FirmLicenseSearch => {
      return new FirmLicenseSearch(init);
    };

    const searchParams = this.getLookup(newSearch, searchTerm, status, statusGroup, searchOnlyOnNumber, maxItems);

    return this.searchFirmLicenses(searchParams).pipe(map((x: ListData<FirmLicense>) => x.data));
  }

  searchFirmLicenses(searchTerm: string);
  searchFirmLicenses(searchTerm: FirmLicenseSearch);
  searchFirmLicenses(searchTerm: string | FirmLicenseSearch): Observable<ListData<FirmLicense>> {
    let searchParams: FirmLicenseSearch;

    if (typeof searchTerm === 'string') {
      searchParams = new FirmLicenseSearch({
        search: searchTerm as string,
        pageSize: this.lookupItemsMax + 1,
        sort: 'name',
      });
    } else {
      searchParams = searchTerm as FirmLicenseSearch;
    }

    const newItem = (init: any): FirmLicense => {
      return new FirmLicense(init);
    };

    return this.search('firmlicense/', searchParams, newItem);
  }

  //#endregion

  //#region Firms

  getFirmLookup(
    searchTerm: string,
    maxItems: number = null,
    status: string = null,
    statusGroup: string = null,
    searchOnlyOnNumber = false,
    hasLicense = null
  ): Observable<FirmLookup[]> {
    const newSearch = (init: any): FirmSearch => {
      return new FirmSearch(init);
    };

    const searchParams = this.getLookup(newSearch, searchTerm, status, statusGroup, searchOnlyOnNumber, maxItems);
    searchParams.hasLicense = hasLicense;

    return this.searchFirms(searchParams).pipe(map((x: ListData<FirmLookup>) => x.data));
  }

  searchFirms(searchTerm: string);
  searchFirms(searchTerm: FirmSearch);
  searchFirms(searchTerm: string | FirmSearch): Observable<ListData<FirmLookup>> {
    let searchParams: FirmSearch;

    if (typeof searchTerm === 'string') {
      searchParams = new FirmSearch({
        search: searchTerm as string,
        pageSize: this.lookupItemsMax + 1,
        sort: 'name',
      });
    } else {
      searchParams = searchTerm as FirmSearch;
    }

    const datedSearchParams = this.getSearchParamsWithLicenseDateRange(searchParams);
    const newItem = (init: any): FirmLookup => {
      return new FirmLookup(init);
    };

    return this.search('firm/', datedSearchParams, newItem);
  }

  //#endregion

  //#region helper

  private getLookup<U extends ISearch>(
    newSearch: (init: any) => U,
    searchTerm: string,
    status: string,
    statusGroup: string,
    searchOnlyOnNumber,
    maxItems: number = null
  ): U {
    maxItems = maxItems || this.lookupItemsMax;

    return newSearch({
      search: searchTerm,
      pageSize: maxItems + 1,
      sort: 'name',
      status,
      searchOnlyOnNumber,
      statusGroup,
    });
  }

  private getSearchParamsWithLicenseDateRange<U extends ISearchWithLicenseDateRanges>(searchInput: U): U {
    const searchParams = { ...searchInput } as any;

    delete searchParams.issueDateRange;
    delete searchParams.expirationDateRange;

    if (searchInput.issueDateRange) {
      if (searchInput.issueDateRange.since) {
        searchParams.issueDateSince = searchInput.issueDateRange.since.format('YYYY-MM-DD');
      }
      if (searchInput.issueDateRange.until) {
        searchParams.issueDateUntil = searchInput.issueDateRange.until.format('YYYY-MM-DD');
      }
    }

    if (searchInput.expirationDateRange) {
      if (searchInput.expirationDateRange.since) {
        searchParams.expirationDateSince = searchInput.expirationDateRange.since.format('YYYY-MM-DD');
      }
      if (searchInput.expirationDateRange.until) {
        searchParams.expirationDateUntil = searchInput.expirationDateRange.until.format('YYYY-MM-DD');
      }
    }

    return searchParams;
  }

  private getSearchParamsWithApplicationDateRange<U extends ISearchWithApplicationDateRanges>(searchInput: U): U {
    const searchParams = { ...searchInput } as any;

    delete searchParams.applicationDateRange;

    if (searchInput.applicationDateRange) {
      if (searchInput.applicationDateRange.since) {
        searchParams.applicationDateSince = searchInput.applicationDateRange.since.format('YYYY-MM-DD');
      }
      if (searchInput.applicationDateRange.until) {
        searchParams.applicationDateUntil = searchInput.applicationDateRange.until.format('YYYY-MM-DD');
      }
    }

    return searchParams;
  }

  private search<T>(path: string, searchParams: ISearch, newItem: (init: any) => T): Observable<ListData<T>> {
    let qs = this.api.queryStringify(searchParams);

    return this.api.get('search/'.concat(path, qs)).pipe(
      map(
        json =>
          new ListData<T>({
            data: json.data.map(result => newItem(result)),
            count: json.count,
          })
      )
    );
  }

  private getLicenseStatusSummary(path: string): Observable<LicenseStatusSummary[]> {
    let url = path.concat('statusSummary');
    return this.api.get(url).pipe(map(data => data.map(s => new LicenseStatusSummary(s))));
  }

  //#endregion
}
