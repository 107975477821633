import { PipeTransform, Pipe } from '@angular/core';
import { spaceCapCase } from '../utility/string-utils';
@Pipe({
  name: 'spaceCapCase',
})
export class SpaceCapCasePipe implements PipeTransform {
  transform(str: string) {
    return spaceCapCase(str);
  }
}
