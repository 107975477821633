<table mat-table [dataSource]="items$" class="simple-table equal-widths last-col-longer">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let poc">
      <a *ngIf="isStaffView; else nameTemplate" [routerLink]="staffViewRoute(poc)">{{
        poc.pointOfContactPerson.name.firstLast
      }}</a>
      <ng-template #nameTemplate>
        {{ poc.pointOfContactPerson.name.firstLast }}
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let poc">
      {{ poc.pointOfContactPerson.email }}
    </td>
  </ng-container>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let poc">{{ poc.title }}</td>
  </ng-container>
  <ng-container matColumnDef="requestInfo">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let poc">
      {{ getRequestLabel(poc) }}
      <br />
      {{ getRequestDate(poc) | date: 'shortDate' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let poc">
      <div *ngIf="poc.isPending && !isProcessing">
        <button mat-button type="button" class="mat-button-approve" color="primary" (click)="onApprove(poc)">Approve</button>
        <button mat-button type="button" class="mat-button-approve" (click)="onDeny(poc)">Deny</button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="menu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let poc">
      <button mat-button type="button" [matMenuTriggerFor]="optionsMenu">
        <mat-icon class="muted-text">more_horiz</mat-icon>
      </button>
      <mat-menu #optionsMenu="matMenu">
        <button mat-button type="button" (click)="onUpdate()">Update POC</button>
        <button mat-button type="button" (click)="onRemove(poc)">Remove Access</button>
      </mat-menu>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let poc; columns: columns" [ngClass]="{ pending: poc.isPending }" [@statusChange]="poc.status"></tr>
</table>
<lib-confirm
  #confirmRemove="confirm"
  yesText="Yes, revoke"
  noText="No, cancel"
  (yes)="revoke(pocToRemove)"
  [template]="confirmPOCRevokeAccessTemplate"
></lib-confirm>
<ng-template #confirmPOCRevokeAccessTemplate>
  <p>Are you sure you want to remove poc access?</p>
</ng-template>
