import { LookupItem } from '../search/lookup-item';
import { UpdatableModel } from '../updatable-model';

export class IndividualLicensesSearchView extends UpdatableModel<IndividualLicensesSearchView> implements LookupItem {
  get lookupId() {
    return this.personId;
  }

  get lookupStatus() {
    return this.licenseStatus;
  }

  get lookupName() {
    return this.name;
  }

  get lookupInfo() {
    return [this.email, this.address, this.primaryPhoneNumber].filter(_ => !!_).join(' | ');
  }

  get status() {
    return this.licenseStatus;
  }

  name: string;
  nameUnaccented: string;
  nameLastFirst: string;
  personId: string;
  email: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  ncarbNumber: number;
  clarbNumber: number;
  firmName: string;
  address: string;
  licenseNumber: string;
  licenseNumeric: number;
  profession: string;
  residency: string;
  licenseOrigin: string;
  licenseId: string;
  licenseStatus: string;
  licenseStatusId: number;
  issueDate: Date;
  expirationDate: Date;
}
