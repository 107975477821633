<table *ngIf="questionnaireResponse; else none">
  <tr *ngFor="let answer of answers">
    <td>
      <span class="question-text" [innerHtml]="answer.questionText"></span>
      <div class="explanation" *ngIf="answer.explanation">
        <label>Explanation</label>
        <div>{{ answer.explanation }}</div>
        <lib-upload-widget
          [aggregateRootId]="aggregateRootId"
          [subjectId]="answer.id"
          [prePopulateUrl]="true"
          [readonly]="true"
          [viewStyle]="tableStyle"
          noDocumentsMessage="No Documents Uploaded"
        >
        </lib-upload-widget>
      </div>
    </td>
    <td>
      {{ answer.responseValue ? 'Yes' : 'No' }}
    </td>
  </tr>
</table>

<ng-template #none>
  <i>No Questionnaire Response</i>
</ng-template>
