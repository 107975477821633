import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable()
export class ModifiedMomentDateAdapter extends MomentDateAdapter {
  deserialize(value: any) {
    // dateonly MM/dd/yyyy format fixup
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      value = new Date(value);
    }
    return super.deserialize(value);
  }

  parse(value: any, parseFormat: string | string[]): Moment | null {
    let originalValue = value;
    value = super.parse(value, parseFormat);
    if (originalValue && originalValue.split) {
      let values = originalValue.split('/');
      if (values.length == 3 && values[2].length < 4) {
        let momentValue = value as Moment;
        if (momentValue && momentValue.year() > moment().year() + 10) {
          momentValue.year(momentValue.year() - 100);
        }
      }
    }
    return value;
  }
}
