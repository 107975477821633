export class UpdatableModel<T> {
  constructor(init?: Partial<T>) {
    this.update(init);
  }

  // Note
  // when working with object with nested properties
  // it is recommended to override the this method,
  // call super.update(init), and then new up the copied properties
  // doing that ensures things like nested property methods are available for use
  // not doing that will work for simple nested objects
  // but only because the json dictionary properties happen to match
  // the class properties. if you don't new up the nested properties
  // use will be working with nested property objects/dicts/{} raw from json
  update(init?: Partial<T>) {
    Object.assign(this, init);
  }
}
