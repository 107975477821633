import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-p',
  template: '<ng-content></ng-content>',
})
export class PComponent {
  @Input() level: '1' | '2';
  @HostBinding('class') get getClass() {
    return `d-block type-p-${this.level}`;
  }
}
