import { Component, Input } from '@angular/core';
import { FirmRoleHolder } from 'projects/common/src/lib/models/firm-role-holder';
import { ListComponent } from 'projects/common/src/lib/components/list';

@Component({
  selector: 'past-roles-list',
  templateUrl: './past-roles-list.component.html',
  styleUrls: ['./past-roles-list.component.scss'],
})
export class PastRolesListComponent extends ListComponent<FirmRoleHolder> {
  columns = ['licenseNumber', 'name', 'role', 'startDate', 'endDate'];
  @Input() linkedName: boolean = true;
  constructor() {
    super();
  }
}
