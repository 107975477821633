import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOne =
  (validator: ValidatorFn, controls: string[] = null, errorMessage: string = null) =>
  (group: FormGroup): ValidationErrors | null => {
    if (!controls) controls = Object.keys(group.controls);

    const hasAtLeastOne = group && group.controls && controls.some(k => !validator(group.controls[k]));

    // causes the collection of controls used in this validation to be highlighted if validation fails
    controls.forEach(control => {
      if (!hasAtLeastOne) group.controls[control].setErrors({ invalid: true });
      else group.controls[control].setErrors(null);
    });

    return hasAtLeastOne ? null : { atLeastOne: { message: errorMessage } };
  };
