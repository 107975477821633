import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressLookups } from '../../models/address';
import { CommonApiService } from '../../services/api/common-api.service';

@Component({
  selector: 'lib-address',
  templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() editing: boolean;
  @Input() line1Label: string;
  @Input() line2Label: string;
  @Input() hideCounty: boolean = false;
  @Input() hideValidation: boolean = false;
  addressLookups: AddressLookups;
  @HostBinding('class.opt-in') optin = true;
  constructor(private commonApiService: CommonApiService) {}

  ngOnInit(): void {
    this.commonApiService.addressLookups().subscribe(lookups => {
      this.addressLookups = lookups;
    });
  }
}
