import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ListComponent } from '../list';
import { WorkHistory } from '../../models/work-history';
import { ConfirmDialogueDirective } from 'projects/common/src/lib/components/confirm-dialogue/confirm.dialogue.directive';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-work-history-list',
  templateUrl: './work-history-list.component.html',
  styleUrls: ['./work-history-list.component.scss'],
})
export class WorkHistoryListComponent extends ListComponent<WorkHistory> {
  @Output() onEdit = new EventEmitter<WorkHistory>();
  @Output() onDelete = new EventEmitter<WorkHistory>();
  columns = ['firmName', 'firmLicenseNumber', 'startDate', 'dateSeparator', 'endDate', 'title', 'menu'];
  workHistoryToDelete: WorkHistory;

  @ViewChild(ConfirmDialogueDirective) confirmDelete: ConfirmDialogueDirective;
  constructor(private settings: TenantSettings) {
    super();
  }

  getLicenseStatus(workHistory: WorkHistory): string {
    return workHistory?.firm?.licenseStatus;
  }

  getStatusDisplay(workHistory: WorkHistory) {
    return this.settings.getStatusLabel(this.getLicenseStatus(workHistory));
  }

  getStatusClass(workHistory: WorkHistory) {
    return this.settings.getCustomStatusCssClass(this.getLicenseStatus(workHistory), 'status-', 'unlicensed');
  }

  route(workHistory: WorkHistory) {
    return ['/firm', workHistory.firm.id];
  }

  edit(workHistory: WorkHistory) {
    this.onEdit.emit(workHistory);
  }

  delete(workHistory: WorkHistory) {
    this.workHistoryToDelete = workHistory;
    this.confirmDelete.open();
  }

  deleteWorkHistory(workHistory: WorkHistory) {
    this.onDelete.emit(workHistory);
  }
}
