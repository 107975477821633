import { Person } from '../person';
import { Order } from '../order';
import { IContinuingEducationModel } from '../continuing-education';
import { ApplicationBase } from './application-base';
import { Application } from './application';

export class ArchitectApplication extends ApplicationBase<ArchitectApplication> implements Application {
  person: Person;
  personId: string;
  profession: string;
  professionDescription: string;
  licenseOrigin: string;
  continuingEducation: IContinuingEducationModel;

  isSameEmployer: boolean;
  isUsCitizen: boolean;
  currentWorkStatus: string;
  employerRegistrationStatus: string;
  selfEmploymentStatus: string;

  order: Order;
  militaryNumber: string;
  currentLicenseExpirationDate: string;

  update(init?: Partial<ArchitectApplication>) {
    super.update(init);
    if (this.person) {
      this.person = new Person(this.person);
    }
  }

  hasGap() {
    if (!this.currentLicenseExpirationDate) {
      return false;
    }
    const compareEndDate = this.submittedOn ? new Date(this.submittedOn) : new Date();
    return new Date(this.currentLicenseExpirationDate) < compareEndDate;
  }

  get entityId() {
    return this.personId;
  }

  get professionAbbreviation() {
    return this.professionDescription
      .split(' ')
      .map(t => t[0])
      .join('');
  }
}
