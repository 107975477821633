import { Component } from '@angular/core';
import { ListComponent } from 'projects/common/src/lib/components/list/list.component';
import { FirmRosterMember } from 'projects/common/src/lib/models/firm-roster-member';

@Component({
  selector: 'app-portal-firm-roster',
  templateUrl: './portal-firm-roster.component.html',
  styleUrls: ['./portal-firm-roster.component.scss'],
})
export class PortalFirmRosterComponent extends ListComponent<FirmRosterMember> {
  columns = ['name', 'title', 'licenseNumber', 'status', 'issueDate', 'expirationDate'];
}
