import { Component, Input, HostBinding, EventEmitter, Output, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { AbstractControl } from '@angular/forms';
import { WizardStepEvent } from './wizard-step-event';
import { WizardStepVisibilityEvent } from './wizard-step-visibility-event';

@Component({
  selector: 'lib-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.scss'],
})
export class WizardStepComponent extends BaseComponent implements OnInit {
  private _isVisible: boolean;
  isValid: boolean;

  @HostBinding('class.wizard-step') stepClass = true;
  @HostBinding('class.displayed') get isVisible() {
    return this._isVisible;
  }
  @Input() name: string;
  @Input() control: AbstractControl;
  // Emitted whenever the control's visibility changes.
  @Output() visibilityChanged = new EventEmitter<WizardStepVisibilityEvent>();
  // Emitted whenever the control status changes.
  @Output() statusChanged = new EventEmitter<WizardStepEvent>();
  // Emitted only when the control goes from valid to a non valid state.
  @Output() validityChanged = new EventEmitter<WizardStepEvent>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._isVisible = false;
    if (this.control) {
      this.unsubOnDestroy = this.control.statusChanges.subscribe(value => {
        this.statusChanged.emit(this.getStatusEvent());
        this.emitValidityChangedIfNecessary(value);
      });
    }
  }

  set isVisible(value: boolean) {
    this._isVisible = value;
    if (this.control) {
      if (value) {
        this.control.enable();
      } else {
        this.control.disable();
      }
    }
    let event = new WizardStepVisibilityEvent(this.getStatusEvent());
    event.visible = value;
    this.visibilityChanged.emit(event);
  }

  private emitValidityChangedIfNecessary(value: any): void {
    let wasValid = this.isValid;
    this.isValid = value === 'VALID';
    if (this.isValid != wasValid) {
      this.validityChanged.emit(this.getStatusEvent());
    }
  }

  private getStatusEvent() {
    return new WizardStepEvent({
      step: this,
    });
  }
}
