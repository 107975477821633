import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';
import { Firm } from 'projects/common/src/lib/models/firm';

@Injectable()
export class FirmResolve implements Resolve<Firm> {
  constructor(private firmService: FirmService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Firm> {
    let firmId = route.params.firmId || route.parent.params.firmId;
    return this.firmService.get(firmId);
  }
}
