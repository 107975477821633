import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { JurisdictionConstants } from '../../constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { Address } from '../../models/address';
import { getApplicationSettings } from '../../utility/router-extensions';

@Component({
  selector: 'application-review-person-address',
  templateUrl: './application-review-person-address.component.html',
})
export class ApplicationReviewPersonAddress implements OnInit {
  @Input() workOrHome: 'work' | 'home';
  @HostBinding('class.d-none')
  get isNotInViewport(): boolean {
    return this.workOrHome == 'home' && this.hideCounty;
  }
  addressForm: FormGroup;
  profession: string;
  line1Label: string;
  line2Label: string;
  hideCounty: boolean = false;
  showFirmName: boolean;

  constructor(
    private route: ActivatedRoute,
    public constants: JurisdictionConstants,
    private formBuilder: FormBuilder,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    const personalSettings = getApplicationSettings(this.route).stepConfiguration.personal;
    this.addressForm = Address.GetFormGroup(this.formBuilder, { workAddressSameAsHome: '', firmName: '' });

    this.showFirmName = !this.settings.hasWorkHistory && this.workOrHome == 'work';
    this.profession = (this.route.snapshot.data.application as ArchitectApplication).profession;
    this.hideCounty = personalSettings.hideCounty;
    let address: Address;
    if (this.workOrHome == 'home') {
      address = this.route.snapshot.data.homeAddress;
      this.line1Label = personalSettings.homeAddressFirstLineLabel;
      this.line2Label = personalSettings.homeAddressSecondLineLabel;
    } else {
      //work
      address = this.route.snapshot.data.workAddress;
      this.line1Label = personalSettings.workAddressFirstLineLabel;
      this.line2Label = personalSettings.workAddressSecondLineLabel;
    }

    this.addressForm.patchValue({ ...address, firmName: this.route.snapshot.data.personalInfo.firmName });
  }
}
