<ng-container *ngFor="let filter of helper.getDisplayedFilters(filters)">
  <app-filter-button
    [filterName]="helper.getFilterButtonName(filter)"
    [menu]="helper.getFilterMenu(filter)"
    (onTriggerSet)="registerFilterTrigger(filter, $event)"
  >
  </app-filter-button>
</ng-container>
<button *ngIf="helper.onMoreClicked" mat-button color="primary" (click)="helper.onMoreClicked()">More</button>
<button *ngIf="helper.onGlobalSearchChanged" mat-button color="primary" [matMenuTriggerFor]="globalSearchMenu">
  <mat-icon>search</mat-icon>
</button>
<mat-menu #globalSearchMenu xPosition="before" yPosition="below">
  <div ls-style-opt-in (click)="prevent($event)">
    <div class="flex-fields">
      <mat-form-field appearance="outline">
        <input
          matInput
          [(ngModel)]="globalSearch"
          type="text"
          placeholder="Search"
          (ngModelChange)="helper.onGlobalSearchChanged($event)"
          (keydown.enter)="closeGlobalSearch()"
        />
      </mat-form-field>
    </div>
  </div>
</mat-menu>
<div class="mt-medium">
  <mat-chip-list aria-label="Active Filters">
    <ng-container *ngFor="let filter of helper.getActiveFilters(filters)">
      <mat-chip>
        {{ helper.getFilterChipName(filter) }}
        <mat-icon matChipRemove (click)="helper.removeFilter(filter)">cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>
