import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

export interface AvailableTransitionModel {
  currentStatus: string;
  availableStatuses: string[];
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private api: ApiService) {}

  availableLicenseStatuses = (licenseId: string): Promise<AvailableTransitionModel> =>
    this.api.getAsync<AvailableTransitionModel>(`status/license/${licenseId}`);

  availableApplicationStatuses = (application: string): Promise<AvailableTransitionModel> =>
    this.api.getAsync<AvailableTransitionModel>(`status/application/${application}`);
}
