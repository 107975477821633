import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalStepSettings, TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { ActivatedRoute } from '@angular/router';
import { getApplicationSettings } from '../../utility/router-extensions';

@Component({
  selector: 'application-review-contact-info',
  templateUrl: './application-review-contact-info.html',
})
export class ApplicationReviewContactInfoComponent implements OnInit {
  @Input() form: FormGroup;
  personalStepSettings: PersonalStepSettings;

  constructor(
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.personalStepSettings = getApplicationSettings(this.route).stepConfiguration.personal;
  }
}
