import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { QuestionnaireService } from 'projects/common/src/lib/services/api/questionnaire.service';

@Injectable()
export class QuestionnaireResponsePrePopulateResolve implements Resolve<QuestionnaireResponse> {
  constructor(private questionnaireService: QuestionnaireService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<QuestionnaireResponse> {
    let application = route.data.application || route.parent.data.application;
    let applicationId = (application && application.id) || route.params.applicationId;
    return this.questionnaireService.getQuestionnaire(applicationId, true);
  }
}
