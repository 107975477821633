import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FileUploader, FileUploaderOptions, FileLikeObject } from 'ng2-file-upload';
import { forkJoin, interval, Observable, of } from 'rxjs';
import { buffer, filter, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { FileInfo } from '../../models/file-info';
import { FileUploadConfig } from '../../constants/file-upload-config';
import { NotificationService } from '../../services/notification.service';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { S3Service } from '../../services/api/s3.service';
import { UploadedFile } from './UploadedFile';
import { UploadListenerComponent } from './upload-listener.component';

export enum UploadViewStyle {
  Pill = 'Pill',
  Table = 'Table',
  None = 'None',
}

export enum UploadError {
  NoUploads = 'NoUploads',
  SystemError = 'SystemError',
  FileTypeNotSupportedError = 'FileTypeNotSupported',
  FileSizeExceedsLimitError = 'FileSizeExceedsLimit',
}

const uploaderOptions: FileUploaderOptions = {
  maxFileSize: FileUploadConfig.maxFileSize,
  allowedFileType: FileUploadConfig.allowedFileTypes,
};

@Component({
  selector: 'lib-upload-widget',
  templateUrl: './upload-widget.component.html',
  styleUrls: ['./upload-widget.component.scss'],
})
export class UploadWidgetComponent extends UploadListenerComponent implements OnInit {
  /**
   * Whether or not the user can upload files or just view existing uploads
   */
  @Input() readonly: boolean;

  /**
   * The message to use when there are no documents.
   */
  @Input() noDocumentsMessage: string = 'Please upload a file';

  /**
   * The style to display the list of uploaded files
   */
  @Input() viewStyle: UploadViewStyle = UploadViewStyle.Pill;

  /**
   * The uploaded files caption to display
   */
  @Input() caption: string = 'Uploaded Documents';

  /**
   * Whether or not uploads are required
   */
  @Input() uploadsRequired: boolean = true;

  /**
   * Columns to display in the uploaded file list table
   */
  @Input() columns: string[] = ['file', 'lastModified', 'documentType', 'stage', 'delete'];

  /**
   * Optional callback to check if the document can be deleted
   */
  @Input() canDocBeDeletedCallback: (doc: S3DocumentReference) => boolean = function (doc: S3DocumentReference) {
    return true;
  };

  /**
   * Whether or not the component is querying for a list of uploaded files
   */
  isLoading: boolean;
  /**
   * Indicates of the hover class should be added to ng2FileDrop when cursor is over element
   */
  isFileOver: boolean;

  /**
   * The list of allowed file extensions.  Set from FileUploader.options
   */
  allowedFileExtensions: string;
  uploader: FileUploader;

  /**
   * List of files that have been successfully uploaded to the S3 bucket
   */
  private uploadedFiles: UploadedFile[];

  /**
   * Used to allow use of enumeration in html template markup
   */
  uploadError = UploadError;

  constructor(
    s3Service: S3Service, //protected in UploadListenerComponent
    notificationService: NotificationService //protected in UploadListenerComponent
  ) {
    super(s3Service, notificationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.filesTitle = this.filesTitle || 'Files';

    this.uploader = this.buildFileUploader();

    this.allowedFileExtensions = UploadWidgetComponent.determineFileExtensions(this.uploader);

    let uploads$ = this.getUploads(this.docSearch, this.uploader);
    this.unsubOnDestroy = uploads$.subscribe(docs => {
      this.docsList$.next(docs);
    });

    this.unsubOnDestroy = this.docsList$.subscribe(docs => {
      this.uploadedFiles = docs.map(d => new UploadedFile({ file: d, error: null }));
    });
  }

  buildFileUploader(): FileUploader {
    const uploader = new FileUploader(uploaderOptions);
    uploader.onWhenAddingFileFailed = this.onWhenAddingFileFailed;
    return uploader;
  }

  onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
    switch (filter.name) {
      case 'fileSize':
        let fileSize = Math.round((item.size / FileUploadConfig.oneMb) * 100) / 100;
        this.notificationService.notifyFail(
          `Maximum upload size exceeded (${fileSize} MB of ${FileUploadConfig.maxFileSizeInMb} MB allowed)`
        );
        break;
      case 'fileType':
        this.notificationService.notifyFail('The selected file type is not supported');
        break;
      default:
        console.error(`Unknown error (filter is ${filter.name})`);
        this.notificationService.notifyFail('The selected file cannot be uploaded, please try again with a different file.');
    }
  };

  isViewStyle(style: UploadViewStyle): boolean {
    return this.viewStyle === style;
  }

  showPillView(): boolean {
    return this.isViewStyle(UploadViewStyle.Pill);
  }

  showTableView(): boolean {
    return this.isViewStyle(UploadViewStyle.Table);
  }

  hideDocumentList(): boolean {
    return this.isViewStyle(UploadViewStyle.None);
  }

  // Need a full reference in the class to this array so that
  // the `getUploadFiles()` returns the same reference and doesn't
  // cause Angular to reload this record constantly.
  noUploads = [
    new UploadedFile({
      error: UploadError.NoUploads,
    }),
  ];
  getUploadFiles(): UploadedFile[] {
    return this.uploadedFiles?.filter(uf => !uf.error) ?? [];
  }

  /**
   * Gets a list `UploadFile` records that have errors (except for the NoUploads error)
   * @returns A UploadFile[] for any errors except a NoUploads error
   */
  getUploadFileErrors(): UploadedFile[] {
    return this.uploadedFiles?.filter(uf => !!uf.error && uf.error !== UploadError.NoUploads) ?? [];
  }

  /**
   * Indicates there are no successful uploads
   */
  hasNoUploads(): boolean {
    return this.getUploadFiles()?.length === 0 ?? true;
  }

  onFileOver(isFileOver: boolean) {
    this.isFileOver = isFileOver;
  }

  private static errorMessages: Map<UploadError, string> = new Map([
    [UploadError.NoUploads, 'Please upload a file'],
    [UploadError.SystemError, 'Upload failed'],
    [UploadError.FileTypeNotSupportedError, 'File type not supported'],
    [UploadError.FileSizeExceedsLimitError, 'File size exceeds 30MB limit'],
  ]);
  getErrorMessage(uploadError: UploadError = UploadError.FileSizeExceedsLimitError): string {
    if (uploadError === UploadError.NoUploads && !!this.noDocumentsMessage) {
      return this.noDocumentsMessage;
    }
    const map = UploadWidgetComponent.errorMessages;
    return map.has(uploadError) ? map.get(uploadError) : 'Unknown error';
  }

  private getUploads(docSearch: S3DocumentSearch, fileUploader: FileUploader): Observable<S3DocumentReference[]> {
    let waitASplitSec = interval(200);
    let onErrorStreamNullWithNotify = errorResponse => {
      let file = Object.keys(errorResponse.error)[0];
      let message = errorResponse.error[file][0];
      setTimeout(() => this.notificationService.notifyFail(`Issues with ${file}: ${message}`), 3000);
      return of([] as S3DocumentReference[]);
    };

    const fileUploaded$ = new EventEmitter<FileInfo>();
    fileUploader.onAfterAddingFile = fileItem => {
      fileUploaded$.emit(fileItem.file);
    };

    return fileUploaded$.pipe(
      filter(x => !!x),
      // the fileInfos all come at the same time
      // we just need to wait a tick to get all of them
      buffer(waitASplitSec),
      // ignore empty array, otherwise constant upload notifications
      filter(x => !!x.length),
      // upload all the docs and then wait for them all to finish
      mergeMap(fileInfos => {
        let names = fileInfos.map(d => d.name);
        this.notificationService.notifyWait('Uploading ' + names.join(', '));
        let uploads = fileInfos.map(fi => {
          let ref = docSearch.AsDocumentReferenceForUpload(fi.name);
          return (
            this.s3Service
              // on errors, handle so the good files continue
              .upload(ref, fi, onErrorStreamNullWithNotify)
              // we just want the names for notification
              // ensure we have the file: errored will return []
              .pipe(map(docs => !!docs.length && docs[0].fileNameWithoutTimestamp))
          );
        });
        return forkJoin(uploads);
      }),
      // remove the error's false entries
      map(names => names.filter(n => !!n)),
      tap(names => {
        // if there were only errors, we have no success to notify
        if (!names.length) {
          return;
        }
        this.notificationService.notifySuccess('Uploaded ' + names.join(', '));
      }),
      switchMap(_ => this.s3Service.getRefs(docSearch))
    );
  }

  private static determineFileExtensions(uploader: FileUploader): string {
    const fileTypeMap = {
      image: ['jpg', 'png', 'gif'],
      doc: ['doc', 'docx'],
      xls: ['xls', 'xlsx', 'csv'],
      ppt: ['ppt'],
      pdf: ['pdf'],
    };
    const fileTypesArr = (uploader?.options?.allowedFileType || ['doc', 'image', 'pdf', 'ppt', 'xls'])
      .map(fileType => fileTypeMap[fileType])
      .filter(x => !!x);
    return []
      .concat(...fileTypesArr)
      .sort()
      .map(x => '.' + x)
      .join(', ');
  }

  showDocDeleteButton(doc: S3DocumentReference): boolean {
    return !this.readonly && this.canDocBeDeletedCallback(doc);
  }
}
