import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../models/applications/application-settings';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { ApplicationVariant } from '../models/applications/application-base';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSettingsService {
  constructor(private api: ApiService) {}

  get = (applicationId: string): Promise<ApplicationSettings> => this.api.getAsync(`tenant/application/${applicationId}`);

  getByVariant = (applicationVariant: ApplicationVariant): Promise<ApplicationSettings> =>
    this.api.getAsync(`tenant/application/${applicationVariant}`);
}

@Injectable()
export class ApplicationSettingsResolve implements Resolve<ApplicationSettings> {
  constructor(private architectLicenseService: ApplicationSettingsService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const applicationId = route.params.applicationId;

    return this.architectLicenseService.get(applicationId);
  }
}
