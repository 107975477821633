import { Injectable } from '@angular/core';
import { PaymentService } from '../../../services/api/payment.service';
import { Order } from '../../../models/order';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
export class OrderReviewResolve implements Resolve<Order> {
  constructor(private paymentService: PaymentService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Order> {
    return this.paymentService.getOrderByApplicationId(route.params.applicationId);
  }
}
