import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogFactoryService } from '../dialog/dialog-factory-service';

@Component({
  selector: 'lib-spinner-dialog',
  templateUrl: './spinner.dialog.html',
  exportAs: 'spinner',
})
export class SpinnerDialog {
  @ViewChild('spinner')
  spinner: TemplateRef<any>;

  constructor(private dialogFactoryService: DialogFactoryService) {}

  open(offerCancel: boolean = false): MatDialogRef<any, any> {
    return this.dialogFactoryService.open(
      this.spinner,
      {
        header: 'Please wait...',
        yesText: 'Cancel',
        hideActions: !offerCancel,
        isOkayWarnColor: true,
      },
      {
        disableClose: false,
        width: 500,
      }
    );
  }
}
