import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AddressService } from '../services/address.service';
import { Address } from '../models/address';

@Injectable()
export class HomeAddressesResolve implements Resolve<Address> {
  constructor(private addressService: AddressService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Address> {
    return await this.addressService.getAddress(route.params.personId, 'home');
  }
}

@Injectable()
export class WorkAddressesResolve implements Resolve<Address> {
  constructor(private addressService: AddressService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Address> {
    return await this.addressService.getAddress(route.params.personId, 'work');
  }
}
