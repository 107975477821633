<article>
  <header>
    <h1>{{ settings.boardName }}</h1>
    <h2>{{ title }}</h2>
  </header>
  <main>
    <div class="flex-justify">
      <h1>{{ firm.nameFull }}</h1>
      <span class="labeled-info">
        <label>Application Date:</label>
        {{ application.submittedOn | date: 'MM/dd/yyyy' }}
      </span>
    </div>
    <div *ngIf="application.licenseNumber" class="licenseNumber">
      <strong>License # {{ application.licenseNumber }}</strong>
    </div>

    <h3 review-section-header>Firm Information</h3>
    <lib-firm-review-info [firm]="firm" class="not-interactive"></lib-firm-review-info>

    <fieldset>
      <legend>Point of Contact</legend>
      <lib-firm-point-of-contact-display [pointOfContact$]="pointOfContact$" [showUnderlines]="true" class="not-interactive">
      </lib-firm-point-of-contact-display>
    </fieldset>

    <h3 review-section-header>Business Information</h3>
    <lib-owners-form
      *ngIf="application.licenseTypeShortName == 'AC'"
      [application]="application"
      class="not-interactive"
      [form]="applicationForm"
      [readonly]="true"
    ></lib-owners-form>
    <lib-supervisors-form
      *ngIf="application.licenseTypeShortName != 'AC'"
      [application]="application"
      class="not-interactive"
      [form]="applicationForm"
      [states]="states"
      [readonly]="true"
    ></lib-supervisors-form>

    <h3 review-section-header>Disclosure Questions</h3>
    <lib-moral-character-review [questionnaireResponse]="questionnaireResponse"></lib-moral-character-review>

    <h3 review-section-header *ngIf="hasDirectors">Officers, Directors, Partners, Members</h3>
    <lib-documents-form
      *ngIf="hasDirectors"
      class="pb-small"
      [applicationId]="application.id"
      documentType="Directors"
    ></lib-documents-form>

    <h3 review-section-header *ngIf="hasStateDocuments">Secretary of State Documents</h3>
    <lib-documents-form
      *ngIf="hasStateDocuments"
      class="pb-small"
      [applicationId]="application.id"
      documentType="SecretaryOfState"
    ></lib-documents-form>

    <h3 review-section-header>Confirmation</h3>
    <lib-firm-application-confirmation [formGroup]="confirmationForm" [application]="application">
    </lib-firm-application-confirmation>

    <h3 review-section-header>Payment</h3>
    <lib-review-payment [order]="order"></lib-review-payment>
  </main>
  <footer lib-review-footer></footer>
</article>
