import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-email-not-found-dialogue',
  templateUrl: './email-not-found.dialogue.html',
  styleUrls: ['./email-not-found.dialogue.scss'],
})
export class EmailNotFoundDialogue implements OnInit {
  email: string;
  loginSupportContact: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.email = this.data.email;
    this.loginSupportContact = this.settings.loginSupportContact ?? this.settings.supportPhoneNumber;
  }
}
