<header class="px-medium d-flex align-items-center gap-small">
  <ng-content select="h2"></ng-content>
  <ng-content select="h3"></ng-content>
  <div class="flex-grow-1">&nbsp;</div>
  <ng-content select="aside"></ng-content>
  <button *ngIf="menu" mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_horiz</mat-icon>
  </button>
</header>
<main class="p-medium"><ng-content></ng-content></main>
