import { Component, Input, HostBinding, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@Component({
  selector: 'lib-edit-phone-redesign',
  templateUrl: './edit-phone-redesign.component.html',
  styleUrls: ['./edit-phone-redesign.component.scss'],
})
export class EditPhoneRedesignComponent implements AfterViewInit {
  @HostBinding('class') get getClass() {
    return `xs-collapse opt-in`;
  }

  //this.formGroup initialized from Phone.GetFormGroup(FormBuilder)
  //controls.number is from API. This component  will copy controls.number into controls.numberRaw
  //controls.numberRaw is managed by NgxMatIntlTelInputComponent.
  //subscription is setup on controls.numberRaw to update values back into controls.number
  @Input() formGroup: FormGroup;
  @Input() label: string;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @ViewChild(NgxMatIntlTelInputComponent) intlTelComponent;

  ngAfterViewInit(): void {
    const strippedValue = EditPhoneRedesignComponent.stripNumber(this.formGroup.controls['number'].value);
    this.formGroup.controls['numberRaw'].setValue(strippedValue); //numberRaw is probably black till this moment

    this.formGroup.controls['numberRaw'].valueChanges.subscribe(_ => {
      //watch updates from controls.numberRaw and save updates back into controls.number - which is what we pass to DB
      this.setNumberToDbFormattedNumber();
    });
  }

  setNumberToDbFormattedNumber() {
    if (!this.intlTelComponent) return;

    const numberValue =
      this.intlTelComponent.selectedCountry.dialCodeWithoutAreaCode === '1'
        ? this.intlTelComponent.formattedPhoneNumber
        : `+${this.intlTelComponent.selectedCountry.dialCode} ${this.intlTelComponent.formattedPhoneNumber}`;

    if (this.formGroup.controls['number'].value !== numberValue) {
      this.formGroup.controls['number'].setValue(numberValue);
    }
  }

  static stripNumber(formattedNumber: string): string {
    if (!formattedNumber) {
      return formattedNumber;
    }
    const stripped = formattedNumber.replace(/([^\+0-9]+)/g, ''); //replace all characters except numbers and plus sign
    //International numbers are stored in DB with a '+' at beginning ie. '+44 1234'
    //US phone numbers are stored without the +
    //this converts what we have stored in DB to a value that works with NgxMatIntlTelInputComponent component
    //and avoids prevents this bug from happening...
    //https://github.com/tanansatpal/ngx-mat-intl-tel-input/issues/108
    const isUsPhoneNumber = stripped.length && stripped[0] !== '+';
    return isUsPhoneNumber ? `+1${stripped}` : stripped;
  }
}
