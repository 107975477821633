import { LookupItem } from '../search/lookup-item';
import { Firm } from '../firm';
import { FirmApplicationBase } from './firm-application-base';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { FirmApplicationPrincipal } from '../firm-application-principal';
import { Application } from './application';

export class FirmApplication extends FirmApplicationBase<FirmApplication> implements LookupItem, Application {
  static GetFormGroup(
    formBuilder: FormBuilder,
    askForStateInFirmSupervisor: boolean,
    showOldBoardOfDirectors: boolean
  ): FormGroup {
    const group = formBuilder.group({
      dateOfIncorporation: '',
      corporationSharesType: '',
      holdingCompanyTitle: '',
      sharesIssued: '',
      sharesAllowed: '',
      supervisors: formBuilder.array([]),
      owner: FirmApplicationPrincipal.GetFormGroup(formBuilder, 'Owner'),
      shareholders: formBuilder.array([]),
    });

    if (askForStateInFirmSupervisor) {
      group.addControl('stateOfIncorporationId', new FormControl(''));
    }

    if (showOldBoardOfDirectors) {
      group.addControl('directorsInState', formBuilder.array([]));
      group.addControl('unlicensedDirectorsCount', new FormControl(''));
    }
    return group;
  }

  update(init?: Partial<FirmApplication>) {
    super.update(init);
    if (this.firm) {
      this.firm = new Firm(this.firm);
    }
  }

  //get shortName() {
  //    return this.type == 'License' ? 'Initial' : this.typeName;
  //}

  get lookupId() {
    return this.firm.id;
  }

  get lookupStatus() {
    return this.status;
  }

  get lookupName() {
    return this.firm.nameFull;
  }

  get lookupInfo() {
    return [
      this.firm.email,
      this.firm.publicAddress ? this.firm.publicAddress.oneLine : null,
      this.firm.primaryPhone && this.firm.primaryPhone.number ? this.firm.primaryPhone.numberFull : null,
    ]
      .filter(_ => !!_)
      .join(' | ');
  }

  acceptPatch(formGroup: FormGroup, showOldBoardOfDirectors: boolean) {
    this.update(formGroup.value);
    this.principals = [];
    let controls = [];
    if (this.corporationSharesType == 'SoleOwnership') {
      controls.push(formGroup.controls.owner.value);
    } else {
      controls = [...formGroup.controls.supervisors.value, ...formGroup.controls.shareholders.value];
      if (showOldBoardOfDirectors) {
        formGroup.controls.directorsInState.value.map(dis => controls.push(dis));
      }
    }
    controls.map(c => {
      this.principals.push(new FirmApplicationPrincipal(c));
    });
  }

  patch(formGroup: FormGroup, formBuilder: FormBuilder) {
    formGroup.patchValue(this);
    let props = ['supervisors', 'shareholders', 'directorsInState', 'directorsOutOfState'];
    props.forEach(prop => {
      this.patchRole(formGroup, formBuilder, prop);
    });

    let owner = this.principalsInRole('Owner')[0];
    if (owner) {
      formGroup.controls.owner.patchValue(owner);
    }
  }

  patchRole(formGroup: FormGroup, formBuilder: FormBuilder, prop: string) {
    let controls = formGroup.controls[prop] as FormArray;
    let role = {
      supervisors: 'Supervisor',
      shareholders: 'Shareholder',
      directorsInState: 'Director',
      directorsOutOfState: 'Director',
    }[prop];
    this.principalsInRole(role, prop).map((p: FirmApplicationPrincipal) => {
      var newFormGroup = FirmApplicationPrincipal.GetFormGroup(formBuilder, role);
      newFormGroup.patchValue(p);
      controls.push(newFormGroup);
    });
  }

  principalsInRole(role, prop = null): FirmApplicationPrincipal[] {
    if (prop == 'directorsInState') var principal = this.principals.filter(p => p.role == role && p.stateId == null);
    else if (prop == 'directorsOutOfState') var principal = this.principals.filter(p => p.role == role && p.stateId != null);
    else var principal = this.principals.filter(p => p.role == role);
    return principal;
  }
}
