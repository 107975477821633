import { datadogRum } from '@datadog/browser-rum';
import { dataDogSettings } from '../constants/environment';

export function initDataDogLogging() {
  const { service, env } = dataDogSettings();
  if (!service || !env) {
    console.log(`datadog not enabled. Need to set service and environment; Service:'${service}', env:'${env}'`);
    return;
  }
  datadogRum.init({
    applicationId: '22961262-5f5e-47a5-b2c3-d6533ae4950c',
    clientToken: 'pub68b99a4be6ef27fed88d8207388d9704',
    site: 'datadoghq.com',
    service: service,
    env: env,
    sessionSampleRate: 100,
    allowedTracingUrls: [window.location.origin, /https:\/\/.*\.ncarb\.(dev|org)/],
    beforeSend: event => {
      event.view.url = event.view.url.replace(
        /(password|socialSecurityNumber|visaNumber|uscisNumber|militaryNumber)=[^&]*/,
        '$1=REDACTED'
      );
    },
  });

  datadogRum.startSessionReplayRecording();
}
