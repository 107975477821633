import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
  name: 'numberToText',
})
export class NumberToTextPipe implements PipeTransform {
  transform(input: number) {
    if (input === 1) {
      return 'one';
    }
    if (input === 2) {
      return 'two';
    }
    if (input === 2) {
      return 'three';
    }
    return input;
  }
}
