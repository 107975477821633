<div [formGroup]="formGroup" class="grid two-columns xs-collapse">
  <mat-form-field appearance="outline" floatLabel="auto">
    <mat-label>{{ firstLine }}</mat-label>
    <input matInput formControlName="streetLine1" [required]="true" [placeholder]="firstLine" />
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="auto">
    <mat-label>{{ secondLine }}</mat-label>
    <input matInput formControlName="streetLine2" [placeholder]="secondLine" />
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="auto">
    <mat-label>City</mat-label>
    <input matInput formControlName="city" [required]="true" placeholder="City" />
  </mat-form-field>
  <div class="grid xs-collapse full-width" [ngClass]="{ 'two-columns': showState && !hideCounty }">
    <mat-form-field [hidden]="!showState" appearance="outline" floatLabel="auto">
      <mat-label> State </mat-label>
      <mat-select formControlName="stateId" [required]="showState">
        <mat-option></mat-option>
        <mat-option *ngFor="let state of addressLookups.states" [value]="state.id">
          {{ state.code }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [hidden]="!showProvince" appearance="outline" floatLabel="auto">
      <mat-label>Province</mat-label>
      <mat-select formControlName="provinceId" [required]="showProvince">
        <mat-option></mat-option>
        <mat-option *ngFor="let province of addressLookups.provinces" [value]="province.id">
          {{ province.code }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="auto" [hidden]="!showRegion">
      <mat-label>Region</mat-label>
      <input matInput formControlName="region" placeholder="Region" [required]="showRegion" />
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="auto" [hidden]="!showState || hideCounty">
      <mat-label>{{ formGroup.controls.stateId.value === 22 ? 'Parish' : 'County' }}</mat-label>
      <input
        matInput
        [required]="showState && !hideCounty"
        formControlName="county"
        [placeholder]="formGroup.controls.stateId.value === 22 ? 'Parish' : 'County'"
      />
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" floatLabel="auto">
    <mat-label>{{ settings.firmProfileSettings.zipCodeLabel }}</mat-label>
    <input
      [placeholder]="settings.firmProfileSettings.zipCodeLabel"
      matInput
      formControlName="zip"
      size="5"
      [required]="true"
      [pattern]="zipcodePattern"
    />
    <mat-error *ngIf="formGroup.controls.zip.hasError('pattern')">Please enter a valid ZIP code</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="auto">
    <mat-label>Country</mat-label>
    <mat-select formControlName="countryId" (selectionChange)="countryChange($event)" [required]="true">
      <mat-option *ngFor="let country of addressLookups.countries" [value]="country.id">{{ country.name }} </mat-option>
    </mat-select>
  </mat-form-field>
  <address-status [formGroup]="formGroup" *ngIf="!hideValidation"></address-status>
</div>
