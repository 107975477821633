import { FirmApplicationBase } from './firm-application-base';
import { Order } from '../order';
import { Firm } from '../firm';
import { Application } from './application';
export class FirmApplicationFull extends FirmApplicationBase<FirmApplicationFull> implements Application {
  firm: Firm;
  order: Order;

  update(init: Partial<FirmApplicationFull>) {
    super.update(init);
    if (this.firm) {
      this.firm = new Firm(this.firm);
    }
    if (this.order) {
      this.order = new Order(this.order);
    }
  }
}
