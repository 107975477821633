import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-caption',
  template: '<ng-content></ng-content>',
})
export class CaptionComponent {
  @HostBinding('class.d-inline-block') inlineBlock = true;
  @HostBinding('class.type-caption') typeClass = true;
}
