import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MilitaryInformation } from '../../models/military-information';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MilitaryInformationService {
  constructor(private api: ApiService) {}

  get = (personId: string, applicationId: string): Promise<MilitaryInformation> =>
    firstValueFrom(this.api.get(`person/${personId}/applications/${applicationId}/militaryInformation`)).then(
      data => new MilitaryInformation(data)
    );

  update = (personId: string, applicationId: string, militaryInformation: MilitaryInformation): Promise<MilitaryInformation> =>
    firstValueFrom(
      this.api.put(`person/${personId}/applications/${applicationId}/militaryInformation`, militaryInformation)
    ).then(data => new MilitaryInformation(data));
}
