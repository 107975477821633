import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';

@Injectable()
export class FirmApplicationsResolve implements Resolve<FirmApplication[]> {
  constructor(private firmApplicationService: FirmApplicationService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let firmId = route.params.firmId || route.parent.data.firm.id;
    return this.firmApplicationService.all(firmId);
  }
}
