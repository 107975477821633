import { Person } from './person';
import { LookupItem } from './search/lookup-item';
import { UpdatableModel } from './updatable-model';
import { IArchitectLicense } from './i-architect-license';
import { ILicense } from './i-license';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Profession } from '../constants/jurisdiction/profession';

export class ArchitectLicense extends UpdatableModel<ArchitectLicense> implements ILicense, IArchitectLicense, LookupItem {
  id: string;
  number: string;
  status: string;
  origin: string;
  profession: Profession;
  professionDescription: string;
  residency: string;
  issueDate: Date;
  lastRenewalDate?: Date;
  expirationDate: Date;
  person: Person;
  jurisdiction: string;
  verifiedBy: string;

  update(init?: Partial<ArchitectLicense>) {
    super.update(init);
    this.person = this.person ? new Person(this.person) : null;
  }

  get lookupId() {
    return this.person.id;
  }

  get lookupStatus() {
    return this.status;
  }

  get lookupName() {
    return this.person.name.full;
  }

  get lookupInfo() {
    return [this.number, this.person.email, this.person.primaryPhone ? this.person.primaryPhone.numberFull : null]
      .filter(_ => !!_)
      .join(' | ');
  }

  get canHaveApplication(): boolean {
    return (
      this.status == 'Due' ||
      this.status == 'Delinquent' ||
      this.status == 'Expired' ||
      this.status == 'Inactive' ||
      this.status == 'Emeritus' ||
      this.status == 'ExpiredEmeritus'
    );
  }

  get residencyText() {
    return this.residency == 'InState' ? 'In-State' : 'Out-of-State';
  }

  get isActive() {
    return this.status == 'Active' || this.status == 'Due';
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      number: '',
      status: '',
      residency: '',
      issueDate: '',
      lastRenewalDate: '',
      expirationDate: '',
    });
  }

  get professionAbbreviation() {
    return this.professionDescription
      .split(' ')
      .map(t => t[0])
      .join('');
  }
}
