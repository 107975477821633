<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="licenseNumber">
    <th mat-header-cell *matHeaderCellDef>License Status</th>
    <td mat-cell *matCellDef="let element" class="text-nowrap">
      <lib-status-pill [applicationOrLicenseStatus]="element.license.status"></lib-status-pill>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      <a routerLink="/person/{{ element.person.id }}" *ngIf="linkedName; else unlinkedName">{{
        element.person.name.complete
      }}</a>
      <ng-template #unlinkedName>{{ element.person.name.complete }}</ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role(s)</th>
    <td mat-cell *matCellDef="let element">
      <div>
        {{ activeRolesByPersonId[element.person.id] | roleNameList: 'nonPoc' }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">
      <a href="mailto:{{ element.person.email }}">{{ element.person.email }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">No known roles</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>
