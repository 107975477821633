import {
  Component,
  ViewChild,
  TemplateRef,
  ContentChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, ReplaySubject, merge, of } from 'rxjs';
import { NgControl, FormControl, NgModel } from '@angular/forms';
import { filter, map, tap, switchMap } from 'rxjs/operators';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-autocomplete',
  exportAs: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  // the main lookup arrow function
  // be sure to have this be an arrow function to avoid *this* issues
  @Input() search: (text: string) => Observable<any[]>;

  // result object -> text arrow function
  @Input() displayWith: ((value: any) => string) | null;

  // (optional) result object -> disabled?
  @Input() optionDisabled: ((value: any) => boolean) | null;

  // the number of items to display
  // if the result set is larger, a "continue typing to filter bar is shown"
  @Input() maxOptions: number;

  // will offer an Add New option which triggers addNewSelected() when selected
  @Input() offerAddNew: boolean;

  // do not use rich display, "old-school" autocomplete ui
  @Input() textDisplay: boolean;

  // selects the sole remaining option automatically
  // user case: user thinks that
  // it's selected when it's just the last remaining
  // and then hits next :(
  @Input() takeOnlyOptionOnBlur: boolean;

  // allows searches on empty text inputs
  @Input() allowEmptySearch: boolean;

  // turns off default clear on blur
  @Input() keepUnselectedTextOnBlur: boolean;

  // a rich option was selected
  // typical use: let obj = $event.option.value
  @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  // the add new was selected
  // typical use: let addNewTypedText = $event.option.value
  @Output() addNewSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  // a selected value was cleared
  // good for clearing out values set in optionSelected()
  @Output() cleared = new EventEmitter<MatAutocompleteSelectedEvent>();

  // results that were retrieved using search()
  @Output() resultsFetched = new EventEmitter<any[]>();

  // text that was typed that triggered the search
  // includes empty text
  @Output() typed = new EventEmitter<string>();

  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  @ContentChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ContentChild(NgControl) controlDirective: NgControl;
  @ContentChild(NgModel) ngModel: NgModel;
  hostElement: HTMLElement;
  input: HTMLInputElement;

  private formControl: FormControl;

  selected: any;
  addNew: any;
  typing: string;
  areThereMore: boolean;
  addNewValue = '~addnew~';
  emptyClear = new ReplaySubject<string>(1);
  resultsCount: number;

  constructor(
    private element: ElementRef,
    private tenantSettings: TenantSettings
  ) {}

  ngOnInit(): void {
    let maxOptions = this.maxOptions || this.tenantSettings.lookupItemsMax;

    this.typed
      .pipe(
        switchMap(text => {
          if (!this.allowEmptySearch && !text) {
            return of([]);
          }
          return this.search(text);
        }),
        map(list => {
          this.areThereMore = list.length > maxOptions;
          if (this.areThereMore) {
            list.length = maxOptions;
          }
          return list;
        }),
        tap(list => (this.resultsCount = list.length))
      )
      .subscribe(list => this.resultsFetched.next(list));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formControl = this.controlDirective.control as FormControl;
      this.hostElement = this.element.nativeElement as HTMLElement;
      this.input = this.hostElement.querySelector('input');
      this.setInitialSelected();
      this.listenForTyping();
      this.setupClears();
    });
  }

  setInitialSelected() {
    let value = this.formControl.value;
    if (value) {
      this.selected = value;
    }
  }

  listenForTyping() {
    merge(this.emptyClear, this.formControl.valueChanges)
      .pipe(
        tap(value => {
          let selected = this.autocomplete.options && this.autocomplete.options.find(o => o.selected);
          if (selected && selected.value == this.addNewValue) {
            // we are getting a change after the Add New was selected
            // so, turn add new off, and display this new value
            this.addNew = null;
            this.selected = value;
          }
        }),
        filter(value => {
          let typingIsValid =
            !this.autocomplete.options || // load case
            !this.autocomplete.options.some(o => o.selected) || // ignore selected action
            !value; // allow empty
          return typingIsValid;
        })
      )
      .subscribe(value => {
        this.typing = this.formControlValueToText(value);
        this.typed.next(this.typing);
      });
  }

  setupClears() {
    this.hostElement.addEventListener('click', () => {
      this.clearAndOpen();
    });
    if (this.takeOnlyOptionOnBlur) {
      this.hostElement.addEventListener('blur', () => this.onBlurSelectIfOneOptionLeft(), true);
    }
    if (!this.keepUnselectedTextOnBlur) {
      this.setupClearOnClose();
    }
    let button = this.hostElement.parentNode.parentNode.querySelector('.mat-form-field-suffix button') as HTMLElement;
    if (button) {
      button.addEventListener('click', () => this.clearAndOpen());
    }
  }

  setupClearOnClose() {
    this.autocomplete.closed.subscribe(_ => {
      if (!this.selected) {
        this.clear();
      }
    });
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    if (this.offerAddNew && $event.option.value == this.addNewValue) {
      $event.option = Object.assign({}, $event.option);
      $event.option.value = this.typing;
      this.addNew = this.typing || ' ';
      this.addNewSelected.emit($event);
      return;
    }
    this.selected = $event.option.value;
    this.optionSelected.emit($event);
  }

  checkOptionDisabled(option) {
    return this.optionDisabled && this.optionDisabled(option);
  }

  formControlValueToText(value): string {
    return (this.autocomplete.displayWith && this.autocomplete.displayWith(value)) || value;
  }

  public clearAndOpen() {
    this.clear();
    this.autocompleteTrigger.openPanel();
  }

  public clear() {
    if (!this.formControl.value && this.formControl.pristine) {
      this.formControl.markAsDirty();
      this.emptyClear.next('');
      return;
    }
    if (!this.formControl.value && !this.selected) {
      return;
    }
    let opt = this.autocomplete.options.find(o => o.selected);
    if (opt) {
      opt.deselect();
    }
    if (this.formControl.value) {
      if (this.ngModel) {
        this.input.value = '';
      }
      this.formControl.setValue('');
      this.formControl.markAsUntouched();
    }
    this.typing = '';
    this.selected = null;
    this.addNew = null;
    let $event = new MatAutocompleteSelectedEvent(this.autocomplete, null);
    this.cleared.next($event);
  }

  onBlurSelectIfOneOptionLeft() {
    if (this.autocomplete.options.some(o => o.selected)) {
      return;
    }
    if (this.autocomplete.isOpen && this.resultsCount == 1 && this.autocomplete.options.first) {
      this.autocomplete.options.first.select();
    }
  }
}
