<ls-title mat-dialog-title>Manually Verify This Account's Email?</ls-title>
<ls-p level="2" mat-dialog-content>
  Verification emails are sent to ensure that users have access to the email address they inputted. By manually verifying, you
  are attesting that the user has access to the specified email account.
</ls-p>

<p mat-dialog-actions class="reverse" *ngIf="!verifying">
  <button mat-flat-button color="primary" type="submit" (click)="handleVerifyEmailClick()" color="primary">
    Yes, Verify Email
  </button>
  <button mat-stroked-button type="button" [mat-dialog-close]="null" color="accent">No, Don't Verify</button>
</p>
<mat-progress-spinner *ngIf="verifying" color="primary" mode="indeterminate" value="50"> </mat-progress-spinner>
