import { Search } from './search';
import { IFirmSearch } from './i-firm-search';
import { DateRangeSelection } from './date-range-selection';

export class FirmSearch extends Search<FirmSearch> implements IFirmSearch {
  search: string;
  hasLicense?: string;
  licenseType?: string;
  licenseStatus?: string;
  issueDateRange?: DateRangeSelection;
  expirationDateRange?: DateRangeSelection;
}
