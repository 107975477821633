import { Component } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <reload-modal></reload-modal>
  `,
})
export class AppComponent {
  constructor(angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag) {
    angulartics2GoogleGlobalSiteTag.startTracking();
  }
}
