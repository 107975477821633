import { UpdatableModel } from './updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArchitectLicense } from './architect-license';

export class FirmApplicationPrincipal extends UpdatableModel<FirmApplicationPrincipal> {
  id: string;
  role: string;
  name: string;
  licenseNumber: string;
  shares: number;
  personId: string;
  stateId: number;

  static GetFormGroup(formBuilder: FormBuilder, role: string): FormGroup {
    return formBuilder.group({
      id: '',
      role: role,
      name: '',
      licenseNumber: '',
      shares: '',
      personId: '',
      stateId: '',
    });
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    return formGroup;
  }

  static FromArchitectLicense(license: ArchitectLicense, role: string): FirmApplicationPrincipal {
    return new FirmApplicationPrincipal({
      name: license.person.name.firstLast,
      licenseNumber: license.number,
      personId: license.person.id,
      role: role,
    });
  }
}
