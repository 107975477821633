import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class ArchitectApplicationsResolve implements Resolve<ArchitectApplication[]> {
  constructor(
    private architectApplicationService: ArchitectApplicationService,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId || this.authService.claims.personId;
    return this.architectApplicationService.all(personId);
  }
}
