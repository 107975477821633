import { Component, Input } from '@angular/core';
import { ILicense } from 'projects/common/src/lib/models/i-license';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-license-status-pill',
  templateUrl: './license-status-pill.component.html',
  styleUrls: ['./license-status-pill.component.scss'],
})
export class LicenseStatusPillComponent {
  @Input() license: ILicense;

  constructor(private settings: TenantSettings) {}

  getStatusStyle(status, prefix): string {
    const customStyle = this.settings.licenseStatuses[status]?.customStyleName;
    return customStyle ? `${prefix}${customStyle}` : `${prefix}${status.toLowerCase()}`;
  }
}
