<fieldset>
  <legend>Fees</legend>
  <lib-fee-items [order]="order"></lib-fee-items>
</fieldset>
<div class="flex-justify">
  <span class="labeled-info mat-form-field">
    <label>Payment Type:</label>
    {{ order?.paymentMethodName }}
  </span>
  <span class="labeled-info mat-form-field">
    <label>Order Confirmation:</label>
    {{ order?.number }}
  </span>
  <span class="labeled-info mat-form-field">
    <label>Payment Date:</label>
    {{ order?.completedOn | date: 'MM/dd/yyyy' }}
  </span>
</div>
