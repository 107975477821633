import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { AuthTokenService, Tokens } from './auth-token-service';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification.service';
import { Claims } from '../../models/auth/claims';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthRefreshService } from './auth-refresh.service';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '@datadog/browser-core';
import { Auth0Settings } from '../api/Auth0Settings';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  claims: Claims;
  isAuthenticated$ = new BehaviorSubject<boolean>(false);

  protected auth0: auth0.WebAuth;
  constructor(
    private router: Router,
    private tokenService: AuthTokenService,
    private authRefreshService: AuthRefreshService,
    private notificationService: NotificationService,
    private auth0Settings: Auth0Settings,
    private jwtHelper: JwtHelperService
  ) {
    this.auth0 = new auth0.WebAuth({
      clientID: auth0Settings.clientId,
      domain: auth0Settings.domain,
      audience: auth0Settings.audience,
      responseType: 'token id_token',
      redirectUri: auth0Settings.redirectUri,
      scope: 'openid profile email ' + auth0Settings.scopes,
    });

    authRefreshService.init(this);
    if (this.isAuthenticated()) {
      this.setClaims();
    }
  }

  isAuthenticated(): boolean {
    if (!this.tokenService.isSet(Tokens.access)) {
      return false;
    }

    const expiresAt = this.tokenService.get(Tokens.expires);
    return new Date().getTime() < expiresAt;
  }

  login(): void {
    this.auth0.authorize({
      connection: this.auth0Settings.connection,
    });
  }

  loginManager(): void {
    this.auth0.authorize({
      connection: this.auth0Settings.managerConnection,
    });
  }

  softLogout() {
    this.tokenService.unsetAll();
    this.isAuthenticated$.next(false);
  }

  logout(): void {
    this.softLogout();

    this.auth0.logout({
      client_id: this.auth0Settings.clientId,
      returnTo: window.location.origin,
    });

    this.router.navigate(['/landing']);
  }

  handleAuthentication(failUrl = '/timedout'): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);
      } else if (err) {
        this.tokenService.set(Tokens.error, err);
        this.router.navigate([failUrl]);
      }
    });
  }

  sendMagicLink(email, applicationPath?: string): void {
    this.auth0.passwordlessStart(
      {
        connection: 'email',
        send: 'link',
        email: email,
        redirectUri: this.auth0Settings.redirectUri,
      },
      (err, res) => {
        if (err) {
          this.notificationService.notifyFail('Unable to send the magic link. Please try again or contact customer service');
        } else {
          this.notificationService.notifySuccess('Email sent! Please check your inbox for login instructions');
        }
      }
    );
  }

  protected setSession(authResult): void {
    this.tokenService.set(Tokens.access, authResult.accessToken);
    this.tokenService.set(Tokens.id, authResult.idToken);
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    // expiresAt = new Date().getTime() + (1000 * 60 * 2) // for refresh testing
    this.tokenService.set(Tokens.expires, expiresAt);
    this.authRefreshService.setRefreshTimeout();

    const scopes = authResult.scope || this.auth0Settings.scopes || '';
    this.tokenService.set(Tokens.scopes, scopes);
    this.setClaims();
  }

  protected setClaims() {
    let accessToken = this.tokenService.get(Tokens.access);
    let decoded = this.jwtHelper.decodeToken(accessToken);
    this.claims = new Claims(decoded);
    const rumUser = this.buildRumUser();
    if (rumUser) {
      datadogRum.setUser(rumUser);
    }
    this.tokenService.set(Tokens.claims, this.claims);

    this.isAuthenticated$.next(true);
  }

  hasScopes(scopes: Array<string>): boolean {
    const grantedScopes = this.tokenService.get(Tokens.scopes).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  buildRumUser(): User {
    const { staffId, personId } = this.claims;
    if (staffId) {
      if (personId) {
        //staff impersonating
        return {
          id: personId,
          staffId: staffId,
        };
      }
      return {
        id: staffId,
        staffId: staffId,
      };
    }
    if (personId) {
      return {
        id: personId,
      };
    }
    console.error('could not find staffId or personId');
    return null;
  }

  renewToken(ignoreFail = false): void {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.error(`Could not get a new token (${err.error}: ${err.error_description}).`);
        if (!ignoreFail) {
          this.login();
        }
      } else {
        this.setSession(result);
      }
    });
  }

  public static ErrorDescriptionTranslations = {
    'Wrong email or verification code.':
      'Your link has expired or timed-out. You can request another secured access link, or create an account for log in access anytime.',
    'Please verify your email before logging in.':
      'Your account has not been verified. Please check your email for the verification link, and verify your account before you log in.',
    '`state` does not match': 'Be sure to open the link in the same browser and device you requested it from',
    QuotaExceededError: 'There was an error logging in. Please refresh your browser and try again.',
  };
}

export const ClaimsProvider = {
  provide: Claims,
  useFactory: (service: AuthService) => service.claims,
  deps: [AuthService],
};
