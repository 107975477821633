import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { WorkHistoryForArchApp } from 'projects/common/src/lib/models/work-history-for-arch-app';
import { FirmLookup } from 'projects/common/src/lib/models/firm-lookup';
import { Address, AddressLookups } from 'projects/common/src/lib/models/address';
import { ActivatedRoute } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-work-history-review',
  templateUrl: './work-history-review.component.html',
  styleUrls: ['./work-history-review.component.scss'],
})
export class WorkHistoryReviewComponent implements OnInit {
  @Input() workHistory: WorkHistoryForArchApp;
  form: FormGroup;
  addressLookups: AddressLookups;
  constructor(
    private formBuilder: FormBuilder,
    route: ActivatedRoute,
    public settings: TenantSettings
  ) {
    this.addressLookups = route.snapshot.data.addressLookups;
  }

  ngOnInit() {
    this.form = WorkHistoryForArchApp.GetFormGroup(this.formBuilder);
    this.form.controls.workHistory['controls'].firm = this.formBuilder.group({
      name: '',
      licenseNumber: '',
      publicAddress: Address.GetFormGroup(this.formBuilder),
    });
    this.workHistory.firm = this.workHistory.firm || new FirmLookup();
    this.form.patchValue(this.workHistory);
    if (this.workHistory.firm.id) {
      // crash avoidance, unregistered firms null values crash the patch
      this.workHistory.firm.licenseNumber = this.workHistory.firm.licenseNumber || '';
      this.workHistory.firm.publicAddress = this.workHistory.firm.publicAddress || new Address();
      this.form.controls.workHistory.patchValue(this.workHistory);
    }
  }
}
