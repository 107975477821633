<lib-titled-card>
  <h2>Uploaded Documents</h2>
  <h3>
    <mat-icon class="mt-xs smaller-icon" matTooltipClass="tooltip" matTooltipPosition="right" [matTooltip]="helpToolTip"
      >help_outline</mat-icon
    >
  </h3>
  <mat-menu>
    <button mat-button type="button" (click)="editMode = !editMode">
      {{ editMode ? 'View Documents' : 'Edit Documents' }}
    </button>
  </mat-menu>
  <div *ngIf="docSearch">
    <lib-upload-widget
      [readonly]="!editMode"
      [docSearch]="docSearch"
      noDocumentsMessage="No uploaded documents for this profile"
      viewStyle="Table"
    ></lib-upload-widget>
  </div>
</lib-titled-card>
