import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-headline',
  templateUrl: './headline.component.html',
})
export class HeadlineComponent {
  @HostBinding('class.d-block') block = true;
  constructor() {}
}
