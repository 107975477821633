<h3>Firm License Types</h3>
<p>
  <span *ngIf="isInitial">There are three (3) types of firm registrations for the state of {{ settings.stateName }}.</span>
  To learn about the requirements for firm registration, please review the
  <a [href]="constants.commonLinks.registrationInfo" target="_blank">Firm Registration</a> section on our website.
</p>
<lib-card>
  <form [formGroup]="choiceForm" (submit)="submitChoice()" *ngIf="!confirm">
    <fieldset>
      <legend>Select the license type you would like to register as.</legend>
      <p>
        <mat-radio-group class="flex-column" formControlName="choice" required>
          <mat-radio-button *ngFor="let available of availables" [value]="available" color="primary">
            {{ available.licenseTypeName }}
          </mat-radio-button>
        </mat-radio-group>
      </p>
    </fieldset>
    <footer>
      <button mat-button type="button" routerLink="../..">Exit</button>
      <button mat-button type="submit">Next</button>
    </footer>
  </form>
  <form [formGroup]="confirmationForm" (submit)="submitConfirmation()" *ngIf="confirm">
    <initial-warning-la
      *ngIf="isInitial && settings.stateCode === 'LA'"
      licenseType="selected.licenseType"
    ></initial-warning-la>
    <fieldset>
      <div class="text-center">
        <p>You have selected to {{ isSwitch ? 'switch' : 'register' }} to the following firm type:</p>
        <h3>
          {{ selected.licenseTypeName }}
        </h3>
        <p>Is this correct?</p>
      </div>
    </fieldset>
    <footer>
      <button mat-button type="button" (click)="confirm = false">No, go back</button>
      <button mat-button type="submit">Yes, continue</button>
    </footer>
  </form>
</lib-card>
