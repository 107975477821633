import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FirmStepSettings, TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { ApplicationSettings } from 'projects/common/src/lib/models/applications/application-settings';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { Firm } from 'projects/common/src/lib/models/firm';
import { getApplicationSettings } from 'projects/common/src/lib/utility/router-extensions';

@Component({
  selector: 'lib-firm-review-info',
  templateUrl: './firm-review-info.component.html',
  styleUrls: ['./firm-review-info.component.scss'],
})
export class FirmReviewInfoComponent implements OnInit {
  @Input() firm: Firm;
  form: FormGroup;
  corporateTitles = Firm.CorporateTitles;
  firmStepSettings: FirmStepSettings;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.form = Firm.GetFormGroup(this.formBuilder);
    this.firm.patch(this.form);

    const settings = getApplicationSettings(this.route);
    this.firmStepSettings = settings.stepConfiguration.firm;
  }
}
