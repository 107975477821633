import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FirmPointOfContact } from 'projects/common/src/lib/models/firm-point-of-contact';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';

@Component({
  selector: 'app-portal-firm-point-of-contact-info',
  templateUrl: './portal-firm-point-of-contact-info.component.html',
  styleUrls: ['./portal-firm-point-of-contact-info.component.scss'],
})
export class PortalFirmPointOfContactInfoComponent {
  @Input() pointOfContact$: Observable<FirmPointOfContact>;

  constructor(public constants: JurisdictionConstants) {}
}
