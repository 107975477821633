import { Component } from '@angular/core';
import { BaseComponent } from './base.component';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  template: '',
})
export class ReloadableComponent extends BaseComponent {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.onLoad();
    this.unsubOnDestroy = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.onLoad();
      }
    });
  }

  onLoad() {}

  reload() {
    this.router.navigate(['.'], { relativeTo: this.route });
  }
}
