<div class="flex-column content" *ngIf="!isCreateInitialApp">
  <button mat-button color="secondary" type="button" (click)="expirationModal.open()">
    {{ label | spaceCapCase }} License Status Schedule for Expiration Date: {{ testOptions.delinquentDateCurrent }}
    <mat-icon inline="true">open_in_new</mat-icon>
  </button>
  <mat-checkbox [checked]="isEmeritus" (change)="setIsEmeritus($event.checked)" *ngIf="hasEmeritus">Is Emeritus</mat-checkbox>
  <mat-form-field appearance="standard" appearance="outline" floatLabel="auto">
    <mat-label>License Status</mat-label>
    <mat-select [(value)]="licenseStatus" (selectionChange)="onStatusChanged()">
      <mat-option></mat-option>
      <mat-option value="Active"
        >{{ (isEmeritus ? 'Emertitus' : 'Active') | licenseStatus }}
        {{ displayOriginalLabelIfRemapped(isEmeritus ? 'Emertitus' : 'Active') }}- (No application)</mat-option
      >
      <mat-option value="Due"
        >{{ (isEmeritus ? 'EmeritusDue' : 'Due') | licenseStatus }}
        {{ displayOriginalLabelIfRemapped(isEmeritus ? 'EmeritusDue' : 'Due') }}-
        {{ isEmeritus ? 'Emeritus Renewal Application' : 'Renewal Application' }}</mat-option
      >
      <mat-option value="Delinquent" *ngIf="!isEmeritus"
        >{{ 'Delinquent' | licenseStatus }}
        {{ displayOriginalLabelIfRemapped('Delinquent') }}
        - Renewal Application</mat-option
      >
      <mat-option value="Expired"
        >{{ (isEmeritus ? 'ExpiredEmeritus' : 'Expired') | licenseStatus }}
        {{ displayOriginalLabelIfRemapped(isEmeritus ? 'ExpiredEmeritus' : 'Expired') }}
        - {{ isEmeritus ? 'Emeritus Renewal Application' : 'Reinstatement Application' }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" appearance="outline" floatLabel="auto">
    <mat-label>Expiration Date</mat-label>
    <input matInput [matDatepicker]="picker" required [formControl]="expirationDate" [min]="minDate" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint>{{ hintText }}</mat-hint>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="addApplication" class="pb-medium" *ngIf="licenseStatus != 'Active'">Add Application</mat-checkbox>
</div>

<button mat-flat-button color="primary" (click)="setupApplication()">
  {{ isCreateInitialApp ? 'Add Initial Application' : 'Setup License' }}
</button>

<lib-dialog
  #expirationModal="dialog"
  yesText="Okay"
  noText=""
  [header]="'License Status Schedule For ' + testOptions.delinquentDateCurrent"
  [template]="userFormField"
></lib-dialog>

<ng-template #userFormField>
  <table mat-table [dataSource]="statusSummary()">
    <ng-container matColumnDef="licenseStatus">
      <td mat-cell *matCellDef="let stat">
        {{ stat.licenseStatus | licenseStatus }}
        {{ displayOriginalLabelIfRemapped(stat.licenseStatus) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="range">
      <td width="" mat-cell *matCellDef="let range">
        {{ range.start || 'Today <=' }}
        {{ range.start && range.end ? '-' : '' }}
        {{ range.end || '<= Today' }}
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let stat; columns: ['licenseStatus', 'range']"></tr>
  </table>
</ng-template>
