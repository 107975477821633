import { Component, HostBinding, Input } from '@angular/core';

//the component approximates what an angular readonly input looks like
@Component({
  selector: 'lib-disabled-field',
  templateUrl: './disabled-field.component.html',
  styleUrls: ['./disabled-field.component.scss'],
})
export class DisabledFieldComponent {
  //lib-disabled-field
  @Input() value: string;
  @HostBinding('class') get getClass() {
    return 'opt-in d-inline-block';
  }
}
