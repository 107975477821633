import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  Confirmations,
  JurisdictionConfirmationService,
} from '../../../constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { CertificationContent, JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';
import { ArchitectApplication } from '../../../models/applications/architect-application';

@Component({
  selector: 'lib-application-confirmation',
  templateUrl: './application-confirmation.component.html',
})
export class ApplicationConfirmationComponent implements OnInit {
  @Input() formGroup: FormGroup;
  application: ArchitectApplication;
  confirmations: Confirmations;
  content: CertificationContent;
  constructor(
    private route: ActivatedRoute,
    public constants: JurisdictionConstants,
    private confirmationService: JurisdictionConfirmationService
  ) {}
  ngOnInit(): void {
    this.application = this.route.snapshot.data.application;
    this.confirmations = this.confirmationService.getArchitectConfirmations(this.application);
    this.content = this.certificationContentDefaults(this.constants.applicationStepContent.certification);
  }

  certificationContentDefaults = (certification: CertificationContent = {}): CertificationContent => {
    return {
      headerSubtextContent:
        'Using the checkboxes below, please attest to understanding and reviewing the applicable rules and laws.',
      attestationsLabel: `I ${this.confirmations.laws.length > 0 ? 'also ' : ''}certify that:`,
      headerSubtext: 'Certification',
      ...certification,
    };
  };
}
