import { Component, OnInit, Input } from '@angular/core';
import { Address } from '../../models/address';

@Component({
  selector: 'lib-display-address',
  templateUrl: './display-address.component.html',
  styleUrls: ['./display-address.component.css'],
})
export class DisplayAddressComponent implements OnInit {
  @Input() address: Address;

  ngOnInit() {}
}
