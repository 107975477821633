import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FirmLicense } from 'projects/common/src/lib/models/firm-license';
import { FirmLicenseService } from 'projects/common/src/lib/services/api/firm-license.service';
import { ActivatedRoute } from '@angular/router';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-portal-firm-license-info',
  templateUrl: './portal-firm-license-info.component.html',
  styleUrls: ['./portal-firm-license-info.component.scss'],
})
export class PortalFirmLicenseInfoComponent implements OnInit {
  @Input() license: FirmLicense;
  @Input() canSwitch$: Observable<boolean>;

  licenseTypeArticle: string;
  showFirmType: boolean;

  constructor(
    private firmLicenseService: FirmLicenseService,
    private route: ActivatedRoute,
    public constants: JurisdictionConstants,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.showFirmType = this.settings.hasFirmTypes();
    this.licenseTypeArticle = /^[aeiouAEIOU]/.test(this.license.typeName) ? 'an' : 'a';
  }

  async download() {
    await this.firmLicenseService.download(this.route.snapshot.params.firmId);
  }
}
