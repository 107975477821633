<mat-form-field
  [appearance]="readonly ? 'standard' : 'outline'"
  [floatLabel]="readonly ? '' : 'auto'"
  [class.readonly]="readonly"
>
  <mat-label *ngIf="!readonly || control.value"> USCIS Number or Alien Registration Number </mat-label>
  <input
    matInput
    [formControl]="control"
    [required]="!notRequired && !readonly"
    [pattern]="pattern"
    [textMask]="mask"
    autocomplete="off"
    placeholder="USCIS Number or Alien Registration Number"
    [type]="type"
    (focus)="type = 'text'"
    (focusout)="type = passwordType"
    [readonly]="!!readonly"
    [class.webkit-password]="type == 'webkit_password'"
  />
  <button type="button" mat-icon-button matSuffix *ngIf="control.value">
    <mat-icon *ngIf="type == passwordType">visibility_off</mat-icon>
    <mat-icon *ngIf="type == 'text'">visibility</mat-icon>
  </button>
  <mat-error *ngIf="control.hasError('pattern')"> Please enter a valid USCIS Number or Alien Registration Number </mat-error>
</mat-form-field>
