import { UpdatableModel } from './updatable-model';

export class PointOfContactRequest extends UpdatableModel<PointOfContactRequest> {
  status: string;
  acceptedDate: Date;
  expirationDate: Date;

  get displayableStatus(): any {
    if (this.isPending) {
      return this.buildDisplayableStatus('Access Pending', this.expirationDate);
    } else if (this.isAccepted) {
      return this.buildDisplayableStatus('Access Approved', this.acceptedDate);
    } else {
      return this.buildDisplayableStatus(null, null); // Should not happen
    }
  }

  get isPending(): boolean {
    return this.status === 'Pending';
  }

  get isAccepted(): boolean {
    return this.status === 'Active';
  }

  private buildDisplayableStatus(status: string, date: Date): any {
    return { status: status, date: date };
  }
}
