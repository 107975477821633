<ng-container *ngIf="readonly">
  <lib-read-field class="phone" [value]="formGroup.controls.number.value">
    {{ label }}
  </lib-read-field>
  <lib-read-field class="phone-extension" [value]="formGroup.controls.extension.value">Ext</lib-read-field>
</ng-container>

<ng-container *ngIf="!readonly">
  <mat-form-field appearance="outline" floatLabel="auto" class="phone">
    <mat-label>{{ label }}</mat-label>
    <ngx-mat-intl-tel-input
      [formControl]="formGroup.controls.numberRaw"
      format="national"
      [preferredCountries]="['us']"
      [required]="required"
      [noAreaCodeWithDialCode]="true"
    >
    </ngx-mat-intl-tel-input>
    <mat-error *ngIf="formGroup.controls.numberRaw.hasError('validatePhoneNumber')"> Please enter a valid number </mat-error>
    <input type="hidden" [formControl]="formGroup.controls.id" />
  </mat-form-field>
  <mat-form-field appearance="outline" floatLabel="auto" class="phone-extension">
    <mat-label>Ext</mat-label>
    <input matInput [formControl]="formGroup.controls.extension" placeholder="Ext" />
  </mat-form-field>
</ng-container>
