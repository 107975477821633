import { JurisdictionConstants } from './jurisdiction-constants';
import { Profession } from './profession';

export const NEConstants: JurisdictionConstants = {
  legal: '',
  acronym: 'NSBLA',
  workAddressSuffix: '(Public)',
  homeAddressSuffix: '(Private)',
  commonLinks: {
    publicSite: 'https://nsbla.nebraska.gov/',
    publicUrl: 'nsbla.nebraska.gov',
    registrationInfo: 'https://nsbla.nebraska.gov/',
    rules: 'https://nsbla.nebraska.gov/professional-landscape-architects-act-0',
    centuryCode: null,
    lapels: '',
    architectLookup: '',
    firmLookup: '',
  },
  manualProfessions: [Profession.LandscapeArchitect],
  architectTabName: 'Licenses',
  ceRule: {
    section: '§1315.D',
    exemption: '§1315.B',
  },
  professionalReferences: {
    text: `If you need to submit additional references to the board, <a target="blank" href="https://nsbla.nebraska.gov/sites/default/files/doc/NSBLA%20Reference%20Form.pdf">download the reference form</a>, send it to another desired reference, and have them send it directly to the board. Applicants that cannot furnish the required number of references for an application, will need to email a detailed letter to the board at nsbla.board@nebraska.gov requesting an exemption from this requirement`,
    dataPoint: 'References Received',
    cardTitle: 'References',
  },
  timezoneAbbreviation: 'CT',
  checkAddress: {
    address1: 'Nebraska State Board of Landscape Architects',
    address2: 'PO Box 95165',
    cityStateZip: 'Lincoln, NE  68509-5165',
  },
  applicationStepLabels: {
    continuingEducation: 'Professional Development Hours',
    confirmation: 'Attestation',
  },
  applicationStepContent: {
    disclosureQuestions: {
      subtitle:
        'Please answer the following questions truthfully. If yes, please provide an explanation and supporting documentation (i.e. court documents, disciplinary action agreement).',
    },
    militaryInfo: {
      question: 'Are you active military or the spouse of an active military member?',
    },
    certification: {
      headerSubtext: 'Attestation',
      headerSubtextContent: 'Using the checkboxes below, please certify the information on your application.',
      attestationsLabel: 'I certify that:',
    },
    payment: {
      headerSubtextContent: `Please select one of the following payment options to submit your application. In the event that
        your check is returned unpaid for insufficient or uncollected funds, we may ask you to resubmit payment using a
        different method. Please be aware that some banks will not allow an electronic debit by a third party, if you are
        unsure if your bank will allow this transaction you may want to contact them before proceeding.`,
      byClickingSubmitAch:
        'By clicking ‘Submit Application’ you are agreeing to pay the total amount stated and submitting your official application for licensure. All fees are non-refundable.',
      byClickingSubmitNotAch:
        'By clicking ‘Submit Application’ you are agreeing to pay the total amount stated and submitting your official application for licensure. All fees are non-refundable.',
    },
    lawfulPresence: {
      headerSubtextContent: `For the purpose of complying with <a href="https://nebraskalegislature.gov/laws/statutes.php?statute=4-108">Neb. Rev. Stat. §§ 4-108 through 4-114</a>, I attest as follows`,
      nonCitizenUploadText: 'Please provide documentation for your selection.',
      hideAcceptableDocuments: true,
    },
    submitted: {
      headerSubtextContent: `Thank you for submitting your license application. Please take note of your confirmation number.
        You should receive an email confirming your application submission within the next 24 hours. The board may follow up
        with you regarding any outstanding details. Your patience and cooperation are much appreciated.`,
    },
  },
};
