import { UpdatableModel } from './updatable-model';
import { License } from './i-license';
import { PointOfContactRequest } from './point-of-contact-request';

export class PointOfContactFor extends UpdatableModel<PointOfContactFor> {
  id: string;
  type: string;
  name: string;
  license: License;
  request: PointOfContactRequest;

  update(init?: Partial<PointOfContactFor>) {
    super.update(init);
    this.license = this.license ? new License(this.license) : null;
    this.request = this.request ? new PointOfContactRequest(this.request) : null;
  }

  get isForArchitect(): boolean {
    return this.type === 'Architect';
  }

  get isForFirm(): boolean {
    return this.type === 'Firm';
  }

  get hasLicense(): boolean {
    return !!this.license;
  }

  get licenseStatusClass(): string {
    return this.hasLicense ? 'status-' + this.license.status.toLowerCase() : 'status-unlicensed';
  }

  get hasRequest(): boolean {
    return !!this.request;
  }

  get isRequestPending(): boolean {
    return this.hasRequest && this.request.isPending;
  }

  // User consumable display of the request status
  // Assumes that there is a request.
  get displayableRequestStatus(): any {
    return this.request.displayableStatus;
  }
}
