import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { S3DocumentSearch } from '../../models/s3/s3-document-search';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { BaseComponent } from '../base.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-upload-widget-dialog',
  templateUrl: './upload-widget.dialog.html',
  styleUrls: ['./upload-widget.dialog.scss'],
})
export class UploadWidgetDialog extends BaseComponent implements OnInit {
  title: string;
  docSearch: S3DocumentSearch;
  docsList$: BehaviorSubject<S3DocumentReference[]>;

  constructor(@Inject(MAT_DIALOG_DATA) private data) {
    super();
  }
  ngOnInit() {
    this.title = this.data.title;
    this.docSearch = this.data.docSearch;
    this.docsList$ = this.data.docsList$;
  }
}
