import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
  name: 'boolToYesNo',
})
export class BoolToYesNoPipe implements PipeTransform {
  transform(data: boolean) {
    if (data === true) {
      return 'Yes';
    }
    if (data === false) {
      return 'No';
    }
    return data;
  }
}
