import { Injectable } from '@angular/core';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import { Confirmations, JurisdictionConfirmationService, LegendWithNote } from './jurisdiction-confirmation-service';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { ArchitectApplication } from '../../../models/applications/architect-application';
import { ApplicationVariant } from '../../../models/applications/application-base';

@Injectable({
  providedIn: 'root',
})
export class NEConfirmationService extends JurisdictionConfirmationService {
  attestations = [
    'The information I have provided on this form is true and accurate to the best of my knowledge.',
    'I attest to being the applicant, I am not an agent acting on behalf of the applicant.',
  ];

  getArchitectConfirmations(application: ArchitectApplication): Confirmations {
    if (application.applicationVariant === 'ArchitectInitial' || application.applicationVariant === 'ArchitectReciprocal') {
      return {
        laws: [],
        attestations: [
          ...this.attestations,
          `I will not represent myself as a landscape architect or offer to perform landscape architecture services in the
          State of Nebraska until this application is approved and a professional landscape architect license has been granted
          by the Nebraska State Board of Landscape Architects.`,
        ],
      };
    }
    return {
      laws: [],
      attestations: this.attestations,
    };
  }

  getShareHolderNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and own the number of shares listed
    (no percentages) and that the following number of total shares is correct. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Nebraska. R.S. 12:3456(A)</a>
    which requires that a majority stock in a professional architectural corporation must be owned by the individually licensed ${this.settings.stateName} architects.`;
  }

  getBoardOfDirectorsNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and serve as Directors of the corporation. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Nebraska. R.S. 12:3456</a>
    which requires that a majority of the board of directors, if more than two, shall be licensed ${this.settings.stateName} architects or, if there are less than three directors, at least one must be a licensed ${this.settings.stateName} architect.`;
  }

  getFirmConfirmations(application: FirmApplicationFull): Confirmations {
    const laws = [];
    if (application.licenseType == 'ProfessionalArchitectural') {
      laws.push(
        `${application.licenseTypeName} Law (<a href="${this.constants.commonLinks.rules}" target="_blank">Nebraska.S. 12:3456 et. seq.</a>)`
      );
    }
    if (application.licenseType == 'ArchitecturalEngineering') {
      laws.push(
        `${application.licenseTypeName} (<a href="${this.constants.commonLinks.rules}" target="_blank">Law Nebraska R.S. 12:65437 et. seq.</a>)`
      );
    }
    return {
      laws,
      attestations: this.attestations,
    };
  }

  getSupervisorNotes(application: FirmApplication): LegendWithNote[] {
    return [
      {
        legend: null,
        note: `<a href="${this.constants.commonLinks.rules}" target="_blank">Nebraska R.S. 12:3456</a>
          requires the applicant to designate the supervising professional architect(s) who shall perform or directly
          supervise the performance of all professional architectural services by said corporation, and further
          designate the supervising professional engineer who shall perform or directly supervise the performance of all
          professional civil, mechanical or electrical engineering services or those services in any other designated
          branch of professional engineering which the State Board of Registration for Professional Engineers and Land
          Surveyors and the State Board of Architectural Examiners may jointly specify. Said statute further provides
          that persons designated must be full-time active employees of the corporation and whose primary occupation is
          with that corporation.`,
      },
      {
        legend: 'Designated Supervising Professional Architect(s)',
        note: `The following architects are hereby designated as the supervising professional architects according to
          <a href="${this.constants.commonLinks.rules}" target="_blank">Nebraska R.S. 12:3456</a>:`,
      },
    ];
  }
}
