import { NgModule, ModuleWithProviders, Provider } from '@angular/core';

import { ScrollSpyDirective } from './scroll-spy.directive';
import { ScrollSpySectionComponent } from './scroll-spy-section.component';
import { ScrollSpyItemDirective } from './scroll-spy-item.directive';
import { ScrollSpyService } from './scroll-spy.service';
import { InViewportDirective } from './in-viewport.directive';

const directives: any[] = [InViewportDirective, ScrollSpyDirective, ScrollSpyItemDirective];

const components: any[] = [ScrollSpySectionComponent];

const providers: Provider[] = [ScrollSpyService];

/**
 * A simple lightweight library for Angular which automatically
 * updates links to indicate the currently active section in the viewport
 *
 */
@NgModule({
  declarations: [...directives, ...components],
  exports: [...directives, ...components],
})
export class ScrollSpyModule {
  /**
   * Specify a static method for root module to ensure providers are
   * only provided once but allows the module to still be imported
   * into other modules without reproviding services.
   *
   * @memberof ScrollSpyModule
   */
  public static forRoot(): ModuleWithProviders<ScrollSpyModule> {
    return {
      ngModule: ScrollSpyModule,
      providers: [...providers],
    };
  }
}
