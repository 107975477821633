import { ISearchWithApplicationDateRanges } from './i-search';
import { Search } from './search';
import { DateRangeSelection } from './date-range-selection';

export class ApplicationSearch extends Search<ApplicationSearch> implements ISearchWithApplicationDateRanges {
  search?: string;
  status?: string;
  type?: string;
  licenseType?: string;
  entityType?: string;
  firmType?: string;
  profession?: string;
  applicationDateRange?: DateRangeSelection;
}
