import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'menu-section',
  template: ` <div class="header-container px-medium">
      <ls-overline>{{ name }}</ls-overline>
    </div>
    <mat-divider></mat-divider>
    <ng-content></ng-content>`,
  styleUrls: ['./menu-section.component.scss'],
})
export class MenuSectionComponent {
  @Input() name: string;

  @HostBinding('class') get getClass() {
    return `d-block`;
  }
}
