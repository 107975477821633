import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FirmAvailableApplication } from 'projects/common/src/lib/models/applications/firm-available-application';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-select-firm-type',
  templateUrl: './select-firm-type.component.html',
  styleUrls: ['./select-firm-type.component.scss'],
})
export class SelectFirmTypeComponent implements OnInit {
  @Input() availables: FirmAvailableApplication[];
  @Output() typeSelected = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();
  isInitial: boolean;
  isSwitch: boolean;
  confirm: boolean;
  selected: FirmAvailableApplication;
  choiceForm: FormGroup;
  confirmationForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    public settings: TenantSettings,
    public constants: JurisdictionConstants
  ) {}

  ngOnInit() {
    this.isSwitch = this.availables.some(a => a.isLicenseTypeSwitch);
    this.isInitial = !this.isSwitch;
    this.choiceForm = this.formBuilder.group({ choice: '' });
    this.confirmationForm = this.formBuilder.group({});
  }

  exit() {
    this.cancel.emit();
  }

  submitChoice() {
    if (this.choiceForm.invalid) {
      this.notificationService.notifyFormIssue('Please select a License Type');
      return;
    }
    this.notificationService.closeLast();
    this.selected = this.choiceForm.controls.choice.value as FirmAvailableApplication;
    this.confirm = true;
  }

  submitConfirmation() {
    this.typeSelected.emit(this.selected.licenseType);
  }
}
