import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Country, State, AddressLookups } from '../../models/address';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  _countries: Country[];
  _states: State[];
  _provinces: State[];

  constructor(private api: ApiService) {}

  countries(): Observable<Country[]> {
    return this.cachedOrLoad('countries');
  }

  states(): Observable<State[]> {
    return this.cachedOrLoad('states');
  }

  provinces(): Observable<State[]> {
    return this.cachedOrLoad('provinces');
  }

  addressLookups(): Observable<AddressLookups> {
    return forkJoin([this.countries(), this.states(), this.provinces()]).pipe(
      map(([countries, states, provinces]) => {
        return {
          countries,
          states,
          provinces,
        };
      })
    );
  }

  private cachedOrLoad(arg) {
    let prop = '_' + arg;
    let ths = this;
    if (this[prop]) {
      return of(this[prop]);
    }
    return this.api.get(arg).pipe(
      map(d => {
        ths[prop] = d;
        return d;
      })
    );
  }
}
