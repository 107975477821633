import { Component, Input } from '@angular/core';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';

@Component({
  selector: 'initial-warning-la',
  templateUrl: './initial-warning-la.html',
})
export class InitialWarningLA {
  @Input() licenseType: string;

  constructor(public constants: JurisdictionConstants) {}
}
