<mat-tab-group>
  <mat-tab label="Current">
    <current-roles-list
      [list$]="currentRoleHolders$"
      [activeRolesByPersonId]="activeRolesByPersonId"
      [columns]="columns"
      [linkedName]="linkedName"
    ></current-roles-list>
  </mat-tab>
  <mat-tab label="Past">
    <past-roles-list [list$]="pastRoleHolders$" [linkedName]="linkedName"></past-roles-list>
  </mat-tab>
</mat-tab-group>
