import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { FirmPointOfContact } from 'projects/common/src/lib/models/firm-point-of-contact';
import { Firm } from 'projects/common/src/lib/models/firm';
import { FirmAvailableApplication } from 'projects/common/src/lib/models/applications/firm-available-application';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { Transaction } from 'projects/common/src/lib/models/transaction';
import { ReloadableComponent } from 'projects/common/src/lib/components/reloadable.component';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { Address } from 'projects/common/src/lib/models/address';
import { FirmLicense } from 'projects/common/src/lib/models/firm-license';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { LinkInfo } from 'projects/common/src/lib/models/links/link-info';
import { FirmRosterMember } from 'projects/common/src/lib/models/firm-roster-member';
import { ApplicationCallToAction } from 'projects/common/src/lib/models/applications/application-call-to-action';
import { Mutex } from 'projects/common/src/lib/utility/mutex';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';
import { FirmRoleHolder } from 'projects/common/src/lib/models/firm-role-holder';

@Component({
  selector: 'app-firm-portal',
  templateUrl: './firm-portal.component.html',
  styleUrls: ['./firm-portal.component.scss'],
})
export class FirmPortalComponent extends ReloadableComponent implements OnInit {
  firm$ = new ReplaySubject<Firm>(1);
  firm: Firm;
  license: FirmLicense;
  pointOfContact$ = new ReplaySubject<FirmPointOfContact>(1);
  roles$ = new ReplaySubject<FirmRoleHolder[]>(1);
  address$ = new ReplaySubject<Address>(1);
  payments$ = new ReplaySubject<Transaction[]>(1);
  roster$ = new ReplaySubject<FirmRosterMember[]>(1);
  applications: FirmApplication[];
  primaryAvailableApplication: FirmAvailableApplication;
  primaryApplicationCallToAction: ApplicationCallToAction;
  canSwitch$ = new ReplaySubject<boolean>(1);
  isLimitedLiabilityStart: boolean;

  pageLinks = [
    { path: 'info', label: 'Info' },
    { path: 'address', label: 'Address' },
    { path: 'pointOfContact', label: 'Point of Contact' },
    { path: 'supervisors', label: 'Supervising Professionals' },
    { path: 'license', label: 'License Information' },
    { path: 'applications', label: 'Applications' },
    { path: 'payments', label: 'Payments' },
    { path: 'roster', label: 'Roster' },
    { path: 'testing', label: 'Testing Setup' },
  ] as LinkInfo[];
  activePageLinks: LinkInfo[];
  applicationMutex: Mutex;
  applicationColumns = [
    'orderNumber',
    'applicationType',
    'residency',
    'applicationDate',
    'paymentDate',
    'approvedOn',
    'status',
  ];
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private firmApplicationService: FirmApplicationService,
    private firmService: FirmService,
    private accountService: AccountService,
    public settings: TenantSettings,
    public constants: JurisdictionConstants
  ) {
    super(router, route);
  }

  onLoad() {
    this.applicationMutex = new Mutex();
    this.firm = this.route.snapshot.data.firm as Firm;
    this.firm$.next(this.firm);

    this.pointOfContact$.next(this.route.snapshot.data.pointOfContact);
    this.roles$.next(this.route.snapshot.data.roles);
    this.payments$.next(this.route.snapshot.data.payments as Transaction[]);
    this.roster$.next(this.route.snapshot.data.roster);

    let availableFirmApplications = this.route.snapshot.data.firmAvailableApplications as FirmAvailableApplication[];
    this.primaryAvailableApplication = availableFirmApplications.sort(FirmAvailableApplication.compare).reverse()[0];
    this.unsubOnDestroy = this.firm$.subscribe(async f => {
      this.address$.next(f.publicAddress);
      this.license = f.license;
      if (this.primaryAvailableApplication && !f.doesPointOfContactNeedVerification) {
        this.primaryApplicationCallToAction = this.primaryAvailableApplication.callToAction;
      }

      await this.setupPageLinks(f);
    });

    this.applications = this.route.snapshot.data.applications as FirmApplication[];
    for (let application of this.applications) {
      application.reviewRoute = ['/firm', this.firm.id, 'application', application.id, 'review'];
    }

    let canSwitch = availableFirmApplications.some(a => a.isLicenseTypeSwitch) && !this.firm.doesPointOfContactNeedVerification;
    this.canSwitch$.next(canSwitch);

    this.isLimitedLiabilityStart =
      this.firm.license &&
      this.firm.license.type == 'LimitedLiability' &&
      !(this.primaryAvailableApplication && this.primaryAvailableApplication.isInProgress);
  }

  async setupPageLinks(firm: Firm) {
    let pointOfContact = this.route.snapshot.data.pointOfContact;
    let applications = this.route.snapshot.data.applications as FirmApplication[];
    let payments = this.route.snapshot.data.payments as Transaction[];
    let showRoster = await this.firmService.showRoster(firm.id);
    this.activePageLinks = this.pageLinks.filter(pl => {
      if (pl.path == 'info') {
        return true;
      } else if (pl.path == 'license') {
        return this.firm.license;
      } else if (pl.path == 'address') {
        return this.firm.publicAddress;
      } else if (pl.path == 'pointOfContact') {
        return !!pointOfContact;
      } else if (pl.path == 'supervisors') {
        return true;
      } else if (pl.path == 'applications' && !this.settings.hideApplicationListOnFirmProfile) {
        return !!applications.length;
      } else if (pl.path == 'payments') {
        return !!payments.length;
      } else if (pl.path == 'roster') {
        return showRoster;
      } else if (pl.path == 'testing') {
        return this.settings.enableEasyTesting;
      }
    });
  }

  async gotoApplication() {
    const available = this.primaryAvailableApplication;
    if (available.applicationType == 'License' && !available.isInProgress) {
      if (this.settings.hasFirmTypes()) {
        this.router.navigate(['register'], { relativeTo: this.route });
        return;
      }
    }
    if (!this.applicationMutex.tryAcquire()) {
      return;
    }
    const firmId = available.switchingFirmId || this.firm.id;
    const applicationType = available?.applicationType ?? 'License';
    const licenseType = available?.licenseType ?? null;

    try {
      const application = await this.firmApplicationService.getOrCreate(firmId, applicationType, licenseType);
      if (!available.switchingFirmId && application.firmId !== this.firm.id) {
        this.accountService.hasCheckedClaims = false;
        this.router.navigate(['switch', 'result'], { relativeTo: this.route });
        return;
      }

      if (application.firmId === this.firm.id) {
        this.router.navigate(['application', application.id], { relativeTo: this.route });
      } else {
        //You are probably switching application types
        //Need to update the URL with the new firm id so that the applicationId and firmId are in sync
        this.router.navigate(['/firm', application.firmId, 'application', application.id]);
      }
    } finally {
      this.applicationMutex.release();
    }
  }

  selectPointOfContact() {
    this.router.navigate(['./pointofcontact/poctype'], { relativeTo: this.route });
  }

  public showRoster(): boolean {
    if (!!this.activePageLinks) return this.activePageLinks.findIndex(l => l.path == 'roster') > -1;
    else return false;
  }
}
