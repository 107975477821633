import { Component, ContentChild, HostBinding, AfterContentInit, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'lib-titled-card',
  templateUrl: './titled-card.component.html',
  styleUrls: ['./titled-card.component.scss'],
})
export class TitledCardComponent implements AfterContentInit {
  @ContentChild(MatMenu) menu: MatMenu;
  @HostBinding('class.high-light')
  @Input()
  highlight: boolean = false;

  constructor() {}
  ngAfterContentInit() {
    if (this.menu) {
      this.menu.xPosition = 'before';
    }
  }
}
