import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArchitectLicense } from '../../models/architect-license';
import { downloadAll } from '../../models/download-file-base64';
import { DownloadFilesBase64 } from '../../models/download-files-base64';

@Injectable({
  providedIn: 'root',
})
export class ArchitectLicenseService {
  constructor(private api: ApiService) {}

  getPath(personId: string) {
    return `person/${personId}/license/`;
  }

  get(personId: string): Observable<ArchitectLicense[]> {
    let path = this.getPath(personId);
    return this.api.get(path).pipe(map(data => data.map(l => new ArchitectLicense(l))));
  }

  getPreview = (personId: string): Promise<ArchitectLicense> =>
    this.api
      .getAsync<any>(`${this.getPath(personId)}preview`)
      .then(json => (json && json?.id ? new ArchitectLicense(json) : null));

  //issue, renew, or reinstate license
  approveLicense = (license: ArchitectLicense): Promise<ArchitectLicense> =>
    this.api
      .postAsync<ArchitectLicense>(this.getPath(license.person.id), { number: license.number })
      .then(data => new ArchitectLicense(data));

  updateStatus = async (license: ArchitectLicense, newLicenseStatus: string): Promise<any> =>
    await this.api.patchAsync(this.getPath(license.person.id), { ...license, status: newLicenseStatus }); //only profession and status is used

  sendLicense(personId: string): Promise<boolean> {
    return this.api
      .postAsync<any>(`${this.getPath(personId)}send`, {}, true, { observe: 'response' })
      .then(resp => resp.status == 200);
  }

  download(personId: string, certificateVariant: string): Promise<boolean> {
    return this.api
      .getAsync(`${this.getPath(personId)}download/${certificateVariant ?? 'default'}`)
      .then((result: DownloadFilesBase64) => {
        downloadAll(result.files);
        return true;
      });
  }
}
