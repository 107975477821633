import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, FormControl } from '@angular/forms';

export const confirmPasswordValidator: ValidatorFn = (confirmPassword: FormControl): ValidationErrors | null => {
  const signUpPassword = confirmPassword.parent.get('signUpPassword');

  return signUpPassword && confirmPassword && confirmPassword.value && signUpPassword.value != confirmPassword.value
    ? { matchConfirmPassword: true }
    : null;
};

@Directive({
  selector: '[matchConfirmPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ConfirmPasswordValidatorDirective, multi: true }],
})
export class ConfirmPasswordValidatorDirective implements Validator {
  validate(confirmPassword: AbstractControl): ValidationErrors {
    return confirmPasswordValidator(confirmPassword);
  }
}
