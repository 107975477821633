<form *ngIf="pointOfContact$ | async as pointOfContact; else noPoc" [formGroup]="form" [class.no-underlines]="!showUnderlines">
  <div class="flex-fields" formGroupName="person">
    <mat-form-field appearance="standard" formGroupName="name">
      <mat-label>Name</mat-label>
      <input matInput formControlName="full" readonly />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" readonly />
    </mat-form-field>
  </div>
  <div class="flex-fields">
    <mat-form-field appearance="standard">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" readonly />
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Phone</mat-label>
      <span formGroupName="phone">
        <input matInput formControlName="numberFull" readonly />
      </span>
    </mat-form-field>
  </div>
  <div class="flex-fields" *ngIf="pointOfContact?.licenseNumber">
    <mat-form-field appearance="standard">
      <mat-label>License Number</mat-label>
      <input matInput formControlName="licenseNumber" readonly />
    </mat-form-field>
  </div>
</form>
<ng-template #noPoc>
  <p class="mt-3">
    <em>No Point of Contact</em>
  </p>
</ng-template>
