import { ApiService } from './api.service';
import { Note } from '../../models/note';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private path: string;

  constructor(private api: ApiService) {
    this.path = 'note';
  }

  get(id: string): Observable<Note> {
    let path = this.path.concat('/', id);
    return this.api.get(path).pipe(map(data => new Note(data)));
  }

  add(note: Note): Observable<Note> {
    return this.api.post(this.path, note).pipe(map(data => new Note(data)));
  }

  update(note: Note): Observable<Note> {
    let path = this.path.concat('/', note.id);
    return this.api.put(path, note).pipe(map(data => new Note(data)));
  }

  delete(note: Note): Observable<Note> {
    let path = this.path.concat('/', note.id);
    return this.api.delete(path).pipe(map(data => new Note(data)));
  }

  getForSubject(subjectId: string, scope: string): Observable<Note[]> {
    let qs = this.api.queryStringify({ subjectId, scope });
    return this.api.get(this.path.concat(qs)).pipe(map(data => data.map(note => new Note(note))));
  }

  getForSubjects(subjectIds: string[]): Observable<Note[]> {
    let qs = this.api.queryStringify({ subjectIds: subjectIds.join(',') });
    return this.api.get(this.path.concat(qs)).pipe(map(data => data.map(note => new Note(note))));
  }

  countForSubject(subjectId: string, scope: string): Observable<number> {
    let qs = this.api.queryStringify({ subjectId, scope });
    return this.api.get(this.path.concat('/count', qs));
  }

  countsForSubjects(subjectIds: string[]): Observable<{ string: number }> {
    let qs = this.api.queryStringify({ subjectIds: subjectIds.join(',') });
    return this.api.get(this.path.concat('/count', qs));
  }
}
