<div class="xs-collapse two-columns" [class]="isNarrow ? 'flex-column' : 'grid'" *ngIf="formGroup">
  <mat-form-field class="readonly remove-hint-pad" appearance="standard">
    <mat-label *ngIf="formGroup.controls.streetLine1.value">{{ firstLine }}</mat-label>
    <input
      matInput
      [ngModel]="formGroup.controls.streetLine1.value"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      [placeholder]="firstLine"
    />
  </mat-form-field>

  <mat-form-field class="readonly remove-hint-pad" appearance="standard">
    <mat-label *ngIf="formGroup.controls.streetLine2.value">{{ secondLine }}</mat-label>
    <input
      matInput
      [ngModel]="formGroup.controls.streetLine2.value"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      [placeholder]="secondLine"
    />
  </mat-form-field>

  <mat-form-field class="readonly remove-hint-pad" appearance="standard">
    <mat-label *ngIf="formGroup.controls.city.value">City</mat-label>
    <input
      matInput
      [ngModel]="formGroup.controls.city.value"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      placeholder="City"
    />
  </mat-form-field>

  <div class="grid state" [ngClass]="{ 'county-with-state': showState && !hideCounty }">
    <mat-form-field class="readonly remove-hint-pad" appearance="standard" [hidden]="!showState">
      <mat-label *ngIf="formGroup.controls.stateId.value">State</mat-label>
      <input
        matInput
        [ngModel]="displayState(formGroup.controls.stateId.value)"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="true"
        placeholder="State"
      />
    </mat-form-field>
    <mat-form-field class="readonly remove-hint-pad" appearance="standard" [hidden]="!showProvince">
      <mat-label *ngIf="formGroup.controls.provinceId.value">Province</mat-label>
      <input
        matInput
        [ngModel]="displayState(formGroup.controls.provinceId.value)"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="true"
        placeholder="Province"
      />
    </mat-form-field>

    <mat-form-field class="readonly remove-hint-pad" appearance="standard" [hidden]="!showRegion">
      <mat-label *ngIf="formGroup.controls.region.value">Region</mat-label>
      <input
        matInput
        [ngModel]="formGroup.controls.region.value"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="true"
        placeholder="Region"
      />
    </mat-form-field>

    <mat-form-field class="readonly remove-hint-pad" appearance="standard" [hidden]="!showState || hideCounty">
      <mat-label *ngIf="formGroup.controls.county.value">{{
        formGroup.controls.stateId.value === 22 ? 'Parish' : 'County'
      }}</mat-label>
      <input
        matInput
        [ngModel]="formGroup.controls.county.value"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="true"
        [placeholder]="formGroup.controls.stateId.value === 22 ? 'Parish' : 'County'"
      />
    </mat-form-field>
  </div>

  <mat-form-field class="readonly remove-hint-pad" appearance="standard">
    <mat-label *ngIf="formGroup.controls.zip.value">{{ settings.firmProfileSettings.zipCodeLabel }}</mat-label>
    <input
      matInput
      [ngModel]="formGroup.controls.zip.value"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      [placeholder]="settings.firmProfileSettings.zipCodeLabel"
    />
  </mat-form-field>

  <mat-form-field class="readonly remove-hint-pad" appearance="standard">
    <mat-label *ngIf="formGroup.controls.countryId.value">Country</mat-label>
    <input
      matInput
      [ngModel]="displayCountry(formGroup.controls.countryId.value)"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      placeholder="Country"
    />
  </mat-form-field>

  <address-status [formGroup]="formGroup" *ngIf="!hideValidation"></address-status>
</div>
