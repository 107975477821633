<div *ngIf="getCurrentMode() === 'readonly'" class="non-edit-mode">
  <lib-read-field [value]="getHonorificDisplay()" [class.d-none]="honorificNotShown"> Honorific </lib-read-field>
  <lib-read-field [value]="formGroup.controls.first.value"> First </lib-read-field>
  <lib-read-field [value]="formGroup.controls.middle.value"> Middle </lib-read-field>
  <lib-read-field [value]="formGroup.controls.last.value"> Last </lib-read-field>
  <lib-read-field [value]="formGroup.controls.suffix.value"> Suffix </lib-read-field>
</div>
<div *ngIf="getCurrentMode() === 'disabled'" class="non-edit-mode">
  <lib-disabled-field [value]="getHonorificDisplay()" [class.d-none]="honorificNotShown"> Honorific </lib-disabled-field>
  <lib-disabled-field [value]="formGroup.controls.first.value"> First </lib-disabled-field>
  <lib-disabled-field [value]="formGroup.controls.middle.value"> Middle </lib-disabled-field>
  <lib-disabled-field [value]="formGroup.controls.last.value"> Last </lib-disabled-field>
  <lib-disabled-field [value]="formGroup.controls.suffix.value"> Suffix </lib-disabled-field>
</div>

<div *ngIf="getCurrentMode() === 'edit'" class="flex-fields flex-fields-name" [formGroup]="formGroup">
  <mat-form-field appearance="outline" floatLabel="auto" [class.d-none]="honorificNotShown">
    <mat-label> Honorific </mat-label>
    <mat-select panelClass="opt-in" formControlName="honorific">
      <mat-option></mat-option>
      <mat-option value="Mr">Mr</mat-option>
      <mat-option value="Mrs">Mrs</mat-option>
      <mat-option value="Ms">Ms</mat-option>
      <mat-option value="Miss">Miss</mat-option>
      <mat-option value="Dr">Dr</mat-option>
      <mat-option value="NonePreferred">
        <span class="d-none">N/A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        None Preferred
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    [appearance]="firstMiddleLastSettings.appearance"
    [floatLabel]="firstMiddleLastSettings.floatLabel"
    [class.readonly]="firstMiddleLastSettings.readonly"
  >
    <mat-label *ngIf="readMode == 'editing' || formGroup.controls.first.value"> First </mat-label>
    <input
      matInput
      formControlName="first"
      [required]="readMode == 'editing'"
      [readonly]="firstMiddleLastSettings.readonly"
      placeholder="First"
    />
  </mat-form-field>

  <mat-form-field
    [appearance]="firstMiddleLastSettings.appearance"
    [floatLabel]="firstMiddleLastSettings.floatLabel"
    [class.readonly]="firstMiddleLastSettings.readonly"
  >
    <mat-label *ngIf="readMode == 'editing' || formGroup.controls.middle.value"> Middle </mat-label>
    <input matInput formControlName="middle" [readonly]="firstMiddleLastSettings.readonly" placeholder="Middle" />
  </mat-form-field>

  <mat-form-field
    [appearance]="firstMiddleLastSettings.appearance"
    [floatLabel]="firstMiddleLastSettings.floatLabel"
    [class.readonly]="firstMiddleLastSettings.readonly"
  >
    <mat-label *ngIf="readMode == 'editing' || formGroup.controls.last.value"> Last </mat-label>
    <input
      matInput
      formControlName="last"
      [required]="readMode == 'editing'"
      [readonly]="firstMiddleLastSettings.readonly"
      placeholder="Last"
    />
  </mat-form-field>

  <mat-form-field
    [floatLabel]="suffixIsEditable ? 'auto' : ''"
    [appearance]="suffixIsEditable ? 'outline' : 'standard'"
    [class.readonly]="!suffixIsEditable"
  >
    <mat-label *ngIf="suffixIsEditable || formGroup.controls.suffix.value"> Suffix </mat-label>
    <input
      matInput
      *ngIf="!suffixIsEditable"
      [ngModel]="formGroup.controls.suffix.value"
      [ngModelOptions]="{ standalone: true }"
      [readonly]="true"
      placeholder="Suffix"
    />
    <mat-select *ngIf="suffixIsEditable" panelClass="opt-in" formControlName="suffix">
      <mat-option></mat-option>
      <mat-option value="Jr">Jr</mat-option>
      <mat-option value="Sr">Sr</mat-option>
      <mat-option value="II">II</mat-option>
      <mat-option value="III">III</mat-option>
      <mat-option value="IV">IV</mat-option>
      <mat-option value="V">V</mat-option>
    </mat-select>
  </mat-form-field>
</div>
