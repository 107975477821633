<header>
  <section>
    <div>
      <span class="login-email" *ngIf="auth.isAuthenticated()">{{ auth.claims?.email }}</span>
      <button *ngIf="auth.isAuthenticated()" (click)="auth.logout()">Log Out</button>
    </div>
  </section>
  <section>
    <div>
      <a [routerLink]="['/']">
        <img lib-image image="logo-reversed.png" width="154" />
      </a>
      <lib-spinner-dialog #spinner="spinner"></lib-spinner-dialog>
      <nav [class.invisible]="!auth.isAuthenticated() || (hideNav$ | async)">
        <a
          (click)="changePortal(myProfilePath)"
          [routerLink]="myProfilePath"
          routerLinkActive
          #myRla="routerLinkActive"
          [class.active]="myRla.isActive"
        >
          My Profile
        </a>
        <a
          [routerLink]="['/portal', currentOtherPersonId]"
          [class.active]="currentOtherPersonId"
          *ngIf="architects.length"
          [matMenuTriggerFor]="archMenu"
          #archMenuTrigger="matMenuTrigger"
          (mouseenter)="archMenuTrigger.openMenu()"
        >
          Architects <mat-icon class="inline">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #archMenu="matMenu">
          <a
            mat-menu-item
            (click)="changePortal(['/portal', architect.id])"
            *ngFor="let architect of architects"
            [routerLink]="['/portal', architect.id]"
            routerLinkActive
            #archItemRla="routerLinkActive"
            [class.font-weight-bold]="archItemRla.isActive"
          >
            {{ architect.name }} {{ architect.licenseNumber }}
          </a>
        </mat-menu>
        <a
          *ngIf="firmLink.isAvailable"
          [routerLink]="firmLink.path"
          routerLinkActive
          #firmRla="routerLinkActive"
          [class.active]="firmRla.isActive"
        >
          {{ firmLink.label }}
        </a>
        <a
          [routerLink]="['/firm', currentFirmId]"
          [class.active]="currentFirmId"
          *ngIf="multiFirms.length"
          [matMenuTriggerFor]="firmMenu"
          #firmMenuTrigger="matMenuTrigger"
          (mouseenter)="firmMenuTrigger.openMenu()"
        >
          Firms <mat-icon class="inline">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #firmMenu="matMenu">
          <a
            mat-menu-item
            *ngFor="let firm of multiFirms"
            [routerLink]="['/firm', firm.id]"
            routerLinkActive
            #firmItemRla="routerLinkActive"
            [class.font-weight-bold]="firmItemRla.isActive"
          >
            {{ firm.name }} {{ firm.licenseNumber }}
          </a>
        </mat-menu>
      </nav>
      <h1>Licensing Portal</h1>
    </div>
  </section>
</header>
<router-outlet></router-outlet>
<footer>
  <div>
    <span> &copy; {{ year }} {{ settings.boardName }} </span>
    <span>
      <a [href]="settings.contactUsUrl" target="_blank">Contact Us</a>
    </span>
  </div>
</footer>
<lib-env-banner></lib-env-banner>
