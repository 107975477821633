import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { MainTitleService } from './shared/services/main-title.service';
import { FirmAvailableService } from './shared/services/firm-available.service';
import { FirmAvailable } from 'projects/common/src/lib/models/firm-available';
import { ArchitectAvailable } from 'projects/common/src/lib/models/architect-available';
import { ArchitectAvailableService } from './shared/services/architect-available.service';
import { SpinnerDialog } from 'projects/common/src/lib/components/spinner-dialog/spinner.dialog';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent extends BaseComponent {
  title = '';
  year = new Date().getFullYear();
  hideNav$ = new BehaviorSubject<boolean>(false);
  myProfilePath = ['/portal'];
  firmLink = { path: '/firm', label: 'Firm Profile', isAvailable: false };
  currentFirmId: string;
  currentOtherPersonId: string;
  multiFirms = [] as FirmAvailable[];
  architects = [] as ArchitectAvailable[];

  @ViewChild(SpinnerDialog)
  spinner: SpinnerDialog;
  constructor(
    public auth: AuthService,
    private mainTitleService: MainTitleService,
    private router: Router,

    private route: ActivatedRoute,
    private firmAvailableService: FirmAvailableService,
    private architectAvailableService: ArchitectAvailableService,
    public settings: TenantSettings,
    titleService: Title
  ) {
    super();
    titleService.setTitle(`${settings.boardAbbreviation} Applications Portal`);
  }

  ngOnInit() {
    this.unsubOnDestroy = this.mainTitleService.title$.subscribe(t => (this.title = t ? t : ''));

    this.unsubOnDestroy = this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(evt => {
      let navEvt = <NavigationEnd>evt;
      let hideNav = ['authorizing'].some(path => navEvt.url.toLowerCase().indexOf('/' + path) > -1);
      this.hideNav$.next(hideNav);
      this.currentFirmId = this.getIdFromPath(this.route.snapshot, 'firmId');
      let personId = this.getIdFromPath(this.route.snapshot, 'personId');
      if (this.auth.isAuthenticated && this.auth.claims) {
        this.currentOtherPersonId = personId == this.auth.claims.personId ? '' : personId;
      } else {
        this.currentOtherPersonId = '';
      }
    });

    this.unsubOnDestroy = this.auth.isAuthenticated$.subscribe(isAuth => {
      if (isAuth) {
        this.myProfilePath = ['/portal', this.auth.claims.personId];
      }
    });

    this.unsubOnDestroy = this.firmAvailableService.firms$.subscribe(firms => {
      if (!firms.length) {
        this.firmLink.isAvailable = false;
        this.multiFirms = [];
      } else if (firms.length == 1) {
        this.firmLink.path = '/firm/'.concat(firms[0].id);
        this.firmLink.isAvailable = true;
        this.multiFirms = [];
      } else {
        this.firmLink.isAvailable = false;
        this.multiFirms = firms;
      }
    });

    this.unsubOnDestroy = this.architectAvailableService.architects$.subscribe(architects => {
      this.architects = architects;
    });
  }

  getIdFromPath(route: ActivatedRouteSnapshot, idParam: string) {
    let child = route.firstChild;
    child = child && child.firstChild;
    let id = child && child.params && child.params[idParam];
    return id;
  }

  async changePortal(route: string[]) {
    const spinner = this.spinner.open();

    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .pipe(first())
      .subscribe(_ => {
        spinner.close(); //close the router when navigation ends
      });

    await this.router.navigate(route);
  }
}
