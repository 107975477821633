import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-display',
  template: '<ng-content></ng-content>',
})
export class DisplayComponent {
  @Input() level: '1' | '2' | '3' | '4';
  @HostBinding('class.type-display-1') get typeClass1() {
    return this.level == '1';
  }
  @HostBinding('class.type-display-2') get typeClass2() {
    return this.level == '2';
  }
  @HostBinding('class.type-display-3') get typeClass3() {
    return this.level == '3';
  }
  @HostBinding('class.type-display-4') get typeClass4() {
    return this.level == '4';
  }
  @HostBinding('class.d-block') block = true;
}
