import { Component, HostBinding, Input } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';

@Component({
  selector: 'lib-firm-lookup-link',
  templateUrl: './firm-lookup-link.component.html',
  styleUrls: ['./firm-lookup-link.component.scss'],
})
export class FirmLookupLinkComponent {
  @Input() trackingLabel: string;

  constructor(
    public constants: JurisdictionConstants,
    private angulartics2: Angulartics2
  ) {}

  @HostBinding('class') get getClass() {
    return 'd-flex align-items-center';
  }

  openFirmLookup() {
    this.angulartics2.eventTrack.next({
      action: 'Firm Lookup',
      properties: {
        category: 'Button Clicks',
        label: this.trackingLabel,
      },
    });
    window.open(this.constants.commonLinks.firmLookup, 'firmLookup');
    return false;
  }
}
