import { Pipe, PipeTransform } from '@angular/core';
import { RoleInfo } from '../models/firm-role-holder-groups';

export type RoleContext = 'all' | 'poc' | 'nonPoc';

@Pipe({ name: 'roleNameList' })
export class RoleNameListPipe implements PipeTransform {
  transform(roles: RoleInfo[], context: RoleContext = 'all') {
    let predicate: (roleInfo: RoleInfo) => boolean = () => true;

    if (context === 'nonPoc') {
      predicate = (roleInfo: RoleInfo) => roleInfo.role !== 'PointOfContact';
    } else if (context === 'poc') {
      predicate = (roleInfo: RoleInfo) => roleInfo.role === 'PointOfContact';
    }

    return roles
      .filter(predicate)
      .map(r => r.name)
      .join(', ');
  }
}
