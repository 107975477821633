import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { filter } from 'rxjs/operators';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';

@Component({
  template: '',
})
export class PersonPortalRedirectComponent extends BaseComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.unsubOnDestroy = this.authService.isAuthenticated$.pipe(filter(isAuth => !!isAuth)).subscribe(_ => {
      this.router.navigate(['/portal/' + this.authService.claims.personId]);
    });
  }
}
