import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { BlankComponent } from 'projects/common/src/lib/components/blank.component';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { Order } from 'projects/common/src/lib/models/order';
import { Firm } from 'projects/common/src/lib/models/firm';
import { FirmApplicationFull } from 'projects/common/src/lib/models/applications/firm-application-full';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { State } from 'projects/common/src/lib/models/address';
import { FirmPointOfContact } from 'projects/common/src/lib/models/firm-point-of-contact';
import { JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';
import { JurisdictionConfirmationService } from '../../../constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { getApplicationSettings } from '../../../utility/router-extensions';

@Component({
  selector: 'app-firm-review',
  templateUrl: './firm-review.component.html',
  styleUrls: ['../review.scss', './firm-review.component.scss'],
})
export class FirmReviewComponent extends BlankComponent implements OnInit {
  title;
  firm: Firm;
  application: FirmApplicationFull;
  questionnaireResponse: QuestionnaireResponse;

  states: State[];
  order: Order;
  pointOfContact$: Observable<FirmPointOfContact>;
  applicationForm: FormGroup;
  confirmationForm: FormGroup;
  hasDirectors: boolean;
  hasStateDocuments: boolean;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public constants: JurisdictionConstants,
    public settings: TenantSettings,
    private confirmationService: JurisdictionConfirmationService
  ) {
    super();
  }

  @HostBinding('class.opt-in') optin = true;

  ngOnInit() {
    this.pointOfContact$ = of(this.route.snapshot.data.pointOfContact);
    this.questionnaireResponse = this.route.snapshot.data.questionnaireResponse;
    const applicationSettings = getApplicationSettings(this.route);

    this.states = this.route.snapshot.data.states;
    this.application = this.route.snapshot.data.application;
    this.hasDirectors = applicationSettings.steps.some(step => step === 'Directors');
    this.hasStateDocuments = applicationSettings.steps.some(step => step === 'StateDocuments');

    this.firm = this.application.firm;
    this.order = this.route.snapshot.data.order;
    this.title = applicationSettings.applicationName;
    this.confirmationForm = this.confirmationService.getFirmForm(this.application, true);
    this.applicationForm = FirmApplication.GetFormGroup(
      this.formBuilder,
      this.constants.firmApplicationSettings?.askForStateInFirmSupervisor ?? false,
      this.constants.firmApplicationSettings?.showOldBoardOfDirectors ?? false
    );
    let firmApplication = new FirmApplication(this.application);
    firmApplication.patch(this.applicationForm, this.formBuilder);
  }
}
