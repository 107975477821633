<lib-titled-card class="opt-in">
  <h2>
    <ng-template
      *ngIf="settings.architectProfileSettings.workAddressCardTitle; then hasCustomTitle; else noCustomTitle"
    ></ng-template>
    <ng-template #hasCustomTitle>
      {{ settings.architectProfileSettings.workAddressCardTitle }}
    </ng-template>
    <ng-template #noCustomTitle>Work Address {{ constants.workAddressSuffix }}</ng-template>
  </h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
  </aside>
  <form [formGroup]="addressForm" [class.no-underlines]="!isEditing">
    <p *ngIf="constants.commonLinks.architectLookup">
      This address will appear in the
      <a [href]="constants.commonLinks.architectLookup" target="_blank">Find {{ profession | spaceCapCase | addAnOrA }}</a>
      section of the {{ constants.acronym }} public website.
    </p>
    <mat-checkbox
      *ngIf="!settings.architectProfileSettings.hideHomeAddress"
      formControlName="workAddressSameAsHome"
      color="primary"
      (change)="onWorkAddressSameChange($event)"
      [disabled]="!isEditing"
    >
      Work Address is same as Home Address
    </mat-checkbox>
    <div class="flex-fields" *ngIf="useFirmName">
      <mat-form-field
        [appearance]="isEditing ? 'outline' : 'standard'"
        [class.readonly]="!isEditing"
        [floatLabel]="isEditing ? 'auto' : ''"
      >
        <mat-label *ngIf="isEditing || addressForm.controls.firmName.value">Firm Name</mat-label>
        <input matInput formControlName="firmName" placeholder="Firm Name" [readonly]="!isEditing" />
      </mat-form-field>
    </div>
    <lib-address
      [editing]="isEditing && !addressForm.value.workAddressSameAsHome"
      [formGroup]="addressForm"
      [line1Label]="line1Label"
      [line2Label]="line2Label"
      [hideCounty]="hideCounty"
    ></lib-address>
  </form>
</lib-titled-card>
