import { UpdatableModel } from './updatable-model';
import { PersonLite } from './person-lite';
import { FormBuilder, FormGroup } from '@angular/forms';

export class PersonPointOfContact extends UpdatableModel<PersonPointOfContact> {
  static readonly pendingStatus = 'Pending';
  static readonly rejectedStatus = 'Rejected';

  id: string;
  pointOfContactPerson: PersonLite;
  forPerson: PersonLite;
  requestType: string;
  requestExpirationDate: Date;
  acceptedDate?: Date;
  endDate?: Date;
  status: string;
  isActive: boolean;
  title: string;

  acceptPatch(formGroup: FormGroup) {
    this.update(formGroup.value);
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    this.pointOfContactPerson.patch(formGroup.controls.pointOfContactPerson as FormGroup);
  }

  update(init?: Partial<PersonPointOfContact>) {
    super.update(init);
    if (this.pointOfContactPerson) {
      this.pointOfContactPerson = new PersonLite(this.pointOfContactPerson);
    }
    if (this.forPerson) {
      this.forPerson = new PersonLite(this.forPerson);
    }
  }

  get isPending(): boolean {
    return this.status === PersonPointOfContact.pendingStatus;
  }

  get isRejected(): boolean {
    return this.status === PersonPointOfContact.rejectedStatus;
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      title: '',
      pointOfContactPerson: PersonLite.GetFormGroup(formBuilder),
    });
  }
}
