import { Component, Input, OnInit, QueryList, ContentChildren, AfterContentInit } from '@angular/core';
import { WizardStepComponent } from './wizard-step/wizard-step.component';

@Component({
  selector: 'lib-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, AfterContentInit {
  @Input() transitions: any;
  @Input() initialStep: string;
  state: string[] = [];
  @ContentChildren(WizardStepComponent) steps: QueryList<WizardStepComponent>;

  ngOnInit(): void {
    this.state.push(this.initialStep);
  }

  ngAfterContentInit(): void {
    let step = this.getStepByName(this.initialStep);
    step.isVisible = true;
  }

  updateState(value: any, stepName: string): WizardStepComponent {
    if (value === null) {
      return;
    }
    this.transitionToPreviousStateIfNecessary(stepName);
    return this.getStepByName(this.transitionToNextStep(value));
  }

  rewindToStep(stepName: string) {
    this.transitionToPreviousStateIfNecessary(stepName);
  }

  isStepVisible(stepName: string): boolean {
    return this.state.includes(stepName);
  }

  get isComplete(): boolean {
    return this.currentStep === 'end';
  }

  private transitionToPreviousStateIfNecessary(stepName: string): void {
    let previousStateIndex = this.state.indexOf(stepName);
    if (previousStateIndex > -1 && previousStateIndex !== this.state.length - 1) {
      let removedSteps = this.state.slice(previousStateIndex + 1, this.state.length);
      this.state = this.state.slice(0, previousStateIndex + 1);
      removedSteps.forEach(stepName => {
        this.setStepVisibility(stepName, false);
      });
    }
  }

  private transitionToNextStep(value: any): string {
    let possibleTransitions = this.transitions[this.currentStep] as any[];
    let transition = possibleTransitions.find(transition => transition.output === value);
    let nextStepName = undefined;
    if (transition) {
      nextStepName = transition.transitionTo;
      this.state.push(nextStepName);
      this.setStepVisibility(nextStepName, true);
    }
    return nextStepName;
  }

  private get currentStep(): string {
    return this.state[this.state.length - 1];
  }

  private getStepByName(name: string): WizardStepComponent {
    return this.steps.find(step => step.name === name);
  }

  private setStepVisibility(name: string, isVisible: boolean): void {
    let nextStep = this.getStepByName(name);
    if (nextStep) {
      nextStep.isVisible = isVisible;
    }
  }
}
