<lib-titled-card>
  <h2>Home Address {{ constants.homeAddressSuffix }}</h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
  </aside>
  <div class="opt-in" [class.no-underlines]="!isEditing">
    <lib-address
      [line1Label]="line1Label"
      [line2Label]="line2Label"
      [hideCounty]="hideCounty"
      [formGroup]="addressForm"
      [editing]="isEditing"
    >
    </lib-address>
  </div>
</lib-titled-card>
