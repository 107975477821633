<ls-style-opt-in>
  <div style="width: 100%">
    <div
      *ngIf="!readonly"
      ng2FileDrop
      [ngClass]="{ 'ls-background-grey': isFileOver }"
      [uploader]="uploader"
      (fileOver)="onFileOver($event)"
      class="drop-zone ls-outline"
      (click)="documentUpload.click()"
      style="display: flex"
    >
      <mat-icon class="icon">file_upload</mat-icon>
      <div>
        <span class="instructions">Click to Browse or Drag and Drop {{ filesTitle }} Here</span>
        <span class="allowedTypes">{{ allowedFileExtensions }}</span>
      </div>
    </div>
    <input
      class="d-none"
      type="file"
      id="documentUpload"
      #documentUpload
      ng2FileSelect
      multiple
      [uploader]="uploader"
      (change)="documentUpload.value = ''"
    />
  </div>

  <!-- Error list -->
  <div class="errorList">
    <lib-pill variant="error" *ngIf="hasNoUploads() && uploadsRequired">
      {{ getErrorMessage(uploadError.NoUploads) }}
    </lib-pill>
    <!-- The if statement prevents the DOM from constantly updating an empty div. -->
    <ng-container *ngIf="getUploadFileErrors().length > 0">
      <div *ngFor="let uf of getUploadFileErrors()">
        <lib-pill variant="error"> {{ uf.file.fileNameWithoutTimestamp }}: {{ getErrorMessage(uf.error) }} </lib-pill>
      </div>
    </ng-container>
  </div>

  <ls-p class="readonly-no-docs ls-text-grey" *ngIf="hasNoUploads() && !uploadsRequired">{{ noDocumentsMessage }}</ls-p>

  <ls-caption *ngIf="!hasNoUploads() && !!caption && !hideDocumentList()" class="ls-text-grey">{{ caption }}</ls-caption>

  <!-- Table Style -->
  <table *ngIf="showTableView()" class="fileList" mat-table [dataSource]="getUploadFiles()" style="width: 100%">
    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let uf">
        <ls-p level="2">
          <a href="#" (click)="download(uf.file)" *ngIf="!uf.file.link">
            <mat-icon>file_download</mat-icon><span>{{ uf.file.fileNameWithoutTimestamp }}</span>
          </a>
          <a [href]="uf.file.link" target="_blank" *ngIf="uf.file.link">
            <mat-icon>file_download</mat-icon><span>{{ uf.file.fileNameWithoutTimestamp }}</span>
          </a>
        </ls-p>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastModified">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let uf">
        <ls-p level="2">{{ uf.file.lastModified | date: 'short' }}</ls-p>
      </td>
    </ng-container>

    <ng-container matColumnDef="documentType">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let uf">
        <ls-p level="2">{{ uf.file.documentType }}</ls-p>
      </td>
    </ng-container>

    <ng-container matColumnDef="uploadedByUsername">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let uf">
        <ls-p level="2">{{ uf.file.uploadedByUsername }}</ls-p>
      </td>
    </ng-container>

    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let uf">
        <ng-container *ngIf="uf.file.documentType === 'Other'; then staffUploadedFile; else applicationStatus"></ng-container>
        <ng-template #staffUploadedFile>
          <ls-p level="2">{{ uf.file.uploadedByName }}</ls-p>
        </ng-template>
        <ng-template #applicationStatus>
          <ls-p level="2">{{ uf.file.description }}</ls-p>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef width="1"></th>
      <td mat-cell *matCellDef="let uf" class="text-right">
        <button mat-icon-button color="warn" (click)="confirmDelete.open()" *ngIf="showDocDeleteButton(uf.file)">
          <mat-icon>delete</mat-icon>
        </button>

        <lib-confirm
          #confirmDelete="confirm"
          [prompt]="'Delete ' + uf.file.fileNameWithoutTimestamp + '?'"
          yesText="Yes, delete"
          noText="No, cancel"
          (yes)="delete(uf.file)"
        ></lib-confirm>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>

  <!-- Pill Style -->
  <div *ngIf="showPillView() && !hasNoUploads()" class="pillContainer">
    <div *ngFor="let uf of getUploadFiles()">
      <lib-file-pill [file]="uf.file" (deleteClicked)="delete($event)" (fileClicked)="download($event)"> </lib-file-pill>
    </div>
  </div>
</ls-style-opt-in>
