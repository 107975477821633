import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import {
  SupervisingProfessionalsSettings,
  TenantSettings,
} from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { ActivatedRoute, Router } from '@angular/router';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';
import { FirmApplicationPrincipal } from 'projects/common/src/lib/models/firm-application-principal';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';

@Component({
  selector: 'lib-supervisor-edit',
  templateUrl: './supervisor-edit.component.html',
  styleUrls: ['./supervisor-edit.component.scss'],
})
export class SupervisorEditComponent implements OnInit {
  @HostBinding('class.opt-in') optin = true;
  @Input() application: FirmApplication;
  supervisors: FormArray;
  isPortal: boolean = false;
  spSettings: SupervisingProfessionalsSettings;
  maximumSupervisors: number = 0;
  aggregateRootId: string;
  docCount: number = 0;
  uploadComponentInitOnChangeFired: boolean = false;

  constructor(
    public constants: JurisdictionConstants,
    public settings: TenantSettings,
    private route: ActivatedRoute,
    private firmService: FirmService,
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    this.maximumSupervisors = this.constants.firmApplicationSettings?.maximumSupervisors ?? 0;
    const principals = await this.firmService.getSupervisors(this.route.snapshot.params.firmId);
    const supervisorPrincipals = principals.map(principal =>
      principal.patch(FirmApplicationPrincipal.GetFormGroup(this.formBuilder, principal.role))
    );

    this.supervisors = this.formBuilder.array(supervisorPrincipals);

    this.isPortal = !!this.route.snapshot.data.isPortal;
    this.spSettings = this.settings.supervisingProfessionalsSettings;
    this.aggregateRootId = this.route.snapshot.params.firmId;
  }

  async save() {
    if (this.isPortal && !!this.spSettings.requireDocumentUploadOnPortalEdit && this.docCount == 0) {
      this.notificationService.notifyFail('Supporting documentation must be uploaded for this change!');
      return;
    }

    await this.firmService.saveSupervisors(this.route.snapshot.params.firmId, this.supervisors.value);
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onDocListChange() {
    // the upload component fires onDocListChange during initialization so we're compensating for that here.
    if (!this.uploadComponentInitOnChangeFired) {
      this.uploadComponentInitOnChangeFired = true;
      return;
    }

    this.docCount += 1;
  }
}
