import { Search } from './search';
import { IFirmSearch } from './i-firm-search';

export class FirmLicenseSearch extends Search<FirmLicenseSearch> implements IFirmSearch {
  search?: string;
  since?: string;
  until?: string;
  dateTarget?: string;
  status?: string;
  licenseType?: string;

  get licenseTypeLabel() {
    return {
      ProfessionalArchitectural: 'AC',
      ArchitecturalEngineering: 'AE',
      ArchitecturalFirm: 'AF',
      LimitedLiability: 'LLC',
    }[this.licenseType];
  }
}
