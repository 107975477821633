<div class="status-row on-pending-text" *ngIf="formGroup.controls.error.value">
  <mat-icon>error</mat-icon>
  <div>
    {{ formGroup.controls.error.value }}
  </div>
</div>
<div class="status-row on-success-text" *ngIf="formGroup.controls.isValid.value">
  <mat-icon>check_circle</mat-icon>
  <div>Address is considered valid</div>
</div>
