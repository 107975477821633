import { Injectable } from '@angular/core';
import { Preferences } from '../models/preferences';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  preferences = new Preferences();

  constructor() {
    let str = localStorage['preferences'];
    if (str) {
      let init = JSON.parse(str);
      this.preferences.update(init);
    }
  }

  save() {
    localStorage['preferences'] = JSON.stringify(this.preferences);
  }
}
