<form id="ae-af-business-info" [formGroup]="form">
  <main>
    <ng-template [ngIf]="isAEApplication">
      <header *ngIf="!readonly">
        <p class="note error" *ngIf="submitAttempted && form.invalid">*Please complete all required fields in red</p>
      </header>

      <section-header title="Corporate Information" class="pb-2 d-block">
        Please provide some information about the organization's business structure
      </section-header>

      <fieldset>
        <div class="flex-fields">
          <mat-form-field *ngIf="showState" class="state" appearance="outline">
            <mat-label>State of Incorporation</mat-label>
            <mat-select formControlName="stateOfIncorporationId" required>
              <mat-option></mat-option>
              <mat-option *ngFor="let state of states" [value]="state.id">
                {{ state.code }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ incorporationLabel ?? 'Date of Incorporation' }}</mat-label>
            <input matInput [matDatepicker]="picker" [max]="today" formControlName="dateOfIncorporation" required />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </fieldset>
    </ng-template>

    <section-header [title]="supervisingProfessionalSectionHeader" class="pb-2 d-block">
      <span [innerHTML]="supervisingProfessionalDescription"></span>
    </section-header>

    <fieldset>
      <lib-multi-arch-select
        [formArray]="form['controls'].supervisors"
        [maximumSelectable]="maximumSupervisors"
        role="Supervisor"
        [readonly]="readonly"
        [supervisorLabel]="supervisingProfessionalArchSelectLabel"
      >
      </lib-multi-arch-select>

      <ng-container *ngFor="let legendWithNote of legendWithNotes">
        <p class="note" [innerHTML]="legendWithNote.note"></p>
      </ng-container>
    </fieldset>
  </main>
</form>
