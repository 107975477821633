import { UpdatableModel } from './updatable-model';
import { Name } from './name';
import { FormBuilder, FormGroup } from '@angular/forms';

export class PersonLite extends UpdatableModel<PersonLite> {
  id: string;
  name: Name;
  credentials: string;
  email: string;

  update(init?: Partial<PersonLite>) {
    super.update(init);
    this.name = new Name(this.name);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      name: Name.GetFormGroup(formBuilder),
      credentials: '',
      email: '',
    });
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    this.name.patch(formGroup.controls.name as FormGroup);
  }
}
