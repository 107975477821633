<lib-call-to-action *ngIf="show">
  <div class="flex-column content">
    <ls-headline>Application Setup: Testing Only</ls-headline>

    <mat-form-field appearance="standard" appearance="outline" floatLabel="auto">
      <mat-label>Profession</mat-label>
      <mat-select [(value)]="profession" (selectionChange)="onProfessionChanged()">
        <mat-option [value]="applicationProfession" *ngFor="let applicationProfession of professions">
          {{ applicationProfession | spaceCapCase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="isCreateInitialApp" *ngIf="canAddInitial">Create Initial Application</mat-checkbox>

    <mat-radio-group class="licenseOrigin" [(ngModel)]="licenseOrigin" *ngIf="isCreateInitialApp">
      <mat-radio-button value="Initial">Initial</mat-radio-button>
      <mat-radio-button value="Reciprocal">Reciprocal</mat-radio-button>
    </mat-radio-group>

    <license-wizard
      *ngIf="profession"
      [profession]="profession"
      [hasEmeritus]="settings.hasEmeritus"
      [isCreateInitialApp]="isCreateInitialApp"
      [testOptions]="testOptions[profession]"
      (submit)="submit($event)"
    ></license-wizard>
  </div>
</lib-call-to-action>
