import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { map, catchError, publishLast, refCount, first } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { forIn } from 'lodash';
import { getApiUrl } from '../../constants/environment';

export const ieNoCacheOptions = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
  }),
};

export const defaultRequestOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
export function extractData(res: Response) {
  return res || {};
}

export class GenericApiService {
  constructor(
    private baseApiUrl: string,
    private http: HttpClient,
    protected notificationService: NotificationService
  ) {}

  get(path: string, options?: object): Observable<any> {
    let url = this.baseApiUrl.concat(path);
    options = options || ieNoCacheOptions;
    return this.http.get(url, options).pipe(
      map(extractData),
      catchError(err => this.handleError(err)),
      publishLast(),
      refCount(),
      first()
    );
  }

  getAsync<T>(path: string, showErrorSnack: boolean = true, options?: object): Promise<T> {
    return this.requestAsync<T>('GET', path, showErrorSnack, options || ieNoCacheOptions);
  }

  deleteAsync<T>(path: string, showErrorSnack: boolean = true, options?: object): Promise<T> {
    return this.requestAsync<T>('DELETE', path, showErrorSnack, options || ieNoCacheOptions);
  }

  patchAsync<T>(path: string, body: any, showErrorSnack: boolean = true, options?: object): Promise<T> {
    return this.requestWithBodyAsync<T>('PATCH', path, body, showErrorSnack, options);
  }

  putAsync<T>(path: string, body: any, showErrorSnack: boolean = true, options?: object): Promise<T> {
    return this.requestWithBodyAsync<T>('PUT', path, body, showErrorSnack, options);
  }

  postAsync<T>(path: string, body: any, showErrorSnack: boolean = true, options?: object): Promise<T> {
    return this.requestWithBodyAsync<T>('POST', path, body, showErrorSnack, options);
  }

  private async requestAsync<T>(
    method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH',
    path: string,
    showErrorSnack: boolean,
    options: object //if 'POST' | 'PUT' | 'PATCH' then options has data
  ): Promise<T> {
    try {
      const promise = firstValueFrom(this.http.request<T>(method, `${this.baseApiUrl}${path}`, options));
      return showErrorSnack ? await promise : promise;
    } catch (err) {
      this.snackError(err); //showErrorSnack must be true
      throw err;
    }
  }

  private async requestWithBodyAsync<T>(
    method: 'POST' | 'PUT' | 'PATCH',
    path: string,
    body: any,
    showErrorSnack: boolean,
    options?: object
  ): Promise<T> {
    return this.requestAsync(method, path, showErrorSnack, { ...(options ?? defaultRequestOptions), body });
  }

  patch(path: string, body: any = undefined, options?: object, handleError = null) {
    let url = this.baseApiUrl.concat(path);
    options = options || defaultRequestOptions;
    return this.http.patch(url, body, options).pipe(
      map(extractData),
      catchError(err => (handleError ? handleError(err) : this.handleError(err))),
      publishLast(),
      refCount(),
      first()
    );
  }

  post(path: string, body: any, options?: object, handleError = null) {
    let url = this.baseApiUrl.concat(path);
    options = options || defaultRequestOptions;
    return this.http.post(url, body, options).pipe(
      map(extractData),
      catchError(err => (handleError ? handleError(err) : this.handleError(err))),
      publishLast(),
      refCount(),
      first()
    );
  }

  put(path: string, body?: any, options?: object) {
    let url = this.baseApiUrl.concat(path);
    options = options || defaultRequestOptions;

    return this.http.put(url, body, options).pipe(
      map(extractData),
      catchError(err => this.handleError(err)),
      publishLast(),
      refCount(),
      first()
    );
  }

  delete(path: string, options?: object): Observable<any> {
    return this.http.delete(this.baseApiUrl.concat(path), options || defaultRequestOptions).pipe(
      map(extractData),
      catchError(err => this.handleError(err)),
      publishLast(),
      refCount(),
      first()
    );
  }

  queryStringify(obj, raw: boolean = false, ignoreEmpties: boolean = true) {
    let str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && (obj[p] || !ignoreEmpties)) {
        if (Array.isArray(obj[p])) {
          for (var i = 0; i < obj[p].length; i++) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][i]));
          }
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    let queryString = str.join('&');
    if (queryString && !raw) {
      return '?' + queryString;
    }
    return queryString;
  }

  snackError(errorResponse: HttpErrorResponse) {
    let errorLogMsg: string;
    let notificationMsg: string;
    let genericMsg: string = `An error occurred while submitting your request.
            Please try again later or contact customer support if this issue persists.`;

    if (errorResponse.error && errorResponse.status !== 0) {
      notificationMsg = ApiService.getParsedError(errorResponse.error);
      errorLogMsg = `${errorResponse.status} - ${errorResponse.statusText || ''} ${notificationMsg}`;
    } else {
      notificationMsg = genericMsg;
      errorLogMsg = errorResponse.message;
    }
    this.notificationService.notifyFail(notificationMsg);
    console.error(errorLogMsg);
  }

  handleError(errorResponse: HttpErrorResponse): Observable<any> {
    this.snackError(errorResponse);
    return throwError(() => errorResponse);
  }

  public static getParsedError(body) {
    let numberOfErrors: number = 0;
    let preMultipleErrorText: string = 'Multiple errors detected: ';
    let thisError: string = '';
    if (typeof body == 'string') {
      return body;
    }
    forIn(body, function (value, key) {
      if (body instanceof Array) {
        numberOfErrors++;
        thisError = thisError.concat(ApiService.replaceEndPunctuation(value));
      } else if (body.hasOwnProperty(key)) {
        numberOfErrors++;
        thisError = ApiService.replaceEndPunctuation(thisError.concat(value));
      }
    });

    if (numberOfErrors > 1) {
      thisError = preMultipleErrorText.concat(ApiService.replaceEndPunctuation(thisError));
    }

    let errorMessage = thisError.trim().slice(-1) === ';' ? thisError.trim().slice(0, -1) : thisError;

    return errorMessage;
  }

  private static replaceEndPunctuation(strValue: string) {
    if (strValue.trim().slice(-1) === '.') {
      strValue = strValue.slice(0, -1).concat('; ');
    }
    return strValue;
  }

  public static getErrorMsg(error, defaultMsg: string): string {
    let errorList = [];
    let paramErrors = error?.error;

    if (!paramErrors) {
      return defaultMsg;
    }

    for (let i = 0; i < Object.keys(paramErrors).length; i++) {
      let errs = paramErrors[Object.keys(paramErrors)[i]];

      errorList = errorList.concat(errs);
    }

    return errorList.length > 0 ? errorList.join('\n') : defaultMsg;
  }
}

//{providedIn: 'root'} would allow a singleton and no need to register in module
@Injectable()
export class ApiService extends GenericApiService {
  constructor(http: HttpClient, notificationService: NotificationService) {
    super(getApiUrl(), http, notificationService);
  }
}
