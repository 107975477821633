import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirmPointOfContactOption } from '../../models/firm-point-of-contact-option';
import { FirmPointOfContact } from '../../models/firm-point-of-contact';

@Injectable({
  providedIn: 'root',
})
export class FirmPointOfContactService {
  constructor(private api: ApiService) {}

  protected getPath(firmId) {
    return `firm/${firmId}/pointofcontact`;
  }

  get(firmId: string): Observable<FirmPointOfContact> {
    let path = this.getPath(firmId);
    return this.api.get(path).pipe(map(data => (data && data.id ? new FirmPointOfContact(data) : null)));
  }

  update(firmPointOfContact: FirmPointOfContact): Observable<FirmPointOfContact> {
    let path = this.getPath(firmPointOfContact.firmId);
    return this.api.put(path, firmPointOfContact).pipe(map(data => new FirmPointOfContact(data)));
  }

  getPointOfContactOptions(firmId: string): Observable<FirmPointOfContactOption[]> {
    let path = `${this.getPath(firmId)}/available`;
    return this.api.get(path).pipe(map(data => data.map(p => new FirmPointOfContactOption(p))));
  }

  getAllPointOfContacts(firmId: string): Promise<FirmPointOfContact[]> {
    let path = `${this.getPath(firmId)}/all`;
    return this.api.getAsync<FirmPointOfContact[]>(path).then(json => json.map(data => new FirmPointOfContact(data)));
  }

  getPointOfContact(firmId: string, personId: string): Observable<FirmPointOfContact> {
    let path = this.getPath(firmId).concat('/available/').concat(personId);
    return this.api.get(path).pipe(map(data => new FirmPointOfContact(data)));
  }

  add(pointOfContact: FirmPointOfContact): Observable<FirmPointOfContact> {
    let path = this.getPath(pointOfContact.firmId);
    return this.api.post(path, pointOfContact).pipe(map(data => new FirmPointOfContact(data.value)));
  }
}
