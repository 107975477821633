import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { PreferencesService } from '../../services/preferences.service';
import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';

@Component({
  providers: [
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
      } as MatPaginatorDefaultOptions,
    },
  ],
  selector: 'lib-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent implements AfterViewInit {
  @Input() count$: Observable<Number>;
  @Input() count: Number;
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() pageSize?: number;
  @Input() pageSizeSettingProp?: 'pageSize' | 'reportPageSize' = 'pageSize';

  ignoreStatus: boolean;

  constructor(private preferencesService: PreferencesService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.pageSize) {
        this.paginator.pageSize = this.pageSize;
        return;
      }
      this.paginator.pageSize = this.preferencesService.preferences[this.pageSizeSettingProp];
      this.paginator.pageSizeOptions = [5, 10, 25, 50, 100];
    });
  }

  page(pageEvent: PageEvent) {
    if (!this.pageSize && this.preferencesService.preferences[this.pageSizeSettingProp] != pageEvent.pageSize) {
      this.preferencesService.preferences[this.pageSizeSettingProp] = pageEvent.pageSize;
      this.preferencesService.save();
      pageEvent.pageIndex = 0;
      this.resetPager(1);
    }
    this.pageChanged.emit(pageEvent);
  }

  resetPager(pageNumber: number) {
    // https://github.com/angular/components/issues/6220
    this.paginator.pageIndex = pageNumber - 1;
  }
}
