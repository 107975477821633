import { Component, Input, HostBinding } from '@angular/core';

export type ColorVariant = 'error' | 'success' | 'warning' | 'pending' | 'submitted' | 'inactive';

@Component({
  selector: 'lib-pill',
  template: '<ng-content></ng-content>',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Input() variant: ColorVariant;
  @HostBinding('class') get getClass() {
    if (this.variant) {
      return `on-${this.variant}-color on-${this.variant}-border px-xs py-xxs`;
    }
    console.error('pill component requires a variant to display border/background color');
    return 'px-xs py-xxs';
  }
}
