import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressLookups, CountryIds } from '../../models/address';
import { MatSelectChange } from '@angular/material/select';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-edit-address-new',
  templateUrl: './edit-address-new.component.html',
  styleUrls: ['./edit-address-new.component.scss'],
})
export class EditAddressNewComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() line1Label: string;
  @Input() line2Label: string;
  @Input() addressLookups: AddressLookups;
  @Input() hideCounty: boolean = false;
  @Input() hideValidation: boolean = false;

  zipcodePattern = /.*/;
  showState = true;
  showProvince = false;
  showRegion = false;
  firstLine: string;
  secondLine: string;

  constructor(public settings: TenantSettings) {}

  ngOnInit(): void {
    this.setZipCodeForCountry(this.formGroup.controls.countryId.value);
    this.firstLine = this.line1Label ?? 'First Line';
    this.secondLine = this.line2Label ?? 'Second Line';
  }

  setZipCodeForCountry(countryId) {
    if (countryId == CountryIds.USA) {
      this.zipcodePattern = /\d{5}(\-\d{4})?/;
      this.showState = true;
      this.showProvince = false;
      this.showRegion = false;
      return;
    }
    this.showState = false;
    this.zipcodePattern = /.*/;
    if (countryId == CountryIds.CAN) {
      this.showProvince = true;
      this.showRegion = false;
    } else {
      this.showRegion = true;
      this.showProvince = false;
    }
  }

  countryChange(event: MatSelectChange) {
    this.setZipCodeForCountry(event.value);
  }
}
