import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LibModule } from 'projects/common/src/lib/lib.module';

import { PersonAvailableApplicationsResolve } from './resolves/person-available-applications.resolve';
import { FirmAvailableApplicationsResolve } from './resolves/firm-available-applications.resolve';
import { InstructionsLA } from './components/submitted-info/instructions-la';
import { CheckExampleDialogue } from '../firm/application/submitted/check-example/check-example.dialogue';
import { CheckExampleDialogueDirective } from '../firm/application/submitted/check-example/check-example.dialogue.directive';
import { FirmResolve } from './resolves/firm.resolve';
import { RouterOutletComponent } from './components/router-outlet.component';
import { SelectFirmTypeComponent } from './components/select-firm-type/select-firm-type.component';
import { InitialWarningLA } from './components/select-firm-type/initial-warning-la';
import {
  ApplicationStepDirective,
  ApplicationStepPlaceholderComponent,
} from './components/application-base/application-step-placeholder.component';
import { ApplicationChromeComponent } from './components/application-base/application-chrome.component';
import { SetupFirmApplicationComponent } from '../portal/setup-firm-application/setup-firm-application.component';
import { LicenseWizardComponent } from '../portal/license-wizard/license-wizard.component';

const components = [
  ApplicationStepDirective,
  ApplicationStepPlaceholderComponent,
  CheckExampleDialogue,
  CheckExampleDialogueDirective,
  ApplicationChromeComponent,
  InitialWarningLA,
  InstructionsLA,
  RouterOutletComponent,
  SelectFirmTypeComponent,
  SetupFirmApplicationComponent,
  LicenseWizardComponent,
];

@NgModule({
  imports: [CommonModule, LibModule],
  declarations: [...components],
  exports: [...components],
  providers: [PersonAvailableApplicationsResolve, FirmAvailableApplicationsResolve, FirmResolve],
  entryComponents: [CheckExampleDialogue],
})
export class SharedApplyModule {}
