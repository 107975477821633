import { Component, OnInit, Input } from '@angular/core';
import { NoteService } from '../../services/api/note.service';
import { BehaviorSubject } from 'rxjs';
import { Note } from '../../models/note';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'lib-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input() subjectId: string;
  @Input() scope: string;
  notes$ = new BehaviorSubject<Note[]>(null);
  constructor(
    private noteService: NoteService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getNotes();
  }

  getNotes() {
    this.noteService.getForSubject(this.subjectId, this.scope).subscribe(notes => this.notes$.next(notes));
  }

  add(comment) {
    this.notificationService.notifyWait('Adding comment');
    this.noteService
      .add(
        new Note({
          text: comment,
          subjectId: this.subjectId,
          scope: this.scope,
        })
      )
      .subscribe(_ => {
        this.notificationService.notifySuccess('Comment added');
        this.getNotes();
      });
  }

  edit(note, newText) {
    note.text = newText;
    this.notificationService.notifyWait('Updating comment');
    this.noteService.update(note).subscribe(_ => {
      this.notificationService.notifySuccess('Comment updated');
      this.getNotes();
    });
  }

  delete(note) {
    this.notificationService.notifyWait('Deleting comment');
    this.noteService.delete(note).subscribe(_ => {
      this.notificationService.notifySuccess('Comment deleted');
      this.getNotes();
    });
  }

  isUserAuthor(note: Note) {
    return note.authorId == this.authService.claims.actorId;
  }
}
