import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ColorVariant } from '../pill/pill.component';

@Component({
  selector: 'ls-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @HostBinding('class') get getClass() {
    return `on-${this.variant || 'success'}-color type-p-2`;
  }
  @Input() variant: ColorVariant = 'success';
  @Output() actionClicked = new EventEmitter();
  @Input() actionText: string;

  actionClick() {
    this.actionClicked.emit();
  }
}
