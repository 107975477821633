import { Pipe, PipeTransform } from '@angular/core';
import { Address, AddressLookups, getStateName } from '../models/address';

@Pipe({ name: 'cityStateZip' })
export class CityStateZipPipe implements PipeTransform {
  transform(address: Address, lookups: AddressLookups): string {
    if (!address) {
      return '';
    }

    return `${address.city}, ${getStateName(address, lookups)} ${address.zip}`;
  }
}
