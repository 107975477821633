import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { Observable, of } from 'rxjs';
import { FormComponent } from '../../form.component';
import { S3DocumentSearch } from '../../../models/s3/s3-document-search';

@Component({
  selector: 'app-person-lawful-presence-proof',
  templateUrl: './person-lawful-presence-proof.component.html',
  styleUrls: ['./person-lawful-presence-proof.component.scss'],
})
export class PersonLawfulPresenceProofComponent extends FormComponent {
  applications$: Observable<ArchitectApplication[]>;
  docSearch: S3DocumentSearch;
  editMode = false;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.applications$ = of(this.route.snapshot.data.applications as ArchitectApplication[]);

    this.unsubOnDestroy = this.route.params.subscribe(d => {
      this.docSearch = new S3DocumentSearch({
        aggregateRootId: d.personId,
        documentType: 'LawfulPresence',
      });
    });
  }

  canDeleteDoc(doc: S3DocumentSearch): boolean {
    // when the subject ID and aggregate root ID match the document is at the root of the bucket
    // and not part of an application/license
    return !doc.subjectId || doc.subjectId == doc.aggregateRootId;
  }
}
