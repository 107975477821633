import { Profession } from './profession';

export type JurisdictionArgsType = 'LA' | 'ND' | 'AL' | 'NE' | 'NEBOG' | 'DEMO';
export type StateCodes = Exclude<JurisdictionArgsType, 'DEMO'> | 'DC'; //use JurisdictionArgsType, but swap DEMO for DC

export const AllJurisdictions: JurisdictionArgsType[] = ['AL', 'DEMO', 'LA', 'ND', 'NE', 'NEBOG'];
export interface Affidavit {
  defaultContent: string;
  [key: string]: string;
}
export interface CommonLinks {
  publicSite: string;
  publicUrl: string;
  registrationInfo: string;
  rules: string;
  centuryCode: string;
  lapels: string;
  architectLookup: string;
  firmLookup: string;
}

export interface Address {
  address1: string;
  address2: string;
  cityStateZip: string;
}

export class JurisdictionConstants {
  legal: string;
  acronym: string; //use settings.boardAbbreviation
  commonLinks: CommonLinks;
  workAddressSuffix: string;
  homeAddressSuffix: string;
  manualProfessions: Profession[];
  architectTabName: string;
  ceRule: any;
  timezoneAbbreviation: string;
  checkAddress?: Address;
  historyLogEventTitleArchitectReplacement?: string;
  firmApplicationSettings?: {
    askForStateInFirmSupervisor?: boolean;
    incorporationDateLabel?: string;
    maximumSupervisors?: number;
    showOldBoardOfDirectors?: boolean;
    directorsUploadCustomText?: string;
  };
  applicationStepLabels?: {
    //used in step service to get step labels in application
    personal?: string;
    lawfulPresence?: string;
    demographic?: string;
    eligibility?: string;
    firm?: string;
    militaryInfo?: string;
    workInfo?: string;
    owners?: string;
    personalIdentification?: string;
    supervisors?: string;
    directors?: string;
    continuingEducation?: string;
    stateDocuments?: string;
    disclosureQuestions?: string;
    confirmation?: string;
    payment?: string;
    submitted?: string;
    references?: string;
    affidavitUpload?: string;
  };
  applicationStepContent: {
    disclosureQuestions?: {
      subtitle: string;
    };
    militaryInfo?: {
      question: string;
    };
    payment?: PaymentContent;
    lawfulPresence?: LawfulPresenceContent;
    certification?: CertificationContent;
    submitted?: SubmittedContent;
    personalIdentification?: {
      headerSubtext: string;
      includeBullets: string[];
    };
    businessInformationContent?: BusinessInformationContent;
  };
  professionalReferences?: {
    text: string;
    cardTitle: string;
    dataPoint: string;
  };
}

export type SubmittedContent = {
  headerSubtext?: string;
  headerSubtextContent?: string;
  questions?: string;
};

export type CertificationContent = {
  headerSubtext?: string;
  headerSubtextContent?: string;
  attestationsLabel?: string;
};

export type PaymentContent = {
  headerSubtextContent?: string;
  headerSubtextContent_architectRenewal?: string;
  byClickingSubmitAch?: string;
  byClickingSubmitNotAch?: string;
};

export type LawfulPresenceContent = {
  headerSubtextContent?: string;
  hideAcceptableDocuments?: boolean;
  isLawfullyPresent?: string;
  nonCitizenUploadText?: string;
};

export type BusinessInformationContent = {
  supervisingProfessionalSectionHeader?: string;
  supervisingProfessionalDescription?: string;
  supervisingProfessionalArchSelectLabel?: string;
};
