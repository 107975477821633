import { Injectable } from '@angular/core';
import { Person } from '../../models/person';
import { ApiService } from './api.service';
import { Observable, throwError, of, Subscription, lastValueFrom } from 'rxjs';
import { map, filter, first, tap, flatMap, skip } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Claims } from '../../models/auth/claims';
import { PreferencesService } from '../preferences.service';
import { MatDialog } from '@angular/material/dialog';
import { ImpersonateDialog } from '../../components/impersonate-dialog/impersonate-dialog';
import { ImpersonateDialogResponse } from '../../components/impersonate-dialog/impersonate-dialog-response';
import { EnvironmentSettings } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private path: string;
  public hasCheckedClaims: boolean;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private preferencesService: PreferencesService,
    private settings: EnvironmentSettings,
    private impersonationDialog: MatDialog
  ) {
    this.path = 'account/';
  }

  add(email: string, password?: string, createDemoUser?: boolean, e2eTesting?: boolean): Observable<Person> {
    return this.api
      .post(this.path, { email, password, createDemoUser, e2eTesting }, null, (resp): Observable<any> => {
        if (resp.error && resp.error.email && resp.error.email.length) {
          if (resp.error.email[0].indexOf('not have') > -1) {
            return throwError(new Error('Email not found'));
          } else if (resp.error.email[0].indexOf('already') > -1) {
            return throwError(new Error('Account already exists'));
          } else {
            return this.api.handleError(resp);
          }
        } else {
          return this.api.handleError(resp);
        }
      })
      .pipe(map(data => new Person(data)));
  }

  logLogin() {
    this.auth.isAuthenticated$
      .pipe(
        filter(a => !!a),
        first()
      )
      .subscribe(_ => this.api.patch(this.path, {}).subscribe());
  }

  portalClaimsCheck(ignoreCache = false): Promise<boolean> {
    return lastValueFrom(this.claimsCheck('portal', ignoreCache));
  }

  claimsCheck(type: 'staff' | 'portal', ignoreCache = false): Observable<boolean> {
    if (!ignoreCache && this.hasCheckedClaims) {
      return of(true);
    }
    return this.api.post(this.path.concat(type), {}).pipe(
      tap(d => (this.hasCheckedClaims = true)),
      map(d => !d.claimsWereRefreshed)
    );
  }

  portalClaimsFullRefresh(): Promise<Claims> {
    return lastValueFrom(this.claimsFullRefresh('portal'));
  }

  private claimsFullRefresh(type: 'staff' | 'portal'): Observable<Claims> {
    return this.claimsCheck(type, true).pipe(
      tap(claimsSame => (claimsSame ? false : this.auth.renewToken())),
      flatMap(claimsSame => (claimsSame ? of(true) : this.auth.isAuthenticated$.pipe(skip(1), first()))),
      map(_ => this.auth.claims)
    );
  }

  staffImpersonate(personId) {
    let path = this.path.concat('impersonate');
    this.api.patch(path, { personId: personId }).subscribe(_ => this.openImpersonation(this.settings.licensingPortalUrl));
  }

  staffImpersonateFirmPointOfContact(firmId) {
    let path = this.path.concat('impersonatefirmpointofcontact');
    this.api.patch(path, { firmId: firmId }).subscribe(_ => this.openImpersonation(this.settings.licensingPortalUrl));
  }

  openImpersonation(portalUrl, prompt = true) {
    if (!prompt || !this.preferencesService.preferences.impersonationPrompt) {
      window.open(portalUrl + 'impersonate', 'portal');
      return;
    }
    const dialogRef = this.impersonationDialog.open(ImpersonateDialog);
    let subscription: Subscription;
    subscription = dialogRef.afterClosed().subscribe((result: ImpersonateDialogResponse) => {
      subscription.unsubscribe();
      if (!result || !result.continue) {
        return;
      }
      if (result.discontinuePrompt) {
        this.preferencesService.preferences.impersonationPrompt = false;
        this.preferencesService.save();
      }
      this.openImpersonation(portalUrl, (prompt = false));
    });
  }
}
