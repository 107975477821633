import { JurisdictionConstants } from './jurisdiction-constants';

export const ALConstants: JurisdictionConstants = {
  legal:
    'The State of Alabama Board for Registration of Architects requires completion of all requirements for a record with NCARB. This includes requirements for examination, education, work experience and references. Once complete, you would have your record submitted to us requesting that Alabama be your initial state of registration.',
  acronym: 'BOA',
  workAddressSuffix: '(Public)',
  homeAddressSuffix: '(Private)',
  commonLinks: {
    publicSite: 'https://boa.alabama.gov/',
    publicUrl: 'boa.alabama.gov',
    registrationInfo: 'https://boa.alabama.gov/applicants/',
    rules: 'https://boa.alabama.gov/law-and-rules/',
    centuryCode: null,
    lapels: '',
    architectLookup: 'https://licensing.boa.alabama.gov/alabama-roster/individual.html',
    firmLookup: 'https://licensing.boa.alabama.gov/alabama-roster/firm.html',
  },
  manualProfessions: [],
  architectTabName: 'Architects',
  ceRule: {
    section: '§1315.D',
    exemption: '§1315.B',
  },
  timezoneAbbreviation: 'ET',
  firmApplicationSettings: {
    askForStateInFirmSupervisor: false,
    incorporationDateLabel: 'Date of Incorporation in Alabama',
  },
  applicationStepLabels: {
    personal: 'Profile',
    lawfulPresence: 'Proof of Citizenship',
    demographic: 'Demographic',
    militaryInfo: 'Military Info',
    personalIdentification: 'Image',
    continuingEducation: 'CE Step',
  },
  applicationStepContent: {
    disclosureQuestions: {
      subtitle:
        'Please answer the following questions truthfully. If you answer “yes” to any of the following questions, you are required to furnish a written explanation, including date, place, reason, and disposition of the matter. Supporting court or disciplinary action documents should also be provided. A “yes” answer does not necessarily mean you will not be granted a license. Failure to furnish complete documentation may result in denial or delay in the processing of your application. Failure to disclose accurate information on your application could result in your application being denied for a material misstatement or a disciplinary action from the Board.',
    },
    militaryInfo: {
      question:
        'Are you or your spouse employed by the Armed Forces, United States Department of Defense, or National Aeronautics Space Administration?',
    },
    payment: {
      headerSubtextContent: ' ', // Added blank value to the headerSubtextContent to avoid alabama payment legal language issue
    },
    submitted: {
      headerSubtextContent:
        'Thank you for submitting your application.  Please take note of your confirmation number. You will receive an email confirmation within the next 24 hours detailing the specifics of your application.',
      questions:
        'Should you have any questions in the interim, please contact <a href="mailto:licensing@boa.alabama.gov">licensing@boa.alabama.gov</a>. Again, thank you.',
    },
    lawfulPresence: {
      headerSubtextContent: `As a state agency providing public benefit, the Alabama Board of Architects must comply with
      <a href="https://www.asbpa.alabama.gov/immigration.aspx">Ala. Code §31-13-7</a> verifying the citizenship or lawful presence
      of all architecture license holders. Please select your citizenship status and provide proper documentation.`,
      isLawfullyPresent: `Under penalty of perjury pursuant to Ala. Code §13A-10-102, I hereby declare I am citizen or person lawfully present in the United States of America.`,
    },
    personalIdentification: {
      headerSubtext: 'A recent photo is required for identification purposes only.',
      includeBullets: [
        'Proper lighting',
        'Only face and shoulders',
        'Photo from the last six months',
        'Any shape or crop of the photo',
      ],
    },
  },
};
