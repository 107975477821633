import { Component, Input } from '@angular/core';
import { Order } from 'projects/common/src/lib/models/order';

@Component({
  selector: 'lib-review-payment',
  templateUrl: './review-payment.component.html',
  styleUrls: ['./review-payment.component.scss'],
})
export class ReviewPaymentComponent {
  @Input() order: Order;
}
