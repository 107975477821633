import { Pipe, PipeTransform, Injector, Type } from '@angular/core';

@Pipe({
  name: 'dynamic',
})
export class DynamicPipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, pipeClass: Type<any>, args: any): any {
    if (!pipeClass) {
      return value;
    }
    const injector = Injector.create({
      name: 'DynamicPipe',
      parent: this.injector,
      providers: [{ provide: pipeClass }],
    });
    const pipe = injector.get(pipeClass);
    return pipe.transform(value, args);
  }
}
