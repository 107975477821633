import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataWithTemplate } from './dialog-data.model';
import { touchFormGroupErrors } from '../../utility/form-utils';

@Component({
  selector: 'dialog-lib',
  templateUrl: 'dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent<T> {
  originalData: any;

  okayColor: 'warn' | 'primary' = 'primary';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    public data: DialogDataWithTemplate
  ) {
    if (data.formGroup) {
      this.originalData = data.formGroup.value;
    }
    if (data.isOkayWarnColor) {
      this.okayColor = 'warn';
    }
  }

  onCancelClick() {
    this.dialogRef.close();
    if (this.data.formGroup) {
      //avoid the form momentarily updating if you click cancel
      setTimeout(() => this.data.formGroup.setValue(this.originalData), 20);
    }
  }

  onOkayClick() {
    if (this.data.formGroup?.invalid) {
      touchFormGroupErrors(this.data.formGroup);
      return;
    }
    //console.log(this.inlineTemplate);
    this.dialogRef.close(true);
  }
}
