import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FirmRoleHolder } from '../../models/firm-role-holder';
import { ActivatedRoute } from '@angular/router';
import { FirmRoleHolderGroups, RoleInfo } from '../../models/firm-role-holder-groups';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'firm-supervisors-list',
  templateUrl: './firm-supervisors-list.component.html',
  styleUrls: ['./firm-supervisors-list.component.scss'],
})
export class FirmSupervisorsListComponent extends BaseComponent implements OnInit {
  @HostBinding('class.opt-in') optin = true;
  @Input() roles$: Observable<FirmRoleHolder[]>;
  @Input() columns: string[] = ['licenseNumber', 'name', 'role', 'email'];
  @Input() linkedName: boolean = true;
  currentRoleHolders$ = new ReplaySubject<FirmRoleHolder[]>(1);
  pastRoleHolders$ = new ReplaySubject<FirmRoleHolder[]>(1);
  activeRolesByPersonId: Record<string, RoleInfo[]>;

  constructor(protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.unsubOnDestroy = this.roles$.subscribe(roles => {
      const group = new FirmRoleHolderGroups(roles);

      this.activeRolesByPersonId = group.activeRolesByPersonId;

      this.currentRoleHolders$.next(group.currentRoleHolders);
      this.pastRoleHolders$.next(group.pastRoleHolders);
    });
  }
}
