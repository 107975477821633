import { Directive } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CheckExampleDialogue } from './check-example.dialogue';

@Directive({
  selector: 'app-check-example, [app-check-example]',
  exportAs: 'modal',
})
export class CheckExampleDialogueDirective {
  dialogRef: MatDialogRef<CheckExampleDialogue>;
  constructor(private dialogue: MatDialog) {}

  open() {
    this.dialogRef = this.dialogue.open(CheckExampleDialogue);
    return false;
  }
}
