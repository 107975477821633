import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ConfirmDialogueDirective } from '../confirm-dialogue/confirm.dialogue.directive';
import { ReloadService } from '../../services/api/reload.service';

@Component({
  selector: 'reload-modal',
  template: `
    <lib-confirm
      #reloadModal="confirm"
      yesText="Okay"
      prompt="A new version of the site is available!"
      [template]="newVersionTemplate"
      [optIn]="true"
      [disableClose]="true"
      (yes)="reload()"
    >
    </lib-confirm>

    <ng-template #newVersionTemplate>
      <ls-banner variant="inactive">
        Press OK to refresh. If this message appears again; try holding the 'Shift' key as you press the 'F5' key.
      </ls-banner>
    </ng-template>
  `,
})
export class ReloadModalComponent implements AfterViewInit {
  constructor(private reloadService: ReloadService) {}
  ngAfterViewInit(): void {
    //gets the reference to the modal and passes it to singleton reloadService
    //then CustomErrorHandler uses the reloadService to open the modal
    this.reloadService.setModal(this.reloadModal);
    //this.reloadModal.open(); //uncomment for testing
  }

  reload() {
    window.location.reload();
  }

  @ViewChild(ConfirmDialogueDirective)
  reloadModal: ConfirmDialogueDirective;
}
