import { LookupItem } from '../search/lookup-item';
import { UpdatableModel } from '../updatable-model';

export class ApplicationSearchView extends UpdatableModel<ApplicationSearchView> implements LookupItem {
  get lookupId() {
    return this.personId || this.firmId;
  }

  get lookupStatus() {
    return this.status;
  }

  get lookupName() {
    return this.name;
  }

  get lookupInfo() {
    return [this.email, this.address, this.primaryPhoneNumber].filter(_ => !!_).join(' | ');
  }

  name: string;
  nameUnaccented: string;
  nameLastFirst: string;
  personId: string;
  email: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  ncarbNumber: number;
  clarbNumber: number;
  firmName: string;
  address: string;
  licenseNumber: string;
  licenseNumeric: number;
  profession: string;
  residency: string;
  applicationId: string;
  submittedOn: Date;
  createdOn: Date;
  approvedOn: Date;
  firmId: string;
  status: string;
  statusId: number;
  licenseType: string;
  firmType: string;
  orderNumber: number;
  type: string;
  paidOn: Date;
}
