import { Injectable } from '@angular/core';
import { FirmApplicationFull } from '../../../models/applications/firm-application-full';
import {
  Confirmations,
  CustomLabels,
  JurisdictionConfirmationService,
  LegendWithNote,
} from './jurisdiction-confirmation-service';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { ArchitectApplication } from '../../../models/applications/architect-application';

@Injectable({
  providedIn: 'root',
})
export class ALConfirmationService extends JurisdictionConfirmationService {
  attestations = [
    'I solemnly swear or affirm that the statements made herein are accurate, complete, and true to the best of my knowledge and belief.',
    'I understand and agree that by checking this box, I am providing an electronic signature that has the same legal effect as a written signature pursuant to Ala. Code §§ 8-1A-2 and 8-1A-7.',
  ];

  commonLaws = ['<a href="https://boa.alabama.gov/law-and-rules/">Alabama BOA Laws and Regulations</a>'];

  getArchitectConfirmations(application: ArchitectApplication): Confirmations {
    const laws = [];
    const attestations = [];

    switch (application.applicationVariant) {
      case 'ArchitectInitial':
      case 'ArchitectReciprocal':
      case 'ArchitectReinstatement':
        attestations.push(...this.attestations);
        attestations.push(
          "I will not represent myself as an architect or offer to perform architectural services in this jurisdiction until this application is approved and an architect's license has been granted by this board."
        );
        attestations.push(
          'I acknowledge that making a false statement to the above questions may subject me to disciplinary action including, but not limited to, immediate revocation or suspension of my registration.'
        );

        return {
          laws: this.commonLaws,
          attestations: attestations,
        };
      case 'ArchitectRenewal':
      case 'ArchitectEmeritusRenewal':
        return {
          laws: laws,
          attestations: this.attestations,
        };
      default:
        return {
          laws: this.commonLaws,
          attestations: this.attestations,
        };
    }
  }

  getCustomLabels(): CustomLabels {
    const cl = super.getCustomLabels();
    cl.moralCharacterApplication = {
      title: 'Disclosure Questions',
      subtitle: `Please answer the following questions truthfully. If you answer “yes” to any of the following questions, you are required to furnish a written explanation, including date, place, reason, and disposition of the matter.  Supporting court or disciplinary action documents should also be provided.  A “yes” answer does not necessarily mean you will not be granted a license.  Failure to furnish complete documentation may result in denial or delay in the processing of your application.  Failure to disclose accurate information on your application could result in your application being denied for a material misstatement or a disciplinary action from the Board.`,
    };
    return cl;
  }

  getShareHolderNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and own the number of shares listed
    (no percentages) and that the following number of total shares is correct. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Alabama. R.S. 12:3456(A)</a>
    which requires that a majority stock in a professional architectural corporation must be owned by the individually licensed ${this.settings.stateName} architects.`;
  }

  getBoardOfDirectorsNote() {
    return `We hereby certify that the following listed architects are licensed in the state of ${this.settings.stateName} and serve as Directors of the corporation. We further certify that our firm complies with
    <a target="_blank" href="${this.constants.commonLinks.rules}">Alabama. R.S. 12:3456</a>
    which requires that a majority of the board of directors, if more than two, shall be licensed ${this.settings.stateName} architects or, if there are less than three directors, at least one must be a licensed ${this.settings.stateName} architect.`;
  }

  getFirmConfirmations(application: FirmApplicationFull): Confirmations {
    const laws = [];

    if (application.applicationVariant == 'FirmInitial') laws.push(...this.commonLaws);

    return {
      laws,
      attestations: this.attestations,
    };
  }

  getSupervisorNotes(application: FirmApplication): LegendWithNote[] {
    return [
      {
        legend: null,
        note: `By identifying this supervising professional, you are also attesting that they are a voting stockholder/stakeholder in the company and that they serve as an officer, director, partner or member of the firm.`,
      },
      {
        legend: 'Designated Supervising Professional Architect(s)',
        note: `The following architects are hereby designated as the supervising professional architects according to
          <a href="${this.constants.commonLinks.rules}" target="_blank">Alabama R.S. 12:3456</a>:`,
      },
    ];
  }
}
