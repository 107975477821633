<div class="icon-and-text">
  <mat-icon *ngIf="variant == 'inactive'">info</mat-icon>
  <mat-icon *ngIf="variant == 'success' || !variant">thumb_up</mat-icon>
  <mat-icon *ngIf="variant == 'error'">thumb_down</mat-icon>
  <ls-p level="2" class="message">
    <ng-content></ng-content>
  </ls-p>
</div>
<button *ngIf="actionText" mat-flat-button color="primary" type="button" (click)="actionClick()">
  {{ actionText }}
  <mat-icon>launch</mat-icon>
</button>
