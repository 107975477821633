import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'address-status',
  templateUrl: './address-status.component.html',
  styleUrls: ['./address-status.component.scss'],
})
export class AddressStatusComponent {
  @Input() formGroup: FormGroup;
}
