import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-portal-actions',
  templateUrl: './portal-actions.component.html',
  styleUrls: ['./portal-actions.component.scss'],
})
export class PortalActionsComponent {
  @Input() canHavePOC: boolean;
  actions = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private settings: TenantSettings
  ) {}

  ngOnInit() {
    if (this.settings.hasArchPocs && this.canHavePOC) {
      this.actions.push({ text: 'Set Point of Contact', value: 'POC' });
    }
  }

  onAction(value) {
    switch (value) {
      case 'POC': {
        this.router.navigate(['archpointofcontact/assign'], { relativeTo: this.route });
        break;
      }
    }
  }
}
