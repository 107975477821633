import { Component, Input } from '@angular/core';
import { MilitaryInformation } from 'projects/common/src/lib/models/military-information';

@Component({
  selector: 'lib-military-info-review',
  templateUrl: './military-info-review.component.html',
  // borrows from moral-character style
  styleUrls: ['../../moral-character-review/moral-character-review.component.scss'],
})
export class MilitaryInfoReviewComponent {
  @Input() militaryInformation: MilitaryInformation;
}
