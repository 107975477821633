import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { LookupItem } from '../../models/search/lookup-item';
import { skip, debounceTime, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';

const searchBarDebounce = 5000;

@Component({
  selector: 'lib-search-bar-lookup',
  templateUrl: './search-bar-lookup.component.html',
  styleUrls: ['./search-bar-lookup.component.scss'],
})
export class SearchBarLookupComponent extends BaseComponent implements AfterViewInit {
  @HostBinding('class.search-bar') seachBar: boolean = true;
  @Input() search: (search: string) => Observable<any[]>;
  @Output() select = new EventEmitter<LookupItem>();

  @ViewChild(MatInput) matInput: MatInput;
  @ViewChild(AutocompleteComponent) autocomplete: AutocompleteComponent;
  @Input() placeholder: String;
  @Output() go = new EventEmitter<string>();
  text = '';
  text$ = new BehaviorSubject<string>('');
  enter$ = new BehaviorSubject<string>('');

  keepCurrentText;
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.placeholder = this.placeholder || 'Enter a Name, Email, License Number, or NCARB Number';
    this.unsubOnDestroy = this.enter$.subscribe(_ => this.debounceSubscribe());
  }

  ngAfterViewInit(): void {
    // https://indepth.dev/posts/1001/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error
    setTimeout(() => {
      // need a closure bc displayWith invokes function without this
      let matInput = this.matInput;
      this.keepCurrentText = () => matInput.value;
    });

    // https://stackoverflow.com/a/59291543/507421
    this.changeDetectorRef.detectChanges();
  }

  onSelect(selectedEvent: MatAutocompleteSelectedEvent) {
    if (selectedEvent.option.value.lookupId) {
      this.select.emit(selectedEvent.option.value);
    }
  }

  debounceSubscribe() {
    this.unsubOnDestroy = this.text$
      .pipe(skip(1), debounceTime(searchBarDebounce), takeUntil(this.enter$.pipe(skip(1))))
      .subscribe(s => this.onDebounce(s));
  }

  onDebounce(search) {
    this.onGo(search);
  }

  onClear() {
    this.matInput.value = '';
    this.onGo('');
  }

  onGo(search) {
    this.go.emit(search);
    this.enter$.next(search);
    this.autocomplete.typed.next('');
  }

  onType(search) {
    this.text$.next(search);
  }
}
