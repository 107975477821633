import { Profession } from '../constants/jurisdiction/profession';

export function numberLabel(count: number, singular: string, plural: string = null) {
  plural = plural || singular + 's';
  return count + ' ' + (count == 1 ? singular : plural);
}

export function spaceCapCase(str: string): string {
  if (!str) {
    return str;
  }
  if (!str.replace) {
    return str; //console.error(str); //was seeing a bool passed in in some circumstances
  }
  return str
    .replace(/([A-Z])(?=[^A-Z])/g, ' $1')
    .replace(/([^A-Za-z ]) (?=[A-Z])/g, '$1')
    .trim()
    .replace(/([0-9])([A-Z])/g, '$1 $2')
    .replace(/([^0-9])([0-9])/g, '$1 $2');
}

const profSubjectMap: Record<Profession, string> = {
  [Profession.Architect]: 'Architecture',
  [Profession.LandscapeArchitect]: 'Landscape Architecture',
  [Profession.Geologist]: 'Geologist',
};

export function profSubject(profession: Profession): string {
  return profSubjectMap[profession];
}

//format('foo {0}', 'bar') will return 'foo bar';
export function format(format: string, ...args): string {
  return format.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

export function isNullOrWhitespace(str: string): boolean {
  return str ? str.match(/^ *$/) !== null : true;
}
