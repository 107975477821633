import { Component, Input } from '@angular/core';
import { S3DocumentReference } from '../../models/s3/s3-document-reference';
import { S3Service } from '../../services/api/s3.service';

@Component({
  selector: 'lib-upload-viewer',
  templateUrl: './upload-viewer.component.html',
  styleUrls: ['./upload-viewer.component.scss'],
})
export class UploadViewerComponent {
  @Input() docList: S3DocumentReference[];

  @Input() filesLabel = 'Documents';
  columns = ['file', 'expand'];
  constructor(private s3Service: S3Service) {}

  async download(docRef: S3DocumentReference) {
    await this.s3Service.open(docRef); //could also use the docRef.link, but calling the API will get refreshed link
    return false;
  }

  hideHeader() {
    return this.docList?.length ? 'hidden' : '';
  }
}
