import { Component, OnInit, Output, EventEmitter, Input, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { Profession } from 'projects/common/src/lib/constants/jurisdiction/profession';
import { ArchitectApplicationService, TestInfo } from 'projects/common/src/lib/services/api/architect-application.service';
import { WizardSetupApplicationArgs } from '../license-wizard/license-wizard.component';

@Component({
  selector: 'app-setup-application',
  templateUrl: './setup-application.component.html',
  styleUrls: ['./setup-application.component.scss'],
})
export class SetupApplicationComponent implements OnInit {
  @HostBinding('class.opt-in') optin = true;
  @Output() change = new EventEmitter();
  show: boolean;
  profession: Profession;
  professions: Profession[] = [];
  testOptions: Partial<Record<Profession, TestInfo>> = {};
  isCreateInitialApp: boolean;
  canAddInitial: boolean;
  licenseOrigin: string = 'Initial';

  constructor(
    private architectApplicationService: ArchitectApplicationService,
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {}

  async ngOnInit() {
    this.show = this.settings.enableEasyTesting;
    await this.getTestOptions();
  }

  async getTestOptions() {
    this.testOptions = await this.architectApplicationService.getTestOptions(this.route.snapshot.params.personId);
    this.professions = Object.keys(this.testOptions) as Array<Profession>;
    this.isCreateInitialApp = false;
    if (this.professions.length === 1) {
      this.profession = this.professions[0];
      this.onProfessionChanged();
    }
  }

  onProfessionChanged() {
    this.canAddInitial = this.testOptions[this.profession].canAddInitial;
    if (!this.canAddInitial) {
      this.isCreateInitialApp = false;
    }
  }

  async submit($event: WizardSetupApplicationArgs | true) {
    const payload =
      $event === true
        ? {
            profession: this.profession,
            licenseOrigin: this.licenseOrigin,
          }
        : {
            ...$event,
            profession: this.profession,
          };

    await this.architectApplicationService.setup(this.route.snapshot.params.personId, payload);
    this.change.emit();
    await this.getTestOptions();
  }
}
