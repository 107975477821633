<h2 mat-dialog-title class="error">You are about to impersonate this user.</h2>
<div mat-dialog-content>
  <p>
    <strong>Please note:</strong> The user will receive any automated messages that correspond with the actions you take during
    the impersonation session.
  </p>
  <p>
    <strong>Would you like to continue?</strong>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="yesResponse.discontinuePrompt"> Don't show me this message again. </mat-checkbox>
  </p>
</div>
<p mat-dialog-actions class="reverse">
  <button mat-button class="large" type="submit" [mat-dialog-close]="yesResponse" color="primary">Yes, continue</button>
  <button mat-button class="large" type="button" [mat-dialog-close]="noResponse" color="accent">No, go back</button>
</p>
