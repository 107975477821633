import { UpdatableModel } from './updatable-model';
import { Name } from './name';
import { ArchitectLicense } from './architect-license';

export class FirmRosterMember extends UpdatableModel<FirmRosterMember> {
  personId: string;
  name: Name;
  title: string;
  license: ArchitectLicense;

  update(init: Partial<FirmRosterMember>) {
    super.update(init);
    if (this.name) {
      this.name = new Name(this.name);
    }
    if (this.license) {
      this.license = new ArchitectLicense(this.license);
    }
  }
}
