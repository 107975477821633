import { OnInit, Input, Output, EventEmitter, Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { NoItemsRow } from './no-items-row';

@Component({
  selector: 'lib-list',
  template: '',
})
export class ListComponent<T> extends BaseComponent implements OnInit {
  @Input() list$: Observable<T[]>;
  @Input() hideOnEmpty: boolean;
  @Output() sortChanged = new EventEmitter<Sort>();

  listToUse$: Observable<T[]>; //why not just use the input this.list$?

  items$ = new BehaviorSubject<(T | NoItemsRow)[]>([]);
  hide: boolean;
  isLoading: boolean;

  ngOnInit() {
    this.isLoading = true;
    this.listToUse$ = this.listToUse$ || this.list$;
    this.unsubOnDestroy = this.listToUse$.subscribe((items: (T | NoItemsRow)[]) => {
      items = [...items];
      if (!items.length) {
        this.hide = this.hideOnEmpty;
        if (!this.hideOnEmpty) {
          items.push(new NoItemsRow());
        }
      } else {
        this.hide = false;
      }
      this.isLoading = false;
      this.items$.next(items);
    });
  }

  noItems = (_, item) => item instanceof NoItemsRow;

  sortChange(sort: Sort) {
    this.sortChanged.emit(sort);
  }
}
