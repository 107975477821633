<div *ngIf="title" class="d-flex justify-content-between">
  <div class="d-flex title-height align-items-center">
    <ls-title class="center-title">
      {{ title }}
    </ls-title>

    <button *ngIf="tipClicked.observed" mat-icon-button class="text-grey ml-small mt-xs" (click)="tipClick()">
      <mat-icon>help_outline</mat-icon>
    </button>

    <mat-icon *ngIf="tip" class="text-grey pl-medium" #tooltip="matTooltip" matTooltipClass="tooltip" [matTooltip]="tip"
      >help_outline</mat-icon
    >
  </div>
  <div *ngIf="isEditing">
    <button mat-stroked-button (click)="cancelClick()">Cancel</button>
    <button mat-flat-button color="primary" class="ml-medium" (click)="saveClick()">Save</button>
  </div>
  <button *ngIf="!isEditing && editClicked.observed" mat-icon-button color="primary" (click)="editClick()">
    <mat-icon>edit</mat-icon>
  </button>
</div>
<ls-p level="2" class="text-grey">
  <ng-content></ng-content>
</ls-p>
