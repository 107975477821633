import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ArchitectApplicationService } from 'projects/common/src/lib/services/api/architect-application.service';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';

@Injectable()
export class ArchitectApplicationReviewResolve implements Resolve<ArchitectApplication> {
  constructor(private applicationService: ArchitectApplicationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ArchitectApplication> {
    return this.applicationService.get(route.params.personId, route.params.applicationId);
  }
}
