import { TemplateRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogData, DialogDataWithTemplate } from './dialog-data.model';
import { DialogOptions } from './dialog-options.model';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogFactoryService {
  constructor(private dialog: MatDialog) {}

  open(
    template: TemplateRef<any>,
    dialogData: Partial<DialogData>,
    options: Partial<DialogOptions> = {}
  ): MatDialogRef<DialogComponent<any>, any> {
    const config = DialogFactoryService.buildConfig(template, options, dialogData);
    return this.dialog.open(DialogComponent, config);
  }

  private static buildConfig(
    template: TemplateRef<any>,
    options: Partial<DialogOptions>,
    dataOverride: Partial<DialogData>
  ): MatDialogConfig<DialogDataWithTemplate> {
    const data: DialogDataWithTemplate = {
      hideActions: false,
      header: 'header',
      yesText: 'Yes',
      noText: '',
      isOkayWarnColor: false,
      minHeightPx: 0,
      ...dataOverride,
      template,
    };

    const optionDefaults: DialogOptions = {
      width: 683,
      disableClose: true,
      ...options,
    };

    return {
      width: `${optionDefaults.width}px`,
      maxWidth: '95vw',
      disableClose: optionDefaults.disableClose,
      autoFocus: 'dialog',
      panelClass: 'opt-in',
      data,
    };
  }
}
