import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: [],
})
export class ChipList {
  @Input() items: any[];
  @Input() itemTextProperty: string = 'name';

  displayText(item: any) {
    return item[this.itemTextProperty];
  }

  @Output() itemsChanged = new EventEmitter<boolean>();

  add(item: any) {
    this.items.push(item);
    this.itemsChanged.emit();
  }

  remove(item: any) {
    const index = this.items.indexOf(item);
    if (index == -1) return;
    this.items.splice(index, 1);
    this.itemsChanged.emit();
  }
}
