import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'h3[review-section-header]',
  template: '<span><ng-content></ng-content></span><div class="flex-grow-1"></div>',
  styleUrls: ['./review-section-header.scss'],
})
export class ReviewSectionHeaderComponent {
  @HostBinding('class') get getClass() {
    return 'd-flex align-items-center mb-medium';
  }
}
