<lib-titled-card>
  <h2>License Information</h2>
  <aside>
    <button mat-button class="auto alt" type="button" *ngIf="settings.downloadableLicense == 'Yes'" (click)="download()">
      Download
    </button>
  </aside>
  <div *ngIf="showFirmType">
    <mat-menu *ngIf="canSwitch$ | async">
      <button mat-menu-item routerLink="switch" *ngIf="canSwitch$ | async">Switch License Type</button>
    </mat-menu>
  </div>
  <p class="note">
    The firm is currently registered<span *ngIf="showFirmType">
      as {{ licenseTypeArticle }} <b>{{ license.typeName }}</b></span
    >. To learn more about the requirements for firm registration, please review the
    <a target="_blank" [href]="constants.commonLinks.registrationInfo">Firm Registration</a> section on our website.
  </p>
  <div>
    <div *ngIf="showFirmType" class="d-flex">
      <lib-read-field [value]="license.typeName"> Firm Registration Type </lib-read-field>
    </div>
    <div class="d-flex justify-content-between">
      <lib-read-field [value]="license.number" class="third-width"> License Number </lib-read-field>

      <lib-read-field [value]="license.issueDate" class="third-width"> Issue Date </lib-read-field>

      <lib-read-field [value]="license.status | licenseStatus" class="third-width"> Status </lib-read-field>
    </div>
    <div class="d-flex justify-content-between">
      <lib-read-field [value]="license.lastRenewalDate" class="third-width"> Last Renewal </lib-read-field>
      <lib-read-field [value]="license.expirationDate" class="third-width"> Expiration Date </lib-read-field>
      <div class="third-width"><!-- PLACEHOLDER FOR SPACING --></div>
    </div>
  </div>
</lib-titled-card>
