import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(val: string, sep: string): string[] {
    return val.split(sep || ' ');
  }
}
