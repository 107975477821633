import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from './base.component';
import { formGroupIsCompletelyValid, touchFormGroupErrors } from '../utility/form-utils';

@Component({
  template: '',
})
export class FormComponent extends BaseComponent {
  formGroupIsCompletelyValid(formGroup: FormGroup): boolean {
    return formGroupIsCompletelyValid(formGroup);
  }

  touchFormGroupErrors(formGroup: FormGroup) {
    touchFormGroupErrors(formGroup);
  }

  enableFormGroupControls(formGroup: FormGroup, controls: string[], controlsToIgnore = []) {
    for (let field in formGroup.controls) {
      if (controlsToIgnore.includes(field)) {
        continue;
      }
      let enable = controls.includes(field);
      let control = formGroup.get(field);
      if (enable) {
        control.enable();
      } else {
        control.disable();
      }
    }
  }

  toggleFormElement(formGroup: FormGroup, formControlName, affectedControlName, resetValue?: any, toggleOn?) {
    let control = formGroup.controls[affectedControlName];
    this.unsubOnDestroy = formGroup.controls[formControlName].valueChanges.subscribe(v => {
      if (v === undefined) {
        return;
      }
      if ((toggleOn === undefined && v) || v == toggleOn) {
        if (resetValue !== undefined) {
          control.setValue(resetValue);
        }
        control.disable();
      } else if (!formGroup.disabled && control.disabled) {
        control.enable();
      }
    });
  }
}
