import { APP_INITIALIZER, Injectable } from '@angular/core';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { getApiUrl } from '../../constants/environment';
import { NcarbSettings } from './NcarbSettings';
import { Auth0Settings } from './Auth0Settings';
import { UpdatableModel } from '../../models/updatable-model';

/**
 * Interface containing tenant settings retrieved from the server. Fields should be used
 * to control UX interaction and not data calculation.  Data calculation, such as setting
 * an expiration date or calculating a renewal window, should always be done on the server
 * to prevent tampering within the browser dev tools by a user.  These settings can be displayed
 * and used by the UX, however.  If the data calculated on the server and present back from
 * an api is different, the data from the api should be used.
 */
@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private _settings: TenantSettings;

  get settings(): TenantSettings {
    if (!this._settings) throw new Error('Tenant settings not initialized');
    return this._settings;
  }

  private _auth0: Auth0Settings;

  get auth0(): Auth0Settings {
    if (!this._auth0) throw new Error('Auth0 settings not initialized');
    return this._auth0;
  }

  private _ncarb: NcarbSettings;

  get ncarb(): NcarbSettings {
    if (!this._ncarb) throw new Error('NcarbSettings settings not initialized');
    return this._ncarb;
  }

  private _envSettings: EnvironmentSettings;

  //these could go in TenantSettings, but they vary by dev vs. local,
  //so it's nice to move these out and let TenantSettings be the same in dev and local
  get envSettings(): EnvironmentSettings {
    if (!this._envSettings) throw new Error('EnvironmentSettings settings not initialized');
    return this._envSettings;
  }

  // TODO: Fix how this is retrieved to work with multitenancy
  loadFromApi = (source: 'portal' | 'staff'): Promise<NcarbSettings> => {
    if (this._ncarb) {
      return Promise.resolve(this._ncarb);
    }
    return fetch(`${getApiUrl()}tenant/settings`, { headers: { Accept: 'application/json' } }).then(data =>
      data.json().then(json => {
        const { settings, auth0, ncarb, config } = json;
        this._settings = new TenantSettings(settings, source);
        this._auth0 = new Auth0Settings(auth0, source);
        this._ncarb = new NcarbSettings(ncarb);
        this._envSettings = new EnvironmentSettings(config);
        this.setHref('css-theme', 'variables.css');
        return this._ncarb;
      })
    );
  };

  private setHref(elementId: string, file: string) {
    try {
      let cssTHeme = document.getElementById(elementId) as any;
      if (!cssTHeme) {
        console.log(`could not find element '${elementId}'`);
        return;
      }
      cssTHeme.href = `/assets/${this._settings.tenant}/${file}`;
    } catch (error) {
      console.error(error);
    }
  }
}

export class EnvironmentSettings extends UpdatableModel<EnvironmentSettings> {
  public licensingPortalUrl: string;
  public user: string;
  public localPassword: string;
  public publicStripeKey: string;
}

//call the API on app initialization
export const TenantServiceProvider = (source: 'portal' | 'staff') => ({
  provide: APP_INITIALIZER,
  useFactory: (tenantService: TenantService) => () => tenantService.loadFromApi(source),
  multi: true,
  deps: [TenantService],
});

//Give ability to use TenantSettings directly and skip using the service
export const TenantSettingsProvider = {
  provide: TenantSettings,
  useFactory: (service: TenantService) => service.settings,
  deps: [TenantService],
};

//Danger; if you move this provider away from tenant.service.ts, then Angular will error saying; ReferenceError: Cannot access 'TenantService' before initialization
//I believe this is because the main.js it put together in the wrong order. So keeping this provider here keep things happy.
export const NcarbSettingsProvider = {
  provide: NcarbSettings,
  useFactory: (service: TenantService) => service.ncarb,
  deps: [TenantService],
};

export const EnvironmentSettingsProvider = {
  provide: EnvironmentSettings,
  useFactory: (service: TenantService) => service.envSettings,
  deps: [TenantService],
};
