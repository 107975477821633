import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';
import { ArchitectApplication } from '../../../models/applications/architect-application';
import { S3DocumentReference } from '../../../models/s3/s3-document-reference';
import { S3DocumentSearch } from '../../../models/s3/s3-document-search';
import { ContinuingEducationStepSettings, TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { UploadViewStyle } from '../../upload-widget/upload-widget.component';
import { MatSelectChange } from '@angular/material/select';
import { ContinuingEducationForm } from '../../../models/continuing-education';
import { getApplicationSettings } from '../../../utility/router-extensions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-continuing-education',
  templateUrl: './continuing-education.component.html',
  styleUrls: ['./continuing-education.component.scss'],
})
export class ContinuingEducationComponent implements OnInit {
  @Input() application: ArchitectApplication;
  @Input() form: FormGroup;
  @Input() readonly: boolean;
  docSearch: S3DocumentSearch;
  stepSettings: ContinuingEducationStepSettings;
  fileViewStyle: UploadViewStyle;
  hideExemptionDocumentationUpload: boolean;
  public options: string[] = [];
  constructor(
    public constants: JurisdictionConstants,
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {}

  async ngOnInit() {
    this.docSearch = new S3DocumentSearch({
      aggregateRootId: this.application.personId,
      subjectId: this.application.id,
      documentType: 'ContinuingEducation',
      prePopulateUrl: this.readonly,
    });

    this.stepSettings = getApplicationSettings(this.route).stepConfiguration.continuingEducation;

    this.fileViewStyle = this.readonly ? UploadViewStyle.Table : UploadViewStyle.Pill;
    if (!this.readonly && this.stepSettings.exemptionConfiguration) {
      //only need the list of options if in edit mode and allowExemption is true
      this.options = this.stepSettings.exemptionConfiguration.reasons;
    }
  }

  getDisplayLabelExemptionReason(): string {
    return this.form.controls.exemption.value ?? '';
  }

  //form initialized from here: ContinuingEducationForm.GetFormGroup
  //isCompleted will same as this.form.controls.completed.value
  onCompletedChange() {
    ContinuingEducationForm.setControls(
      this.form.controls.completed.value,
      this.form.controls.exemption.value,
      this.form.controls.exemption,
      this.form.controls.docCount,
      this.stepSettings
    );
  }

  onExemptionReasonChange({ value: reason }: Partial<MatSelectChange>) {
    //only if requireDocumentationForExemption true would we need to recalculate controls on a reason change
    if (!this.stepSettings.exemptionConfiguration) return;

    //this.form.controls.completed.value should always be false
    ContinuingEducationForm.setControls(
      this.form.controls.completed.value,
      reason,
      this.form.controls.exemption,
      this.form.controls.docCount,
      this.stepSettings
    );
  }

  showExempt(): boolean {
    return this.form.controls?.completed?.value === false && !!this.stepSettings.exemptionConfiguration;
  }

  onDocListChange(docList: S3DocumentReference[]) {
    this.form.controls.docCount.setValue(docList.length);
  }

  showCompletedFileUpload(): boolean {
    return this.stepSettings.completedEducationDocumentation && this.form.controls?.completed?.value === true;
  }

  showExemptionFileUpload(): boolean {
    //there is possibility requireDocumentationForExemption is true and control is disabled;
    //given:renewal app
    //when: selects 'New Registrant'
    //then docCount is disabled
    return (
      this.stepSettings.exemptionConfiguration?.requireDocumentation &&
      this.form.controls?.completed?.value === false &&
      !this.form.controls.docCount.disabled
    );
  }
}
