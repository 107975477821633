import { ActivatedRoute } from '@angular/router';
import { ApplicationSettings } from '../models/applications/application-settings';
import { Application } from '../models/applications/application';

export const getApplicationSettings = (route: ActivatedRoute) => {
  if (route.snapshot.data.applicationSettings) return route.snapshot.data.applicationSettings as ApplicationSettings;
  throw new Error('route.snapshot.data.applicationSettings not set');
};

export const getPersonOrFirmId = (route: ActivatedRoute): string => {
  const personOrFirmId = route.snapshot.params.personId || route.snapshot.params.firmId;
  if (!personOrFirmId) throw new Error('could not find personId or firmId in route params');
  return personOrFirmId;
};

export const getApplication = (route: ActivatedRoute) => {
  if (route.snapshot.data.applicationSettings) return route.snapshot.data.application as Application;
  throw new Error('route.snapshot.data.applicationSettings not set');
};
