import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { S3DocumentSearch } from 'projects/common/src/lib/models/s3/s3-document-search';
import { S3DocumentReference } from 'projects/common/src/lib/models/s3/s3-document-reference';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadViewStyle } from '../../../upload-widget/upload-widget.component';
import { ActivatedRoute } from '@angular/router';
import { getPersonOrFirmId } from 'projects/common/src/lib/utility/router-extensions';

@Component({
  selector: 'lib-documents-form',
  templateUrl: './documents-form.component.html',
})
export class DocumentsFormComponent implements OnInit {
  @HostBinding('class.flex-column') flex = true;
  @Input() applicationId: string;

  //form should have a control called 'docCount'
  //if no control passed in, then assumes read-only
  @Input() form?: FormGroup;
  @Input() documentType: string;
  public readonly: boolean;
  public docSearch: S3DocumentSearch;
  public fileViewStyle: UploadViewStyle;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.readonly = !this.form;
    this.docSearch = new S3DocumentSearch({
      aggregateRootId: getPersonOrFirmId(this.route),
      subjectId: this.applicationId,
      documentType: this.documentType,
      prePopulateUrl: this.readonly,
    });
    this.fileViewStyle = this.readonly ? UploadViewStyle.Table : UploadViewStyle.Pill;
  }

  onDocListChange(docList: S3DocumentReference[]) {
    if (this.readonly) {
      return;
    }
    this.form.controls.docCount.setValue(docList.length);
  }

  static buildDocCountRequiredForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      docCount: [0, [Validators.required, Validators.min(1)]],
    });
  }
}
