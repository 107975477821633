import { PipeTransform, Pipe, SecurityContext } from '@angular/core';
import { LabelService } from '../components/label-swapper/label.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'labelSwapper' })
export class LabelSwapperPipe implements PipeTransform {
  constructor(
    private labelService: LabelService,
    private sanitizer: DomSanitizer
  ) {}
  transform(contentKey: string, labelType: string = 'content', isHtml: boolean = false): Promise<string | SafeHtml> {
    //const safeHtml = this.sanitizer.bypassSecurityTrustHtml(result);

    if (!isHtml) {
      return this.labelService.getLabel({
        contentKey: contentKey,
        contentType: labelType,
      });
    }
    return this.labelService
      .getLabel({
        contentKey: contentKey,
        contentType: labelType,
      })
      .then(html => {
        const safeHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        return this.sanitizer.sanitize(SecurityContext.HTML, safeHtml);
      });
  }
}
