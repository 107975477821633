import { Component, Input } from '@angular/core';
import { Order } from 'projects/common/src/lib/models/order';

@Component({
  selector: 'lib-fee-items',
  templateUrl: './fee-items.component.html',
})
export class FeeItemsComponent {
  @Input() order: Order;
  columns = ['name', 'amount'];
}
