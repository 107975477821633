import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';

@Component({
  selector: 'app-impersonate',
  template: `<p>Impersonation in progress...</p>`,
  styles: [],
})
export class ImpersonateComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.softLogout();
    this.auth.loginManager();
  }
}
