import { Injectable } from '@angular/core';
import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { PersonalInfo } from 'projects/common/src/lib/models/personal-info';

//only currently used in application on person info step
@Injectable({
  providedIn: 'root',
})
export class PersonalInfoService {
  constructor(private api: ApiService) {}

  async get(personId: string): Promise<PersonalInfo> {
    return await this.api.getAsync(`person/${personId}/personalInfoStep`).then(data => new PersonalInfo(data));
  }

  async update(personId: string, personalInfo: PersonalInfo): Promise<PersonalInfo> {
    return await this.api.putAsync(`person/${personId}/personalInfoStep`, personalInfo).then(data => new PersonalInfo(data));
  }
}
