import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-overline',
  template: '<ng-content></ng-content>',
})
export class OverlineComponent {
  @HostBinding('class.d-inline-block') inlineBlock = true;
  @HostBinding('class.type-overline') typeClass = true;
}
