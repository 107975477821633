import { Router } from '@angular/router';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { Subscription } from 'rxjs';

export abstract class AuthorizingComponent {
  protected onAuthSubscription: Subscription;
  constructor(
    private auth: AuthService,
    private router: Router,
    settings: TenantSettings,
    private accountService: AccountService
  ) {
    this.onAuthSubscription = this.auth.isAuthenticated$
      .pipe(
        filter(a => !!a), // was there an authorization event (ignore initial or logouts)
        switchMap(a => this.accountService.claimsCheck(settings.source)),
        tap(claimsOk => {
          if (!claimsOk) {
            // if claims were refreshed, then do a silent refresh
            // it will trigger another auth event
            this.auth.renewToken();
          }
        }),
        filter(a => !!a), // ignore the claim refresh case
        first() // just do this once
      )
      .subscribe(_ => {
        this.onLogin();
        let redirect = localStorage.getItem('redirect');
        if (redirect) {
          localStorage.removeItem('redirect');
        }
        setTimeout(_ => this.router.navigate([redirect || '/']), 1000);
      });
  }

  protected toLandingPageIfNotAuthenticated() {
    if (!this.auth.isAuthenticated()) {
      if (!window.location.hash) {
        this.router.navigate(['/landing']);
        return;
      }
      this.auth.handleAuthentication();
    }
  }

  private onLogin() {
    this.accountService.logLogin();
  }
}
