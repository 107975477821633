<form [formGroup]="form">
  <header></header>
  <main>
    <fieldset>
      <legend>Corporate Information</legend>
      <mat-radio-group class="flex-column" formControlName="corporationSharesType" required>
        <label> How are the shares divided in your firm? </label>
        <mat-radio-button value="SoleOwnership" color="primary"> Sole Ownership </mat-radio-button>
        <mat-radio-button value="Shareholders" color="primary"> Multiple Shareholders </mat-radio-button>
        <mat-radio-button value="HoldingCompany" color="primary"> Holding Company </mat-radio-button>
      </mat-radio-group>
    </fieldset>

    <fieldset *ngIf="isSoleOwner">
      <legend>Sole Ownership</legend>
      <p class="note">A sole owner owns ALL outstanding shares of this firm.</p>
      <lib-arch-select [formGroup]="form['controls'].owner" role="Owner"></lib-arch-select>
    </fieldset>

    <ng-template [ngIf]="isHoldingCompany">
      <fieldset>
        <legend>Holding Company</legend>
        <div class="flex-fields">
          <mat-form-field appearance="standard">
            <mat-label>Holding Company Title</mat-label>
            <input matInput formControlName="holdingCompanyTitle" required />
          </mat-form-field>
        </div>
      </fieldset>
    </ng-template>

    <ng-template [ngIf]="isShareholdersOrHoldingCompany">
      <fieldset>
        <legend>Shareholder Information</legend>
        <p class="note" [innerHTML]="shareHolderNote"></p>
        <lib-multi-arch-select
          [formArray]="form['controls'].shareholders"
          role="Shareholder"
          [readonly]="readonly"
        ></lib-multi-arch-select>
      </fieldset>

      <fieldset>
        <legend>
          Calculation of Shares
          <span class="html-tooltip">
            <mat-icon class="inline">info</mat-icon>
            <div>
              <h4>Defining Calculation of Shares</h4>

              <h5>Total shares ALLOWED by Corporation:</h5>
              The maximum number of shares allowed by the Articles of Incorporation, as amended.

              <h5>Total shares ISSUED by Corporation:</h5>

              The total number of shares issued or distributed to all shareholders, including out of state shareholders.
            </div>
          </span>
        </legend>
        <p class="note">
          At least 50.1% of shares must be owned by {{ settings.stateName }} Architects If your firm does not meet this
          requirement, you may qualify to
          <a href="#" (click)="doConfirmSwitch(confirmSwitch)">apply as a different firm type</a>. To learn more about the
          requirements for firm registration, please review the
          <a [href]="constants.commonLinks.registrationInfo" target="_blank">Firm Registration</a> section on our website.
        </p>
        <lib-confirm
          prompt="Apply as a Different Firm Type"
          (yes)="switch()"
          yesText="Yes, continue"
          noText="No, go Back"
          #confirmSwitch="confirm"
          [template]="confirmSwitchTemplate"
        >
        </lib-confirm>
        <ng-template #confirmSwitchTemplate>
          <p class="note">
            Your firm registration type will be updated upon approval of the new application. Your firm is not active in the new
            firm registration type until the new registration is issued.
          </p>
          <p class="note">Are you sure you want to apply as a different type of firm now?</p>
        </ng-template>
        <div class="shares">
          <table>
            <tr>
              <th>
                <label for="sharesAllowed"> Total shares ALLOWED by Corporation: </label>
              </th>
              <td>
                <mat-form-field appearance="standard">
                  <input matInput formControlName="sharesAllowed" required [pattern]="patterns.wholeNumber" />
                </mat-form-field>
              </td>
            </tr>

            <tr *ngIf="form.controls?.sharesAllowed?.hasError('pattern')">
              <th colspan="2">
                <mat-error>Please enter whole numbers for shares</mat-error>
              </th>
            </tr>

            <tr>
              <th>
                <label for="sharesIssued"> Total shares ISSUED by Corporation: </label>
              </th>
              <td>
                <mat-form-field appearance="standard">
                  <input matInput formControlName="sharesIssued" required [pattern]="patterns.wholeNumber" />
                </mat-form-field>
              </td>
            </tr>

            <tr *ngIf="form.controls?.sharesIssued?.hasError('pattern')">
              <th colspan="2">
                <mat-error>Please enter whole numbers for shares</mat-error>
              </th>
            </tr>

            <tr *ngIf="form.hasError('allowed')">
              <th colspan="2">
                <mat-error>Issued shares must be less than or equal to allowed shares</mat-error>
              </th>
            </tr>

            <tr>
              <th>
                <label> Total shares OWNED by {{ settings.stateName }} Architects: </label>
              </th>
              <td>
                {{ sharesOwned }}
              </td>
            </tr>

            <tr>
              <th>
                <label> Percentage of shares owned by {{ settings.stateName }} Architects: </label>
              </th>
              <td [class.success]="goodPercent" [class.error]="!goodPercent">
                <ng-template [ngIf]="percentOwned">
                  {{ percentOwned }}%
                  <mat-icon class="inline" *ngIf="goodPercent">done</mat-icon>
                  <mat-icon class="inline" *ngIf="!goodPercent">clear</mat-icon>
                </ng-template>
              </td>
            </tr>

            <tr *ngIf="percentOwned && form.hasError('fifty')">
              <th colspan="2">
                <mat-error> At least 50.1% percent of shares must be owned by {{ settings.stateName }} Architects. </mat-error>
              </th>
            </tr>

            <tr *ngIf="percentOwned && form.hasError('over')">
              <th colspan="2">
                <mat-error
                  >The number shares indicated owned by {{ settings.stateName }} Architects is more than the total shares
                  issued</mat-error
                >
              </th>
            </tr>
          </table>
        </div>
      </fieldset>

      <div *ngIf="showOldBoardOfDirectors">
        <fieldset>
          <legend>Board of Directors Information</legend>
          <p class="note" [innerHTML]="boardOfDirectorsNote"></p>
        </fieldset>

        <fieldset>
          <legend>Architect Directors Licensed in {{ settings.stateName }}</legend>
          <lib-multi-arch-select
            [formArray]="form['controls'].directorsInState"
            role="Director"
            [readonly]="readonly"
          ></lib-multi-arch-select>
        </fieldset>

        <fieldset>
          <legend>
            Other Directors
            <div>
              <i>
                Includes Licensed Directors in other states, Non-Licensed Directors in other states, and/or Non-Licensed
                Directors in {{ settings.stateName }}.
              </i>
            </div>
          </legend>
          <div class="flex-fields">
            <mat-form-field appearance="standard" [class.faux-disable]="readonly">
              <mat-label>Number of Unlicensed Directors</mat-label>
              <mat-select matInput panelClass="scroll" [required]="!readonly" formControlName="unlicensedDirectorsCount">
                <mat-option></mat-option>
                <mat-option *ngFor="let directorCount of listOfDirectorCounts" [value]="directorCount">
                  {{ directorCount }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </fieldset>
      </div>

      <div *ngIf="!showOldBoardOfDirectors">
        <section-header title="Officers, Directors, Partners, Members">
          Please attach a list of officers, directors, partners, or members for your firm, their license numbers (if
          applicable), and shares held in the organization. If the firm is structured as a sole ownership, please upload the
          sole owner's name, license number, and shares held.
        </section-header>
        <div class="directors-upload">
          <lib-upload-widget documentType="Directors" [docSearch]="docSearch" (onDocListChange)="onDocListChange($event)">
          </lib-upload-widget>
          <ls-p level="2" class="ls-text-grey" [innerHTML]="directorsUploadCustomText"></ls-p>
        </div>
      </div>
    </ng-template>
  </main>
</form>
