<table class="simple-table with-detail-row" mat-table [dataSource]="items$">
  <ng-container matColumnDef="applicationType">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">{{ element.type }}</td>
  </ng-container>
  <ng-container matColumnDef="submittedOn">
    <th mat-header-cell *matHeaderCellDef>Application Date</th>
    <td mat-cell *matCellDef="let element">{{ element.submittedOn | date: 'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="orderNumber">
    <th mat-header-cell *matHeaderCellDef>Confirmation Number</th>
    <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
  </ng-container>
  <ng-container matColumnDef="isUsCitizen">
    <th mat-header-cell *matHeaderCellDef>Citizenship Status</th>
    <td mat-cell *matCellDef="let element">
      {{ element.isUsCitizen ? citizenText : element.isUsCitizen != null ? nonCitizenText : 'Unknown' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">No Lawful Presence Responses on file</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
</table>
