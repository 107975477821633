import { Component, HostBinding } from '@angular/core';
import { RegexPatterns } from '../../constants/regex-patterns';
import { Masks } from '../../constants/masks';
import { EditSecretComponent } from '../edit-secret-component';

@Component({
  selector: 'lib-edit-visa',
  templateUrl: './edit-visa.component.html',
  styleUrls: ['./edit-visa.component.scss'],
  inputs: EditSecretComponent.Inputs,
})
export class EditVisaComponent extends EditSecretComponent {
  constructor() {
    super({ pattern: RegexPatterns.visa, mask: Masks.visa });
  }

  //putting on EditSecretComponent doesn't work (does work in storybook)
  @HostBinding('class') get getClass(): string {
    return super.getClass;
  }
}
