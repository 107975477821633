import { UpdatableModel } from './updatable-model';
import { OrderOverride } from './order-override';

interface LineItem {
  name: string;
  amount: number;
}

export class Order extends UpdatableModel<Order> {
  id: string;
  name: string;
  number: number;
  paymentMethodType: 'Ach' | 'Check' | 'Card' | 'NotApplicable';
  paymentMethodName: string;
  totalAmount: number;
  hasDelinquencyFee: boolean;
  hasDelinquencyFeeDiscount: boolean;
  totalOriginalAmount: number;
  isDiscounted: boolean;
  completedOn?: Date;
  status: string;
  items: LineItem[];
  override: OrderOverride;
  clientSecret: string; //used with stripe ach payments
}
