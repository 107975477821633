import { Component, ChangeDetectionStrategy } from '@angular/core';
import { XhrProgressService } from '../services/xhr-progress/xhr-progress.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-progress-bar',
  template: `
    <mat-progress-bar mode="determinate" [value]="progress$ | async" *ngIf="(progress$ | async) > 0"></mat-progress-bar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  progress$: Observable<number>;
  constructor(progressService: XhrProgressService) {
    this.progress$ = progressService.progress$;
    this.progress$.subscribe();
  }
}
