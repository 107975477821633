<ng-content></ng-content>
<mat-form-field appearance="standard">
  <mat-select [(ngModel)]="selectedStatus" (ngModelChange)="onStatusChanged()">
    <mat-option *ngFor="let _status of statuses" [value]="_status">
      <span
        class="status-box"
        [ngClass]="'status-' + _status.style?.toLowerCase()"
        attr.data-status-abbreviation="{{ _status.display | firstLetter }}"
      ></span>
      {{ _status.display | spaceCapCase }}
    </mat-option>
  </mat-select>
</mat-form-field>

<lib-confirm [optIn]="true" yesText="Yes" noText="No, cancel">
  <ng-template>Are you sure you want to change the status to {{ selectedStatus.display }}?</ng-template>
</lib-confirm>
