import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService implements OnDestroy {
  private smSub: Subscription;
  public sm = new BehaviorSubject<boolean>(null);
  constructor(private breakpointObserver: BreakpointObserver) {
    this.smSub = this.breakpointObserver.observe(['(max-width: 960px)']).subscribe((state: BreakpointState) => {
      this.sm.next(state.matches);
    });
  }

  ngOnDestroy(): void {
    this.smSub.unsubscribe();
  }
}
