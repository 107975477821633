<mat-form-field appearance="outline" class="remove-hint-pad" *ngIf="form?.controls?.docCount; else uploadWidget">
  <input class="d-none" matInput [formControl]="form?.controls?.docCount" required />
  <ng-container *ngTemplateOutlet="uploadWidget"></ng-container>
</mat-form-field>

<ng-template #uploadWidget>
  <lib-upload-widget
    [readonly]="readonly"
    [docSearch]="docSearch"
    (onDocListChange)="onDocListChange($event)"
    [viewStyle]="fileViewStyle"
    [noDocumentsMessage]="readonly && 'No Documents Uploaded'"
  >
  </lib-upload-widget>
</ng-template>
