import { Component, Input } from '@angular/core';
import { LinkInfo } from '../../models/links/link-info';

@Component({
  selector: 'ls-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadCrumbsComponent {
  @Input() links: LinkInfo[] = [];
}
