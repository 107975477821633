import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { WorkHistoryService } from '../services/api/work-history.service';
import { WorkHistory } from '../models/work-history';

@Injectable()
export class PersonWorkHistoryResolve implements Resolve<WorkHistory[]> {
  constructor(private workHistoryService: WorkHistoryService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId;
    return this.workHistoryService.get(personId);
  }
}
