import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-side-nav-container',
  templateUrl: './side-nav-container.component.html',
  styleUrls: ['./side-nav-container.component.scss'],
})
export class SideNavContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
