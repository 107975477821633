import { UpdatableModel } from './updatable-model';
import { Phone } from './phone';
import { FormGroup } from '@angular/forms';

export class PersonContactInfo extends UpdatableModel<PersonContactInfo> {
  email: string;
  primaryPhone: Phone;
  secondaryPhone: Phone;

  cleanup() {
    ['primaryPhone', 'secondaryPhone'].forEach(p => {
      if (!this[p] || !this[p].number) {
        delete this[p];
      } else {
        this[p] = new Phone(this[p]);
      }
    });
  }

  patch(contactInfoForm: FormGroup) {
    this.cleanup();
    contactInfoForm.controls.email.setValue(this.email);
    contactInfoForm.controls.primaryPhone.patchValue(this.primaryPhone || new Phone());
    contactInfoForm.controls.secondaryPhone.patchValue(this.secondaryPhone || new Phone());
  }

  acceptPatch(form: FormGroup) {
    this.email = form.controls.email.value;
    this.acceptPhonePatch('primaryPhone', <FormGroup>form.controls.primaryPhone);
    this.acceptPhonePatch('secondaryPhone', <FormGroup>form.controls.secondaryPhone);
  }

  acceptPhonePatch(key: string, phoneForm: FormGroup) {
    const phone = phoneForm.value;
    if (!phone?.number) {
      // If we don't have a phone number, delete the phone number from the main object
      if (this[key]) delete this[key];
    } else {
      this[key] = new Phone(phone);
    }
  }
}
