import { Component, forwardRef, OnInit } from '@angular/core';
import { WizardStepComponent } from './wizard-step/wizard-step.component';

@Component({
  selector: 'lib-wizard-form-control-step',
  templateUrl: 'wizard-step/wizard-step.component.html',
  styleUrls: ['wizard-step/wizard-step.component.scss'],
  providers: [{ provide: WizardStepComponent, useExisting: forwardRef(() => WizardFormControlStepComponent) }],
})
export class WizardFormControlStepComponent extends WizardStepComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.unsubOnDestroy = this.visibilityChanged.subscribe(event => {
      if (!event.visible) {
        this.control.setValue('');
      }
    });
  }
}
