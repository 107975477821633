import { UpdatableModel } from '../updatable-model';

export class S3DocumentReference extends UpdatableModel<S3DocumentReference> {
  documentType: string;
  contentType: string;
  aggregateRootId: string;
  subjectId: string;
  path: string;
  fileName: string;
  fileNameWithoutTimestamp: string;
  description: string;
  timestamp: string; // jurisdiction tz-based
  lastModified: string; // utc-based
  link?: string; // to be added on manually
  uploadedByPersonId: string;
  uploadedByUsername: string;
  uploadedByName: string;

  get safePath() {
    return `${this.aggregateRootId}/${this.subjectId}/${encodeURIComponent(this.fileName)}`;
  }

  get isPdf() {
    return this.contentType.indexOf('/pdf') >= 0;
  }

  get isImage() {
    return ['gif', 'png', 'jpeg', 'jpg'].indexOf(this.extension) >= 0;
  }

  get extension() {
    return this.fileNameWithoutTimestamp.split('.').pop();
  }
}
