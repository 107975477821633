import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FirmAvailableService } from '../shared/services/firm-available.service';
import { FirmAvailable } from 'projects/common/src/lib/models/firm-available';

@Component({
  template: `
    <div *ngIf="firms?.length > 1">
      <h1>Please Choose a Firm</h1>
      <ul>
        <li *ngFor="let firm of firms">
          <a [routerLink]="firm.id">{{ firm.name }}</a>
        </li>
      </ul>
    </div>
  `,
})
export class FirmPortalRedirectComponent implements OnInit {
  firms: FirmAvailable[];
  constructor(
    public firmAvailableService: FirmAvailableService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.firmAvailableService.firms$.pipe(first()).subscribe(firms => {
      this.firms = firms;
      if (firms.length > 1) {
        // offer choice
      } else if (firms.length) {
        this.router.navigate(['/firm/' + this.firms[0]]);
      } else {
        this.router.navigate(['/portal']);
      }
    });
  }
}
