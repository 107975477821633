import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

const currencyPipe = new CurrencyPipe('en-US');

@Pipe({
  name: 'wholeDollars',
})
export class WholeDollarsPipe implements PipeTransform {
  transform(val: string): string {
    val = currencyPipe.transform(val, 'USD');
    var dotZeroZeroIndex = val.indexOf('.00');
    if (dotZeroZeroIndex > -1) {
      return val.substring(0, dotZeroZeroIndex);
    }
    return val;
  }
}
