import { Component, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';

@Component({
  selector: 'lib-menu-wrapper',
  template: '',
})
export class MenuWrapperComponent extends BaseComponent {
  @Input() id: string;
  @ViewChild(MatMenu) menu: MatMenu;
}
