import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PersonFull } from 'projects/common/src/lib/models/person-full';
import { PersonDetailService } from 'projects/common/src/lib/services/person-detail.service';

@Injectable()
export class PersonDetailResolve implements Resolve<PersonFull> {
  constructor(private personDetailService: PersonDetailService) {}

  resolve(route: ActivatedRouteSnapshot) {
    let personId = route.params.personId;
    return this.personDetailService.init(personId);
  }
}
