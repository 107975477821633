import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Form } from '@angular/forms';

import { BlankComponent } from 'projects/common/src/lib/components/blank.component';
import { Person } from 'projects/common/src/lib/models/person';
import { WorkHistoryForArchApp } from 'projects/common/src/lib/models/work-history-for-arch-app';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { Order } from 'projects/common/src/lib/models/order';
import { ContinuingEducationForm } from 'projects/common/src/lib/models/continuing-education';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { JurisdictionConfirmationService } from '../../../constants/jurisdiction/confirmations/jurisdiction-confirmation-service';
import { ApplicationEligibilityComponent } from 'projects/common/src/lib/components/application/eligibility/application-eligibility.component';
import { PersonalInfo } from 'projects/common/src/lib/models/personal-info';
import { MilitaryInformation } from '../../../models/military-information';
import { PersonalStepSettings, TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { getApplicationSettings } from '../../../utility/router-extensions';

@Component({
  selector: 'app-architect-review',
  templateUrl: './architect-review.component.html',
  styleUrls: ['../review.scss', './architect-review.component.scss'],
})
export class ArchitectReviewComponent extends BlankComponent implements OnInit {
  title;
  person: Person;
  workHistory: WorkHistoryForArchApp;
  application: ArchitectApplication;
  questionnaireResponse: QuestionnaireResponse;
  militaryInformation: MilitaryInformation;
  order: Order;
  confirmationForm: FormGroup;
  eligibilityForm: FormGroup;
  ceForm: FormGroup;
  reviewContinuingEducation: boolean;
  reviewWorkHistory: boolean;
  reviewMilitaryInfo: boolean;
  hasEligibility: boolean;
  hasDisclosureQuestions: boolean;
  hasAffidavitUpload: boolean;
  personInfo: PersonalInfo;
  profileForm: FormGroup;
  personalStepSettings: PersonalStepSettings;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public settings: TenantSettings,
    private confirmationService: JurisdictionConfirmationService
  ) {
    super();
  }

  ngOnInit() {
    this.profileForm = PersonalInfo.GetFormGroup(this.formBuilder);

    this.personInfo = this.route.snapshot.data.personalInfo;
    this.personInfo.populateForm(this.profileForm);
    this.application = this.route.snapshot.data.application;
    this.workHistory = this.route.snapshot.data.workHistory;
    this.questionnaireResponse = this.route.snapshot.data.questionnaireResponse;
    this.militaryInformation = this.route.snapshot.data.militaryInformation;
    const applicationSettings = getApplicationSettings(this.route);

    this.person = this.application.person;
    this.order = this.route.snapshot.data.order;
    this.title = applicationSettings.applicationName;
    const alreadyChecked = !!this.application.submittedOn;
    this.confirmationForm = this.confirmationService.getArchitectForm(this.application, alreadyChecked);
    this.eligibilityForm = ApplicationEligibilityComponent.GetFormGroup(this.formBuilder, alreadyChecked);

    this.hasEligibility = applicationSettings.steps.some(s => s === 'Eligibility');
    this.hasAffidavitUpload = applicationSettings.steps.some(s => s === 'AffidavitUpload');
    this.reviewMilitaryInfo = applicationSettings.steps.some(s => s === 'MilitaryInfo');
    this.reviewWorkHistory = applicationSettings.steps.some(s => s === 'WorkInfo');
    this.hasDisclosureQuestions = applicationSettings.steps.some(s => s === 'DisclosureQuestions');

    if (this.settings.hasContinuingEducation && applicationSettings.stepConfiguration.continuingEducation) {
      //CE doesn't look at applicationSettings.steps because Emeritus doesn't have the step, but will show up on review (step is treated as hidden)
      this.reviewContinuingEducation = true;
      this.ceForm = ContinuingEducationForm.GetFormGroup(
        this.formBuilder,
        this.application,
        applicationSettings.stepConfiguration.continuingEducation
      );
    } else {
      this.reviewContinuingEducation = false;
    }

    this.personalStepSettings = applicationSettings.stepConfiguration.personal;
  }
}
