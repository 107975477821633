import { Component, OnInit } from '@angular/core';
import { FirmAvailableApplication } from 'projects/common/src/lib/models/applications/firm-available-application';
import { ActivatedRoute, Router } from '@angular/router';
import { FirmApplicationService } from 'projects/common/src/lib/services/api/firm-application.service';
import { FauxModalComponent } from 'projects/common/src/lib/components/faux-modal.component';
import { Mutex } from 'projects/common/src/lib/utility/mutex';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { FirmManagerGuard } from '../firm-manager.guard';

@Component({
  selector: 'app-firm-select-type',
  templateUrl: './firm-select-type.component.html',
  styleUrls: ['./firm-select-type.component.scss'],
})
export class FirmSelectTypeComponent extends FauxModalComponent implements OnInit {
  availables: FirmAvailableApplication[];
  firmId: string;
  applicationMutex: Mutex;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private firmApplicationService: FirmApplicationService,
    private settings: TenantSettings,
    private accountService: AccountService,
    private firmManagerGuard: FirmManagerGuard
  ) {
    super();
  }

  ngOnInit() {
    this.applicationMutex = new Mutex();
    this.availables = this.route.snapshot.data.firmAvailableApplications as FirmAvailableApplication[];
    this.firmId = this.route.snapshot.params.firmId;

    if (!this.settings.hasFirmTypes()) {
      this.onTypeSelected('ArchitecturalFirm');
    }
  }

  async onTypeSelected(selectedLicenseType) {
    if (!this.applicationMutex.tryAcquire()) {
      return;
    }
    try {
      const application = await this.firmApplicationService.getOrCreate(this.firmId, 'License', selectedLicenseType);
      if (application.firmId === this.firmId) {
        this.router.navigate(['..', 'application', application.id], { relativeTo: this.route });
      } else {
        //firmApplicationService.getOrCreate created a new firm
        //need to update claims or else the firmManagerGuard will stop navigating to the application
        this.firmManagerGuard.claims = await this.accountService.portalClaimsFullRefresh();
        //You are probably switching application types
        //Need to update the URL with the new firm id so that the applicationId and firmId are in sync
        this.router.navigate(['/firm', application.firmId, 'application', application.id]);
      }
    } finally {
      this.applicationMutex.release();
    }
  }

  onCancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
