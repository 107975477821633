import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdatableModel } from './updatable-model';

export interface ILicense {
  id: string;
  number: string;
  status: string;
  residency: string;
  issueDate: Date;
  lastRenewalDate?: Date;
  expirationDate: Date;
  canHaveApplication: boolean;
}

// This implementation of ILicense can be used when you
// don't care if it is a firm or architect license.
export class License extends UpdatableModel<ILicense> implements ILicense {
  id: string;
  number: string;
  status: string;
  residency: string;
  issueDate: Date;
  lastRenewalDate?: Date;
  expirationDate: Date;
  canHaveApplication: boolean;
}
