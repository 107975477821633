// https://medium.com/@zeljkoradic/loader-bar-on-every-http-request-in-angular-6-60d8572a21a9
// https://stackoverflow.com/questions/50172055/angular-5-httpinterceptor-detect-cancelled-xhr/50620910#50620910
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { XhrProgressService } from './xhr-progress.service';
@Injectable({
  providedIn: 'root',
})
export class XhrProgressInterceptorService implements HttpInterceptor {
  count = 0;
  constructor(private progressService: XhrProgressService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.onStart();
    return next.handle(req).pipe(
      finalize(() => {
        this.onEnd();
      })
    );
  }

  private onStart(): void {
    this.count++;
    this.progressService.requests(this.count);
  }
  private onEnd(): void {
    this.count--;
    this.progressService.requests(this.count);
  }
}
