import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-just-portal-body',
  templateUrl: './just-portal-body.component.html',
  styleUrls: ['./just-portal-body.component.scss'],
})
export class JustPortalBodyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
