import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { getApiErrorMessage, touchFormGroupErrors } from 'projects/common/src/lib/utility/form-utils';
import { Address } from 'projects/common/src/lib/models/address';
import { AddressService } from 'projects/common/src/lib/services/address.service';
import { ActivatedRoute } from '@angular/router';

//consolidate with PersonHomeAddressComponent?
@Component({
  selector: 'firm-detail-address',
  templateUrl: './firm-detail-address.component.html',
  styleUrls: ['./firm-detail-address.component.scss'],
})
export class FirmDetailAddressComponent implements OnInit {
  addressForm: FormGroup;
  isEditing: boolean;
  private formSnapshot: FormGroup;

  edit() {
    this.formSnapshot = this.addressForm.getRawValue();
    this.isEditing = true;
  }

  constructor(
    formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private addressService: AddressService,
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {
    this.addressForm = Address.GetFormGroup(formBuilder);
  }

  async ngOnInit() {
    const address = await this.addressService.getAddress(this.route.snapshot.params.firmId, 'firm', true);
    this.addressForm.patchValue(address);
  }

  cancel() {
    this.addressForm.setValue(this.formSnapshot);
    this.isEditing = false;
  }

  async save() {
    if (this.addressForm.invalid) {
      touchFormGroupErrors(this.addressForm);
      this.notificationService.notifyFormIssue('Please check the form. There were some issues.');
      return;
    }
    try {
      const updatedAddress = await this.addressService.updateAddress(
        this.route.snapshot.params.firmId,
        'firm',
        this.addressForm.value
      );
      this.addressForm.patchValue(updatedAddress); //zip might be updated and any validation errors
      this.isEditing = false;
    } catch (e) {
      const errorMessage = getApiErrorMessage(e, 'error occurred saving address');
      this.notificationService.notifyFail(errorMessage);
    }
  }
}
