<h3>ATTENTION: Things to Know Before Applying</h3>
<p>
  The legislator has enacted laws which govern all firms practicing architecture in Louisiana. In accordance with those laws,
  the LSBAE has adopted rules which regulate the registration of such firms. All firms practicing architecture in Louisiana are
  required to register with the LSBAE and receive a Certificate of Authority within thirty days following registration with the
  Secretary of State. You should consult your legal counsel or CPA regarding the need for business registration with the
  Louisiana Secretary of State.
</p>
<div class="info-box">
  <p>
    <strong>Louisiana Secretary of State &mdash; Corporations Section</strong>
    <br />P.O. Box 94125 <br />Baton Rouge, LA 70804-4704
  </p>
  <p><strong>Telephone: </strong> (225) 925-4704 <br /><strong>Fax: </strong> (225) 925-4726</p>
  <p>
    <strong>Email: </strong> <a href="mailto:commerical@sos.louisiana.gov">commerical@sos.louisiana.gov</a> <br /><strong
      >Website:
    </strong>
    <a target="_blank" href="https://geauxbiz.sos.la.gov/">https://geauxbiz.sos.la.gov/</a>
  </p>
</div>

<ng-template [ngIf]="licenseType == 'ProfessionalArchitectural'">
  <p>
    To register as a Professional Architectural Corporation, your firm must meet both ownership (shareholder) and control
    (director) requirements. Your firm must be lawfully incorporated and a majority of shares must be held by a licensed
    Louisiana architect or by a holding company, of which a majority of shares are owned by licensed Louisiana architects. See
    <a target="_blank" [href]="constants.commonLinks.rules">La. R.S. 12:1090</a>. In addition, depending upon the number of
    directors, a majority of the directors must be licensed Louisiana architects. For more information, see
    <a target="_blank" [href]="constants.commonLinks.rules">La. R.S. 12:1095</a>.
  </p>
</ng-template>
<ng-template [ngIf]="licenseType == 'ArchitecturalFirm'">
  <p>
    Types of firms that may qualify for registration as an Architectural Firm include corporations, partnerships, limited
    liability companies or partnerships, limited liability companies, associations, sole proprietorships, or other entities
    lawfully organized under the laws of louisiana or other lawful jurisdiction for the purpose of practicing architecture. An
    Architectural Firm will be required to designate one or more supervising professional architects who shall perform or
    directly supervise the performance of all architectural services by the firm in Louisiana. See
    <a [href]="constants.commonLinks.rules" target="_blank">Board Rule §1705.G</a>.
  </p>
</ng-template>
<ng-template [ngIf]="licenseType == 'ArchitecturalEngineering'">
  <p>
    To register as an Architectural-Engineering Corporation, your firm must include one or more Louisiana licensed architect(s)
    in order to meet the definition of an Architectural-Engineering Corporation within the meaning of
    <a [href]="constants.commonLinks.rules" target="_blank">La. R.S. 12:1171</a>. To register as an A-E Corporation, such firm
    must be lawfully incorporated, and must apply with LSBAE and with the Louisiana Professional Engineering and Land Surveying
    Board (<a [href]="constants.commonLinks.lapels" target="_blank">LAPELS</a>), designating therein a supervising Louisiana
    registered architect and a supervising Louisiana registered engineer. For more information, see
    <a [href]="constants.commonLinks.rules" target="_blank">La. R.S. 12:1173</a>.
  </p>
</ng-template>
