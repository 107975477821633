import { Input, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListData } from '../../models/search/list-data';
import { GroupedListComponent } from './grouped-list.component';
import { Group } from './group';

@Component({
  selector: 'lib-statused-data-list',
  template: '',
})
export class StatusedDataListComponent<T> extends GroupedListComponent<T> {
  @Input() listData$: Observable<ListData<T>>;
  includeEmptyHeaders = true;

  ngOnInit() {
    this.groups$ = this.listData$.pipe(
      map(listData => {
        let groups = [] as Group<T>[];
        let group = null;
        listData.data.forEach(item => {
          if (!group || group.header.label != item['status']) {
            group = new Group(item['status'], item['status'].toLowerCase());
            groups.push(group);
          }
          group.items.push(item);
        });
        if (!groups.length) {
          if (listData.metadata && listData.metadata.status && listData.metadata.status != 'All') {
            groups.push(new Group(listData.metadata.status, listData.metadata.status.toLowerCase()));
          }
        }
        return groups;
      })
    );
    super.ngOnInit();
  }
}
