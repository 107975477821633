import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ArchitectLicense } from 'projects/common/src/lib/models/architect-license';
import { ArchitectLicenseService } from 'projects/common/src/lib/services/api/architect-license.service';
import { ActivatedRoute } from '@angular/router';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-portal-architect-registration',
  templateUrl: './portal-architect-registration.component.html',
  styleUrls: ['./portal-architect-registration.component.scss'],
})
export class PortalArchitectRegistrationComponent implements OnInit {
  @Input() license: ArchitectLicense;
  form: FormGroup;
  show: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private architectLicenseService: ArchitectLicenseService,
    private route: ActivatedRoute,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.form = ArchitectLicense.GetFormGroup(this.formBuilder);
    this.show = !!this.license;
    if (this.show) {
      this.form.patchValue(this.license);
    }
  }

  async download(certificateVariant: string) {
    let personId = this.route.snapshot.params.personId;
    await this.architectLicenseService.download(personId, certificateVariant);
  }
}
