import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EmailNotFoundDialogue } from './email-not-found/email-not-found.dialogue';
import { RegexPatterns } from 'projects/common/src/lib/constants/regex-patterns';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent implements OnInit {
  applicationPath: string;
  enableEasyTesting: boolean;
  devAddMeToDb: boolean;
  e2eTesting: boolean;
  selectedTabIndex = 0;
  patterns = RegexPatterns;
  createAccountRequested: boolean;
  quickAccessEmail: string;
  signUpEmail: string;
  signUpPassword: string;
  confirmPassword: string;
  constructor(
    private auth: AuthService,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    public settings: TenantSettings,
    private tenantSettings: TenantSettings,
    private dialogue: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.createAccountRequested = false;
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/']);
      return;
    }
    this.applicationPath = this.route.snapshot.queryParamMap.get('application');
    this.enableEasyTesting = this.tenantSettings.enableEasyTesting;
    this.devAddMeToDb = this.tenantSettings.enableEasyTesting;
    if (this.route.snapshot.queryParamMap.get('login')) {
      this.openLoginTab();
    }
  }

  quickAccess(email) {
    this.accessRequest(email);
  }

  signUp(email, password) {
    this.createAccountRequested = true;
    this.accessRequest(email, password);
  }

  accessRequest(email, password = null) {
    let actionDescription = password ? 'Creating account' : 'Sending email';
    this.notificationService.notifyWait(actionDescription);
    let addAccount = this.enableEasyTesting
      ? this.accountService.add(email, password, this.devAddMeToDb, this.e2eTesting)
      : this.accountService.add(email, password);
    this.unsubOnDestroy = addAccount.subscribe(
      _ => {
        if (password) {
          this.router.navigate(['/accountcreated']);
        } else {
          this.auth.sendMagicLink(email, this.applicationPath);
        }
      },
      error => this.createAccountErrorHandler(error.message, email)
    );
  }

  login() {
    this.auth.login();
  }

  createAccountErrorHandler(errorMessage, email) {
    this.createAccountRequested = false;
    if (errorMessage == 'Email not found') {
      this.notificationService.closeLast();
      this.dialogue.open(EmailNotFoundDialogue, {
        data: {
          email: email,
        },
      });
    }
    if (errorMessage == 'Account already exists') {
      this.auth.login();
    }
    this.notificationService.notifyFail(errorMessage);
  }

  openLoginTab() {
    this.selectedTabIndex = 1;
  }
}
