import { UpdatableModel } from './updatable-model';
import { PersonLite } from './person-lite';
import * as moment from 'moment';
export class Note extends UpdatableModel<Note> {
  id: string;
  subjectId: string;
  authorId: string;
  author: PersonLite;
  text: string;
  createdOn: Date;
  updatedOn: Date;
  scope: string;

  update(init?: Partial<Note>) {
    super.update(init);
    this.author = new PersonLite(this.author);
    this.createdOn = moment.utc(this.createdOn).milliseconds(0).local().toDate();
    this.updatedOn = moment.utc(this.updatedOn).milliseconds(0).local().toDate();
  }

  get edited(): boolean {
    return this.updatedOn.getTime() > this.createdOn.getTime();
  }
}
