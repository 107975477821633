import { UpdatableModel } from './updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';
export class Name extends UpdatableModel<Name> {
  honorific: string;
  first: string;
  middle: string;
  last: string;
  suffix: string;
  complete: string;

  get firstLast() {
    return this.first && this.first + ' ' + this.last;
  }
  set firstLast(value) {}
  get suffixDisplay() {
    if (this.suffix == 'Jr') {
      return 'Jr.';
    }
    if (this.suffix == 'Sr') {
      return 'Sr.';
    }
    return this.suffix;
  }
  get full() {
    return [this.first, this.middle, this.last, this.suffixDisplay].filter(_ => _).join(' ');
  }
  set full(value) {}

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      honorific: '',
      first: '',
      middle: '',
      last: '',
      suffix: '',
      full: '',
    });
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    formGroup.controls.full.setValue(this.full);
  }
}
