import { download } from '../utility/download-utils';

export interface DownloadFileBase64 {
  filename: string;
  content: string;
  MimeType: string;
}

export function downloadAll(files: DownloadFileBase64[]) {
  files.map((f, i) => {
    const blob = asBlob(f);
    setTimeout(() => download(f.filename, blob), i * 500);
  });
}

function asBlob(file: DownloadFileBase64) {
  let binary = b64toBlob(file.content, file.MimeType);
  return new Blob([binary], {
    type: file.MimeType,
  });
}

//https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
