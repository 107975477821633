import { JurisdictionConstants } from './jurisdiction-constants';
import { Profession } from './profession';

export const NDConstants: JurisdictionConstants = {
  legal:
    'The legislature has enacted laws which govern the practice of architecture in North Dakota. In accordance with those laws, NDSBA has adopted rules which regulate the registration of individuals practicing architecture. To register as an architect, one must meet the education, experience and examination requirements. For more information see <a target="_blank" href="https://www.legis.nd.gov/information/acdata/html/Title8.html">Title 8 of the North Dakota Administrative Rules</a>.',
  acronym: 'NDSBA',
  workAddressSuffix: '',
  homeAddressSuffix: '',
  commonLinks: {
    publicSite: 'http://www.ndsba.net/',
    publicUrl: 'www.ndsba.net',
    registrationInfo: 'http://www.ndsba.net/',
    rules: 'http://www.legis.nd.gov/information/acdata/html/Title8.html',
    centuryCode: 'https://www.legis.nd.gov/cencode/t43c03.html',
    lapels: 'http://example.com/',
    architectLookup: 'http://www.ndsba.net/find-an-architect/',
    firmLookup: 'http://example.com/',
  },
  manualProfessions: [Profession.LandscapeArchitect],
  architectTabName: 'Licenses',
  ceRule: null,
  timezoneAbbreviation: 'CT',
  checkAddress: {
    address1: 'NDSBA',
    address2: 'PO Box 7370',
    cityStateZip: 'Bismarck, ND 58507-7370',
  },
  applicationStepContent: {
    militaryInfo: {
      question: 'Are you active military or the spouse of an active military member?',
    },
  },
};
