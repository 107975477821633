import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointService } from '../../services/breakpoint/breakpoint.service';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { filter } from 'rxjs';

@Component({
  selector: '[reopener]',
  template: 'chevron_left',
  styleUrls: ['./reopener.component.scss'],
})
export class ReopenerComponent extends BaseComponent implements AfterViewInit {
  private toggleTimeout;
  private isWithBreakpoint: boolean;

  @Output() openedChange = new EventEmitter<boolean>();

  @Input()
  matDrawer: MatDrawer;
  constructor(
    private element: ElementRef<HTMLElement>,
    private router: Router,
    private breakpointService: BreakpointService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.unsubOnDestroy = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(e => this.toggleAside(false));

    this.unsubOnDestroy = this.breakpointService.sm.subscribe(isWithBreakpoint => {
      this.isWithBreakpoint = isWithBreakpoint;
      this.matDrawer.mode = isWithBreakpoint ? 'over' : 'side';
      this.openedChange.emit(!isWithBreakpoint);
    });
    this.element.nativeElement.onmouseover = () => this.toggleAside(true);
    this.element.nativeElement.onclick = () => this.toggleAside(true);

    const matDrawerElement = (this.matDrawer as any)._elementRef.nativeElement;

    matDrawerElement.onmouseleave = () => this.scheduleCloseAside();
    matDrawerElement.onmouseenter = () => this.cancelScheduledCloseAside();
  }

  private toggleAside(open: boolean) {
    if (this.matDrawer.opened === open) {
      return;
    }
    if (!open && !this.isWithBreakpoint) {
      return;
    }
    this.openedChange.emit(open);
  }

  private scheduleCloseAside() {
    if (!this.matDrawer.opened || !this.isWithBreakpoint) {
      return;
    }
    this.toggleTimeout = setTimeout(() => this.toggleAside(false), 200);
  }

  private cancelScheduledCloseAside() {
    clearTimeout(this.toggleTimeout);
  }
}
