<span class="flex-fields">
  <span class="disable-checkbox" *ngIf="disableLabel">
    <mat-checkbox [(ngModel)]="disabled" (change)="onDisableChange($event)" name="disableBox" color="primary">{{
      disableLabel
    }}</mat-checkbox>
  </span>
  <mat-form-field appearance="standard">
    <mat-label>
      {{ label }}
    </mat-label>
    <input class="d-none" matInput [min]="min" [max]="max" [formControl]="control" [required]="required" />
    <mat-select
      [(ngModel)]="month"
      (selectionChange)="selectChanged()"
      [disabled]="readonly || disabled"
      placeholder="Month"
      panelClass="scroll"
      [required]="required"
    >
      <mat-option></mat-option>
      <mat-option
        *ngFor="let month of months"
        [value]="month.value"
        [class.d-none]="disableMonth(month.value)"
        [disabled]="disableMonth(month.value)"
      >
        {{ month.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard">
    <mat-select
      matInput
      [(ngModel)]="year"
      (selectionChange)="selectChanged()"
      [disabled]="readonly || disabled"
      placeholder="Year"
      panelClass="scroll"
      [required]="required"
    >
      <mat-option></mat-option>
      <mat-option *ngFor="let year of years" [value]="year" [class.d-none]="disableYear(year)" [disabled]="disableYear(year)">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</span>
