import { Component, Input } from '@angular/core';
import { FirmLicense } from '../../../models/firm-license';
import { LookupOptionComponent } from './lookup-option.component';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-firm-license-lookup-option',
  templateUrl: './firm-license-lookup-option.component.html',
  styleUrls: ['./lookup-option.component.scss'],
})
export class FirmLicenseLookupOptionComponent extends LookupOptionComponent {
  @Input() license: FirmLicense;
  constructor(settings: TenantSettings) {
    super(settings);
  }
}
