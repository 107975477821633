import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainTitleService {
  title$ = new ReplaySubject<string>(1);
  constructor() {}
}
