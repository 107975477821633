import { Component, HostBinding, Input } from '@angular/core';

//the component approximates what an angular readonly input looks like
@Component({
  selector: 'lib-read-field',
  templateUrl: './read-field.component.html',
  styleUrls: ['./read-field.component.scss'],
})
export class ReadFieldComponent {
  //lib-read-field
  @Input() value: string;
  @HostBinding('class.opt-in') optin = true;
}
