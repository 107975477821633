import { UpdatableModel } from './updatable-model';
import { FirmRelationship } from './firm-relationship';

export class FirmRelationships extends UpdatableModel<FirmRelationships> {
  successorFirmRelationship: FirmRelationship;
  pastFirmRelationships: FirmRelationship[];

  update(init: Partial<FirmRelationships>) {
    super.update(init);
    if (this.successorFirmRelationship) {
      this.successorFirmRelationship = new FirmRelationship(this.successorFirmRelationship);
    }
    if (this.pastFirmRelationships) {
      this.pastFirmRelationships = this.pastFirmRelationships.map(r => new FirmRelationship(r));
    }
  }
}
