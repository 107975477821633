import { AfterViewInit, Directive, Host, HostBinding, OnInit, Optional, Self } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatMenu } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[ls-style-opt-in], ls-style-opt-in',
})
export class StyleOptInDirective implements OnInit {
  panelClassComponent: { panelClass: string | string[] | Set<string> | { [key: string]: any } };
  @HostBinding('class.opt-in') optin = true;
  constructor(
    @Host() @Self() @Optional() public matMenu: MatMenu,
    @Host() @Self() @Optional() public matSelect: MatSelect,
    @Host() @Self() @Optional() public matDatePicker: MatDatepicker<any> // TODO any other panelClass components
  ) {
    this.panelClassComponent = this.matMenu || this.matSelect || this.matDatePicker;
  }
  ngOnInit() {
    const panelClassComp = this.matMenu || this.matSelect || (this.matDatePicker as { panelClass: string });
    if (panelClassComp) {
      // tbh, not sure how to stop the panelClass from overwriting any other class set on the component
      // so, for those cases, just add a div ls-style-opt-in inside the mat-menu
      panelClassComp.panelClass = 'opt-in';
    }
  }
}
