<ng-content select="input"></ng-content>
<div class="selected-display" *ngIf="!textDisplay && selected" (click)="clearAndOpen()">
  <ng-container *ngTemplateOutlet="template; context: { $implicit: selected }"> </ng-container>
</div>
<div class="selected-display" *ngIf="!textDisplay && addNew" (click)="clearAndOpen()">
  <span class="add-new"> + Add New</span> {{ addNew }}
</div>
<mat-autocomplete class="lib-autocomplete-panel" [displayWith]="displayWith" (optionSelected)="onOptionSelected($event)">
  <mat-option *ngFor="let option of resultsFetched | async" [value]="option" [disabled]="checkOptionDisabled(option)">
    <ng-container *ngTemplateOutlet="template; context: { $implicit: option, highlight: typing }"> </ng-container>
  </mat-option>
  <mat-option *ngIf="areThereMore" [disabled]="true">
    <em class="muted">keep typing to view more focused search results...</em>
  </mat-option>
  <mat-option *ngIf="offerAddNew && (typing || (resultsFetched | async))" [value]="addNewValue">
    <span class="add-new"> + Add New</span>
    {{ typing }}
  </mat-option>
</mat-autocomplete>
