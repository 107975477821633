import { Injectable } from '@angular/core';
import { ApiService } from 'projects/common/src/lib/services/api/api.service';

interface ContentRequest {
  contentKey: string;
  contentType: string;
}

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private runningPromise: Promise<Array<string>>;

  constructor(private api: ApiService) {}

  private queuedParams: Array<ContentRequest> = [];

  getLabel = (contentRequest: ContentRequest): Promise<string> => {
    const index = this.queuedParams.length;

    this.queuedParams.push(contentRequest);

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 1);
    })
      .then(() => {
        if (!this.runningPromise) {
          this.runningPromise = this.callApiAsync();
        }
        //console.log('starting api slug');
        return this.runningPromise;
      })
      .then(results => {
        //console.log('ending api slug');
        this.runningPromise = null;
        return results[index];
      });
  };

  private async callApiAsync(): Promise<Array<string>> {
    const post = this.api.postAsync<Array<string>>('content', this.queuedParams);
    this.queuedParams = [];
    return post;
  }
}
