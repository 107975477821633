import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Firm } from 'projects/common/src/lib/models/firm';
import { BaseComponent } from 'projects/common/src/lib/components/base.component';

@Component({
  selector: 'app-portal-firm-info',
  templateUrl: './portal-firm-info.component.html',
  styleUrls: ['./portal-firm-info.component.scss'],
})
export class PortalFirmInfoComponent extends BaseComponent implements OnInit {
  @Input() firm$: Observable<Firm>;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.unsubOnDestroy = this.firm$.subscribe(f => this.setFirm(f));
  }

  setFirm(firm: Firm) {
    this.form = Firm.GetFormGroup(this.formBuilder);
    firm.patch(this.form);
  }
}
