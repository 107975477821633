import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { QuestionnaireResponse } from '../../models/questionnaire/questionnaire-response';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(private api: ApiService) {}

  private getPath(applicationId: string) {
    return 'application/' + applicationId + '/questionnaire';
  }

  getQuestionnaire(applicationId: string, prePopulate: boolean): Observable<QuestionnaireResponse> {
    let qs = this.api.queryStringify({ prePopulate });
    return this.api.get(this.getPath(applicationId).concat(qs)).pipe(map(data => new QuestionnaireResponse(data)));
  }

  answerQuestionnaire(applicationId: string, questionnaire: QuestionnaireResponse): Observable<QuestionnaireResponse> {
    return this.api.post(this.getPath(applicationId), questionnaire).pipe(map(data => new QuestionnaireResponse(data)));
  }
}
