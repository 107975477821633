import { Component, Input } from '@angular/core';

export interface CheckListConfig<T> {
  isChecked(item: T): boolean;
  toggle(item: T): void;
  items: T[];
  itemName(item: T): string;
}

@Component({
  selector: 'selectable-menu-items',
  template: ` <button mat-menu-item *ngFor="let item of config.items" (click)="toggle($event, item)">
    <mat-icon [color]="checkboxColor(item)">{{ checkboxIcon(item) }}</mat-icon>
    {{ config.itemName(item) }}
  </button>`,
})
export class SelecableMenuItemsComponent {
  @Input() config: CheckListConfig<any>;
  isSelected(item: any) {
    return this.config.isChecked(item);
  }

  checkboxIcon(item) {
    return this.isSelected(item) ? 'check_box' : 'check_box_outline_blank';
  }

  checkboxColor(item) {
    if (this.isSelected(item)) {
      return 'primary';
    }
    return '';
  }

  toggle($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    this.config.toggle(item);
  }
}
