import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'footer[lib-review-footer]',
  templateUrl: './review-footer.component.html',
  styleUrls: ['./review-footer.component.scss'],
})
export class ReviewFooterComponent {
  @HostBinding('class') get getClass() {
    return 'd-flex mt-large';
  }
}
