import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
  name: 'initialsCapCase',
})
export class InitialsCapCasePipe implements PipeTransform {
  transform(str: string) {
    if (!str) {
      return str;
    }
    return str.replace(/([a-z])/g, '').trim();
  }
}
