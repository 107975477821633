<mat-form-field appearance="standard">
  <mat-label>{{ label }}</mat-label>
  <lib-autocomplete
    #auto="autocomplete"
    [search]="search"
    (optionSelected)="onOptionSelected($event)"
    (cleared)="onCleared($event)"
  >
    <input
      matInput
      [required]="true"
      [placeholder]="placeholder"
      [formControl]="control"
      [matAutocomplete]="auto.autocomplete"
    />
    <ng-template let-license let-highlight="highlight">
      <lib-firm-license-lookup-option [license]="license" [highlight]="highlight"> </lib-firm-license-lookup-option>
    </ng-template>
  </lib-autocomplete>
  <button type="button" mat-icon-button matSuffix>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>
