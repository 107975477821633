import { Injectable } from '@angular/core';
import { ReplaySubject, of, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FirmAvailable } from 'projects/common/src/lib/models/firm-available';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { FirmService } from 'projects/common/src/lib/services/api/firm.service';

@Injectable({
  providedIn: 'root',
})
export class FirmAvailableService {
  firms$ = new ReplaySubject<FirmAvailable[]>(1);
  dict: { [id: string]: FirmAvailable };
  constructor(
    private authService: AuthService,
    private firmService: FirmService
  ) {
    this.dict = {};
    this.authService.isAuthenticated$.subscribe(isAuth => {
      let obs = null as Observable<FirmAvailable[]>;
      if (!isAuth) {
        this.firms$.next([]);
        return;
      }
      let firms = this.authService.claims.firms;
      let needsInfo = firms.some(f => !this.dict[f.id]);
      obs = needsInfo ? firmService.available() : of([] as FirmAvailable[]);
      obs.pipe(first()).subscribe(fas => {
        fas.forEach(fa => (this.dict[fa.id] = fa));
        let firms = this.authService.claims.firms.map(f => this.dict[f.id]);
        // there is a prosumption that all the firm claims will be retrieved in the firmService.available
        // but an error suggests an edge-case, hence the !!f check ¯\_(ツ)_/¯
        firms = firms.filter(f => !!f && !f.isSwitch);
        firms = firms.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.firms$.next(firms);
      });
    });
  }
}
