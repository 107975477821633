<table mat-table [dataSource]="order?.items" class="my-medium">
  <ng-container matColumnDef="name">
    <td mat-cell *matCellDef="let item">{{ item.name }}</td>
    <td mat-footer-cell *matFooterCellDef [class.on-error-text]="order?.isDiscounted">
      <ls-subtitle level="2" [zeroMargin]="true">Total</ls-subtitle>
    </td>
  </ng-container>
  <ng-container matColumnDef="amount">
    <td mat-cell *matCellDef="let item">{{ item.amount | currency }}</td>
    <td mat-footer-cell *matFooterCellDef [class.on-error-text]="order?.isDiscounted">
      <ls-subtitle level="2" [zeroMargin]="true">{{ order?.totalAmount | currency }}</ls-subtitle>
    </td>
  </ng-container>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
  <tr mat-footer-row *matFooterRowDef="columns"></tr>
</table>
