<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
    <td mat-cell *matCellDef="let element">{{ element.transactionDate | date: 'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="orderNumber">
    <th mat-header-cell *matHeaderCellDef>Confirmation<br />Number</th>
    <td mat-cell *matCellDef="let element">{{ element.orderNumber }}</td>
  </ng-container>
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>
      Description
      <span class="crimper"
        ><mat-icon (click)="toggleCrimp()">{{ (crimp$ | async) ? 'unfold_more' : 'unfold_less' }}</mat-icon></span
      >
    </th>
    <td mat-cell *matCellDef="let element">
      <div [class.clamp-2]="crimp$ | async">{{ element.description }}</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="paymentMethodType">
    <th mat-header-cell *matHeaderCellDef>Payment<br />Type</th>
    <td mat-cell *matCellDef="let element">{{ element.paymentMethodType }}</td>
  </ng-container>
  <ng-container matColumnDef="paymentDescription">
    <th mat-header-cell *matHeaderCellDef>Payment #</th>
    <td mat-cell *matCellDef="let element" [class.crimp]="crimp$ | async">
      {{ element.paymentDescription }}
    </td>
  </ng-container>
  <ng-container matColumnDef="totalAmount">
    <th mat-header-cell *matHeaderCellDef>Amount</th>
    <td mat-cell *matCellDef="let element">{{ element.totalAmount | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="invoice">
    <th mat-header-cell *matHeaderCellDef width="1">Invoice</th>
    <td mat-cell *matCellDef="let element"></td>
  </ng-container>
  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">No Payments on file</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>
