import * as moment from 'moment-timezone';

export function formatNcarbDate(date, mode: ModeType = 'date') {
  if (!date) {
    return date;
  }
  let dateAtNcarb = moment.tz(date, 'America/New_York').local();
  if (mode == 'date' && date.indexOf && date.indexOf('T00:00:00') > -1) {
    dateAtNcarb = moment.utc(date);
  }
  return formatMoment(dateAtNcarb, mode);
}

export function formatDatepickerUtc(date, mode: ModeType = 'date') {
  if (!date) {
    return date;
  }
  const localDate = moment.utc(date).local(true);
  return formatMoment(localDate, mode);
}

export function parseDateAsMoment(date, mode?: ModeType): moment.Moment {
  if (!date) {
    return null;
  }
  if (date.indexOf && (date.indexOf('T00:00:00') > -1 || date.indexOf('T') === -1 || mode == 'utc')) {
    return moment.utc(date);
  }
  return moment.utc(date).local();
}

export function formatDateTime(date, mode: ModeType) {
  return date && formatMoment(parseDateAsMoment(date, mode), mode);
}

export function formatMoment(momentDate: moment.Moment, mode: ModeType) {
  return (
    momentDate && momentDate.format(TIMEFORMATS[mode]) + (/time/.test(mode) ? moment.tz(moment.tz.guess()).format(' z') : '')
  );
}

export const TIMEFORMATS: Record<ModeType, string> = {
  datetime: 'MM/DD/YYYY hh:mma',
  date: 'MM/DD/YYYY',
  longDate: 'MMMM D, Y',
  time: 'hh:mma',
  monthYear: 'MMMM Y',
  utc: 'MM/DD/YYYY hh:mma UTC',
};

export type ModeType = 'datetime' | 'date' | 'longDate' | 'time' | 'monthYear' | 'utc';
