import { LookupItem } from './lookup-item';
import { UpdatableModel } from '../updatable-model';

// A lookup item that supports being mixed with lookup items
// of other types.
export class GlobalSearchLookupItem extends UpdatableModel<GlobalSearchLookupItem> implements LookupItem {
  lookupId: string;
  lookupName: string;
  lookupStatus: string;
  lookupInfo: string;

  lookupType: string;
}
