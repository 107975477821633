<lib-titled-card *ngIf="pointOfContact$ | async">
  <h2>Point of Contact</h2>
  <mat-menu>
    <button mat-button type="button" routerLink="pointofcontact">Update Point of Contact</button>
  </mat-menu>
  <p class="note">
    The Point of Contact has the authority to perform any duties pertaining to the maintenance of your firm's license and record
    with {{ constants.acronym }}. This does not absolve the firm's owners, directors, or supervising professionals from any of
    their responsibilities for the firm.
  </p>
  <lib-firm-point-of-contact-display [pointOfContact$]="pointOfContact$"> </lib-firm-point-of-contact-display>
</lib-titled-card>
