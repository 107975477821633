// modified from
// https://github.com/thisissoon/angular-scrollspy/blob/master/src/app/scroll-spy/scroll-spy-section/scroll-spy-section.component.ts
import { Component, Input } from '@angular/core';
import { ScrollSpyService } from './scroll-spy.service';

/**
 * A component to wrap section content within that will update the
 * `ScrollSpyService` when it's in view
 *
 * @example
 * ```html
 *  <sn-scroll-spy-section id="section1" for="foo">
 *    ...
 *  </sn-scroll-spy-section>
 * ```
 */
@Component({
  selector: 'sn-scroll-spy-section',
  template: `<div class="sn-hidden" snInViewport (inViewportChange)="onInViewportChange($event)"></div>
    <ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }

      .sn-hidden {
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
      }
    `,
  ],
})
export class ScrollSpySectionComponent {
  /**
   * Identifies the section
   *
   * @memberof ScrollSpySectionComponent
   */
  @Input()
  public id: string;
  /**
   * Specifies which `ScrollSpy` instance to update
   *
   * @memberof ScrollSpySectionComponent
   */
  @Input()
  public for: string;
  /**
   * Creates an instance of ScrollSpySectionComponent.
   * @memberof ScrollSpySectionComponent
   */
  constructor(private scrollSpySvc: ScrollSpyService) {}
  /**
   * Updates `ScrollSpy` section when element enters/leaves viewport
   *
   * @memberof ScrollSpySectionComponent
   */
  public onInViewportChange(inViewport: boolean): void {
    this.scrollSpySvc.setSpySectionStatus(this.id, this.for, inViewport);
  }
}
