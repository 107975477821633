import { FormGroup } from '@angular/forms';

export function touchFormGroupErrors(formGroup: FormGroup) {
  for (const field in formGroup.controls) {
    let control = formGroup.get(field);
    if (control['controls']) {
      touchFormGroupErrors(control as FormGroup);
    } else if (control.invalid && !control.touched) {
      control.markAsTouched();
    }
  }
}
export function getFormErrorMessage(formGroup: FormGroup, defaultMessage: string): string {
  const errorList = new Array<string>();
  Object.keys(formGroup.controls).forEach(groupKey => {
    let group = formGroup.controls[groupKey] as FormGroup;

    if (group.errors) {
      Object.keys(group.errors).forEach(errorKey => {
        let error = group.errors[errorKey];
        if (error.message) errorList.push(error.message);
        else if (errorKey === 'required') errorList.push(`${groupKey} required`);
        else if (error.actualValue && error.requiredPattern) errorList.push(`${groupKey} not valid`);
      });
    }
  });
  return errorList.join('\n') || defaultMessage;
}
export function getApiErrorMessage(exception: any, defaultMessage: string) {
  let errors = [defaultMessage];
  for (let errorKey in exception.error) {
    errors.push(exception.error[errorKey]);
  }
  return errors.join(' ');
}

//Won't work under sume conditions in safari
//https://github.com/angular/angular/issues/24836#issuecomment-1023819950
export function formGroupIsCompletelyUntouched(formGroup: FormGroup): boolean {
  for (const field in formGroup.controls) {
    let control = formGroup.get(field);
    if (control['controls']) {
      if (!formGroupIsCompletelyUntouched(control as FormGroup)) {
        return false;
      }
    }
  }
  return formGroup.untouched;
}

export function formGroupIsCompletelyValid(formGroup: FormGroup): boolean {
  for (const field in formGroup.controls) {
    let control = formGroup.get(field);
    if (control['controls']) {
      if (!formGroupIsCompletelyValid(control as FormGroup)) {
        return false;
      }
    }
  }
  return formGroup.valid || formGroup.disabled;
}
