import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SaveParameters } from '../person-detail-base.component';
import { TenantSettings } from '../../../constants/jurisdiction/TenantSettings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../../../models/address';
import { JurisdictionConstants } from '../../../constants/jurisdiction/jurisdiction-constants';
import { AddressService } from '../../../services/address.service';
import { Subscription } from 'rxjs';

//consolidate with FirmDetailAddressComponent?
@Component({
  selector: 'app-person-home-address',
  templateUrl: './person-home-address.component.html',
  styleUrls: ['./person-home-address.component.scss'],
})
export class PersonHomeAddressComponent implements OnInit, OnDestroy {
  hideCounty: boolean;
  line1Label: string;
  line2Label: string;
  addressForm: FormGroup;
  isEditing: boolean;
  @Output() onSectionSave = new EventEmitter<SaveParameters>();
  private formSnapshot: FormGroup;
  private unsubscribeOnDestroy: Subscription;

  edit() {
    this.formSnapshot = this.addressForm.getRawValue();
    this.isEditing = true;
  }

  cancel() {
    this.addressForm.setValue(this.formSnapshot);
    this.isEditing = false;
  }

  constructor(
    public constants: JurisdictionConstants,
    public settings: TenantSettings,
    private addressService: AddressService,
    formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.line1Label = this.settings.architectProfileSettings.homeAddressFirstLineLabel ?? 'Home Address First Line';
    this.line2Label = this.settings.architectProfileSettings.homeAddressSecondLineLabel ?? 'Home Address Second Line';
    this.hideCounty = this.settings.architectProfileSettings.hideCounty;
    this.addressForm = Address.GetFormGroup(formBuilder);
  }

  ngOnInit() {
    //need to do this because onInit doesn't run when switching between two different people so address wouldn't update
    this.unsubscribeOnDestroy = this.route.params.subscribe(async params => {
      const address = await this.addressService.getAddress(params.personId, 'home', true);
      this.addressForm.patchValue(address);
    });
  }

  ngOnDestroy() {
    this.unsubscribeOnDestroy.unsubscribe();
  }

  save() {
    this.onSectionSave.emit({
      form: this.addressForm,
      defaultErrorMessage: 'There was an issue updating address information.',
      successMessage: 'Address information was saved',
      callApi: async () => {
        const updatedAddress = await this.addressService.updateAddress(
          this.route.snapshot.params.personId,
          'home',
          this.addressForm.value
        );
        this.addressForm.patchValue(updatedAddress); //zip might be updated and any validation errors
        this.isEditing = false;
      },
    });
  }
}
