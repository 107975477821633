import { LAConfirmationService } from './LA-confirmation-service';
import { NDConfirmationService } from './ND-confirmation-service';
import { DemoConfirmationService } from './DEMO-confirmation-service';
import { NEConfirmationService } from './NE-confirmation-service';
import { ALConfirmationService } from './AL-confirmation-service';
import { JurisdictionConfirmationService } from './jurisdiction-confirmation-service';
import { FormBuilder } from '@angular/forms';
import { JurisdictionConstants } from '../jurisdiction-constants';
import { TenantSettings } from '../TenantSettings';
import { NEBOGConfirmationService } from './NEBOG-confirmation-service';

const allJurisdictionConfirmations = {
  LA: LAConfirmationService,
  ND: NDConfirmationService,
  DEMO: DemoConfirmationService,
  AL: ALConfirmationService,
  NE: NEConfirmationService,
  NEBOG: NEBOGConfirmationService,
};

export const JurisdictionConfirmationServiceProvider = {
  provide: JurisdictionConfirmationService,
  useFactory: (formBuilder: FormBuilder, constants: JurisdictionConstants, settings: TenantSettings) =>
    JurisdictionConfirmationServiceForJurisdiction(formBuilder, constants, settings),
  deps: [FormBuilder, JurisdictionConstants, TenantSettings],
};

function JurisdictionConfirmationServiceForJurisdiction(
  formBuilder: FormBuilder,
  constants: JurisdictionConstants,
  settings: TenantSettings
): JurisdictionConfirmationService {
  const confirmationService = allJurisdictionConfirmations[settings.tenant];
  if (!confirmationService) {
    throw new Error(`no Jurisdiction Confirmation Service found for ${settings.tenant}`);
  }
  return new confirmationService(formBuilder, constants, settings);
}
