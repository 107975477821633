// https://medium.com/@zeljkoradic/loader-bar-on-every-http-request-in-angular-6-60d8572a21a9
import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, interval, combineLatest, Subscription } from 'rxjs';
import { takeUntil, filter, map, distinctUntilChanged } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class XhrProgressService {
  requests$ = new Subject<number>();
  progress$ = new ReplaySubject<number>(1);

  constructor() {
    let onOffs$ = this.requests$.pipe(
      map(r => !!r),
      distinctUntilChanged()
    );
    let turnOff$ = onOffs$.pipe(filter(on => !on));
    let sub: Subscription;
    turnOff$.subscribe(_ => {
      this.progress$.next(0);
      if (sub) {
        sub.unsubscribe();
      }
    });
    onOffs$.subscribe(on => {
      if (on) {
        let interval$ = interval(10).pipe(takeUntil(turnOff$));
        sub = combineLatest(interval$, this.requests$).subscribe(([hundredths, requests]) => {
          let log = Math.log(hundredths + 1);
          let multiplier = 14 - requests;
          let progress = Math.min(99, multiplier * (log + 1));
          this.progress$.next(progress);
        });
      }
    });
  }
  requests(count: number) {
    this.requests$.next(count);
  }
}
