import { Component, Output, EventEmitter, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LicenseStatus } from 'projects/common/src/lib/components/pill/status-pill.component';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { TestInfo } from 'projects/common/src/lib/services/api/architect-application.service';
import { isMoment } from 'moment';
import * as moment from 'moment';
import { NotificationService } from 'projects/common/src/lib/services/notification.service';
import { spaceCapCase } from 'projects/common/src/lib/utility/string-utils';

@Component({
  selector: 'license-wizard',
  templateUrl: './license-wizard.component.html',
  styleUrls: ['./license-wizard.component.scss'],
})
export class LicenseWizardComponent {
  @HostBinding('class.flex-column') optin = true;
  @Output() submit = new EventEmitter<WizardSetupApplicationArgs | true>();
  hintText: String;

  @Input()
  label: String;
  @Input()
  hasEmeritus: boolean;
  @Input()
  testOptions: TestInfo;
  @Input()
  isCreateInitialApp: boolean;

  expirationDate: FormControl;
  addApplication: boolean = true;
  licenseStatus?: LicenseStatus;

  minDate: moment.Moment;
  maxDate: moment.Moment;
  isEmeritus: boolean = false; //TODO: set to true if emeritus
  constructor(
    public settings: TenantSettings,
    private notificationService: NotificationService
  ) {
    this.expirationDate = new FormControl();
  }

  setIsEmeritus(checked: boolean) {
    this.isEmeritus = checked;
    if (checked && this.licenseStatus === 'Delinquent') {
      this.licenseStatus = 'Due';
    }
  }

  statusSummary(): {
    licenseStatus: String;
    start?: String;
    end?: String;
  }[] {
    return Object.entries(this.testOptions.activeRange).map(([licenseStatus, value]) => ({
      licenseStatus: licenseStatus,
      ...value,
    }));
  }

  //if there's a rename, displays the original status within parentheses
  displayOriginalLabelIfRemapped(status) {
    const label = this.settings.getStatusLabel(status);
    const displayStatus = spaceCapCase(status);
    if (label !== displayStatus) return `(${displayStatus})`;
  }

  onStatusChanged() {
    const { hintText, minDate, maxDate } = this.getRangeAndHint(this.licenseStatus);

    this.hintText = hintText;
    this.minDate = minDate;
    this.maxDate = maxDate;
    if (minDate || maxDate) {
      //if minDate and maxDate not set, no need to re-check that expirationDate is in range
      const newDate = this.calculateNewDate();
      if (this.expirationDate.value != newDate) {
        this.expirationDate.setValue(newDate);
      }
    }
  }

  getRangeAndHint(licenseStatus?: LicenseStatus): {
    minDate: moment.Moment;
    maxDate: moment.Moment;
    hintText: string;
  } {
    if (!licenseStatus) {
      return {
        minDate: null,
        maxDate: null,
        hintText: '',
      };
    }
    let { start, end } = this.testOptions.expirationDates[licenseStatus];
    if (licenseStatus === 'Due' && this.isEmeritus) {
      start = this.testOptions.expirationDates.Delinquent.start;
    }

    if (!start) {
      return {
        hintText: `<=${end}`,
        minDate: moment(start, 'MM/DD/YYYY'),
        maxDate: moment(end, 'MM/DD/YYYY'),
      };
    } else if (!end) {
      return {
        hintText: `${start}<=`,
        minDate: moment(start, 'MM/DD/YYYY'),
        maxDate: moment(end, 'MM/DD/YYYY'),
      };
    }

    return {
      hintText: `${start}-${end}`,
      minDate: moment(start, 'MM/DD/YYYY'),
      maxDate: moment(end, 'MM/DD/YYYY'),
    };
  }

  calculateNewDate(): moment.Moment {
    if (isMoment(this.expirationDate.value)) {
      if (this.minDate.isValid() && this.minDate.isAfter(this.expirationDate.value)) {
        return this.minDate;
      } else if (this.maxDate.isValid() && this.maxDate.isBefore(this.expirationDate.value)) {
        return this.maxDate;
      }
      //expiration Date between minDate and max date
      return this.expirationDate.value;
    }
    //expirationDate not set
    return this.minDate.isValid() ? this.minDate : this.maxDate;
  }

  async setupApplication() {
    if (this.isCreateInitialApp) {
      this.submit.emit(true);
    }
    if (!this.isCreateInitialApp && !this.expirationDate.value) {
      this.expirationDate.markAsTouched();
      this.notificationService.notifyFormIssue('Need to fill in expiration date');
      return;
    }

    this.submit.emit({
      isEmeritus: this.isEmeritus,
      licenseStatus: this.licenseStatus,
      addApplication: this.licenseStatus == 'Active' ? false : this.addApplication,
      newDelinquentDate: this.expirationDate.value.format('MM/DD/YYYY'),
    });
  }
}

export interface WizardSetupApplicationArgs {
  isEmeritus: boolean;
  addApplication: boolean;
  licenseStatus: LicenseStatus;
  newDelinquentDate: string;
}
