import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';

@Injectable()
export class ClaimCheckResolve implements Resolve<any> {
  constructor(
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  async resolve(): Promise<any> {
    return await this.accountService.portalClaimsCheck().then(ok => {
      if (!ok) {
        this.authService.renewToken();
      }
    });
  }
}
