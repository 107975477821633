import { Injectable } from '@angular/core';
import { ReplaySubject, of, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { ArchitectAvailable } from 'projects/common/src/lib/models/architect-available';
import { PersonPointOfContactService } from 'projects/common/src/lib/services/api/person-point-of-contact.service';

@Injectable({
  providedIn: 'root',
})
export class ArchitectAvailableService {
  architects$ = new ReplaySubject<ArchitectAvailable[]>(1);
  dict: { [id: string]: ArchitectAvailable };
  constructor(
    private authService: AuthService,
    private personPointOfContactService: PersonPointOfContactService
  ) {
    this.dict = {};
    this.authService.isAuthenticated$.subscribe(isAuth => {
      let obs = null as Observable<ArchitectAvailable[]>;
      if (!isAuth) {
        this.architects$.next([]);
        return;
      }
      let pocFor = this.authService.claims.personPointOfContactFor;
      let needsInfo = pocFor.some(id => !this.dict[id]);
      obs = needsInfo
        ? personPointOfContactService.available(this.authService.claims.personId)
        : of([] as ArchitectAvailable[]);
      obs.pipe(first()).subscribe(availables => {
        availables.forEach(available => (this.dict[available.id] = available));
        availables = this.authService.claims.personPointOfContactFor.map(id => this.dict[id]);
        availables = availables.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.architects$.next(availables);
      });
    });
  }
}
