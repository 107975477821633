import { AvailableApplication } from './available-application';

export class FirmAvailableApplication extends AvailableApplication {
  licenseType: string;
  licenseTypeName: string;
  isLicenseTypeSwitch: boolean;
  switchingFirmId?: string;

  get title(): string {
    return `${this.licenseTypeName} - ${this.applicationTypeName}`;
  }

  // Sorts FirmAvailableApplications in the order
  // 1. In progress
  // 2. Non-initial
  // 3. Initial
  static compare(a: FirmAvailableApplication, b: FirmAvailableApplication): number {
    if (a.isInProgress && !b.isInProgress) {
      return 1;
    }
    if (!a.isInProgress && b.isInProgress) {
      return -1;
    }
    if (a.applicationType !== 'License' && b.applicationType === 'License') {
      return 1;
    }
    if (a.applicationType === 'License' && b.applicationType !== 'License') {
      return -1;
    }
    return 0;
  }
}
