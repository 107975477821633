import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdatableModel } from './updatable-model';
import { Name } from './name';

//Maps to PersonPersonalInfoModel.cs
//Only used on Profile | Personal Information form on manager and portal
export class PersonPersonalInfo extends UpdatableModel<PersonPersonalInfo> {
  id: string;
  name: Name;
  credentials: string;
  dateOfBirth: Date;
  gender: string;
  socialSecurityNumber: string;
  visaNumber: string;
  uscisNumber: string;
  immigrationStatus: string;
  ncarbNumber?: number;
  clarbNumber?: number;

  update(init?: Partial<PersonPersonalInfo>) {
    super.update(init);

    if (this.name) {
      this.name = new Name(this.name);
    }
  }

  patch(formGroup: FormGroup) {
    this.cleanup();
    formGroup.controls.id.setValue(this.id);
    formGroup.controls.names.patchValue({
      name: this.name,
      nameFull: this.name.full,
      credentials: this.credentials,
    });
    formGroup.controls.info.patchValue({
      dateOfBirth: this.dateOfBirth,
      gender: this.gender,
      socialSecurityNumber: this.socialSecurityNumber,
      visaNumber: this.visaNumber,
      uscisNumber: this.uscisNumber,
      immigrationStatus: this.immigrationStatus,
      ncarbNumber: this.ncarbNumber,
      clarbNumber: this.clarbNumber,
    });
  }

  acceptPatch(form: FormGroup) {
    this.cleanup();

    this.acceptNamePatch(<FormGroup>(<FormGroup>form.controls.names).controls.name);
    this.acceptCredentialsPatch(<FormGroup>(<FormGroup>form.controls.names).controls.credentials);
    this.acceptInfoPatch(<FormGroup>form.controls.info);
  }

  acceptNamePatch(form: FormGroup) {
    const name = form.value;

    this['name'] = new Name(name);
  }

  acceptCredentialsPatch(form: FormGroup) {
    const credentials = form.value;

    this['credentials'] = credentials;
  }

  acceptInfoPatch(form: FormGroup) {
    const info = form.getRawValue();

    this['dateOfBirth'] = info.dateOfBirth;
    this['gender'] = info.gender;
    this['socialSecurityNumber'] = info.socialSecurityNumber;
    this['visaNumber'] = info.visaNumber;
    this['uscisNumber'] = info.uscisNumber;
    this['immigrationStatus'] = info.immigrationStatus;
    this['ncarbNumber'] = info.ncarbNumber;
    this['clarbNumber'] = info.clarbNumber;
  }

  cleanup() {
    if (this.socialSecurityNumber) {
      if (/\d{9}/.test(this.socialSecurityNumber)) {
        this.socialSecurityNumber = this.socialSecurityNumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  populateForm(formGroup: FormGroup) {
    this.cleanup();
    formGroup.patchValue(this);
  }

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: Name.GetFormGroup(formBuilder),
      credentials: '',
      dateOfBirth: '',
      gender: '',
      socialSecurityNumber: '',
      noSocialSecurityNumber: '',
      visaNumber: '',
      uscisNumber: '',
      immigrationStatus: '',
      ncarbNumber: '',
      clarbNumber: '',
    });
  }
}
