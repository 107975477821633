import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PersonPointOfContactService } from '../services/api/person-point-of-contact.service';
import { PersonPointOfContact } from '../models/person-point-of-contact';

@Injectable()
export class ArchitectPointOfContactResolve implements Resolve<PersonPointOfContact[]> {
  constructor(private pointOfContactService: PersonPointOfContactService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PersonPointOfContact[]> {
    return this.pointOfContactService.get(route.params.personId);
  }
}
