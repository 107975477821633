import { Component, OnInit, Input } from '@angular/core';
import { QuestionnaireResponse } from 'projects/common/src/lib/models/questionnaire/questionnaire-response';
import { QuestionnaireResponseAnswer } from 'projects/common/src/lib/models/questionnaire/questionnaire-response-answer';
import { UploadViewStyle } from '../../../components/upload-widget/upload-widget.component';
import { ActivatedRoute } from '@angular/router';
import { getPersonOrFirmId } from '../../../utility/router-extensions';

@Component({
  selector: 'lib-moral-character-review',
  templateUrl: './moral-character-review.component.html',
  styleUrls: ['./moral-character-review.component.scss'],
})
export class MoralCharacterReviewComponent implements OnInit {
  @Input() questionnaireResponse: QuestionnaireResponse;
  answers: QuestionnaireResponseAnswer[];
  tableStyle = UploadViewStyle.Table;
  aggregateRootId: string;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.answers = this.questionnaireResponse && this.questionnaireResponse.answers;
    this.aggregateRootId = getPersonOrFirmId(this.route);
  }
}
