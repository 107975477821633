import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkHistoryService } from 'projects/common/src/lib/services/api/work-history.service';
import { AuthService } from 'projects/common/src/lib/services/auth/auth.service';
import { AccountService } from 'projects/common/src/lib/services/api/account.service';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'app-setup-manage-firm',
  templateUrl: './setup-manage-firm.component.html',
  styleUrls: ['./setup-manage-firm.component.scss'],
})
export class SetupManageFirmComponent implements OnInit {
  @Output() change = new EventEmitter();
  show: boolean;
  constructor(
    private authService: AuthService,
    private workHistoryService: WorkHistoryService,
    private accountService: AccountService,
    private settings: TenantSettings,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setShow();
    this.authService.isAuthenticated$.subscribe(isAuth => {
      this.setShow();
    });
  }

  setShow() {
    this.show =
      this.settings.enableEasyTesting &&
      !this.authService.claims.managedFirms.length &&
      this.authService.claims.personId == this.route.snapshot.params.personId;
  }

  setupManageFirm() {
    this.workHistoryService.setupCanManage(this.authService.claims.personId).subscribe(a => {
      this.accountService.hasCheckedClaims = false;
      this.change.emit();
    });
  }
}
