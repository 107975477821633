<article class="opt-in">
  <header>
    <h1>{{ settings.boardName }}</h1>
    <h2>{{ title }}</h2>
  </header>
  <main>
    <div class="flex-justify">
      <h1>{{ person.name.full }}</h1>
      <span class="labeled-info">
        <label>Application Date:</label>
        {{ application.submittedOn | date: 'MM/dd/yyyy' }}
      </span>
    </div>
    <div *ngIf="application.licenseNumber" class="licenseNumber">
      <strong>License # {{ application.licenseNumber }}</strong>
    </div>

    <h3 review-section-header>{{ 'personal' | labelSwapper | async }}</h3>
    <application-review-personal-info class="not-interactive" [form]="profileForm"></application-review-personal-info>

    <h3 review-section-header>Contact Information</h3>
    <application-review-contact-info class="not-interactive" [form]="profileForm"></application-review-contact-info>

    <application-review-person-address class="not-interactive" workOrHome="home">
      <h3 review-section-header>Home Address</h3>
    </application-review-person-address>

    <application-review-person-address class="not-interactive" workOrHome="work">
      <h3 review-section-header>{{ personalStepSettings.stepLabel }}</h3>
    </application-review-person-address>

    <div *ngIf="!personalStepSettings.hideHomeAddress" class="not-interactive">
      <h3 review-section-header>Preferences</h3>
      <fieldset [formGroup]="profileForm">
        <div class="flex-fields no-underlines">
          <mat-form-field appearance="standard">
            <mat-label>Mailing Address</mat-label>
            <mat-radio-group formControlName="sendMailTo" required>
              <mat-radio-button color="primary" value="Home">Home Address</mat-radio-button>
              <mat-radio-button color="primary" value="Work">Work Address</mat-radio-button>
            </mat-radio-group>
            <input matInput class="d-none" />
          </mat-form-field>
        </div>
      </fieldset>
    </div>

    <ng-container *ngIf="hasEligibility">
      <h3 review-section-header>{{ 'eligibility' | labelSwapper | async }}</h3>
      <lib-application-eligibility [formGroup]="eligibilityForm" class="not-interactive"></lib-application-eligibility>
    </ng-container>

    <ng-container *ngIf="reviewWorkHistory">
      <h3 review-section-header>{{ 'workInfo' | labelSwapper | async }}</h3>
      <lib-work-history-review [workHistory]="workHistory" class="not-interactive"></lib-work-history-review>
    </ng-container>

    <ng-container *ngIf="reviewContinuingEducation">
      <h3 review-section-header>{{ 'continuingEducation' | labelSwapper | async }}</h3>
      <lib-continuing-education [form]="ceForm" [application]="application" [readonly]="true"></lib-continuing-education>
    </ng-container>

    <ng-container *ngIf="reviewMilitaryInfo">
      <h3 review-section-header>{{ 'militaryInfo' | labelSwapper | async }}</h3>
      <lib-military-info-review [militaryInformation]="militaryInformation" class="not-interactive"></lib-military-info-review>
    </ng-container>

    <ng-container *ngIf="hasDisclosureQuestions">
      <h3 review-section-header>{{ 'disclosureQuestions' | labelSwapper | async }}</h3>
      <lib-moral-character-review [questionnaireResponse]="questionnaireResponse"></lib-moral-character-review>
    </ng-container>

    <ng-container *ngIf="hasAffidavitUpload">
      <h3 review-section-header>{{ 'affidavitUpload' | labelSwapper | async }}</h3>
      <lib-documents-form
        class="pb-small"
        [applicationId]="application.id"
        documentType="ReinstatementAffidavit"
      ></lib-documents-form>
    </ng-container>

    <h3 review-section-header>{{ 'confirmation' | labelSwapper | async }}</h3>
    <lib-application-confirmation [formGroup]="confirmationForm" class="not-interactive"> </lib-application-confirmation>

    <h3 review-section-header>{{ 'payment' | labelSwapper | async }}</h3>

    <lib-review-payment [order]="order" class="not-interactive"></lib-review-payment>
  </main>
  <footer lib-review-footer></footer>
</article>
