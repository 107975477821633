<lib-titled-card class="opt-in">
  <h2>Personal Information</h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
  </aside>
  <form [formGroup]="personProfileForm" [class.no-underlines]="!isEditing">
    <div formGroupName="names">
      <lib-edit-name
        [formGroup]="namesForm['controls'].name"
        [readMode]="isEditing ? 'editing' : 'readonly'"
        [honorificNotShown]="settings.architectProfileSettings.hideHonorific"
      ></lib-edit-name>
      <div class="flex-fields">
        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [class.readonly]="!isEditing"
          [floatLabel]="isEditing ? 'auto' : ''"
          *ngIf="!settings.architectProfileSettings.hideCredentials"
        >
          <mat-label *ngIf="isEditing || namesForm.controls.credentials.value"> Credentials </mat-label>
          <input matInput formControlName="credentials" [readonly]="!isEditing" placeholder="Credentials" />
        </mat-form-field>
      </div>
    </div>
    <div formGroupName="info">
      <div class="flex-fields">
        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [class.readonly]="!isEditing"
          [floatLabel]="isEditing ? 'auto' : ''"
          *ngIf="!settings.architectProfileSettings.hideDateOfBirth"
        >
          <mat-label *ngIf="isEditing || infoForm.controls.dateOfBirth.value"> Date of Birth </mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [max]="over10YearsAgo"
            formControlName="dateOfBirth"
            [readonly]="!isEditing"
            [required]="isEditing && portalRequiredFields?.dateOfBirth"
            placeholder="Date of Birth"
          />
          <mat-datepicker-toggle *ngIf="isEditing" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [floatLabel]="isEditing ? 'auto' : ''"
          [class.faux-disable]="!isEditing"
          *ngIf="!settings.architectProfileSettings.hideGender"
        >
          <mat-label *ngIf="isEditing || infoForm.controls.gender.value">Gender</mat-label>

          <input
            matInput
            *ngIf="!isEditing"
            [ngModel]="infoForm.controls.gender.value"
            [ngModelOptions]="{ standalone: true }"
            [readonly]="true"
            placeholder="Gender"
          />

          <mat-select formControlName="gender" *ngIf="isEditing">
            <mat-option></mat-option>
            <mat-option *ngFor="let gender of genders" [value]="gender">
              {{ gender }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-fields flex-fields-split">
        <lib-edit-ssn
          [control]="infoForm.controls.socialSecurityNumber"
          [notRequired]="!isEditing || !requiredFields?.socialSecurityNumber"
          [readonly]="!isEditing"
        >
        </lib-edit-ssn>

        <lib-edit-visa
          [control]="infoForm.controls.visaNumber"
          [notRequired]="!isEditing || !requiredFields?.visaNumber"
          [readonly]="!isEditing"
          *ngIf="settings.showSocialSecurityNumberAlternative == 'VisaNumber'"
        >
        </lib-edit-visa>

        <lib-edit-uscis
          [control]="infoForm.controls.uscisNumber"
          [notRequired]="!isEditing || !requiredFields?.uscisNumber"
          [readonly]="!isEditing"
          *ngIf="settings.showSocialSecurityNumberAlternative == 'UscisNumber'"
        >
        </lib-edit-uscis>

        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [class.readonly]="!isEditing"
          [floatLabel]="isEditing ? 'auto' : ''"
          *ngIf="settings.showSocialSecurityNumberAlternative != 'None'"
        >
          <mat-label *ngIf="isEditing || infoForm.controls.immigrationStatus.value"> Immigration Status </mat-label>
          <input matInput formControlName="immigrationStatus" [readonly]="!isEditing" placeholder="Immigration Status" />
        </mat-form-field>

        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [class.readonly]="!isEditing"
          [floatLabel]="isEditing ? 'auto' : ''"
        >
          <mat-label *ngIf="isEditing || infoForm.controls.ncarbNumber.value"> NCARB Record Number </mat-label>
          <input matInput formControlName="ncarbNumber" [readonly]="!isEditing" placeholder="NCARB Record Number" />
        </mat-form-field>

        <mat-form-field
          [appearance]="isEditing ? 'outline' : 'standard'"
          [class.readonly]="!isEditing"
          [floatLabel]="isEditing ? 'auto' : ''"
          *ngIf="useCLARBNumber"
        >
          <mat-label *ngIf="isEditing || infoForm.controls.clarbNumber.value"> CLARB Number </mat-label>
          <input matInput formControlName="clarbNumber" [readonly]="!isEditing" placeholder="CLARB Number" />
        </mat-form-field>
      </div>
    </div>
  </form>
</lib-titled-card>
