<fieldset [formGroup]="form">
  <lib-edit-name
    [formGroup]="form.controls.name"
    readMode="readonly"
    [honorificNotShown]="personalStepSettings.hideHonorific"
  ></lib-edit-name>

  <div class="flex-fields">
    <mat-form-field appearance="standard" *ngIf="!personalStepSettings.hideCredentials">
      <mat-label>Credentials</mat-label>
      <input matInput formControlName="credentials" />
    </mat-form-field>
  </div>

  <div class="flex-fields flex-fields-split">
    <mat-form-field appearance="standard" *ngIf="!personalStepSettings.hideDateOfBirth">
      <mat-label>Date of Birth</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" required />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Social Security Number</mat-label>
      <input matInput formControlName="socialSecurityNumber" />
    </mat-form-field>

    <mat-form-field *ngIf="settings.showSocialSecurityNumberAlternative == 'VisaNumber'" appearance="standard">
      <mat-label>Visa Number</mat-label>
      <input matInput formControlName="visaNumber" />
    </mat-form-field>

    <mat-form-field *ngIf="settings.showSocialSecurityNumberAlternative == 'UscisNumber'" appearance="standard">
      <mat-label>USCIS Number or Alien Registration Number</mat-label>
      <input matInput formControlName="uscisNumber" />
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Immigration Status</mat-label>
      <input matInput formControlName="immigrationStatus" />
    </mat-form-field>

    <mat-form-field *ngIf="showNcarbNumber" appearance="standard">
      <mat-label>NCARB Record Number</mat-label>
      <input matInput formControlName="ncarbNumber" />
    </mat-form-field>

    <mat-form-field *ngIf="showClarbNumber" appearance="standard">
      <mat-label>CLARB Number</mat-label>
      <input matInput formControlName="clarbNumber" />
    </mat-form-field>
  </div>
</fieldset>
