import { PipeTransform, Pipe } from '@angular/core';

/// https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
/// usage: <span [innerHTML]="text | highlight : search"></span>
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (!search) {
      return text;
    }
    var pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = this.includeAccents(pattern);
    pattern = pattern
      .split(' ')
      .filter(t => {
        return t.length > 0;
      })
      .join('|');
    var regex = new RegExp(pattern, 'gi');

    return text.replace(regex, match => `<span class="highlight">${match}</span>`);
  }

  includeAccents(pattern: string): string {
    pattern = pattern.replace(/a/g, '[aàáâãäå]');
    pattern = pattern.replace(/e/g, '[eèéêë]');
    pattern = pattern.replace(/i/g, '[iìíîï]');
    pattern = pattern.replace(/o/g, '[oòóôõö]');
    pattern = pattern.replace(/u/g, '[uùúûü]');
    pattern = pattern.replace(/c/g, '[cç]');
    pattern = pattern.replace(/n/g, '[nñ]');
    return pattern;
  }
}
