import { UpdatableModel } from './updatable-model';
import { Phone } from './phone';
import { Name } from './name';
import { LookupItem } from './search/lookup-item';

export class Person extends UpdatableModel<Person> implements LookupItem {
  id: string;
  name: Name;
  credentials: string;
  email: string;
  primaryPhone: Phone;
  ncarbNumber?: number;
  clarbNumber?: number;

  update(init?: Partial<Person>) {
    super.update(init);
    this.primaryPhone = this.primaryPhone ? new Phone(this.primaryPhone) : null;
    if (this.name) {
      this.name = new Name(this.name);
    }
  }

  get lookupId() {
    return this.id;
  }

  get lookupStatus() {
    return null;
  }

  get lookupName() {
    return this.name.full;
  }

  get lookupInfo() {
    return [this.email, this.primaryPhone ? this.primaryPhone.numberFull : null].filter(_ => !!_).join(' | ');
  }
}
