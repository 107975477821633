<form [formGroup]="form">
  <div [class.d-none]="form['controls'].worksForFirm.value">
    <p>
      <mat-radio-group class="yes-no" formControlName="isEmployed" required>
        <mat-radio-button [value]="true" color="primary"> Yes </mat-radio-button>
        <mat-radio-button [value]="false" color="primary"> No </mat-radio-button>
      </mat-radio-group>
      <label> Are you currently employed? </label>
    </p>
    <p *ngIf="form['controls'].isEmployed.value">
      <mat-radio-group class="yes-no" formControlName="worksForFirm" required>
        <mat-radio-button [value]="true" color="primary"> Yes </mat-radio-button>
        <mat-radio-button [value]="false" color="primary"> No </mat-radio-button>
      </mat-radio-group>
      <label> Do you work for a firm? </label>
    </p>
  </div>

  <div [class.d-none]="!form['controls'].worksForFirm.value">
    <div formGroupName="workHistory">
      <div class="flex-fields" formGroupName="firm">
        <mat-form-field id="firmNameFormField" appearance="standard">
          <mat-label>Firm Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>License #</mat-label>
          <input matInput formControlName="licenseNumber" />
        </mat-form-field>
      </div>
      <div class="flex-fields">
        <div class="mat-form-field">
          <h4>Firm Address</h4>
          <lib-read-address-new
            [hideValidation]="true"
            [line1Label]="settings.firmProfileSettings.addressFirstLineLabel"
            [line2Label]="settings.firmProfileSettings.addressSecondLineLabel"
            [hideCounty]="settings.firmProfileSettings.hideCounty"
            [addressLookups]="addressLookups"
            [isNarrow]="true"
            [formGroup]="form['controls'].workHistory['controls'].firm['controls'].publicAddress"
          >
          </lib-read-address-new>
        </div>
        <div class="mat-form-field">
          <h4>Firm Contact</h4>
          <div class="flex-fields">
            <mat-form-field appearance="standard">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title" required />
            </mat-form-field>
          </div>
          <div class="flex-fields">
            <mat-form-field appearance="standard">
              <mat-label>Work Email</mat-label>
              <input type="email" matInput formControlName="email" email required />
            </mat-form-field>
          </div>
          <div class="flex-fields">
            <lib-edit-phone-redesign
              label="Work Phone Number"
              [readonly]="true"
              [formGroup]="form['controls'].workHistory['controls'].phone"
            ></lib-edit-phone-redesign>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
