import { ApplicationBase } from './application-base';
import { Firm } from '../firm';
import { FirmApplicationPrincipal } from '../firm-application-principal';
import { Order } from '../order';
export type LicenseType = 'ProfessionalArchitectural' | 'ArchitecturalEngineering' | 'ArchitecturalFirm' | 'LimitedLiability';

export class FirmApplicationBase<T> extends ApplicationBase<T> {
  firm: Firm;
  firmId: string;
  licenseType?: LicenseType; //or null
  licenseTypeName: string;
  licenseTypeShortName: string;
  corporationSharesType?: string;
  corporationSharesTypeName?: string;
  sharesIssued?: number;
  sharesAllowed?: number;
  holdingCompanyTitle?: string;
  stateOfIncorporationId?: number;
  dateOfIncorporation?: Date;
  unlicensedDirectorsCount: number;
  principals: FirmApplicationPrincipal[];
  order: Order;

  get entityId() {
    return this.firmId;
  }
}
