<mat-tab-group [(selectedIndex)]="selectedTabIndex">
  <mat-tab label="Create Account">
    <h3>Create an Account</h3>
    <form #signUpForm="ngForm" (submit)="signUpForm.valid && signUp(signUpEmail, signUpPassword)">
      <p>
        <mat-form-field appearance="standard">
          <mat-label>Email Address</mat-label>
          <input matInput [(ngModel)]="signUpEmail" name="signUpEmail" email required />
        </mat-form-field>
      </p>
      <p class="small note">
        You <strong>must</strong> sign-up with the email address that is on file. If you are unsure, please check your
        confirmation email or contact the board.
      </p>
      <div class="flex-fields">
        <mat-form-field appearance="standard">
          <mat-label>Create Password</mat-label>
          <input
            type="password"
            matInput
            [(ngModel)]="signUpPassword"
            name="signUpPassword"
            required
            [pattern]="patterns.password"
          />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Confirm Password</mat-label>
          <input
            type="password"
            matInput
            name="confirmPassword"
            [(ngModel)]="confirmPassword"
            required
            matchConfirmPassword
            #confirmpassword="ngModel"
          />
        </mat-form-field>
      </div>
      <p class="small note" [ngClass]="{ error: signUpForm.controls?.signUpPassword?.hasError('pattern') }">
        Use at least 8 characters, consisting of an uppercase letter, a lowercase letter, a number (0-9), and a special
        character (!@#$%^&* only)
      </p>
      <p class="small error note" *ngIf="signUpForm.controls?.confirmPassword?.hasError('matchConfirmPassword')">
        Your passwords should match
      </p>
      <div class="mt-4 flex-fields reversed">
        <div class="mat-form-field">
          <button mat-button class="full" color="primary" type="submit" *ngIf="!createAccountRequested">Create Account</button>
          <mat-progress-spinner *ngIf="createAccountRequested" color="primary" mode="indeterminate" diameter="40" value="5">
          </mat-progress-spinner>
        </div>
        <div class="mat-form-field">
          <button mat-button class="alt-inverse full" (click)="login()">Log in instead</button>
        </div>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="Log In">
    <h3>Log in</h3>
    <p class="small note mb-2">Click the button to log into the portal.</p>
    <p class="small note">
      You <strong>must</strong> use the email address that is on file. If you are unsure, please check your confirmation email
      or contact the board.
    </p>
    <div class="my-4 flex-fields reversed">
      <div class="mat-form-field">
        <form #loginForm="ngForm" (submit)="login()">
          <button mat-button class="full" color="primary" type="submit">Log In</button>
        </form>
      </div>
      <div class="mat-form-field">
        <button mat-button class="alt-inverse full" (click)="selectedTabIndex = 0">Don't have an account?</button>
      </div>
    </div>
    <div class="note-area">
      <h5>Having trouble with logging in?</h5>
      <ol>
        <li>Ensure you have verified your email before you log in.</li>
        <li>
          If you are applying for an initial or reciprocal application, you must
          <strong>wait for {{ settings.boardAbbreviation }} to receive your transmittal</strong>.
          <!-- TODO
          {{settings.boardAbbreviation}} will notify you via email once they have received it from NCARB.
          -->
        </li>
        <li>
          If you are applying for a renewal or reinstatement and previously created an account, check your reminder email to
          ensure you are using the correct email address.
        </li>
      </ol>
    </div>
  </mat-tab>
  <mat-tab label="One-time Access" *ngIf="enableEasyTesting">
    <p>Start your application with a Secured Access Link.</p>
    <p>Creating an account is not required.</p>
    <ul>
      <li>
        To receive a secured access link to your application, please enter the email address on file with
        {{ settings.boardAbbreviation }}.
      </li>
      <li>
        If the email address entered does not match our records, you will not be able to apply at this time. For help, please
        contact {{ settings.boardAbbreviation }} at {{ settings.supportPhoneNumber }}.
      </li>
      <li>
        For security purposes, you must open the link in the same browser and the same device you requested it from. This link
        will expire in 5 minutes.
      </li>
    </ul>
    <form #quickAccessForm="ngForm" (submit)="quickAccessForm.valid && quickAccess(quickAccessEmail)">
      <p>
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="quickAccessEmail" name="quickAccessEmail" email required />
        </mat-form-field>
      </p>
      <p>
        <button mat-button color="primary" type="submit">Request Access Link</button>
      </p>
    </form>
  </mat-tab>
</mat-tab-group>

<div *ngIf="enableEasyTesting">
  <p>
    <mat-checkbox [(ngModel)]="devAddMeToDb" name="devAddMeToDb" color="primary"
      >Add me to the database if needed (dev only)</mat-checkbox
    >
  </p>
  <p style="opacity: 0">
    <mat-checkbox [(ngModel)]="e2eTesting" name="e2eTesting" id="e2eTesting" color="primary"
      >E2E Testing (dev only)</mat-checkbox
    >
  </p>
</div>
