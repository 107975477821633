import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ls-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss'],
})
export class SubtitleComponent {
  @Input() level: 1 | 2;
  @HostBinding('class.d-block') block = true;
  get isLevel1() {
    return this.level == 1;
  }

  @Input() zeroMargin = false;
}
