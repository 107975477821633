import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base.component';
import { SelectOption } from '../../models/select-option';
import { parseDateAsMoment } from '../../utility/date-utils';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'lib-edit-month-year',
  templateUrl: './edit-month-year.component.html',
  styleUrls: ['./edit-month-year.component.scss'],
})
export class EditMonthYearComponent extends BaseComponent implements OnInit {
  @HostBinding('class.mat-form-field') matFormField = true;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() max?: any;
  @Input() min?: any;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disableLabel: string;
  disabled: boolean;
  month?: number;
  year?: number;
  months: SelectOption[] = [];
  years: number[] = [];

  constructor() {
    super();
    for (var month = 0; month < 12; month++) {
      this.months.push(
        new SelectOption({
          value: month,
          text: new Date(2000, month, 1).toLocaleString('default', { month: 'long' }),
        })
      );
    }
    for (var year = 1900; year < 2050; year++) {
      this.years.push(year);
    }
  }

  ngOnInit() {
    var moment = parseDateAsMoment(this.control.value);
    if (moment) {
      this.month = moment.month();
      this.year = moment.year();
    }
    this.disabled = this.disableLabel && !this.control.value;
    if (this.disabled) {
      this.control.disable();
    }
  }

  selectChanged() {
    this.control.setValue(this.dateString);
  }

  get dateString(): string {
    if (!this.year || (!this.month && this.month !== 0)) {
      return null;
    }
    var month: any = this.month + 1;
    month = month + 1 >= 10 ? month : '0' + month;
    return `${this.year}-${month}-01T00:00:00`;
  }

  disableMonth(month: number) {
    if (!this.year) {
      return false;
    }
    var maxMoment = parseDateAsMoment(this.max);
    var date = new Date(this.year, month, 1);
    if (maxMoment && date > maxMoment.toDate()) {
      return true;
    }
    var minMoment = parseDateAsMoment(this.min);
    if (minMoment && date < minMoment.toDate()) {
      return true;
    }
    return false;
  }

  disableYear(year: number) {
    let maxMoment = parseDateAsMoment(this.max);
    if (maxMoment && year > maxMoment.year()) {
      return true;
    }
    let minMoment = parseDateAsMoment(this.min);
    if (minMoment && year < minMoment.year()) {
      return true;
    }
    return false;
  }

  onDisableChange(change: MatCheckboxChange) {
    if (change.checked) {
      this.control.disable();
      this.month = null;
      this.year = null;
    } else {
      this.control.enable();
    }
  }
}
