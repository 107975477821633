<div [formGroup]="formGroup" class="flex-fields">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <lib-autocomplete
      #auto="autocomplete"
      [textDisplay]="true"
      [search]="autocompleteSearch"
      [optionDisabled]="optionDisabled"
      [takeOnlyOptionOnBlur]="true"
      [allowEmptySearch]="false"
      (optionSelected)="licenseSelected($event)"
      (cleared)="onCleared()"
    >
      <input #name matInput formControlName="name" [matAutocomplete]="auto.autocomplete" required />
      <ng-template let-license let-highlight="highlight">
        <span [innerHTML]="license.person.name.firstLast | highlight: highlight"></span>
        <span class="license-number">{{ license.number }}</span>
      </ng-template>
    </lib-autocomplete>
    <input type="hidden" formControlName="personId" [required]="true" />
    <button type="button" mat-icon-button matSuffix>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field appearance="outline" class="license" [class.readonly]="!readonly">
    <mat-label>License Number</mat-label>
    <input matInput formControlName="licenseNumber" [readonly]="true" />
  </mat-form-field>
  <mat-form-field *ngIf="role == 'Shareholder'" class="shares" appearance="outline">
    <mat-label>Shares</mat-label>
    <input matInput formControlName="shares" [pattern]="patterns.decimal2" required />
    <mat-error *ngIf="formGroup.controls?.shares?.hasError('pattern')" class="mb-4"> Invalid number </mat-error>
  </mat-form-field>
  <div class="mat-form-field clear-action pointer" style="top: -12px">
    <button mat-icon-button [disabled]="!removable" (click)="clear()">
      <mat-icon [ngClass]="{ disabled: !removable, 'mat-warn': removable }">delete</mat-icon>
    </button>
  </div>
</div>
