import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { JurisdictionConstants } from '../../constants/jurisdiction/jurisdiction-constants';
import { PersonalStepSettings, TenantSettings } from '../../constants/jurisdiction/TenantSettings';
import { getApplicationSettings } from '../../utility/router-extensions';

@Component({
  selector: 'application-review-personal-info',
  templateUrl: './application-review-personal-info.html',
})
export class ApplicationReviewPersonalInfoComponent implements OnInit {
  @Input() form: FormGroup;
  over10YearsAgo = new Date(new Date().getFullYear() - 10, 0, -1);
  application: ArchitectApplication;
  showNcarbNumber: boolean;
  showClarbNumber: boolean;
  personalStepSettings: PersonalStepSettings;

  constructor(
    private route: ActivatedRoute,
    public constants: JurisdictionConstants,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.application = this.route.snapshot.data.application;
    this.showNcarbNumber = this.application.profession == 'Architect';
    this.showClarbNumber = this.application.profession == 'LandscapeArchitect';
    this.personalStepSettings = getApplicationSettings(this.route).stepConfiguration.personal;
  }
}
