import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PersonPointOfContact } from '../../models/person-point-of-contact';
import { ArchitectAvailable } from '../../models/architect-available';
import { PersonLite } from '../../models/person-lite';
import { PointOfContactFor } from '../../models/point-of-contact-for';

@Injectable({
  providedIn: 'root',
})
export class PersonPointOfContactService {
  constructor(private api: ApiService) {}

  protected getPath(personId) {
    return `person/${personId}/pointofcontact`;
  }

  get(personId: string): Observable<PersonPointOfContact[]> {
    let path = this.getPath(personId);
    return this.api.get(path).pipe(map(data => data.map(poc => new PersonPointOfContact(poc))));
  }

  available(personId): Observable<ArchitectAvailable[]> {
    return this.api
      .get(this.getPath(personId).concat('/available'))
      .pipe(map(aas => aas.map(aa => new ArchitectAvailable(aa))));
  }

  checkPocEmailExists(personId, email: string): Observable<PersonLite> {
    let qs = this.api.queryStringify({ email });
    return this.api.get(this.getPath(personId).concat('/email').concat(qs)).pipe(map(data => new PersonLite(data)));
  }

  getPointOfContactForList(personId: string): Observable<PointOfContactFor[]> {
    let path = this.getPath(personId).concat('/for');
    return this.api.get(path).pipe(map(data => data.map(pocFor => new PointOfContactFor(pocFor))));
  }

  update(personId, personPointOfContact: PersonPointOfContact): Observable<PersonPointOfContact> {
    let path = this.getPath(personId);
    return this.api.put(path, personPointOfContact).pipe(map(data => new PersonPointOfContact(data)));
  }

  add(personId, personPointOfContact: PersonPointOfContact): Observable<PersonPointOfContact> {
    let path = this.getPath(personId);
    return this.api.post(path, personPointOfContact).pipe(map(data => new PersonPointOfContact(data)));
  }

  requestAccess(personId, architectIds: string[]): Observable<any> {
    let path = this.getPath(personId).concat('/requestaccess');
    return this.api.post(path, architectIds);
  }

  approve(personId, personPointOfContact: PersonPointOfContact): Observable<PersonPointOfContact> {
    let path = this.getPath(personId).concat(`/${personPointOfContact.id}/approve`);
    return this.api.post(path, {}).pipe(map(data => new PersonPointOfContact(data)));
  }

  deny(personId, personPointOfContact: PersonPointOfContact): Observable<PersonPointOfContact> {
    let path = this.getPath(personId).concat(`/${personPointOfContact.id}/deny`);
    return this.api.post(path, {}).pipe(map(data => new PersonPointOfContact(data)));
  }

  revoke(personId, personPointOfContact: PersonPointOfContact): Observable<PersonPointOfContact> {
    let path = this.getPath(personId).concat(`/${personPointOfContact.id}/revoke`);
    return this.api.post(path, {}).pipe(map(data => new PersonPointOfContact(data)));
  }
}
