import { UpdatableModel } from './updatable-model';
import { FormBuilder, FormGroup } from '@angular/forms';

export class Phone extends UpdatableModel<Phone> {
  id: string;
  number: string;
  numberRaw: string;
  type: string;
  extension: string;

  get numberFull() {
    if (!this.extension) {
      return this.number;
    }
    return `${this.number} x ${this.extension}`;
  }

  set numberFull(ignoreme) {}

  static GetFormGroup(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      id: '',
      number: '',
      numberRaw: '',
      type: '',
      extension: '',
      numberFull: '',
    });
  }

  patch(formGroup: FormGroup) {
    formGroup.patchValue(this);
    formGroup.controls.numberFull.setValue(this.numberFull);
  }
}
