<form [formGroup]="form">
  <fieldset>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Firm Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
    </div>
    <div class="flex-fields" *ngIf="firm.corporateTitle">
      <mat-form-field appearance="standard">
        <mat-label>Corporate Title</mat-label>
        <mat-select formControlName="corporateTitle" required>
          <mat-option></mat-option>
          <mat-option *ngFor="let ct of corporateTitles" [value]="ct.value">{{ ct.text }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Doing Business As (DBA)</mat-label>
        <input matInput formControlName="doingBusinessAs" />
      </mat-form-field>
    </div>
  </fieldset>

  <fieldset>
    <legend>Contact Information</legend>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required />
      </mat-form-field>
      <lib-edit-phone-redesign
        label="Firm Phone Number"
        [formGroup]="form['controls'].primaryPhone"
        [readonly]="true"
      ></lib-edit-phone-redesign>
    </div>
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Website</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
    </div>
  </fieldset>

  <fieldset>
    <legend>Firm Address</legend>
    <lib-address
      [editing]="false"
      [formGroup]="form['controls'].publicAddress"
      [line1Label]="firmStepSettings.addressFirstLineLabel"
      [line2Label]="firmStepSettings.addressSecondLineLabel"
      [hideCounty]="firmStepSettings.hideCounty"
    ></lib-address>
  </fieldset>
</form>
