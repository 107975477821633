<lib-call-to-action *ngIf="show">
  <div class="flex-column content">
    <ls-headline>Application Setup: Testing Only</ls-headline>

    <mat-form-field appearance="standard" appearance="outline" floatLabel="auto" *ngIf="settings.validFirmTypes.length">
      <mat-label>License Type</mat-label>
      <mat-select [(value)]="firmLicenseType">
        <mat-option></mat-option>
        <mat-option [value]="type.id" *ngFor="let type of settings.validFirmTypes">
          {{ type.id | spaceCapCase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="isCreateInitialApp" *ngIf="testOptions?.canAddInitial">Create Initial Application</mat-checkbox>
    <license-wizard
      *ngIf="testOptions"
      label="Firm"
      [hasEmeritus]="false"
      [isCreateInitialApp]="isCreateInitialApp"
      [testOptions]="testOptions"
      (submit)="submit($event)"
    ></license-wizard>
  </div>
</lib-call-to-action>
