import { Component, HostBinding } from '@angular/core';
import { EditSecretComponent } from '../edit-secret-component';
import { RegexPatterns } from '../../constants/regex-patterns';
import { Masks } from '../../constants/masks';

@Component({
  selector: 'lib-edit-uscis',
  templateUrl: './edit-uscis.component.html',
  inputs: EditSecretComponent.Inputs,
})
export class EditUscisComponent extends EditSecretComponent {
  constructor() {
    super({ pattern: RegexPatterns.uscis, mask: Masks.uscis });
  }

  //putting on EditSecretComponent doesn't work (does work in storybook)
  @HostBinding('class') get getClass(): string {
    return super.getClass;
  }
}
