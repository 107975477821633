import { Directive, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cdkDetailRow]',
  exportAs: 'cdkDetailRow',
})
export class CdkDetailRowDirective {
  private row: any;
  private tRef: TemplateRef<any>;
  private preRendered: boolean;
  opened: boolean;

  constructor(private vcRef: ViewContainerRef) {}

  @HostBinding('class.expanded')
  get expanded(): boolean {
    return this.opened;
  }

  @Input()
  set cdkDetailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
      if (this.preRendered) {
        this.render();
      }
    }
  }

  @Input('cdkDetailRowTpl')
  set template(value: TemplateRef<any>) {
    if (value !== this.tRef) {
      this.tRef = value;
      if (this.preRendered) {
        this.render();
      }
    }
  }

  @Input()
  set cdkDetailRowPreRender(value: any) {
    this.preRendered = !!value;
    if (this.preRendered) {
      this.render();
    }
  }

  @HostListener('click')
  onClick(): void {
    this.toggle();
  }

  toggle(): void {
    if (this.opened) {
      if (!this.preRendered) {
        this.vcRef.clear();
      }
    } else {
      this.render();
    }
    this.opened = !this.opened;
  }

  private render(): void {
    if (this.vcRef.length && this.preRendered) {
      return;
    }
    this.vcRef.clear();
    if (this.tRef && this.row) {
      this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
    }
  }
}
