import { Component } from '@angular/core';
import { PersonDetailBaseComponent } from '../person-detail-base.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-person-preferences',
  templateUrl: './person-preferences.component.html',
  styleUrls: ['./person-preferences.component.scss'],
})
export class PersonPreferencesComponent extends PersonDetailBaseComponent {
  constructor() {
    super();
  }

  get preferencesForm(): FormGroup {
    return this.personProfileForm['controls'].addresses as FormGroup;
  }

  get successfulSaveMessage(): string {
    return 'Preferences was saved';
  }

  get errorSaveMessage(): string {
    return 'There was an issue updating preferences.';
  }
}
