import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JurisdictionConstants } from 'projects/common/src/lib/constants/jurisdiction/jurisdiction-constants';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';
import { Application } from 'projects/common/src/lib/models/applications/application';
import { ApplicationSettings } from 'projects/common/src/lib/models/applications/application-settings';
import { LinkInfo } from 'projects/common/src/lib/models/links/link-info';
import { getApplicationSettings } from 'projects/common/src/lib/utility/router-extensions';

@Component({
  selector: 'app-application-chrome',
  styleUrls: ['./application-chrome.component.scss'],
  templateUrl: './application-chrome.component.html',
})
export class ApplicationChromeComponent implements OnInit {
  applicationSettings: ApplicationSettings;
  breadcrumbs: LinkInfo[];
  constructor(
    public constants: JurisdictionConstants,
    public settings: TenantSettings,
    private route: ActivatedRoute
  ) {}

  @HostBinding('class') get getClass(): string {
    return 'app-section application opt-in';
  }

  private *getCrumbs(): Generator<LinkInfo> {
    yield {
      path: '/',
      label: 'HOME',
      inactive: false,
    };

    yield this.isFirm
      ? {
          path: `/firm/${this.firmId}`,
          label: 'FIRM',
          inactive: false,
        }
      : {
          path: `/portal/${this.personId}`,
          label: this.route.snapshot.data.application.profession, //TODO; application.profession
          inactive: false,
        };
    if (this.isFirm) {
      yield {
        path: `/firm/${this.firmId}/application/${this.applicationId}`,
        label: 'APPLICATION',
        inactive: false,
      };
      yield {
        path: `/firm/${this.firmId}/application/${this.applicationId}`,
        label: `${this.applicationSettings.typeName} APPLICATION`,
        inactive: true,
      };
    } else {
      yield {
        path: `/architect/${this.personId}/application/${this.applicationId}`,
        label: 'APPLICATION',
        inactive: false,
      };
      yield {
        path: `/architect/${this.personId}/application/${this.applicationId}`,
        label: `${this.applicationSettings.typeName} APPLICATION`,
        inactive: true,
      };
    }
  }
  get applicationId(): string {
    return this.route.snapshot.params.applicationId;
  }

  get isFirm(): boolean {
    return !!this.firmId;
  }
  get personId(): string {
    return this.route.snapshot.params.personId;
  }

  get firmId(): string {
    return this.route.snapshot.params.firmId;
  }

  ngOnInit(): void {
    if (!this.route) {
      console.error('no route');
      return;
    }

    if (!this.route.snapshot.data.application) {
      console.error('no this.route.snapshot.data.application set');
      return;
    }
    this.applicationSettings = getApplicationSettings(this.route);
    this.breadcrumbs = Array.from(this.getCrumbs());
  }
}
