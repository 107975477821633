import { UpdatableModel } from 'projects/common/src/lib/models/updatable-model';
import { LookupItem } from 'projects/common/src/lib/models/search/lookup-item';
import { ArchitectLicense } from 'projects/common/src/lib/models/architect-license';
import { Name } from 'projects/common/src/lib/models/name';

export class FirmPointOfContactOption extends UpdatableModel<FirmPointOfContactOption> implements LookupItem {
  id: string;
  name: Name;
  email: string;
  licenseNumber: string;

  get lookupId() {
    return this.id;
  }

  get lookupStatus() {
    return null;
  }

  get lookupName() {
    return this.name && this.name.firstLast;
  }

  get lookupInfo() {
    return [this.email, this.licenseNumber].filter(_ => !!_).join(' | ');
  }

  static FromArchitectLicense(architectLicense: ArchitectLicense): FirmPointOfContactOption {
    return new FirmPointOfContactOption({
      id: architectLicense.person.id,
      name: architectLicense.person.name,
      email: architectLicense.person.email,
      licenseNumber: architectLicense.number,
    });
  }
}
