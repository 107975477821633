<mat-form-field appearance="standard">
  <lib-autocomplete
    #auto="autocomplete"
    [search]="search"
    (optionSelected)="onOptionSelected($event)"
    (cleared)="onCleared($event)"
  >
    <input
      matInput
      [required]="true"
      [placeholder]="placeholder"
      [formControl]="control"
      [matAutocomplete]="auto.autocomplete"
    />
    <ng-template let-license let-highlight="highlight">
      <div class="license-info">
        <span class="lookup-result-status status-word" [ngClass]="getStatusCss(license.status) | highlight: highlight"
          >{{ license.number }}
        </span>
        <span class="lookup-result-name" [innerHTML]="license.person.name.full | highlight: highlight"></span>
        <span class="lookup-result-info" [innerHTML]="license.person.email | highlight: highlight"></span>
      </div>
    </ng-template>
  </lib-autocomplete>
  <button type="button" mat-icon-button matSuffix>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>
