import { Component } from '@angular/core';
import { ImpersonateDialogResponse } from './impersonate-dialog-response';

@Component({
  selector: 'lib-impersonate-dialog',
  templateUrl: './impersonate-dialog.html',
  styleUrls: ['./impersonate-dialog.scss'],
})
export class ImpersonateDialog {
  yesResponse = new ImpersonateDialogResponse({ continue: true });
  noResponse = new ImpersonateDialogResponse();
}
