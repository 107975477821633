import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonMaterialModule } from './common-material.module';
import { ErrorHandlingModule } from './utility/error.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { TextMaskModule } from 'angular2-text-mask';

import { ActionSelectorComponent } from './components/action-selector/action-selector.component';
import { AddressComponent } from './components/edit-address/address.component';
import { AddressLookupsResolve } from './resolves/address-lookups.resolve';
import { AddressService } from './services/address.service';
import { AddressStatusComponent } from './components/edit-address/address-status.component';
import { AnOrAPipe } from './pipes/add-pronoun.pipe';
import { ApiService } from './services/api/api.service';
import { ApplicationConfirmationComponent } from './components/application/confirmation/application-confirmation.component';
import { ApplicationEligibilityComponent } from './components/application/eligibility/application-eligibility.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { ApplicationReviewContactInfoComponent } from './components/application-review-contact-info/application-review-contact-info.component';
import { ApplicationReviewPersonAddress } from './components/application-review-person-address/application-review-person-address.component';
import { ApplicationReviewPersonalInfoComponent } from './components/application-review-personal-info/application-review-personal-info.component';
import { ApplicationSettingsResolve } from './resolves/application-settings.resolve';
import { ApplicationStatusPipe } from './pipes/application-status.pipe';
import { ArchitectApplicationsResolve } from './resolves/architect-applications.resolve';
import { ArchitectAutocompleteComponent } from './components/architect-autocomplete/architect-autocomplete.component';
import { ArchitectLicensesResolve } from './resolves/architect-licenses.resolve';
import { ArchitectPointOfContactResolve } from './resolves/architect-point-of-contact.resolve';
import { ArchSelectComponent } from './components/application/firm/arch-select/arch-select.component';
import { Auth0SettingsProvider } from './services/api/Auth0Settings';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthRefreshService } from './services/auth/auth-refresh.service';
import { AuthService, ClaimsProvider } from './services/auth/auth.service';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BannerComponent } from './components/banner/banner.component';
import { BaseComponent } from './components/base.component';
import { BlankComponent } from './components/blank.component';
import { BoolToYesNoPipe } from './pipes/bool-to-yes-no.pipe';
import { BreadCrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CallToActionComponent } from './components/call-to-action/call-to-action.component';
import { CaptionComponent } from './components/typography/caption/caption.component';
import { CardComponent } from './components/card/card.component';
import { CardListHeaderComponent } from './components/card-list-header/card-list-header.component';
import { CardListItemComponent } from './components/card-list-item/card-list-item.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { ChipList } from './components/chip-list/chip-list.component';
import { CityStateZipPipe } from './pipes/city-state-zip.pipe';
import { ConfirmDialogue } from './components/confirm-dialogue/confirm.dialogue';
import { ConfirmDialogueDirective } from './components/confirm-dialogue/confirm.dialogue.directive';
import { ContinuingEducationComponent } from './components/application/continuing-education/continuing-education.component';
import { CurrentRolesListComponent } from 'src/app/shared/components/details/business-info/current-roles/current-roles-list.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogDirective } from './components/dialog/dialog.directive';
import { DialogFactoryService } from './components/dialog/dialog-factory-service';
import { DisabledFieldComponent } from './components/disabled-field/disabled-field.component';
import { DisplayAddressComponent } from './components/display-address/display-address.component';
import { DisplayComponent } from './components/typography/display/display.component';
import { DisplayRoleIconsComponent } from './components/display-role-icons/display-role-icons.component';
import { DocumentsFormComponent } from './components/application/firm/documents-form/documents-form.component';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { EditAddressNewComponent } from './components/edit-address/edit-address-new.component';
import { EditMonthYearComponent } from './components/edit-month-year/edit-month-year.component';
import { EditNameComponent } from './components/edit-name/edit-name.component';
import { EditPhoneRedesignComponent } from './components/edit-phone/edit-phone-redesign.component';
import { EditSsnComponent } from './components/edit-ssn/edit-ssn.component';
import { EditUscisComponent } from './components/edit-uscis/edit-uscis.component';
import { EditVisaComponent } from './components/edit-visa/edit-visa.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { EnvBannerComponent } from './components/env-banner/env-banner.component';
import { FauxModalComponent } from './components/faux-modal.component';
import { FeeItemsComponent } from './components/application/fee-items/fee-items.component';
import { FilePillComponent } from './components/pill/file-pill.component';
import { FilterButtonComponent } from './components/filter-panel/filter-button/filter-button.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { FirmApplicationConfirmationComponent } from './components/application/firm-confirmation/firm-application-confirmation.component';
import { FirmApplicationsResolve } from './resolves/firm-applications.resolve';
import { FirmDetailAddressComponent } from './components/firm-detail-address/firm-detail-address.component';
import { FirmLicenseAutocompleteComponent } from './components/firm-license-autocomplete/firm-license-autocomplete.component';
import { FirmLicenseLookupOptionComponent } from './components/autocomplete/lookup-option/firm-license-lookup-option.component';
import { FirmLookupLinkComponent } from './components/autocomplete/firm-lookup-link/firm-lookup-link.component';
import { FirmPaymentsResolve } from './resolves/firm-payments.resolve';
import { FirmPointOfContactDisplayComponent } from './components/firm-point-of-contact-display/firm-point-of-contact-display.component';
import { FirmPointOfContactResolve } from './resolves/firm-point-of-contact.resolve';
import { FirmRolesResolve } from './resolves/firm-roles.resolve';
import { FirmRosterResolve } from './resolves/firm-roster.resolve';
import { FirmSupervisorsListComponent } from './components/firm-supervisors-list/firm-supervisors-list.component';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { FormComponent } from './components/form.component';
import { HeadlineComponent } from './components/typography/headline/headline.component';
import { HighlightPipe } from './pipes/hightlight.pipe';
import { HomeAddressesResolve, WorkAddressesResolve } from './resolves/personal-addresses.resolve';
import { ImageLoaderComponent, ImagePathConfig } from './components/image/lib-image.component';
import { ImpersonateDialog } from './components/impersonate-dialog/impersonate-dialog';
import { InitialsCapCasePipe } from './pipes/initials-cap-case.pipe';
import { InjectorModule } from './modules/injector.module';
import { JurisdictionConfirmationServiceProvider } from './constants/jurisdiction/confirmations/jurisdiction-confirmation-service-factory';
import { JurisdictionConstantsProvider } from './constants/jurisdiction/jurisdiction-constants-factory';
import { LabelSwapperComponent } from './components/label-swapper/label-swapper.component';
import { LabelSwapperPipe } from './pipes/label-swapper.pipe';
import { LicenseStatusPillComponent } from './components/license-status-pill/license-status-pill.component';
import { LicenseStatusPipe } from './pipes/license-status.pipe';
import { ListComponent, GroupedListComponent, StatusedDataListComponent } from './components/list';
import { LookupOptionComponent } from './components/autocomplete/lookup-option/lookup-option.component';
import { LookupPipe } from './pipes/lookup.pipe';
import { MenuSectionComponent } from './components/menu/menu-section.component';
import { MenuWrapperComponent } from './components/filter-panel/menu-wrapper.component';
import { MultiArchSelectComponent } from './components/application/firm/multi-arch-select/multi-arch-select.component';
import { NcarbDateTimeFormatPipe } from './pipes/ncarb-date-time-format.pipe';
import { NoteComponent } from './components/notes/note/note.component';
import { NotesComponent } from './components/notes/notes.component';
import { NumberToTextPipe } from './pipes/number-to-text.pipe';
import { OverlineComponent } from './components/typography/overline/overline.component';
import { OwnersFormComponent } from './components/application/firm/owners-form/owners-form.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PagerComponent } from './components/pager/pager.component';
import { PastRolesListComponent } from 'src/app/shared/components/details/business-info/past-roles/past-roles-list.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';
import { PaymentsResolve } from './resolves/payments.resolve';
import { PComponent } from './components/typography/p/p.component';
import { PersonDetailPersonalInfoComponent } from './components/person/person-detail-personal-info/person-detail-personal-info.component';
import { PersonDetailResolve } from './resolves/person-detail.resolve';
import { PersonWorkHistoryResolve } from './resolves/person-work-history.resolve';
import { PillComponent } from './components/pill/pill.component';
import { PointOfContactCardComponent } from './components/point-of-contact-card/point-of-contact-card.component';
import { PointOfContactForListComponent } from './components/point-of-contact-for-list/point-of-contact-for-list.component';
import { PointOfContactForListResolve } from './resolves/point-of-contact-for-list.resolve';
import { PointOfContactListComponent } from './components/point-of-contact-list/point-of-contact-list.component';
import { ProfessionalReferenceListComponent } from './components/professional-reference-list/professional-reference-list.component';
import { ProgressBarComponent } from './components/progress-bar.component';
import { QuestionnaireResponsePrePopulateResolve } from './resolves/questionnaire-response-prepopulate.resolve';
import { ReadAddressNewComponent } from './components/edit-address/read-address-new.component';
import { ReadFieldComponent } from './components/read-field/read-field.component';
import { ReferencesDetailComponent } from './components/references/references-detail.component';
import { ReloadableComponent } from './components/reloadable.component';
import { ReloadModalComponent } from './components/reload-modal/reload-modal.component';
import { ReopenerComponent } from './components/reopener/reopener.component';
import { RoleNameListPipe } from './pipes/role-name-list.pipe';
import { SafePipe } from './pipes/safe-url.pipe';
import { ScopeGuardService } from './services/auth/scope-guard.service';
import { SearchBarLookupComponent } from './components/search-bar-lookup/search-bar-lookup.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SelecableMenuItemsComponent } from './components/menu/selectable-menu-items.component';
import { SideNavContainerComponent } from './components/side-nav-container/side-nav-container.component';
import { SpaceCapCasePipe } from './pipes/space-cap-case.pipe';
import { SpinnerDialog } from './components/spinner-dialog/spinner.dialog';
import { SplitPipe } from './pipes/split.pipe';
import { StatusPillComponent } from './components/pill/status-pill.component';
import { StatusSelectorComponent } from './components/status-selector/status-selector.component';
import { StyleOptInDirective } from './components/style-opt-in.directive';
import { SubtitleComponent } from './components/typography/subtitle/subtitle.component';
import { SupervisorEditComponent } from './components/application/firm/supervisor-edit/supervisor-edit.component';
import { SupervisorsFormComponent } from './components/application/firm/supervisors-form/supervisors-form.component';
import { TenantSettingsProvider, NcarbSettingsProvider, EnvironmentSettingsProvider } from './services/api/tenant.service';
import { TitleComponent } from './components/typography/title/title.component';
import { TitledCardComponent } from './components/titled-card/titled-card.component';
import { TitledCardUploadWidgetComponent } from './components/titled-card-upload-widget/titled-card-upload-widget.component';
import { ToFragmentDirective } from './directives/to-fragment.directive';
import { ToggleEditFormGroupComponent } from './components/toggle-edit-form-group/toggle-edit-form-group.component';
import { UploadButtonWithFileListComponent } from 'projects/common/src/lib/components/upload-button-with-file-list/upload-button-with-file-list.component';
import { UploadListenerComponent } from './components/upload-widget/upload-listener.component';
import { UploadViewerComponent } from './components/upload-viewer/upload-viewer.component';
import { UploadWidgetComponent } from './components/upload-widget/upload-widget.component';
import { UploadWidgetDialog } from './components/upload-widget/upload-widget.dialog';
import { WholeDollarsPipe } from './pipes/whole-dollars';
import { WizardComponent, WizardStepComponent, WizardFormControlStepComponent } from './components/wizard';
import { WorkHistoryListComponent } from './components/work-history-list/work-history-list.component';
import { XhrProgressInterceptorService } from './services/xhr-progress/xhr-progress.interceptor.service';
import { XhrProgressService } from './services/xhr-progress/xhr-progress.service';
import { VerifyEmailDialog } from './components/verify-email-dialog/verify-email-dialog';
import { FirmAutocompleteComponent } from './components/firm-autocomplete/firm-autocomplete.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

const declarations = [
  ActionSelectorComponent,
  AddressComponent,
  AddressStatusComponent,
  AnOrAPipe,
  ApplicationConfirmationComponent,
  ApplicationEligibilityComponent,
  ApplicationListComponent,
  ApplicationReviewContactInfoComponent,
  ApplicationReviewPersonAddress,
  ApplicationReviewPersonalInfoComponent,
  ApplicationStatusPipe,
  ArchitectAutocompleteComponent,
  ArchSelectComponent,
  AutocompleteComponent,
  BannerComponent,
  BaseComponent,
  BlankComponent,
  BoolToYesNoPipe,
  BreadCrumbsComponent,
  CallToActionComponent,
  CaptionComponent,
  CardComponent,
  CardListHeaderComponent,
  CardListItemComponent,
  CdkDetailRowDirective,
  ChipList,
  CityStateZipPipe,
  ConfirmDialogue,
  ConfirmDialogueDirective,
  ContinuingEducationComponent,
  CurrentRolesListComponent,
  DateTimeFormatPipe,
  DialogComponent,
  DialogDirective,
  DisabledFieldComponent,
  DisplayAddressComponent,
  DisplayComponent,
  DisplayRoleIconsComponent,
  DocumentsFormComponent,
  DynamicPipe,
  EditAddressNewComponent,
  EditMonthYearComponent,
  EditNameComponent,
  EditPhoneRedesignComponent,
  EditSsnComponent,
  EditUscisComponent,
  EditVisaComponent,
  EnumToArrayPipe,
  EnvBannerComponent,
  FauxModalComponent,
  FeeItemsComponent,
  FilePillComponent,
  FilterButtonComponent,
  FilterPanelComponent,
  FirmApplicationConfirmationComponent,
  FirmDetailAddressComponent,
  FirmAutocompleteComponent,
  FirmLicenseAutocompleteComponent,
  FirmLicenseLookupOptionComponent,
  FirmLookupLinkComponent,
  FirmPointOfContactDisplayComponent,
  FirmSupervisorsListComponent,
  FirstLetterPipe,
  FormComponent,
  GroupedListComponent,
  HeadlineComponent,
  HighlightPipe,
  ImageLoaderComponent,
  ImpersonateDialog,
  InitialsCapCasePipe,
  LabelSwapperComponent,
  LabelSwapperPipe,
  LicenseStatusPillComponent,
  LicenseStatusPipe,
  ListComponent,
  ListComponent,
  LookupOptionComponent,
  LookupPipe,
  MenuSectionComponent,
  MenuWrapperComponent,
  MultiArchSelectComponent,
  NcarbDateTimeFormatPipe,
  NoteComponent,
  NotesComponent,
  NumberToTextPipe,
  OverlineComponent,
  OwnersFormComponent,
  PageHeaderComponent,
  PagerComponent,
  PastRolesListComponent,
  PaymentListComponent,
  PComponent,
  PersonDetailPersonalInfoComponent,
  PillComponent,
  PointOfContactCardComponent,
  PointOfContactForListComponent,
  PointOfContactListComponent,
  ProfessionalReferenceListComponent,
  ProgressBarComponent,
  ReadAddressNewComponent,
  ReadFieldComponent,
  ReferencesDetailComponent,
  ReloadableComponent,
  ReloadModalComponent,
  ReopenerComponent,
  RoleNameListPipe,
  SafePipe,
  SafeHtmlPipe,
  SearchBarLookupComponent,
  SectionHeaderComponent,
  SelecableMenuItemsComponent,
  SideNavContainerComponent,
  SpaceCapCasePipe,
  SpinnerDialog,
  SplitPipe,
  StatusedDataListComponent,
  StatusPillComponent,
  StatusSelectorComponent,
  StyleOptInDirective,
  SubtitleComponent,
  SupervisorEditComponent,
  SupervisorsFormComponent,
  TitleComponent,
  TitledCardComponent,
  TitledCardUploadWidgetComponent,
  ToFragmentDirective,
  ToggleEditFormGroupComponent,
  UploadButtonWithFileListComponent,
  UploadListenerComponent,
  UploadViewerComponent,
  UploadWidgetComponent,
  UploadWidgetDialog,
  VerifyEmailDialog,
  WholeDollarsPipe,
  WizardComponent,
  WizardFormControlStepComponent,
  WizardStepComponent,
  WorkHistoryListComponent,
];

const providers = [
  ApiService,
  AuthService,
  AddressService,
  ApplicationSettingsResolve,
  AuthRefreshService,
  AuthGuardService,
  ScopeGuardService,
  XhrProgressService,
  XhrProgressInterceptorService,
  AddressLookupsResolve,
  QuestionnaireResponsePrePopulateResolve,
  FirmPointOfContactResolve,
  FirmRolesResolve,
  ArchitectPointOfContactResolve,
  PersonDetailResolve,
  FirmRosterResolve,
  FirmApplicationsResolve,
  PaymentsResolve,
  FirmPaymentsResolve,
  ArchitectLicensesResolve,
  ArchitectApplicationsResolve,
  PersonWorkHistoryResolve,
  PointOfContactForListResolve,
  HomeAddressesResolve,
  WorkAddressesResolve,
  JurisdictionConstantsProvider,
  TenantSettingsProvider,
  Auth0SettingsProvider,
  NcarbSettingsProvider,
  EnvironmentSettingsProvider,
  JurisdictionConfirmationServiceProvider,
  DialogFactoryService,
  ClaimsProvider,
];

const imports = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonMaterialModule,
  TextMaskModule,
  FileUploadModule,
  NgxMatIntlTelInputModule,
];

@NgModule({
  imports: [...imports, ErrorHandlingModule.forRoot()],
  declarations,
  exports: [...declarations, InjectorModule, ...imports],
  providers,
  entryComponents: [ConfirmDialogue, ImpersonateDialog, SpinnerDialog, UploadWidgetDialog, VerifyEmailDialog],
})
export class LibModule {
  static forRoot(config: ImagePathConfig): ModuleWithProviders<LibModule> {
    return {
      ngModule: LibModule,
      providers: [{ provide: ImagePathConfig, useValue: config }],
    };
  }
}
