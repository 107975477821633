import { ISearchWithLicenseDateRanges } from './i-search';
import { Search } from './search';
import { DateRangeSelection } from './date-range-selection';

export class IndividualLicenseSearch extends Search<IndividualLicenseSearch> implements ISearchWithLicenseDateRanges {
  search?: string;
  status?: string;
  licenseType?: string;
  profession?: string;
  issueDateRange?: DateRangeSelection;
  expirationDateRange?: DateRangeSelection;
}
