import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from 'projects/common/src/lib/services/auth/auth-guard.service';
import { MainComponent } from './main.component';
import { LandingComponent } from './access/landing/landing.component';
import { ApplyAuthorizingComponent } from './access/apply-authorizing/apply-authorizing.component';
import { UnauthorizedComponent } from './access/unauthorized.component';
import { TimedoutComponent } from './access/timedout/timedout.component';
import { AccountCreatedComponent } from './access/account-created/account-created.component';
import { NotApplicableComponent } from './access/notapplicable.component';
import { ImpersonateComponent } from './access/impersonate.component';
import { FirmAvailableApplicationsResolve } from './shared/resolves/firm-available-applications.resolve';
import { ArchitectLicensesResolve } from 'projects/common/src/lib/resolves/architect-licenses.resolve';
import { ArchitectApplicationsResolve } from 'projects/common/src/lib/resolves/architect-applications.resolve';
import { PersonAvailableApplicationsResolve } from './shared/resolves/person-available-applications.resolve';
import { JustWhiteBodyComponent } from './just-white-body/just-white-body.component';
import { JustPortalBodyComponent } from './just-portal-body/just-portal-body.component';
import { PortalComponent } from './portal/portal.component';
import { FirmPortalComponent } from './firm/firm-portal.component';
import { FirmPointOfContactResolve } from 'projects/common/src/lib/resolves/firm-point-of-contact.resolve';
import { FirmResolve } from './shared/resolves/firm.resolve';
import { FirmRolesResolve } from 'projects/common/src/lib/resolves/firm-roles.resolve';
import { ClaimCheckResolve } from './portal/claim-check.resolve';
import { RouterOutletComponent } from './shared/components/router-outlet.component';
import { FirmManagerGuard } from './firm/firm-manager.guard';
import { FirmPortalRedirectComponent } from './firm/firm-portal-redirect.component';
import { FirmApplicationsResolve } from 'projects/common/src/lib/resolves/firm-applications.resolve';
import { PersonDetailResolve } from 'projects/common/src/lib/resolves/person-detail.resolve';
import { AddressLookupsResolve } from 'projects/common/src/lib/resolves/address-lookups.resolve';
import { FirmRosterResolve } from 'projects/common/src/lib/resolves/firm-roster.resolve';
import { FirmPaymentsResolve } from 'projects/common/src/lib/resolves/firm-payments.resolve';
import { PaymentsResolve } from 'projects/common/src/lib/resolves/payments.resolve';
import { FirmSelectTypeComponent } from './firm/register/firm-select-type.component';
import { ArchitectPointOfContactResolve } from 'projects/common/src/lib/resolves/architect-point-of-contact.resolve';
import { PersonPortalRedirectComponent } from './portal/person-portal-redirect.component';
import { PersonPortalGuard } from './portal/person-portal.guard';
import { PointOfContactForListResolve } from 'projects/common/src/lib/resolves/point-of-contact-for-list.resolve';
import { ApplicationResolve } from './application/resolves/application.resolve';
import { ApplicationChromeComponent } from './shared/components/application-base/application-chrome.component';
import { SupervisorEditComponent } from 'projects/common/src/lib/components/application/firm/supervisor-edit/supervisor-edit.component';
import { ReviewRoutes } from 'projects/common/src/lib/modules/application-review/application-review.module';
import { ApplicationSettingsResolve } from 'projects/common/src/lib/resolves/application-settings.resolve';

const reviewRoutes = ReviewRoutes(
  'architect/:personId/application/:applicationId/review',
  'firm/:firmId/application/:applicationId/review',
  AuthGuardService
);

const routes: Routes = [
  reviewRoutes.architectReviewRoute,
  reviewRoutes.firmReviewRoute,
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'portal' },
      {
        path: '',
        component: JustWhiteBodyComponent,
        children: [
          { path: 'authorizing', component: ApplyAuthorizingComponent },
          { path: 'landing', component: LandingComponent },
          { path: 'unauthorized', component: UnauthorizedComponent },
          { path: 'timedout', component: TimedoutComponent },
          { path: 'accountcreated', component: AccountCreatedComponent },
          { path: 'notapplicable', component: NotApplicableComponent },
          { path: 'impersonate', component: ImpersonateComponent },
        ],
      },
      {
        path: 'firm/:firmId/application/:applicationId',
        component: ApplicationChromeComponent,
        canActivate: [FirmManagerGuard],
        resolve: {
          application: ApplicationResolve,
          addressLookups: AddressLookupsResolve,
          applicationSettings: ApplicationSettingsResolve,
        },
        loadChildren: () => import('./firm/application/firm-application.module').then(m => m.FirmApplicationModule),
      },
      {
        path: 'architect/:personId/application/:applicationId',
        component: ApplicationChromeComponent,
        canActivate: [AuthGuardService],
        resolve: {
          application: ApplicationResolve,
          applicationSettings: ApplicationSettingsResolve,
          addressLookups: AddressLookupsResolve,
        },
        loadChildren: () => import('./application/architect-application.module').then(m => m.ArchitectApplicationModule),
      },
      {
        path: '',
        component: JustPortalBodyComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'portal',
            pathMatch: 'full',
            component: PersonPortalRedirectComponent,
            canActivate: [AuthGuardService],
            resolve: {
              claimCheck: ClaimCheckResolve,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'portal/:personId',
            component: RouterOutletComponent,
            canActivate: [AuthGuardService, PersonPortalGuard],
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: PortalComponent,
                canActivate: [AuthGuardService],
                resolve: {
                  person: PersonDetailResolve,
                  licenses: ArchitectLicensesResolve,
                  pointOfContacts: ArchitectPointOfContactResolve,
                  pointOfContactForList: PointOfContactForListResolve,
                  applications: ArchitectApplicationsResolve,
                  availableApplications: PersonAvailableApplicationsResolve,
                  claimCheck: ClaimCheckResolve,
                  payments: PaymentsResolve,
                  addressLookups: AddressLookupsResolve,
                },
                runGuardsAndResolvers: 'always',
              },
              {
                path: 'archpointofcontact',
                canActivate: [AuthGuardService],
                loadChildren: () =>
                  import('./portal/architect-point-of-contact.import.module').then(m => m.ArchitectPointOfContactImportModule),
              },
            ],
          },
          {
            path: 'registerFirm',
            canActivate: [AuthGuardService],
            loadChildren: () => import('./portal/register-firm/register-firm.module').then(m => m.RegisterFirmModule),
          },
          {
            path: 'firm',
            pathMatch: 'full',
            component: FirmPortalRedirectComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'firm/:firmId',
            component: RouterOutletComponent,
            canActivate: [AuthGuardService, FirmManagerGuard],
            children: [
              {
                path: 'pointofcontact',
                canActivate: [AuthGuardService],
                loadChildren: () =>
                  import('./firm/firm-point-of-contact/firm-point-of-contact.module').then(m => m.FirmPointOfContactModule),
              },
              {
                path: 'supervisorsEdit',
                canActivate: [AuthGuardService],
                component: SupervisorEditComponent,
                data: { useNewPageHeader: true, isPortal: true },
              },
              {
                path: 'switch',
                canActivate: [AuthGuardService],
                loadChildren: () => import('./firm/switch-type/switch-type.module').then(m => m.SwitchTypeModule),
              },
              {
                path: 'register',
                canActivate: [AuthGuardService],
                component: FirmSelectTypeComponent,
                resolve: {
                  firmAvailableApplications: FirmAvailableApplicationsResolve,
                },
              },
              {
                path: '',
                pathMatch: 'full',
                component: FirmPortalComponent,
                canActivate: [AuthGuardService],
                resolve: {
                  pointOfContact: FirmPointOfContactResolve,
                  roles: FirmRolesResolve,
                  firm: FirmResolve,
                  payments: FirmPaymentsResolve,
                  firmAvailableApplications: FirmAvailableApplicationsResolve,
                  applications: FirmApplicationsResolve,
                  roster: FirmRosterResolve,
                  addressLookups: AddressLookupsResolve,
                },
                runGuardsAndResolvers: 'always',
              },
            ],
          },
        ],
      },
      { path: '**', redirectTo: 'portal' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
      enableTracing: false,
    }),
  ],
  providers: [ApplicationResolve],
  exports: [RouterModule],
})
export class ApplyRoutingModule {}
