<table mat-table [dataSource]="items$">
  <ng-container matColumnDef="firmName">
    <th mat-header-cell *matHeaderCellDef>Firm</th>
    <td mat-cell *matCellDef="let workHistory">
      <a [routerLink]="route(workHistory)">{{ workHistory.firm.name }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="firmLicenseNumber">
    <th mat-header-cell *matHeaderCellDef>License #</th>
    <td mat-cell *matCellDef="let workHistory">
      <ng-container *ngIf="!!workHistory.firm.licenseNumber">
        <span
          class="status-box"
          [ngClass]="getStatusClass(workHistory)"
          attr.data-status-abbreviation="{{ getStatusDisplay(workHistory) | firstLetter }}"
        ></span>
        <a [routerLink]="route(workHistory)">{{ workHistory.firm.licenseNumber }}</a>
      </ng-container>
      <ng-container *ngIf="!workHistory.firm.licenseNumber">
        <span class="not-registered">Not Registered</span>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef>Start</th>
    <td mat-cell *matCellDef="let workHistory">
      {{ workHistory.startDate | date: 'shortDate' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="dateSeparator">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let workHistory">—</td>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef>End</th>
    <td mat-cell *matCellDef="let workHistory">
      {{ workHistory.endDate != null ? (workHistory.endDate | date: 'shortDate') : 'Present' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let workHistory">{{ workHistory.title }}</td>
  </ng-container>

  <ng-container matColumnDef="menu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let workHistory">
      <mat-icon class="muted-text" [matMenuTriggerFor]="optionsMenu">more_horiz</mat-icon>
      <mat-menu #optionsMenu="matMenu">
        <button mat-button type="button" (click)="edit(workHistory)">Edit Work Experience</button>
        <button mat-button type="button" (click)="delete(workHistory)">Delete Work Experience</button>
      </mat-menu>
    </td>
  </ng-container>

  <ng-container matColumnDef="noItems">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">No records found</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: ['noItems']; when: noItems"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columns"></tr>
</table>
<lib-confirm
  #confirmDelete="confirm"
  yesText="Yes, delete"
  noText="No, cancel"
  (yes)="deleteWorkHistory(workHistoryToDelete)"
  [template]="confirmWorkHistoryDeletionTemplate"
></lib-confirm>
<ng-template #confirmWorkHistoryDeletionTemplate>
  <p>Are you sure you want to delete this work experience?</p>
  <p>
    {{ workHistoryToDelete.firm.name }}: {{ workHistoryToDelete.startDate | date: 'shortDate' }} to
    {{ workHistoryToDelete.endDate | date: 'shortDate' }}
  </p>
</ng-template>
