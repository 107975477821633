import { Search } from './search';
import { IArchitectSearch } from './i-architect-search';

export class ArchitectLicenseSearch extends Search<ArchitectLicenseSearch> implements IArchitectSearch {
  search?: string;
  since?: string;
  until?: string;
  dateTarget?: string;
  status?: string;
  profession?: string;
}
