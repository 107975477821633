export class Mutex {
  private locked = false;

  tryAcquire(): boolean {
    if (this.locked) {
      return false;
    }
    this.locked = true;
    return true;
  }

  release() {
    this.locked = false;
  }
}
