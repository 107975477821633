import { Component, Input } from '@angular/core';
import { FirmRoleHolder } from 'projects/common/src/lib/models/firm-role-holder';
import { ListComponent } from 'projects/common/src/lib/components/list';
import { RoleInfo } from 'projects/common/src/lib/models/firm-role-holder-groups';

@Component({
  selector: 'current-roles-list',
  templateUrl: './current-roles-list.component.html',
  styleUrls: ['./current-roles-list.component.scss'],
})
export class CurrentRolesListComponent extends ListComponent<FirmRoleHolder> {
  @Input() columns: string[] = ['licenseNumber', 'name', 'role', 'email'];
  @Input() linkedName: boolean = true;
  @Input() activeRolesByPersonId: Record<string, RoleInfo[]>;

  constructor() {
    super();
  }
}
