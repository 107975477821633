import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirmLicense } from '../../models/firm-license';
import { downloadAll } from '../../models/download-file-base64';
import { DownloadFilesBase64 } from '../../models/download-files-base64';

@Injectable({
  providedIn: 'root',
})
export class FirmLicenseService {
  constructor(private api: ApiService) {}

  getPath(firmId: string) {
    return `firm/${firmId}/license/`;
  }

  get(firmId: string): Observable<FirmLicense> {
    let path = this.getPath(firmId);
    return this.api.get(path).pipe(map(data => (data && data.id ? new FirmLicense(data) : null)));
  }

  getPreview = (firmId: string): Promise<FirmLicense> =>
    this.api.getAsync<any>(`${this.getPath(firmId)}preview`).then(json => (json && json.id ? new FirmLicense(json) : null));

  //issue, renew, or reinstate license
  approveLicense = (firmId: string, license: FirmLicense): Promise<FirmLicense> =>
    this.api.postAsync<FirmLicense>(this.getPath(firmId), { number: license.number }).then(json => new FirmLicense(json));

  updateStatus = (license: FirmLicense, newLicenseStatus: string): Promise<any> =>
    this.api.patchAsync(this.getPath(license.firm.id), {
      ...license,
      status: newLicenseStatus,
    });

  sendLicense(firmId: string): Promise<boolean> {
    return this.api
      .postAsync<any>(`${this.getPath(firmId)}send`, {}, true, { observe: 'response' })
      .then(resp => resp.status == 200);
  }

  download = (firmId: string): Promise<boolean> => {
    return this.api.getAsync(`${this.getPath(firmId)}download`).then((result: DownloadFilesBase64) => {
      downloadAll(result.files);
      return true;
    });
  };
}
