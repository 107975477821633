import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RegexPatterns } from 'projects/common/src/lib/constants/regex-patterns';
import { ArchitectLicense } from 'projects/common/src/lib/models/architect-license';
import { FirmApplicationPrincipal } from 'projects/common/src/lib/models/firm-application-principal';
import { SearchApiService } from 'projects/common/src/lib/services/api/search/search-api.service';

@Component({
  selector: 'lib-arch-select',
  templateUrl: './arch-select.component.html',
  styleUrls: ['./arch-select.component.scss'],
})
export class ArchSelectComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() role: string;
  @Input() removable: boolean;
  @Input() alreadySelectedPersonIds?: string[];
  @Input() readonly: boolean;
  @Input() supervisorLabel: string;
  @Output() cleared = new EventEmitter();

  justDeselectedPersonId: string;
  label: string;
  nameControl: FormControl;
  licenseNumberControl: FormControl;
  personIdControl: FormControl;
  @ViewChild('name') nameElementRef: ElementRef;
  patterns = RegexPatterns;
  constructor(private searchApiService: SearchApiService) {}

  ngOnInit() {
    this.label = this.getLabel();
    this.nameControl = this.formGroup.controls.name as FormControl;
    this.licenseNumberControl = this.formGroup.controls.licenseNumber as FormControl;
    this.personIdControl = this.formGroup.controls.personId as FormControl;

    this.licenseNumberControl.disable();
  }

  getLabel() {
    switch (this.role) {
      case 'Owner':
        return 'Owner’s Name';
      case 'Supervisor':
        return this.supervisorLabel ?? 'Supervising Professional Architect';
      case 'Director':
        return 'Architect Director’s Name';
      case 'Shareholder':
        return 'Architect’s Name';
    }
  }

  autocompleteSearch = name =>
    this.searchApiService.getArchitectLicenseLookup(name, null, null, 'GoodStandingOrDelinquentOrProbation');

  optionDisabled = (license: ArchitectLicense) => this.alreadySelected(license);

  licenseToName(architectLicense: ArchitectLicense) {
    let name = (architectLicense && architectLicense.person && architectLicense.person.name.firstLast) || architectLicense;
    return name;
  }

  licenseSelected(selectedEvent: MatAutocompleteSelectedEvent) {
    let principal = FirmApplicationPrincipal.FromArchitectLicense(selectedEvent.option.value, this.role);
    this.formGroup.patchValue(principal);
  }

  onCleared() {
    this.justDeselectedPersonId = this.personIdControl.value;
    this.licenseNumberControl.setValue('');
    this.personIdControl.setValue('');
  }

  clear() {
    this.cleared.emit();
  }

  alreadySelected(license: ArchitectLicense) {
    if (!license.person) {
      return false;
    }
    let personId = license.person.id;
    if (personId == this.justDeselectedPersonId) {
      return false;
    }
    return this.alreadySelectedPersonIds && this.alreadySelectedPersonIds.some(id => id == personId);
  }
}
