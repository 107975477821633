import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { isProdModeEnabled } from 'projects/common/src/lib/constants/environment';
import { initDataDogLogging } from 'projects/common/src/lib/utility/datadog';
import { AppApplyModule } from './app/app-apply.module';

if (isProdModeEnabled()) {
  enableProdMode();
}

initDataDogLogging();

platformBrowserDynamic()
  .bootstrapModule(AppApplyModule)
  .catch(err => console.log(err));
