<lib-titled-card>
  <h2>Preferences</h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
  </aside>
  <form [formGroup]="preferencesForm" class="no-underlines">
    <div class="flex-fields">
      <mat-form-field appearance="standard">
        <mat-label>Mailing Address</mat-label>
        <input [class.d-none]="isEditing" matInput formControlName="sendMailTo" readonly />
        <div [class.d-none]="!isEditing">
          <mat-radio-group formControlName="sendMailTo">
            <mat-error
              *ngIf="preferencesForm.controls.sendMailTo.touched && preferencesForm.controls.sendMailTo.hasError('required')"
            >
              Please select your mailing address preference
            </mat-error>
            <mat-radio-button color="primary" value="Home"> Home </mat-radio-button>
            <mat-radio-button color="primary" value="Work" class="pl-medium"> Work </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-form-field>
    </div>
  </form>
</lib-titled-card>
