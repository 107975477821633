<lib-titled-card *ngIf="true">
  <h2>Address</h2>
  <mat-menu *ngIf="!isEditing">
    <button mat-button type="button" (click)="edit()">Edit</button>
  </mat-menu>
  <aside *ngIf="isEditing">
    <button class="auto alt" mat-button type="button" (click)="save()">Save</button>
    <button class="auto alt-cancel" mat-button type="button" (click)="cancel()">Cancel</button>
  </aside>
  <form [formGroup]="form" [class.no-underlines]="!isEditing">
    <lib-address
      [formGroup]="addressForm"
      [editing]="isEditing"
      [line1Label]="settings.firmProfileSettings.addressFirstLineLabel"
      [line2Label]="settings.firmProfileSettings.addressSecondLineLabel"
      [hideCounty]="settings.firmProfileSettings.hideCounty"
    ></lib-address>
  </form>
</lib-titled-card>
