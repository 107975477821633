import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base.component';
import { FirmPointOfContact } from '../../models/firm-point-of-contact';

@Component({
  selector: 'lib-firm-point-of-contact-display',
  templateUrl: './firm-point-of-contact-display.component.html',
  styleUrls: ['./firm-point-of-contact-display.component.scss'],
})
export class FirmPointOfContactDisplayComponent extends BaseComponent implements OnInit {
  @Input() pointOfContact$: Observable<FirmPointOfContact>;
  @Input() showUnderlines: boolean;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.unsubOnDestroy = this.pointOfContact$.subscribe(poc => this.loadPointOfContact(poc));
  }

  loadPointOfContact(poc: FirmPointOfContact) {
    this.form = FirmPointOfContact.GetFormGroup(this.formBuilder);
    if (poc) {
      poc.patch(this.form);
    }
  }
}
