import { ViewContainerRef, Directive, Component, Input, ViewChild } from '@angular/core';

@Directive({ selector: '[appApplicationStep]' })
export class ApplicationStepDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'app-application-step-component',
  template: ` <ng-template appApplicationStep></ng-template> `,
})
export class ApplicationStepPlaceholderComponent {
  @Input() stepName = '';
  @ViewChild(ApplicationStepDirective, { read: ViewContainerRef }) container: ViewContainerRef;
}
