import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { FirmApplicationPrincipal } from 'projects/common/src/lib/models/firm-application-principal';
import { TenantSettings } from 'projects/common/src/lib/constants/jurisdiction/TenantSettings';

@Component({
  selector: 'lib-multi-arch-select',
  templateUrl: './multi-arch-select.component.html',
  styleUrls: ['./multi-arch-select.component.scss'],
})
export class MultiArchSelectComponent implements OnInit {
  @Input() formArray: FormArray;
  @Input() role: string;
  @Input() allowNone: boolean;
  @Input() readonly: boolean;
  @Input() maximumSelectable: number = 0;
  @Input() supervisorLabel: string;

  label: string;
  constructor(
    private formBuilder: FormBuilder,
    public settings: TenantSettings
  ) {}

  ngOnInit() {
    this.label = {
      Owner: 'Owner',
      Supervisor: this.supervisorLabel ?? 'Supervising Professional Architect',
      Director: `${this.settings.stateName} Licensed Architect Director`,
      Shareholder: 'Architect Shareholder',
    }[this.role];

    if (!this.formArray.length && !this.allowNone) {
      this.addAnother();
    }
  }

  addAnother() {
    let group = FirmApplicationPrincipal.GetFormGroup(this.formBuilder, this.role);
    this.formArray.push(group);
    return false;
  }

  get selectedPersonIds() {
    return this.formArray.controls.map(c => c.value.personId);
  }

  showAddAnother(): boolean {
    const count = this.formArray?.length ?? 0;
    return !this.readonly && (this.maximumSelectable === 0 || count < this.maximumSelectable);
  }
}
