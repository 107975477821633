import { AvailableApplication } from './available-application';

export class ArchitectAvailableApplication extends AvailableApplication {
  profession: string;

  update(init?: Partial<ArchitectAvailableApplication>): void {
    super.update(init);
    this.profession = init.profession;
  }
}
