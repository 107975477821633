import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ListComponent } from '../list';
import { ConfirmDialogueDirective } from '../confirm-dialogue/confirm.dialogue.directive';
import { ProfessionalReference } from '../../models/professional-reference';

@Component({
  selector: 'lib-professional-reference-list',
  templateUrl: './professional-reference-list.component.html',
  styleUrls: ['./professional-reference-list.component.scss'],
})
export class ProfessionalReferenceListComponent extends ListComponent<ProfessionalReference> {
  @Output() onDelete = new EventEmitter<ProfessionalReference>();
  @Output() onEdit = new EventEmitter<ProfessionalReference>();
  @ViewChild(ConfirmDialogueDirective) confirmDeleteDirective: ConfirmDialogueDirective;
  columns = ['name', 'email', 'dateReceived', 'menu'];

  edit(reference: ProfessionalReference) {
    this.onEdit.emit(reference);
  }

  referenceToDelete: ProfessionalReference;
  confirmDelete(reference: ProfessionalReference) {
    this.referenceToDelete = reference;
    this.confirmDeleteDirective.open();
  }

  delete() {
    this.onDelete.emit(this.referenceToDelete);
  }

  getName(tableRef: any): string {
    // Create a new ProfessionalReference object in order to use
    // the name getter, otherwise the list row template doesn't use the getter
    // and the name property doesn't exist on a anonymous json object
    return !!tableRef ? new ProfessionalReference(tableRef).name : undefined;
  }
}
