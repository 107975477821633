<ls-breadcrumbs [links]="breadcrumbs"></ls-breadcrumbs>
<ls-page-header>{{ applicationSettings?.typeName }} Application</ls-page-header>
<!--ngcontent is only for storybook-->
<ng-content></ng-content>
<router-outlet></router-outlet>

<div class="footer pt-x-large" *ngIf="settings.showApplicationFooter">
  <mat-divider></mat-divider>
  <ls-caption class="ls-text-grey p-medium">
    <span [innerHTML]="constants.legal"></span>
  </ls-caption>
</div>
