import { Component, ElementRef, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LabelService } from './label.service';

//it might not be necessary to use this
//You can write it like this: [innerHTML]="'review-footer' | labelSwapper: 'content' : true | async"
//using this would be like
//<div label-swapper labelName="review-footer" [isHtml]="true"></div>
@Component({
  selector: '[label-swapper]',
  template: '',
})
export class LabelSwapperComponent implements OnInit {
  @Input() labelName: string;
  @Input() isHtml: boolean = false;
  @Input() labelType: string = 'content';
  @Input() params: any;

  constructor(
    private element: ElementRef<HTMLElement>,
    private labelService: LabelService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    const result = await this.labelService.getLabel({
      contentKey: this.labelName,
      contentType: this.labelType,
    });

    this.element.nativeElement.innerHTML = this.isHtml ? this.sanitize(result) : result;
  }

  private sanitize = (result: string) => {
    //saw the element.nativeElement.innerHTML technique in https://github.com/jfcere/ngx-markdown/blob/3769a4b7a3644543e53cf9bc5d8bfdab32a9a4c1/lib/src/markdown.component.ts#L178
    const safeHtml = this.sanitizer.bypassSecurityTrustHtml(result);
    return this.sanitizer.sanitize(SecurityContext.HTML, safeHtml);
  };
}
